/* eslint-disable */
const DEFS = {
  "demographics": {
    "pageDataKey": "demographics",
    "pIndex": "1",
    "hasGridForm": true,
    "pageChildren": [
      /*{
        "elementKey": "givenName",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "fqn": "demographics.givenName"
      },
      {
        "elementKey": "middleName",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.middleName"
      },
      {
        "elementKey": "preferredName",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "fqn": "demographics.preferredName"
      },
      {
        "elementKey": "pronouns",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "she",
            "text": "She/Her/Hers"
          },
          {
            "key": "he",
            "text": "He/Him/His"
          },
          {
            "key": "they",
            "text": "They/Them/Theirs"
          },
          {
            "key": "ze",
            "text": "Ze/Hir/Hirs"
          },
          {
            "key": "none",
            "text": "I do not use a pronoun"
          },
          {
            "key": "other",
            "text": "Other, please ask"
          },
          {
            "key": "all",
            "text": "I use all gender pronouns"
          }
        ],
        "fqn": "demographics.pronouns"
      },*/
      {
        "elementKey": "familyName",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "fqn": "demographics.familyName"
      },
      {
        "elementKey": "mrn",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.mrn"
      },
      {
        "elementKey": "personAge",
        "formIndex": "1",
        "inputType": "personAge",
        "fqn": "demographics.personAge"
      },
      {
        "elementKey": "dateOfBirth",
        "dependentOn": "age:personAge",
        "formIndex": "1",
        "inputType": "mainDOB",
        "fqn": "demographics.dateOfBirth"
      },
      {
        "elementKey": "languagePrimary",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "普通话",
            "text": "普通话"
          },
          {
            "key": "方言",
            "text": "方言"
          },
          {
            "key": "英语",
            "text": "英语"
          },
          {
            "key": "其他",
            "text": "其他"
          }
          /*{
            "key": "Chinese",
            "text": "Chinese"
          },
          {
            "key": "Other",
            "text": "Other"
          }*/
        ],
        "fqn": "demographics.languagePrimary"
      },
      /*{
        "elementKey": "birthSex",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "male",
            "text": "Male"
          },
          {
            "key": "fem",
            "text": "Female"
          },
          {
            "key": "inter",
            "text": "Intersex"
          }
        ],
        "fqn": "demographics.birthSex"
      },*/
      {
        "elementKey": "gender",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "未知",
            "text": "未知"
          },
          {
            "key": "女性",
            "text": "女性"
          },
          {
            "key": "男性",
            "text": "男性"
          }
          /*{
            "key": "Transgender female",
            "text": "Transgender female"
          },
          {
            "key": "Transgender male",
            "text": "Transgender male"
          },
          {
            "key": "Undifferentiated",
            "text": "Undifferentiated"
          },
          {
            "key": "Prefer not to say",
            "text": "Prefer not to say"
          }*/
        ],
        "fqn": "demographics.gender"
      },
      /*{
        "elementKey": "legalGender",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "M",
            "text": "M"
          },
          {
            "key": "F",
            "text": "F"
          },
          {
            "key": "X",
            "text": "X"
          }
        ],
        "fqn": "demographics.legalGender"
      },*/
      {
        "elementKey": "height",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "cm",
        "fqn": "demographics.height",
        "suffixText": "cm"
      },
      {
        "elementKey": "weight",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "kg",
        "fqn": "demographics.weight",
        "suffixText": "kg"
      },
      {
        "elementKey": "religion",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.religion"
      },
      {
        "elementKey": "martialStatus",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "已婚",
            "text": "已婚"
          },
          {
            "key": "未婚",
            "text": "未婚"
          },
          {
            "key": "同居",
            "text": "同居"
          },
          {
            "key": "离婚",
            "text": "离婚"
          },
          {
            "key": "分居",
            "text": "分居"
          },
          {
            "key": "丧偶",
            "text": "丧偶"
          }
        ],
        "fqn": "demographics.martialStatus"
      },
      /*{
        "elementKey": "indigenousIdentifyAs",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Yes",
            "text": "Yes"
          },
          {
            "key": "No",
            "text": "No"
          }
        ],
        "fqn": "demographics.indigenousIdentifyAs"
      },*/
      {
        "elementKey": "streetAddress",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "fqn": "demographics.streetAddress"
      },
      {
        "elementKey": "city",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.city"
      },
      {
        "elementKey": "country",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.country"
      },
      {
        "elementKey": "postalCode",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.postalCode"
      },
      {
        "elementKey": "noAddress",
        "defaultValue": "FALSE",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "demographics.noAddress"
      },
      {
        "elementKey": "phoneNumber",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.phoneNumber"
      },
      {
        "elementKey": "emailAddress",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.emailAddress"
      },
      {
        "elementKey": "occupationStudent",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.occupationStudent"
      },
      {
        "elementKey": "phn",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.phn"
      },
      /*{
        "elementKey": "patientService",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.patientService"
      },*/
      {
        "elementKey": "nextOfKinName",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.nextOfKinName"
      },
      {
        "elementKey": "nextOfKinRelationship",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "妻子",
            "text": "妻子"
          },
          {
            "key": "丈夫",
            "text": "丈夫"
          },
          {
            "key": "母亲",
            "text": "母亲"
          },
          {
            "key": "父亲",
            "text": "父亲"
          },
          {
            "key": "监护人",
            "text": "监护人"
          },
          {
            "key": "姐妹",
            "text": "姐妹"
          },
          {
            "key": "兄弟",
            "text": "兄弟"
          },
          {
            "key": "女儿",
            "text": "女儿"
          },
          {
            "key": "儿子",
            "text": "儿子"
          },
          {
            "key": "阿姨",
            "text": "阿姨"
          },
          {
            "key": "叔叔",
            "text": "叔叔"
          },
          {
            "key": "奶奶",
            "text": "奶奶"
          },
          {
            "key": "爷爷",
            "text": "爷爷"
          },
          {
            "key": "朋友",
            "text": "朋友"
          },
          {
            "key": "其他",
            "text": "其他"
          }
        ],
        "fqn": "demographics.nextOfKinRelationship"
      },
      {
        "elementKey": "nextOfKinPhone",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.nextOfKinPhone"
      },
      {
        "elementKey": "decisionMakerName",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.decisionMakerName"
      },
      {
        "elementKey": "decisionMakerRelationship",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "配偶",
            "text": "配偶"
          },
          {
            "key": "子女",
            "text": "子女"
          },
          {
            "key": "父母",
            "text": "父母"
          },
          {
            "key": "兄弟姐妹",
            "text": "兄弟姐妹"
          },
          {
            "key": "祖父母",
            "text": "祖父母"
          },
          {
            "key": "孙子孙女",
            "text": "孙子孙女"
          },
          {
            "key": "朋友",
            "text": "朋友"
          },
          {
            "key": "伴侣",
            "text": "伴侣"
          },
          {
            "key": "监护人",
            "text": "监护人"
          },
          {
            "key": "其他",
            "text": "其他"
          }
        ],
        "fqn": "demographics.decisionMakerRelationship"
      },
      {
        "elementKey": "decisionMakerPhone",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "demographics.decisionMakerPhone"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "demographics.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "personGroup",
            "formCss": "section-divider grid-left-to-right-3",
            "fqn": "demographics.personGroup",
            "gIndex": "1",
            "gChildren": [
              /*"givenName",
              "middleName",
              "preferredName",
              "pronouns",*/
              "familyName",
              "mrn",
              "personAge",
              "dateOfBirth",
              "languagePrimary",
              /*"birthSex",*/
              "gender",
              /*"legalGender",*/
              "height",
              "weight",
              "religion",
              "martialStatus",
              /*"indigenousIdentifyAs"*/
            ]
          },
          {
            "elementKey": "addressGroup",
            "formCss": "section-divider grid-left-to-right-3",
            "fqn": "demographics.addressGroup",
            "gIndex": "2",
            "gChildren": [
              "streetAddress",
              "city",
              "country",
              "postalCode",
              "noAddress",
              "phoneNumber",
              "emailAddress",
              "occupationStudent",
              "phn",
              /*"patientService"*/
            ]
          },
          {
            "elementKey": "kinGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "demographics.kinGroup",
            "gIndex": "3",
            "gChildren": [
              "nextOfKinName",
              "nextOfKinRelationship",
              "nextOfKinPhone",
              "decisionMakerName",
              "decisionMakerRelationship",
              "decisionMakerPhone"
            ]
          }
        ]
      }
    }
  },
  "allergies": {
    "pageDataKey": "allergies",
    "pIndex": "2",
    "formOption": "no-tabs",
    "hasGridTable": true,
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "checkbox",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "allergies.checkbox"
      },
      {
        "elementKey": "text",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "allergies.text"
      },
      {
        "elementKey": "allergyList_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "allergies.name"
      },
      {
        "elementKey": "allergyList_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "allergyList_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "allergies.profession"
      },
      {
        "elementKey": "allergyList_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "allergies.day"
      },
      {
        "elementKey": "allergyList_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "allergies.time"
      },
      {
        "elementKey": "allergen",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "allergies.allergen"
      },
      {
        "elementKey": "reaction",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "allergies.reaction"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "allergies.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "formGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "allergies.formGroup",
            "gIndex": "1",
            "gChildren": [
              "checkbox",
              "text"
            ]
          }
        ]
      },
      "allergyList": {
        "elementKey": "allergyList",
        "pageElementIndex": "2",
        "pageKey": "allergies",
        "tableKey": "allergyList",
        "isTable": true,
        "form": {
          "elementKey": "allergyList",
          "label": "Allergies",
          "addButtonText": "Add an allergy",
          "formKey": "allergyList",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "allergies.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "allergyList_name",
                "allergyList_profession",
                "allergyList_day",
                "allergyList_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-3",
              "fqn": "allergies.cGroup",
              "gIndex": "2",
              "gChildren": [
                "allergen",
                "reaction"
              ]
            }
          ],
          "ehr_data": {
            "allergyList_name": "",
            "allergyList_profession": "",
            "allergyList_day": "",
            "allergyList_time": "",
            "allergen": "",
            "reaction": ""
          }
        },
        "fqn": "allergies.allergyList",
        "tableChildren": [
          "allergyList_id",
          "allergyList_day",
          "allergyList_time",
          "allergen",
          "reaction",
          "allergyList_name",
          "allergyList_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medical": {
    "pageDataKey": "medical",
    "pIndex": "3",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "history",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "medical.history"
      },
      {
        "elementKey": "xianbingshi",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "medical.xianbingshi"
      },
      {
        "elementKey": "jiwangshi",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "medical.jiwangshi"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "medical.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "medical.cGroup",
            "gIndex": "1",
            "gChildren": [
              "history",
              "xianbingshi",
              "jiwangshi"
            ]
          }
        ]
      }
    }
  },
  "psychosocial": {
    "pageDataKey": "psychosocial",
    "pIndex": "4",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "history",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "psychosocial.history"
      },
      {
        "elementKey": "diet",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "psychosocial.diet"
      },
      {
        "elementKey": "livingSituation",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "psychosocial.livingSituation"
      },
      {
        "elementKey": "employmentSchool",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "psychosocial.employmentSchool"
      },
     /* {
        "elementKey": "hobbies",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "psychosocial.hobbies"
      },*/
      {
        "elementKey": "exercise",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "psychosocial.exercise"
      },
      {
        "elementKey": "exerciseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.exerciseText"
      },
      {
        "elementKey": "exerciseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.exerciseSpacer"
      },
      /*{
        "elementKey": "caffeineUse",
        "formIndex": "1",
        "inputType": "checkbox",
        "fqn": "psychosocial.caffeineUse"
      },
      {
        "elementKey": "caffeineUseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.caffeineUseText"
      },
      {
        "elementKey": "caffeineUseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.caffeineUseSpacer"
      },*/
      {
        "elementKey": "tobaccoUse",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "psychosocial.tobaccoUse"
      },
      {
        "elementKey": "tobaccoUseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.tobaccoUseText"
      },
      {
        "elementKey": "tobaccoUseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.tobaccoUseSpacer"
      },
      /*{
        "elementKey": "cannabisUse",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "psychosocial.cannabisUse"
      },
      {
        "elementKey": "cannabisUseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.cannabisUseText"
      },
      {
        "elementKey": "cannabisUseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.cannabisUseSpacer"
      },*/
      {
        "elementKey": "alcoholUse",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "psychosocial.alcoholUse"
      },
      {
        "elementKey": "alcoholUseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.alcoholUseText"
      },
      {
        "elementKey": "alcoholUseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.alcoholUseSpacer"
      },
      {
        "elementKey": "substanceUse",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "psychosocial.substanceUse"
      },
      {
        "elementKey": "substanceUseText",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "text",
        "fqn": "psychosocial.substanceUseText"
      },
      {
        "elementKey": "substanceUseSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "psychosocial.substanceUseSpacer"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "psychosocial.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "psychosocial.cGroup",
            "gIndex": "1",
            "gChildren": [
              "history",
              "diet",
              "livingSituation",
              "employmentSchool",
              /*"hobbies"*/
            ]
          },
          {
            "elementKey": "cGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "psychosocial.cGroup2",
            "gIndex": "2",
            "gChildren": [
              "exercise",
              "exerciseText",
              "exerciseSpacer",
              /*"caffeineUse",
              "caffeineUseText",
              "caffeineUseSpacer",*/
              "tobaccoUse",
              "tobaccoUseText",
              "tobaccoUseSpacer",
              /*"cannabisUse",
              "cannabisUseText",
              "cannabisUseSpacer",*/
              "alcoholUse",
              "alcoholUseText",
              "alcoholUseSpacer",
              "substanceUse",
              "substanceUseText",
              "substanceUseSpacer"
            ]
          }
        ]
      }
    }
  },
  'surgical': {
    'pageDataKey': 'surgical',
    'pIndex': '5',
    'hasGridForm': true,
    'pageChildren': [
      {
        'elementKey': 'xinlizhuangkuang',
        'formIndex': '1',
        'inputType': 'textarea',
        'label': '心理状况',
        'fqn': 'surgical.xinlizhuangkuang'
      },
      {
        'elementKey': 'shehuizhuangkuang',
        'formIndex': '1',
        'inputType': 'textarea',
        'label': '社会状况',
        'fqn': 'surgical.shehuizhuangkuang'
      }
    ],
    'pageElements': {
      'pageForm': {
        'elementKey': 'pageForm',
        'pageElementIndex': '1',
        'formKey': 'pageForm',
        'isPageForm': true,
        'ehr_groups': [
          {
            'elementKey': 'ehr_group13',
            'formCss': 'grid-left-to-right-1',
            'gIndex': '1',
            'gChildren': [
              'xinlizhuangkuang',
              'shehuizhuangkuang'
            ]
          }
        ]
      }
    }
  },
  "immunization": {
    "pageDataKey": "immunization",
    "pIndex": "6",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "hepA",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.hepA"
      },
      {
        "elementKey": "hepADate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.hepADate"
      },
      {
        "elementKey": "hepASpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.hepASpacer"
      },
      {
        "elementKey": "hepB",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.hepB"
      },
      {
        "elementKey": "hepBDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.hepBDate"
      },
      {
        "elementKey": "hepBSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.hepBSpacer"
      },
      {
        "elementKey": "mmr",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.mmr"
      },
      {
        "elementKey": "mmrDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.mmrDate"
      },
      {
        "elementKey": "mmrSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.mmrSpacer"
      },
      {
        "elementKey": "pneumococcal",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.pneumococcal"
      },
      {
        "elementKey": "pneumococcalDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.pneumococcalDate"
      },
      {
        "elementKey": "pneumococcalSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.pneumococcalSpacer"
      },
      {
        "elementKey": "polioDiptheriaPertussis",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.polioDiptheriaPertussis"
      },
      {
        "elementKey": "polioDiptheriaPertussisDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.polioDiptheriaPertussisDate"
      },
      {
        "elementKey": "polioDiptheriaPertussisSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.polioDiptheriaPertussisSpacer"
      },
      {
        "elementKey": "tb",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.tb"
      },
      {
        "elementKey": "tbDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.tbDate"
      },
      {
        "elementKey": "tbSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.tbSpacer"
      },
      {
        "elementKey": "tetanus",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.tetanus"
      },
      {
        "elementKey": "tetanusDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.tetanusDate"
      },
      {
        "elementKey": "tetanusSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.tetanusSpacer"
      },
      {
        "elementKey": "covid",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.covid"
      },
      {
        "elementKey": "covidDate",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.covidDate"
      },
      {
        "elementKey": "covidSpacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.covidSpacer"
      },
      {
        "elementKey": "other1",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.other1"
      },
      {
        "elementKey": "other1Date",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.other1Date"
      },
      {
        "elementKey": "other1Spacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.other1Spacer"
      },
      {
        "elementKey": "other2",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.other2"
      },
      {
        "elementKey": "other2Date",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.other2Date"
      },
      {
        "elementKey": "other2Spacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.other2Spacer"
      },
      {
        "elementKey": "other3",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "immunization.other3"
      },
      {
        "elementKey": "other3Date",
        "formIndex": "1",
        "formOption": "hideLabel",
        "inputType": "date",
        "fqn": "immunization.other3Date"
      },
      {
        "elementKey": "other3Spacer",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "immunization.other3Spacer"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "immunization.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "immunization.cGroup",
            "gIndex": "1",
            "gChildren": [
              "hepA",
              "hepADate",
              "hepASpacer",
              "hepB",
              "hepBDate",
              "hepBSpacer",
              "mmr",
              "mmrDate",
              "mmrSpacer",
              "pneumococcal",
              "pneumococcalDate",
              "pneumococcalSpacer",
              "polioDiptheriaPertussis",
              "polioDiptheriaPertussisDate",
              "polioDiptheriaPertussisSpacer",
              "tb",
              "tbDate",
              "tbSpacer",
              "tetanus",
              "tetanusDate",
              "tetanusSpacer",
              "covid",
              "covidDate",
              "covidSpacer",
              "other1",
              "other1Date",
              "other1Spacer",
              "other2",
              "other2Date",
              "other2Spacer",
              "other3",
              "other3Date",
              "other3Spacer"
            ]
          }
        ]
      }
    }
  },
  "familyHistory": {
    "pageDataKey": "familyHistory",
    "pIndex": "7",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "history",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "familyHistory.history"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "familyHistory.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "familyHistory.cGroup",
            "gIndex": "1",
            "gChildren": [
              "history"
            ]
          }
        ]
      }
    }
  },
  "careTeam": {
    "pageDataKey": "careTeam",
    "pIndex": "8",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "gerenshi",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "careTeam.gerenshi"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "careTeam.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "careTeam.cGroup",
            "gIndex": "1",
            "gChildren": [
              "gerenshi"
            ]
          }
        ]
      }
    }
  },
  "pastAppointments": {
    "pageDataKey": "pastAppointments",
    "pIndex": "9",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "encounterDate",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "pastAppointments.encounterDate"
      },
      {
        "elementKey": "encounters_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "site",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "pastAppointments.site"
      },
      {
        "elementKey": "diagnosis",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "pastAppointments.diagnosis"
      },
      {
        "elementKey": "mrp",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "pastAppointments.mrp"
      },
      {
        "elementKey": "appointmentDate",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "pastAppointments.appointmentDate"
      },
      {
        "elementKey": "outpatientAppointments_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "site",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "pastAppointments.site"
      },
      {
        "elementKey": "reasonForVisit",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "pastAppointments.reasonForVisit"
      },
      {
        "elementKey": "diagnosis",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "pastAppointments.diagnosis"
      }
    ],
    "pageElements": {
      "encounters": {
        "elementKey": "encounters",
        "pageElementIndex": "1",
        "pageKey": "pastAppointments",
        "tableKey": "encounters",
        "isTable": true,
        "form": {
          "elementKey": "encounters",
          "label": "Past encounters",
          "addButtonText": "Add a past encounter",
          "formKey": "encounters",
          "ehr_groups": [
            {
              "elementKey": "encounters",
              "formCss": "grid-left-to-right-3",
              "fqn": "pastAppointments.encounters",
              "gIndex": "1",
              "gChildren": [
                "encounterDate",
                "site",
                "diagnosis",
                "mrp"
              ]
            }
          ],
          "ehr_data": {
            "encounterDate": "",
            "site": "",
            "diagnosis": "",
            "mrp": ""
          }
        },
        "fqn": "pastAppointments.encounters",
        "tableChildren": [
          "encounters_id",
          "diagnosis",
          "mrp",
          "encounterDate",
          "site"
        ]
      },
      "outpatientAppointments": {
        "elementKey": "outpatientAppointments",
        "pageElementIndex": "2",
        "pageKey": "pastAppointments",
        "tableKey": "outpatientAppointments",
        "isTable": true,
        "form": {
          "elementKey": "outpatientAppointments",
          "label": "Past outpatient appointments",
          "addButtonText": "Add an outpatient appointment",
          "formKey": "outpatientAppointments",
          "ehr_groups": [
            {
              "elementKey": "outpatientAppointments",
              "formCss": "grid-left-to-right-3",
              "fqn": "pastAppointments.outpatientAppointments",
              "gIndex": "1",
              "gChildren": [
                "appointmentDate",
                "site",
                "reasonForVisit",
                "diagnosis"
              ]
            }
          ],
          "ehr_data": {
            "appointmentDate": "",
            "site": "",
            "reasonForVisit": "",
            "diagnosis": ""
          }
        },
        "fqn": "pastAppointments.outpatientAppointments",
        "tableChildren": [
          "outpatientAppointments_id",
          "reasonForVisit",
          "diagnosis",
          "appointmentDate",
          "site"
        ]
      }
    }
  },
  "visit": {
    "pageDataKey": "visit",
    "pIndex": "10",
    "hasGridTable": true,
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "admissionDay",
       /* "defaultValue": "0",*/
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": "TRUE",
        "validation": "visitDay",
        "fqn": "visit.admissionDay"
      },
      {
        "elementKey": "admissionTime",
        /*"defaultValue": "900",*/
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": "TRUE",
        "validation": "time24",
        "fqn": "visit.admissionTime"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "select",
        "mandatory": "TRUE",
        "options": [
          {
            "key": "手续办理中",
            "text": "手续办理中"
          },
          /*{
            "key": "Admitted",
            "text": "Admitted"
          },*/
          {
            "key": "已入院",
            "text": "已入院"
          },
          {
            "key": "门诊",
            "text": "门诊"
          },
          /*{
            "key": "Surgical day care",
            "text": "Surgical day care"
          },*/
          {
            "key": "准备出院",
            "text": "准备出院"
          },
          {
            "key": "已出院",
            "text": "已出院"
          }
        ],
        "fqn": "visit.status"
      },
      {
        "elementKey": "locationLabel",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "visit.locationLabel"
      },
      {
        "elementKey": "consentForTreatment",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "visit.consentForTreatment"
      },
      {
        "elementKey": "consentForBlood",
        "formIndex": "1",
        'inputType': 'checkset',
        'options': [
          {
            'key': '是',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "fqn": "visit.consentForBlood"
      },
      {
        "elementKey": "codeStatus",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "visit.codeStatus"
      },
      {
        "elementKey": "diagnosis",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "visit.diagnosis"
      },
      {
        "elementKey": "notes",
        "formIndex": "1",
        "inputType": "textarea",
        "fqn": "visit.notes"
      },
      {
        "elementKey": "table_name",
        "formIndex": "3",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "visit.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "3",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "visit.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "3",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "visit.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "3",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "visit.time"
      },
      {
        "elementKey": "location",
        "formIndex": "3",
        "inputType": "text",
        "mandatory": "TRUE",
        "tableColumn": "2",
        "fqn": "visit.location"
      },
      {
        "elementKey": "transferInDay",
        "formIndex": "3",
        "inputType": "visitDay",
        "tableColumn": "3",
        "validation": "visitDay",
        "fqn": "visit.transferInDay"
      },
      {
        "elementKey": "transferInTime",
        "formIndex": "3",
        "inputType": "visitTime",
        "tableColumn": "4",
        "validation": "time24",
        "fqn": "visit.transferInTime"
      },
      {
        "elementKey": "aSpacer",
        "formIndex": "3",
        "inputType": "spacer",
        "fqn": "visit.aSpacer"
      },
      {
        "elementKey": "transferOutDay",
        "formIndex": "3",
        "inputType": "visitDay",
        "tableColumn": "5",
        "validation": "visitDay",
        "fqn": "visit.transferOutDay"
      },
      {
        "elementKey": "transferOutTime",
        "formIndex": "3",
        "inputType": "visitTime",
        "tableColumn": "6",
        "validation": "time24",
        "fqn": "visit.transferOutTime"
      },
      {
        "elementKey": "diagnosisTable_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "visit.name"
      },
      {
        "elementKey": "diagnosisTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "diagnosisTable_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "visit.profession"
      },
      {
        "elementKey": "diagnosisTable_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "visit.day"
      },
      {
        "elementKey": "diagnosisTable_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "visit.time"
      },
      {
        "elementKey": "diagnosisRecord",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "visit.diagnosisRecord"
      },
      {
        "elementKey": "diagnosisDetails",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "visit.diagnosisDetails"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "visit.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "visit.cGroup",
            "gIndex": "1",
            "gChildren": [
              "admissionDay",
              "admissionTime",
              "status",
              "locationLabel",
              "consentForTreatment",
              "consentForBlood",
              "codeStatus"
            ]
          },
          {
            "elementKey": "cGroup2",
            "formCss": "grid-left-to-right-1",
            "fqn": "visit.cGroup2",
            "gIndex": "2",
            "gChildren": [
              "diagnosis",
              "notes"
            ]
          }
        ]
      },
      "diagnosisTable": {
        "elementKey": "diagnosisTable",
        "pageElementIndex": "2",
        "pageKey": "visit",
        "tableKey": "diagnosisTable",
        "isTable": true,
        "form": {
          "elementKey": "diagnosisTable",
          "label": "Diagnosis",
          "addButtonText": "Add a diagnosis record",
          "formKey": "diagnosisTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "visit.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "diagnosisTable_name",
                "diagnosisTable_profession",
                "diagnosisTable_day",
                "diagnosisTable_time"
              ]
            },
            {
              "elementKey": "cGroup3",
              "formCss": "grid-left-to-right-1",
              "fqn": "visit.cGroup3",
              "gIndex": "2",
              "gChildren": [
                "diagnosisRecord",
                "diagnosisDetails"
              ]
            }
          ],
          "ehr_data": {
            "diagnosisTable_name": "",
            "diagnosisTable_profession": "",
            "diagnosisTable_day": "",
            "diagnosisTable_time": "",
            "diagnosisRecord": "",
            "diagnosisDetails": ""
          }
        },
        "fqn": "visit.diagnosisTable",
        "tableChildren": [
          "diagnosisTable_id",
          "diagnosisTable_day",
          "diagnosisTable_time",
          "diagnosisRecord",
          "diagnosisDetails",
          "diagnosisTable_name",
          "diagnosisTable_profession"
        ],
        "hasRecHeader": true
      },
      "table": {
        "elementKey": "table",
        "pageElementIndex": "3",
        "pageKey": "visit",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Patient Location",
          "addButtonText": "Add a patient location",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "visit.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "visit.cGroup",
              "gIndex": "2",
              "gChildren": [
                "location"
              ]
            },
            {
              "elementKey": "cGroup2",
              "formCss": "grid-left-to-right-3",
              "fqn": "visit.cGroup2",
              "gIndex": "3",
              "gChildren": [
                "transferInDay",
                "transferInTime",
                "aSpacer",
                "transferOutDay",
                "transferOutTime"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "location": "",
            "transferInDay": "",
            "transferInTime": "",
            "transferOutDay": "",
            "transferOutTime": ""
          }
        },
        "fqn": "visit.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "location",
          "transferInDay",
          "transferInTime",
          "transferOutDay",
          "transferOutTime",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "vitals": {
    "pageDataKey": "vitals",
    "pIndex": "11",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "vitals.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "vitals.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "vitals.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "vitals.time"
      },
      {
        "elementKey": "systolic",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "validation": "numeric",
        "fqn": "vitals.systolic"
      },
      {
        "elementKey": "diastolic",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "validation": "numeric",
        "fqn": "vitals.diastolic"
      },
      {
        "elementKey": "patientPosition",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "卧位",
            "text": "卧位"
          },
          {
            "key": "坐位",
            "text": "坐位"
          },
          {
            "key": "站位",
            "text": "站位"
          }
        ],
        "tableColumn": "4",
        "fqn": "vitals.patientPosition"
      },
      {
        "elementKey": "temperature",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "℃",
        "tableColumn": "5",
        "validation": "numeric",
        "fqn": "vitals.temperature",
        "suffixText": "℃"
      },
      {
        "elementKey": "source",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "口温",
            "text": "口温"
          },
          {
            "key": "腋温",
            "text": "腋温"
          },
          {
            "key": "肛温",
            "text": "肛温"
          },
          {
            "key": "耳温",
            "text": "耳温"
          }
        ],
        "tableColumn": "6",
        "fqn": "vitals.source"
      },
      {
        "elementKey": "spacer1",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "vitals.spacer1"
      },
      {
        "elementKey": "strength",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "桡动脉",
            "text": "桡动脉"
          },
          {
            "key": "其他",
            "text": "其他"
          }
        ],
        "tableColumn": "7",
        "fqn": "vitals.strength"
      },
      {
        "elementKey": "rate",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "8",
        "validation": "numeric",
        "fqn": "vitals.rate"
      },
      {
        "elementKey": "rhythm",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "规律",
            "text": "规律"
          },
          {
            "key": "不规律",
            "text": "不规律"
          }
        ],
        "tableColumn": "9",
        "fqn": "vitals.rhythm"
      },
      {
        "elementKey": "respirationRate",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "validation": "numeric",
        "fqn": "vitals.respirationRate"
      },
      {
        "elementKey": "spacer2",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "vitals.spacer2"
      },
      {
        "elementKey": "spacer3",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "vitals.spacer3"
      },
      {
        "elementKey": "oxygenSaturation",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "11",
        "validation": "numeric",
        "fqn": "vitals.oxygenSaturation",
        "suffixText": "%"
      },
      {
        "elementKey": "oxygenMode",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "自然空气",
            "text": "自然空气"
          },
          {
            "key": "鼻导管吸氧",
            "text": "鼻导管吸氧"
          },
          {
            "key": "面罩吸氧",
            "text": "面罩吸氧"
          },
          {
            "key": "呼吸机吸氧",
            "text": "呼吸机吸氧"
          },
          {
            "key": "高流量吸氧",
            "text": "高流量吸氧"
          }
        ],
        "tableColumn": "12",
        "fqn": "vitals.oxygenMode"
      },
      {
        "elementKey": "flowRate",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "/lpm",
        "tableColumn": "13",
        "validation": "numeric",
        "fqn": "vitals.flowRate",
        "suffixText": "/lpm"
      },
      {
        "elementKey": "bloodSugar",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "mmol/L",
        "tableColumn": "14",
        "validation": "numeric",
        "fqn": "vitals.bloodSugar",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "cvp",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "15",
        "validation": "cvp",
        "fqn": "vitals.cvp"
      },
      {
        "elementKey": "spacer4",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "vitals.spacer4"
      },
      {
        "elementKey": "spacer5",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "vitals.spacer5"
      },
      {
        "elementKey": "beizhu74835",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "label": "备注",
        "tableColumn": "16",
        "fqn": "vitals.beizhu74835"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "vitals",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Vital signs",
          "addButtonText": "Add vital signs",
          "formOption": "transpose",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "vitals.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-3",
              "fqn": "vitals.cGroup",
              "gIndex": "2",
              "gChildren": [
                "systolic",
                "diastolic",
                "patientPosition",
                "temperature",
                "source",
                "spacer1",
                "strength",
                "rate",
                "rhythm",
                "respirationRate",
                "spacer2",
                "spacer3",
                "oxygenSaturation",
                "oxygenMode",
                "flowRate",
                "bloodSugar",
                "cvp",
                "spacer4",
                "spacer5",
                "beizhu74835"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "systolic": "",
            "diastolic": "",
            "patientPosition": "",
            "temperature": "",
            "source": "",
            "strength": "",
            "rate": "",
            "rhythm": "",
            "respirationRate": "",
            "oxygenSaturation": "",
            "oxygenMode": "",
            "flowRate": "",
            "bloodSugar": "",
            "cvp": "",
            "beizhu74835": ""
          }
        },
        "fqn": "vitals.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "systolic",
          "diastolic",
          "patientPosition",
          "temperature",
          "source",
          "strength",
          "rate",
          "rhythm",
          "respirationRate",
          "oxygenSaturation",
          "oxygenMode",
          "flowRate",
          "bloodSugar",
          "cvp",
          "beizhu74835",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "fluidBalance": {
    "pageDataKey": "fluidBalance",
    "pIndex": "12",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "fluidInOutTable_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "fluidBalance.name"
      },
      {
        "elementKey": "fluidInOutTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "fluidInOutTable_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "fluidBalance.profession"
      },
      {
        "elementKey": "fluidInOutTable_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "fluidBalance.day"
      },
      {
        "elementKey": "fluidInOutTable_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "fluidBalance.time"
      },
      {
        "elementKey": "inOral",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalInputs",
        "suffix": "mL",
        "tableColumn": "2",
        "validation": "numeric",
        "fqn": "fluidBalance.inOral",
        "suffixText": "mL"
      },
      {
        "elementKey": "inIV",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalInputs",
        "suffix": "mL",
        "tableColumn": "3",
        "validation": "numeric",
        "fqn": "fluidBalance.inIV",
        "suffixText": "mL"
      },
      {
        "elementKey": "inEnteral",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalInputs",
        "suffix": "mL",
        "tableColumn": "4",
        "validation": "numeric",
        "fqn": "fluidBalance.inEnteral",
        "suffixText": "mL"
      },
      {
        "elementKey": "inOther",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalInputs",
        "suffix": "mL",
        "tableColumn": "5",
        "validation": "numeric",
        "fqn": "fluidBalance.inOther",
        "suffixText": "mL"
      },
      {
        "elementKey": "totalInputs",
        "calculationType": "sum",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "calculatedValue",
        "passToFunction": "cumulativeTotal",
        "suffix": "mL",
        "tableColumn": "6",
        "fqn": "fluidBalance.totalInputs",
        "suffixText": "mL"
      },
      {
        "elementKey": "outUrine",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalOutputs",
        "suffix": "mL",
        "tableColumn": "7",
        "validation": "numeric",
        "fqn": "fluidBalance.outUrine",
        "suffixText": "mL"
      },
      {
        "elementKey": "outDrains",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalOutputs",
        "suffix": "mL",
        "tableColumn": "8",
        "validation": "numeric",
        "fqn": "fluidBalance.outDrains",
        "suffixText": "mL"
      },
      {
        "elementKey": "outBlood",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalOutputs",
        "suffix": "mL",
        "tableColumn": "9",
        "validation": "numeric",
        "fqn": "fluidBalance.outBlood",
        "suffixText": "mL"
      },
      {
        "elementKey": "outOther",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "number",
        "passToFunction": "totalOutputs",
        "suffix": "mL",
        "tableColumn": "10",
        "validation": "numeric",
        "fqn": "fluidBalance.outOther",
        "suffixText": "mL"
      },
      {
        "elementKey": "totalOutputs",
        "calculationType": "sum",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "calculatedValue",
        "passToFunction": "cumulativeTotal",
        "suffix": "mL",
        "tableColumn": "11",
        "fqn": "fluidBalance.totalOutputs",
        "suffixText": "mL"
      },
      {
        "elementKey": "cumulativeTotal",
        "calculationType": "fluidBalance",
        "formIndex": "1",
        "formCss": "number-short",
        "inputType": "calculatedValue",
        "suffix": "mL",
        "tableColumn": "12",
        "fqn": "fluidBalance.cumulativeTotal",
        "suffixText": "mL"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "13",
        "fqn": "fluidBalance.comments"
      },
      {
        "elementKey": "table_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "fluidBalance.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "fluidBalance.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "fluidBalance.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "fluidBalance.time"
      },
      {
        "elementKey": "fluidIn",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "fluidBalance.fluidIn"
      },
      {
        "elementKey": "fluidOut",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "fluidBalance.fluidOut"
      }
    ],
    "pageElements": {
      "fluidInOutTable": {
        "elementKey": "fluidInOutTable",
        "pageElementIndex": "1",
        "pageKey": "fluidBalance",
        "tableKey": "fluidInOutTable",
        "isTable": true,
        "form": {
          "elementKey": "fluidInOutTable",
          "label": "Fluid balance",
          "addButtonText": "Add a fluid record",
          "formOption": "transpose",
          "formKey": "fluidInOutTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "fluidBalance.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "fluidInOutTable_name",
                "fluidInOutTable_profession",
                "fluidInOutTable_day",
                "fluidInOutTable_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-4",
              "fqn": "fluidBalance.cGroup",
              "gIndex": "2",
              "gChildren": [
                "inOral",
                "inIV",
                "inEnteral",
                "inOther",
                "totalInputs"
              ]
            },
            {
              "elementKey": "cGroup2",
              "formCss": "grid-left-to-right-4",
              "fqn": "fluidBalance.cGroup2",
              "gIndex": "3",
              "gChildren": [
                "outUrine",
                "outDrains",
                "outBlood",
                "outOther",
                "totalOutputs"
              ]
            },
            {
              "elementKey": "cGroup3",
              "formCss": "grid-left-to-right-1",
              "fqn": "fluidBalance.cGroup3",
              "gIndex": "4",
              "gChildren": [
                "cumulativeTotal",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "fluidInOutTable_name": "",
            "fluidInOutTable_profession": "",
            "fluidInOutTable_day": "",
            "fluidInOutTable_time": "",
            "inOral": "",
            "inIV": "",
            "inEnteral": "",
            "inOther": "",
            "totalInputs": "",
            "outUrine": "",
            "outDrains": "",
            "outBlood": "",
            "outOther": "",
            "totalOutputs": "",
            "cumulativeTotal": "",
            "comments": ""
          }
        },
        "fqn": "fluidBalance.fluidInOutTable",
        "tableChildren": [
          "fluidInOutTable_id",
          "fluidInOutTable_day",
          "fluidInOutTable_time",
          "inOral",
          "inIV",
          "inEnteral",
          "inOther",
          "totalInputs",
          "outUrine",
          "outDrains",
          "outBlood",
          "outOther",
          "totalOutputs",
          "cumulativeTotal",
          "comments",
          "fluidInOutTable_name",
          "fluidInOutTable_profession"
        ],
        "hasRecHeader": true
      },
      "table": {
        "elementKey": "table",
        "pageElementIndex": "2",
        "pageKey": "fluidBalance",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Fluid balance v1",
          "addButtonText": "Add a fluid in/out record",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup2",
              "formCss": "record-header",
              "fqn": "fluidBalance.hdrGroup2",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup4",
              "formCss": "grid-left-to-right-3",
              "fqn": "fluidBalance.cGroup4",
              "gIndex": "2",
              "gChildren": [
                "fluidIn",
                "fluidOut"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "fluidIn": "",
            "fluidOut": ""
          }
        },
        "fqn": "fluidBalance.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "fluidIn",
          "fluidOut",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  'neurological': {
    'pageDataKey': 'neurological',
    'pageTitle': '一般状况评估',
    'pIndex': '14',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'neurological.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'neurological.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'neurological.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'neurological.time'
      },
      {
        'elementKey': 'yishizhuangtai683',
        'formIndex': '1',
        'inputType': 'select',
        'label': '意识状态',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '嗜睡',
            'text': '嗜睡'
          },
          {
            'key': '昏睡',
            'text': '昏睡'
          },
          {
            'key': '昏迷',
            'text': '昏迷'
          },
          {
            'key': '意识模糊',
            'text': '意识模糊'
          },
          {
            'key': '谵妄',
            'text': '谵妄'
          }
        ],
        'passToFunction': 'butaizishi362',
        'tableColumn': '2',
        'tableLabel': '意识状态',
        'fqn': 'neurological.yishizhuangtai683'
      },
      {
        'elementKey': 'mianbubiaoqing362',
        'formIndex': '1',
        'inputType': 'select',
        'label': '面部表情',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '急性病容',
            'text': '急性病容'
          },
          {
            'key': '慢性病容',
            'text': '慢性病容'
          },
          {
            'key': '贫血面容',
            'text': '贫血面容'
          },
          {
            'key': '二尖瓣面容',
            'text': '二尖瓣面容'
          },
          {
            'key': '甲亢面容',
            'text': '甲亢面容'
          },
          {
            'key': '临床特殊面容',
            'text': '临床特殊面容'
          }
        ],
        'passToFunction': 'butaizishi362',
        'tableColumn': '3',
        'tableLabel': '面部表情',
        'fqn': 'neurological.mianbubiaoqing362'
      },
      {
        'elementKey': 'tiwrei372',
        'formIndex': '1',
        'inputType': 'select',
        'label': '体位',
        'options': [
          {
            'key': '自动体位',
            'text': '自动体位'
          },
          {
            'key': '被动体位',
            'text': '被动体位'
          },
          {
            'key': '强迫体位',
            'text': '强迫体位'
          }
        ],
        'passToFunction': 'butaizishi362',
        'tableColumn': '4',
        'tableLabel': '体位',
        'fqn': 'neurological.tiwrei372'
      },
      {
        'elementKey': 'butaizishi362',
        'formIndex': '1',
        'inputType': 'select',
        'label': '步态/姿势',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '醉酒步态',
            'text': '醉酒步态'
          },
          {
            'key': '蹒跚步态',
            'text': '蹒跚步态'
          },
          {
            'key': '跨越步态',
            'text': '跨越步态'
          }
        ],
        'passToFunction': 'butaizishi362',
        'tableColumn': '5',
        'tableLabel': '步态/姿势',
        'fqn': 'neurological.butaizishi362'
      },
      {
        'elementKey': 'yuyangoutongnengli327',
        'formIndex': '1',
        'inputType': 'text',
        'label': '语言沟通能力',
        'tableColumn': '6',
        'tableLabel': '语言沟通能力',
        'fqn': 'neurological.yuyangoutongnengli327'
      },
      {
        'elementKey': 'xuexinengli296',
        'formIndex': '1',
        'inputType': 'text',
        'label': '学习能力',
        'tableColumn': '7',
        'tableLabel': '学习能力',
        'fqn': 'neurological.xuexinengli296'
      },
      {
        'elementKey': 'miaoshu4839',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '8',
        'tableLabel': '描述',
        'fqn': 'neurological.miaoshu4839'
      },
      {
        'elementKey': 'del_table_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'neurological.name'
      },
      {
        'elementKey': 'del_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'del_table_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'neurological.profession'
      },
      {
        'elementKey': 'del_table_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'neurological.day'
      },
      {
        'elementKey': 'del_table_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'neurological.time'
      },
      {
        'elementKey': 'yanse529',
        'formIndex': '2',
        'inputType': 'select',
        'label': '颜色',
        'options': [
          {
            'key': '正常肤色',
            'text': '正常肤色'
          },
          {
            'key': '皮肤苍白',
            'text': '皮肤苍白'
          },
          {
            'key': '发红',
            'text': '发红'
          },
          {
            'key': '紫绀',
            'text': '紫绀'
          },
          {
            'key': '黄染',
            'text': '黄染'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '2',
        'tableLabel': '颜色',
        'fqn': 'neurological.yanse529'
      },
      {
        'elementKey': 'tanxing736',
        'formIndex': '2',
        'inputType': 'select',
        'label': '弹性',
        'options': [
          {
            'key': '良好',
            'text': '良好'
          },
          {
            'key': '减弱',
            'text': '减弱'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '3',
        'tableLabel': '弹性',
        'fqn': 'neurological.tanxing736'
      },
      {
        'elementKey': 'wenduheshidu',
        'formIndex': '2',
        'inputType': 'select',
        'label': '温度和湿度',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '发热',
            'text': '发热'
          },
          {
            'key': '发冷',
            'text': '发冷'
          },
          {
            'key': '出汗',
            'text': '出汗'
          },
          {
            'key': '冷汗',
            'text': '冷汗'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '4',
        'tableLabel': '温度和湿度',
        'fqn': 'neurological.wenduheshidu'
      },
      {
        'elementKey': 'pokuiwaishang',
        'formIndex': '2',
        'inputType': 'select',
        'label': '破溃外伤',
        'options': [
          {
            'key': '无外伤',
            'text': '无外伤'
          },
          {
            'key': '压疮',
            'text': '压疮'
          },
          {
            'key': '其他外伤',
            'text': '其他外伤'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '4',
        'tableLabel': '破溃外伤',
        'fqn': 'neurological.pokuiwaishang'
      },
      {
        'elementKey': 'pizhen380',
        'formIndex': '2',
        'inputType': 'select',
        'label': '皮疹',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '斑疹',
            'text': '斑疹'
          },
          {
            'key': '丘疹',
            'text': '丘疹'
          },
          {
            'key': '斑丘疹',
            'text': '斑丘疹'
          },
          {
            'key': '荨麻疹',
            'text': '荨麻疹'
          },
          {
            'key': '疱疹',
            'text': '疱疹'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '5',
        'tableLabel': '皮疹',
        'fqn': 'neurological.pizhen380'
      },
      {
        'elementKey': 'pixiachuxue630',
        'formIndex': '2',
        'inputType': 'select',
        'label': '皮下出血',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '淤点',
            'text': '淤点'
          },
          {
            'key': '紫癜',
            'text': '紫癜'
          },
          {
            'key': '瘀斑',
            'text': '瘀斑'
          },
          {
            'key': '血肿',
            'text': '血肿'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '6',
        'tableLabel': '皮下出血',
        'fqn': 'neurological.pixiachuxue630'
      },
      {
        'elementKey': 'zhizhuzhi4638',
        'formIndex': '2',
        'inputType': 'select',
        'label': '蜘蛛痣',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '7',
        'tableLabel': '蜘蛛痣',
        'fqn': 'neurological.zhizhuzhi4638'
      },
      {
        'elementKey': 'ganzhang3820',
        'formIndex': '2',
        'inputType': 'select',
        'label': '肝掌',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '8',
        'tableLabel': '肝掌',
        'fqn': 'neurological.ganzhang3820'
      },
      {
        'elementKey': 'shuizhong3729',
        'formIndex': '2',
        'inputType': 'select',
        'label': '水肿',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '轻度',
            'text': '轻度'
          },
          {
            'key': '中度',
            'text': '中度'
          },
          {
            'key': '重度',
            'text': '重度'
          }
        ],
        'passToFunction': 'pizhen380',
        'tableColumn': '9',
        'tableLabel': '水肿',
        'fqn': 'neurological.shuizhong3729'
      },
      {
        'elementKey': 'miaoshu3729',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '10',
        'tableLabel': '描述',
        'fqn': 'neurological.miaoshu3729'
      },
      {
        'elementKey': 'cam_table_name',
        'formIndex': '3',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'neurological.name'
      },
      {
        'elementKey': 'cam_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'cam_table_profession',
        'formIndex': '3',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'neurological.profession'
      },
      {
        'elementKey': 'cam_table_day',
        'formIndex': '3',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'neurological.day'
      },
      {
        'elementKey': 'cam_table_time',
        'formIndex': '3',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'neurological.time'
      },
      {
        'elementKey': 'hexia6382',
        'formIndex': '3',
        'inputType': 'text',
        'label': '颌下',
        'tableColumn': '1',
        'tableLabel': '颌下',
        'fqn': 'neurological.hexia6382'
      },
      {
        'elementKey': 'jingbu3628',
        'formIndex': '3',
        'inputType': 'text',
        'label': '颈部',
        'tableColumn': '2',
        'tableLabel': '颈部',
        'fqn': 'neurological.jingbu3628'
      },
      {
        'elementKey': 'suogushangwo387',
        'formIndex': '3',
        'inputType': 'text',
        'label': '锁骨丄窝',
        'tableColumn': '3',
        'tableLabel': '锁骨丄窝',
        'fqn': 'neurological.suogushangwo387'
      },
      {
        'elementKey': 'yewo672',
        'formIndex': '3',
        'inputType': 'text',
        'label': '腋窝',
        'tableColumn': '4',
        'tableLabel': '腋窝',
        'fqn': 'neurological.yewo672'
      },
      {
        'elementKey': 'fugugou3729',
        'formIndex': '3',
        'inputType': 'text',
        'label': '腹股沟',
        'tableColumn': '5',
        'tableLabel': '腹股沟',
        'fqn': 'neurological.fugugou3729'
      },
      {
        'elementKey': 'miaoshu3274',
        'formIndex': '3',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '7',
        'fqn': 'neurological.miaoshu3274'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'neurological',
        'tableKey': 'table',
        'isTable': true,
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '意识状态',
            'ehr_list_index': '2',
            'items': [
              'yishizhuangtai683'
            ]
          },
          {
            'label': '面部表情',
            'ehr_list_index': '3',
            'items': [
              'mianbubiaoqing362'
            ]
          },
          {
            'label': '体位',
            'ehr_list_index': '4',
            'items': [
              'tiwrei372'
            ]
          },
          {
            'label': '步态/姿势',
            'ehr_list_index': '5',
            'items': [
              'butaizishi362'
            ]
          },
          {
            'label': '语言沟通能力',
            'ehr_list_index': '6',
            'items': [
              'yuyangoutongnengli327'
            ]
          },
          {
            'label': '学习能力',
            'ehr_list_index': '7',
            'items': [
              'xuexinengli296'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '8',
            'items': [
              'miaoshu4839'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Neurological assessment",
          "addButtonText": "Add a neurological assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group37',
              'formCss': 'record-header',
              "fqn": "neurological.ehr_group37",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group38',
              'formCss': 'grid-left-to-right-3',
              "fqn": "neurological.ehr_group38",
              'gIndex': '2',
              'gChildren': [
                'yishizhuangtai683',
                'mianbubiaoqing362',
                'tiwrei372',
                'butaizishi362',
                'yuyangoutongnengli327',
                'xuexinengli296',
                'miaoshu4839'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'yishizhuangtai683': '',
            'mianbubiaoqing362': '',
            'tiwrei372': '',
            'butaizishi362': '',
            'yuyangoutongnengli327': '',
            'xuexinengli296': '',
            'miaoshu4839': ''
          }
        },
        "fqn": "neurological.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'yishizhuangtai683',
          'mianbubiaoqing362',
          'tiwrei372',
          'butaizishi362',
          'yuyangoutongnengli327',
          'xuexinengli296',
          'miaoshu4839'
        ],
        'hasRecHeader': true
      },
      'del_table': {
        'elementKey': 'del_table',
        'pageElementIndex': '2',
        'pageKey': 'neurological',
        'tableKey': 'del_table',
        'isTable': true,
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'del_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'del_table_name',
              'del_table_profession',
              'del_table_day',
              'del_table_time'
            ]
          },
          {
            'label': '颜色',
            'ehr_list_index': '2',
            'items': [
              'yanse529'
            ]
          },
          {
            'label': '弹性',
            'ehr_list_index': '3',
            'items': [
              'tanxing736'
            ]
          },
          {
            'label': '温度和湿度',
            'ehr_list_index': '4',
            'items': [
              'wenduheshidu',
              'pokuiwaishang'
            ]
          },
          {
            'label': '皮疹',
            'ehr_list_index': '5',
            'items': [
              'pizhen380'
            ]
          },
          {
            'label': '皮下出血',
            'ehr_list_index': '6',
            'items': [
              'pixiachuxue630'
            ]
          },
          {
            'label': '蜘蛛痣',
            'ehr_list_index': '7',
            'items': [
              'zhizhuzhi4638'
            ]
          },
          {
            'label': '肝掌',
            'ehr_list_index': '8',
            'items': [
              'ganzhang3820'
            ]
          },
          {
            'label': '水肿',
            'ehr_list_index': '9',
            'items': [
              'shuizhong3729'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '10',
            'items': [
              'miaoshu3729'
            ]
          }
        ],
        'form': {
          'elementKey': 'del_table',
          "label": "Delirium risk assessment",
          "addButtonText": "Add a delirium assessment",
          'formOption': 'transpose',
          'formKey': 'del_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group39',
              'formCss': 'record-header',
              "fqn": "neurological.ehr_group39",               
              'gIndex': '1',
              'gChildren': [
                'del_table_name',
                'del_table_profession',
                'del_table_day',
                'del_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group40',
              'formCss': 'grid-left-to-right-3',
              "fqn": "neurological.ehr_group40",              
              'gIndex': '2',
              'gChildren': [
                'yanse529',
                'tanxing736',
                'wenduheshidu',
                'pokuiwaishang',
                'pizhen380',
                'pixiachuxue630',
                'zhizhuzhi4638',
                'ganzhang3820',
                'shuizhong3729',
                'miaoshu3729'
              ]
            }
          ],
          'ehr_data': {
            'del_table_name': '',
            'del_table_profession': '',
            'del_table_day': '',
            'del_table_time': '',
            'yanse529': '',
            'tanxing736': '',
            'wenduheshidu': '',
            'pokuiwaishang': '',
            'pizhen380': '',
            'pixiachuxue630': '',
            'zhizhuzhi4638': '',
            'ganzhang3820': '',
            'shuizhong3729': '',
            'miaoshu3729': ''
          }
        },
        "fqn": "neurological.del_table",   
        'tableChildren': [
          'del_table_id',
          'del_table_name',
          'del_table_profession',
          'del_table_day',
          'del_table_time',
          'yanse529',
          'tanxing736',
          'wenduheshidu',
          'pokuiwaishang',
          'pizhen380',
          'pixiachuxue630',
          'zhizhuzhi4638',
          'ganzhang3820',
          'shuizhong3729',
          'miaoshu3729'
        ],
        'hasRecHeader': true
      },
      'cam_table': {
        'elementKey': 'cam_table',
        'pageElementIndex': '3',
        'pageKey': 'neurological',
        'tableKey': 'cam_table',
        'isTable': true,
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'cam_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'cam_table_name',
              'cam_table_profession',
              'cam_table_day',
              'cam_table_time',
              'hexia6382'
            ]
          },
          {
            'label': '颈部',
            'ehr_list_index': '2',
            'items': [
              'jingbu3628'
            ]
          },
          {
            'label': '锁骨丄窝',
            'ehr_list_index': '3',
            'items': [
              'suogushangwo387'
            ]
          },
          {
            'label': '腋窝',
            'ehr_list_index': '4',
            'items': [
              'yewo672'
            ]
          },
          {
            'label': '腹股沟',
            'ehr_list_index': '5',
            'items': [
              'fugugou3729'
            ]
          },
          {
            'ehr_list_index': '7',
            'items': [
              'miaoshu3274'
            ]
          }
        ],
        'form': {
          'elementKey': 'cam_table',
          "label": "CAM assessment",
          "addButtonText": "Add a confusion assessment",
          'formOption': 'transpose',
          'formKey': 'cam_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group41',
              'formCss': 'record-header',
              "fqn": "neurological.ehr_group41",
              'gIndex': '1',
              'gChildren': [
                'cam_table_name',
                'cam_table_profession',
                'cam_table_day',
                'cam_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group42',
              'formCss': 'grid-left-to-right-3',
              "fqn": "neurological.ehr_group42",
              'gIndex': '2',
              'gChildren': [
                'hexia6382',
                'jingbu3628',
                'suogushangwo387',
                'yewo672',
                'fugugou3729',
                'miaoshu3274'
              ]
            }
          ],
          'ehr_data': {
            'cam_table_name': '',
            'cam_table_profession': '',
            'cam_table_day': '',
            'cam_table_time': '',
            'hexia6382': '',
            'jingbu3628': '',
            'suogushangwo387': '',
            'yewo672': '',
            'fugugou3729': '',
            'miaoshu3274': ''
          }
        },
        "fqn": "neurological.cam_table",
        'tableChildren': [
          'cam_table_id',
          'cam_table_name',
          'cam_table_profession',
          'cam_table_day',
          'cam_table_time',
          'hexia6382',
          'jingbu3628',
          'suogushangwo387',
          'yewo672',
          'fugugou3729',
          'miaoshu3274'
        ],
        'hasRecHeader': true
      }
    }
  },
  'respiratory': {
    'pageDataKey': 'respiratory',
    'pageTitle': '头颈部评估',
    'pIndex': '15',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'respiratory.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'respiratory.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'respiratory.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'respiratory.time'
      },
      {
        'elementKey': 'toufayutoupi',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '头发与头皮',
        'options': [
          {
            'key': '稀疏',
            'text': '稀疏'
          },
          {
            'key': '浓密',
            'text': '浓密'
          },
          {
            'key': '秃顶',
            'text': '秃顶'
          },
          {
            'key': '有头屑',
            'text': '有头屑'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '头发与头皮',
        'fqn': 'respiratory.toufayutoupi'
      },
      {
        'elementKey': 'touludaxiaojixingzhuang',
        'formIndex': '1',
        'inputType': 'text',
        'label': '头颅大小及形状',
        'tableColumn': '3',
        'tableLabel': '头颅大小及形状',
        'fqn': 'respiratory.touludaxiaojixingzhuang'
      },
      {
        'elementKey': 'touluchutong',
        'formIndex': '1',
        'inputType': 'select',
        'label': '头颅触痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '头颅触痛',
        'fqn': 'respiratory.touluchutong'
      },
      {
        'elementKey': 'toubuyundong',
        'formIndex': '1',
        'inputType': 'select',
        'label': '头部运动',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '活动受限',
            'text': '活动受限'
          },
          {
            'key': '不能胎头',
            'text': '不能胎头'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '头部运动',
        'fqn': 'respiratory.toubuyundong'
      },
      {
        'elementKey': 'yanjian4739',
        'formIndex': '1',
        'inputType': 'select',
        'label': '眼睑',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '水肿',
            'text': '水肿'
          },
          {
            'key': '下垂',
            'text': '下垂'
          },
          {
            'key': '闭合障碍',
            'text': '闭合障碍'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '眼睑',
        'fqn': 'respiratory.yanjian4739'
      },
      {
        'elementKey': 'jiemo3729',
        'formIndex': '1',
        'inputType': 'select',
        'label': '结膜',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '充血',
            'text': '充血'
          },
          {
            'key': '出血',
            'text': '出血'
          },
          {
            'key': '苍白',
            'text': '苍白'
          },
          {
            'key': '水肿',
            'text': '水肿'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '结膜',
        'fqn': 'respiratory.jiemo3729'
      },
      {
        'elementKey': 'gongmo3647',
        'formIndex': '1',
        'inputType': 'select',
        'label': '巩膜',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '黄染',
            'text': '黄染'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '巩膜',
        'fqn': 'respiratory.gongmo3647'
      },
      {
        'elementKey': 'jiaomo3768',
        'formIndex': '1',
        'inputType': 'select',
        'label': '角膜',
        'options': [
          {
            'key': '透明度好',
            'text': '透明度好'
          },
          {
            'key': '透明度差',
            'text': '透明度差'
          },
          {
            'key': '溃疡',
            'text': '溃疡'
          },
          {
            'key': '云翳',
            'text': '云翳'
          },
          {
            'key': '白斑',
            'text': '白斑'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '角膜',
        'fqn': 'respiratory.jiaomo3768'
      },
      {
        'elementKey': 'tongkongdaxiaojixingzhuang',
        'formIndex': '1',
        'inputType': 'select',
        'label': '瞳孔大小及形状',
        'options': [
          {
            'key': '等大等圆',
            'text': '等大等圆'
          },
          {
            'key': '缩小',
            'text': '缩小'
          },
          {
            'key': '散大',
            'text': '散大'
          },
          {
            'key': '大小不等',
            'text': '大小不等'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '瞳孔大小及形状',
        'fqn': 'respiratory.tongkongdaxiaojixingzhuang'
      },
      {
        'elementKey': 'duigaungfanshe',
        'formIndex': '1',
        'inputType': 'select',
        'label': '对光反射',
        'options': [
          {
            'key': '灵敏',
            'text': '灵敏'
          },
          {
            'key': '迟钝',
            'text': '迟钝'
          },
          {
            'key': '消失',
            'text': '消失'
          }
        ],
        'tableColumn': '11',
        'tableLabel': '对光反射',
        'fqn': 'respiratory.duigaungfanshe'
      },
      {
        'elementKey': 'yanqiu4387',
        'formIndex': '1',
        'inputType': 'select',
        'label': '眼球',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '突出',
            'text': '突出'
          },
          {
            'key': '下陷',
            'text': '下陷'
          },
          {
            'key': '运动受限',
            'text': '运动受限'
          }
        ],
        'tableColumn': '12',
        'tableLabel': '眼球',
        'fqn': 'respiratory.yanqiu4387'
      },
      {
        'elementKey': 'shili3648',
        'formIndex': '1',
        'inputType': 'text',
        'label': '视力',
        'tableColumn': '13',
        'tableLabel': '视力',
        'fqn': 'respiratory.shili3648'
      },
      {
        'elementKey': 'shiye5239',
        'formIndex': '1',
        'inputType': 'text',
        'label': '视野',
        'tableColumn': '14',
        'tableLabel': '视野',
        'fqn': 'respiratory.shiye5239'
      },
      {
        'elementKey': 'sejue4128',
        'formIndex': '1',
        'inputType': 'text',
        'label': '色觉',
        'tableColumn': '15',
        'tableLabel': '色觉',
        'fqn': 'respiratory.sejue4128'
      },
      {
        'elementKey': 'erkuo2749',
        'formIndex': '1',
        'inputType': 'select',
        'label': '耳廓',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '畸形',
            'text': '畸形'
          }
        ],
        'tableColumn': '16',
        'tableLabel': '耳廓',
        'fqn': 'respiratory.erkuo2749'
      },
      {
        'elementKey': 'waierdao389',
        'formIndex': '1',
        'inputType': 'select',
        'label': '外耳道',
        'options': [
          {
            'key': '无异常分泌物',
            'text': '无异常分泌物'
          },
          {
            'key': '有异常分泌物',
            'text': '有异常分泌物'
          }
        ],
        'tableColumn': '17',
        'tableLabel': '外耳道',
        'fqn': 'respiratory.waierdao389'
      },
      {
        'elementKey': 'rutu358',
        'formIndex': '1',
        'formCss': 'grid-new-subsection',
        'inputType': 'select',
        'label': '乳突',
        'options': [
          {
            'key': '有压痛',
            'text': '有压痛'
          },
          {
            'key': '无压痛',
            'text': '无压痛'
          }
        ],
        'tableColumn': '18',
        'tableLabel': '乳突',
        'fqn': 'respiratory.rutu358'
      },
      {
        'elementKey': 'tingli428',
        'formIndex': '1',
        'formCss': 'grid-new-subsection',
        'inputType': 'select',
        'label': '听力',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '受限',
            'text': '受限'
          }
        ],
        'tableColumn': '19',
        'tableLabel': '听力',
        'fqn': 'respiratory.tingli428'
      },
      {
        'elementKey': 'waixing5287',
        'formIndex': '1',
        'inputType': 'text',
        'label': '外形',
        'tableColumn': '20',
        'tableLabel': '外形',
        'fqn': 'respiratory.waixing5287'
      },
      {
        'elementKey': 'biyishandong',
        'formIndex': '1',
        'inputType': 'select',
        'label': '鼻翼煽动',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '21',
        'tableLabel': '鼻翼煽动',
        'fqn': 'respiratory.biyishandong'
      },
      {
        'elementKey': 'biqiangchuxue374',
        'formIndex': '1',
        'inputType': 'select',
        'label': '鼻腔出血',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '有，一侧',
            'text': '有，一侧'
          },
          {
            'key': '有，两侧',
            'text': '有，两侧'
          }
        ],
        'tableColumn': '22',
        'tableLabel': '鼻腔出血',
        'fqn': 'respiratory.biqiangchuxue374'
      },
      {
        'elementKey': 'tongchang4832',
        'formIndex': '1',
        'inputType': 'select',
        'label': '通畅',
        'options': [
          {
            'key': '是',
            'text': '是'
          },
          {
            'key': '否',
            'text': '否'
          }
        ],
        'tableColumn': '23',
        'tableLabel': '通畅',
        'fqn': 'respiratory.tongchang4832'
      },
      {
        'elementKey': 'yicahngfenmiwu438',
        'formIndex': '1',
        'inputType': 'select',
        'label': '异常分泌物',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '24',
        'tableLabel': '异常分泌物',
        'fqn': 'respiratory.yicahngfenmiwu438'
      },
      {
        'elementKey': 'bidouyatong4378',
        'formIndex': '1',
        'inputType': 'select',
        'label': '鼻窦压痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '25',
        'tableLabel': '鼻窦压痛',
        'fqn': 'respiratory.bidouyatong4378'
      },
      {
        'elementKey': 'qiwei4367',
        'formIndex': '1',
        'inputType': 'select',
        'label': '气味',
        'options': [
          {
            'key': '有异味',
            'text': '有异味'
          },
          {
            'key': '无异味',
            'text': '无异味'
          }
        ],
        'tableColumn': '26',
        'tableLabel': '气味',
        'fqn': 'respiratory.qiwei4367'
      },
      {
        'elementKey': 'kouchunyanse275',
        'formIndex': '1',
        'inputType': 'text',
        'label': '口唇颜色',
        'tableColumn': '27',
        'tableLabel': '口唇颜色',
        'fqn': 'respiratory.kouchunyanse275'
      },
      {
        'elementKey': 'kouqiangnianmo327',
        'formIndex': '1',
        'inputType': 'select',
        'label': '口腔黏膜',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '溃疡',
            'text': '溃疡'
          },
          {
            'key': '出血',
            'text': '出血'
          }
        ],
        'tableColumn': '28',
        'tableLabel': '口腔黏膜',
        'fqn': 'respiratory.kouqiangnianmo327'
      },
      {
        'elementKey': 'yachi4628',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '牙齿',
        'options': [
          {
            'key': '整齐',
            'text': '整齐'
          },
          {
            'key': '不整齐',
            'text': '不整齐'
          },
          {
            'key': '松动',
            'text': '松动'
          },
          {
            'key': '义齿',
            'text': '义齿'
          },
          {
            'key': '脱齿',
            'text': '脱齿'
          }
        ],
        'tableColumn': '29',
        'tableLabel': '牙齿',
        'fqn': 'respiratory.yachi4628'
      },
      {
        'elementKey': 'yayin5368',
        'formIndex': '1',
        'inputType': 'select',
        'label': '牙龈',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '红肿',
            'text': '红肿'
          },
          {
            'key': '溢浓',
            'text': '溢浓'
          },
          {
            'key': '出血',
            'text': '出血'
          }
        ],
        'tableColumn': '30',
        'tableLabel': '牙龈',
        'fqn': 'respiratory.yayin5368'
      },
      {
        'elementKey': 'shetai4389',
        'formIndex': '1',
        'inputType': 'text',
        'label': '舌苔',
        'tableColumn': '31',
        'tableLabel': '舌苔',
        'fqn': 'respiratory.shetai4389'
      },
      {
        'elementKey': 'shedaxiao437',
        'formIndex': '1',
        'inputType': 'text',
        'label': '舌大小',
        'tableColumn': '32',
        'tableLabel': '舌大小',
        'fqn': 'respiratory.shedaxiao437'
      },
      {
        'elementKey': 'yanbu3980',
        'formIndex': '1',
        'inputType': 'text',
        'label': '咽部',
        'tableColumn': '33',
        'tableLabel': '咽部',
        'fqn': 'respiratory.yanbu3980'
      },
      {
        'elementKey': 'yanbunianmo346',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '咽部粘膜',
        'options': [
          {
            'key': '有红肿',
            'text': '有红肿'
          },
          {
            'key': '无红肿',
            'text': '无红肿'
          },
          {
            'key': '有淋巴滤泡',
            'text': '有淋巴滤泡'
          },
          {
            'key': '无淋巴滤泡',
            'text': '无淋巴滤泡'
          }
        ],
        'tableColumn': '34',
        'tableLabel': '咽部粘膜',
        'fqn': 'respiratory.yanbunianmo346'
      },
      {
        'elementKey': 'biantaoti4638',
        'formIndex': '1',
        'inputType': 'select',
        'label': '扁桃体',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': 'Ⅰ度肿大',
            'text': 'Ⅰ度肿大'
          },
          {
            'key': 'Ⅱ度肿大',
            'text': 'Ⅱ度肿大'
          },
          {
            'key': 'Ⅲ度肿大',
            'text': 'Ⅲ度肿大'
          }
        ],
        'tableColumn': '35',
        'tableLabel': '扁桃体',
        'fqn': 'respiratory.biantaoti4638'
      },
      {
        'elementKey': 'saixian3658',
        'formIndex': '1',
        'inputType': 'select',
        'label': '腮腺',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '肿大',
            'text': '肿大'
          },
          {
            'key': '溢浓',
            'text': '溢浓'
          }
        ],
        'tableColumn': '36',
        'tableLabel': '腮腺',
        'fqn': 'respiratory.saixian3658'
      },
      {
        'elementKey': 'miaoshu4386',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '37',
        'fqn': 'respiratory.miaoshu4386'
      },
      {
        'elementKey': 'jin_table_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'respiratory.name'
      },
      {
        'elementKey': 'jin_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'jin_table_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'respiratory.profession'
      },
      {
        'elementKey': 'jin_table_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'respiratory.day'
      },
      {
        'elementKey': 'jin_table_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'respiratory.time'
      },
      {
        'elementKey': 'waixingyuyundong',
        'formIndex': '2',
        'inputType': 'textarea',
        'label': '外形与运动',
        'tableColumn': '2',
        'tableLabel': '外形与运动',
        'fqn': 'respiratory.waixingyuyundong'
      },
      {
        'elementKey': 'jingdongmaichongyingdu',
        'formIndex': '2',
        'inputType': 'select',
        'label': '颈动脉充盈度',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '颈动脉怒张',
            'text': '颈动脉怒张'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '颈动脉充盈度',
        'fqn': 'respiratory.jingdongmaichongyingdu'
      },
      {
        'elementKey': 'jingjingmaibodong473',
        'formIndex': '2',
        'inputType': 'select',
        'label': '颈静脉搏动',
        'options': [
          {
            'key': '微弱',
            'text': '微弱'
          },
          {
            'key': '明显',
            'text': '明显'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '颈静脉搏动',
        'fqn': 'respiratory.jingjingmaibodong473'
      },
      {
        'elementKey': 'jingdongmaibodong658',
        'formIndex': '2',
        'inputType': 'select',
        'label': '颈动脉搏动',
        'options': [
          {
            'key': '微弱',
            'text': '微弱'
          },
          {
            'key': '明显',
            'text': '明显'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '颈动脉搏动',
        'fqn': 'respiratory.jingdongmaibodong658'
      },
      {
        'elementKey': 'jiazhuangxainzhongda438',
        'formIndex': '2',
        'inputType': 'select',
        'label': '甲状腺肿大',
        'options': [
          {
            'key': 'Ⅰ度',
            'text': 'Ⅰ度'
          },
          {
            'key': 'Ⅱ度',
            'text': 'Ⅱ度'
          },
          {
            'key': 'Ⅲ度',
            'text': 'Ⅲ度'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '甲状腺肿大',
        'fqn': 'respiratory.jiazhuangxainzhongda438'
      },
      {
        'elementKey': 'daixao5479',
        'formIndex': '2',
        'inputType': 'textarea',
        'label': '大小',
        'tableColumn': '7',
        'tableLabel': '大小',
        'fqn': 'respiratory.daixao5479'
      },
      {
        'elementKey': 'lunkuo765',
        'formIndex': '2',
        'inputType': 'textarea',
        'label': '轮廓',
        'tableColumn': '8',
        'tableLabel': '轮廓',
        'fqn': 'respiratory.lunkuo765'
      },
      {
        'elementKey': 'zhidi3275',
        'formIndex': '2',
        'inputType': 'textarea',
        'label': '质地',
        'tableColumn': '9',
        'tableLabel': '质地',
        'fqn': 'respiratory.zhidi3275'
      },
      {
        'elementKey': 'zhencahn3658',
        'formIndex': '2',
        'inputType': 'textarea',
        'label': '震颤',
        'tableColumn': '10',
        'tableLabel': '震颤',
        'fqn': 'respiratory.zhencahn3658'
      },
      {
        'elementKey': 'xueguanzayin438',
        'formIndex': '2',
        'inputType': 'select',
        'label': '血管杂音',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '11',
        'tableLabel': '血管杂音',
        'fqn': 'respiratory.xueguanzayin438'
      },
      {
        'elementKey': 'weizhi4376',
        'formIndex': '2',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '居中',
            'text': '居中'
          },
          {
            'key': '移向左侧',
            'text': '移向左侧'
          },
          {
            'key': '移向右侧',
            'text': '移向右侧'
          }
        ],
        'tableColumn': '12',
        'tableLabel': '位置',
        'fqn': 'respiratory.weizhi4376'
      },
      {
        'elementKey': 'miaoshu3754',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '13',
        'fqn': 'respiratory.miaoshu3754'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'respiratory',
        'tableKey': 'table',
        'isTable': true,
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '头发与头皮',
            'ehr_list_index': '2',
            'items': [
              'toufayutoupi'
            ]
          },
          {
            'label': '头颅大小及形状',
            'ehr_list_index': '3',
            'items': [
              'touludaxiaojixingzhuang'
            ]
          },
          {
            'label': '头颅触痛',
            'ehr_list_index': '4',
            'items': [
              'touluchutong'
            ]
          },
          {
            'label': '头部运动',
            'ehr_list_index': '5',
            'items': [
              'toubuyundong'
            ]
          },
          {
            'label': '眼睑',
            'ehr_list_index': '6',
            'items': [
              'yanjian4739'
            ]
          },
          {
            'label': '结膜',
            'ehr_list_index': '7',
            'items': [
              'jiemo3729'
            ]
          },
          {
            'label': '巩膜',
            'ehr_list_index': '8',
            'items': [
              'gongmo3647'
            ]
          },
          {
            'label': '角膜',
            'ehr_list_index': '9',
            'items': [
              'jiaomo3768'
            ]
          },
          {
            'label': '瞳孔大小及形状',
            'ehr_list_index': '10',
            'items': [
              'tongkongdaxiaojixingzhuang'
            ]
          },
          {
            'label': '对光反射',
            'ehr_list_index': '11',
            'items': [
              'duigaungfanshe'
            ]
          },
          {
            'label': '眼球',
            'ehr_list_index': '12',
            'items': [
              'yanqiu4387'
            ]
          },
          {
            'label': '视力',
            'ehr_list_index': '13',
            'items': [
              'shili3648'
            ]
          },
          {
            'label': '视野',
            'ehr_list_index': '14',
            'items': [
              'shiye5239'
            ]
          },
          {
            'label': '色觉',
            'ehr_list_index': '15',
            'items': [
              'sejue4128'
            ]
          },
          {
            'label': '耳廓',
            'ehr_list_index': '16',
            'items': [
              'erkuo2749'
            ]
          },
          {
            'label': '外耳道',
            'ehr_list_index': '17',
            'items': [
              'waierdao389'
            ]
          },
          {
            'label': '乳突',
            'ehr_list_index': '18',
            'items': [
              'rutu358'
            ]
          },
          {
            'label': '听力',
            'ehr_list_index': '19',
            'items': [
              'tingli428'
            ]
          },
          {
            'label': '外形',
            'ehr_list_index': '20',
            'items': [
              'waixing5287'
            ]
          },
          {
            'label': '鼻翼煽动',
            'ehr_list_index': '21',
            'items': [
              'biyishandong'
            ]
          },
          {
            'label': '鼻腔出血',
            'ehr_list_index': '22',
            'items': [
              'biqiangchuxue374'
            ]
          },
          {
            'label': '通畅',
            'ehr_list_index': '23',
            'items': [
              'tongchang4832'
            ]
          },
          {
            'label': '异常分泌物',
            'ehr_list_index': '24',
            'items': [
              'yicahngfenmiwu438'
            ]
          },
          {
            'label': '鼻窦压痛',
            'ehr_list_index': '25',
            'items': [
              'bidouyatong4378'
            ]
          },
          {
            'label': '气味',
            'ehr_list_index': '26',
            'items': [
              'qiwei4367'
            ]
          },
          {
            'label': '口唇颜色',
            'ehr_list_index': '27',
            'items': [
              'kouchunyanse275'
            ]
          },
          {
            'label': '口腔黏膜',
            'ehr_list_index': '28',
            'items': [
              'kouqiangnianmo327'
            ]
          },
          {
            'label': '牙齿',
            'ehr_list_index': '29',
            'items': [
              'yachi4628'
            ]
          },
          {
            'label': '牙龈',
            'ehr_list_index': '30',
            'items': [
              'yayin5368'
            ]
          },
          {
            'label': '舌苔',
            'ehr_list_index': '31',
            'items': [
              'shetai4389'
            ]
          },
          {
            'label': '舌大小',
            'ehr_list_index': '32',
            'items': [
              'shedaxiao437'
            ]
          },
          {
            'label': '咽部',
            'ehr_list_index': '33',
            'items': [
              'yanbu3980'
            ]
          },
          {
            'label': '咽部粘膜',
            'ehr_list_index': '34',
            'items': [
              'yanbunianmo346'
            ]
          },
          {
            'label': '扁桃体',
            'ehr_list_index': '35',
            'items': [
              'biantaoti4638'
            ]
          },
          {
            'label': '腮腺',
            'ehr_list_index': '36',
            'items': [
              'saixian3658'
            ]
          },
          {
            'ehr_list_index': '37',
            'items': [
              'miaoshu4386'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Respiratory assessment",
          "addButtonText": "Add a respiratory assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group43',
              'formCss': 'record-header',
              "fqn": "respiratory.ehr_group43",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group44',
              'label': '头部',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group44",
              'gIndex': '2',
              'gChildren': [
                'toufayutoupi',
                'touludaxiaojixingzhuang',
                'touluchutong',
                'toubuyundong'
              ]
            },
            {
              'elementKey': 'ehr_group45',
              'label': '眼',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group45",
              'gIndex': '3',
              'gChildren': [
                'yanjian4739',
                'jiemo3729',
                'gongmo3647',
                'jiaomo3768',
                'tongkongdaxiaojixingzhuang',
                'duigaungfanshe',
                'yanqiu4387',
                'shili3648',
                'shiye5239',
                'sejue4128'
              ]
            },
            {
              'elementKey': 'ehr_group46',
              'label': '耳',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group46",
              'gIndex': '4',
              'gChildren': [
                'erkuo2749',
                'waierdao389',
                'rutu358',
                'tingli428'
              ]
            },
            {
              'elementKey': 'ehr_group47',
              'label': '鼻',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group47",
              'gIndex': '5',
              'gChildren': [
                'waixing5287',
                'biyishandong',
                'biqiangchuxue374',
                'tongchang4832',
                'yicahngfenmiwu438',
                'bidouyatong4378'
              ]
            },
            {
              'elementKey': 'ehr_group48',
              'label': '口腔',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group48",
              'gIndex': '6',
              'gChildren': [
                'qiwei4367',
                'kouchunyanse275',
                'kouqiangnianmo327',
                'yachi4628',
                'yayin5368',
                'shetai4389',
                'shedaxiao437',
                'yanbu3980',
                'yanbunianmo346',
                'biantaoti4638',
                'saixian3658'
              ]
            },
            {
              'elementKey': 'ehr_group49',
              'formCss': 'grid-left-to-right-3',
              "fqn": "respiratory.ehr_group49",
              'gIndex': '7',
              'gChildren': [
                'miaoshu4386'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'toufayutoupi': '',
            'touludaxiaojixingzhuang': '',
            'touluchutong': '',
            'toubuyundong': '',
            'yanjian4739': '',
            'jiemo3729': '',
            'gongmo3647': '',
            'jiaomo3768': '',
            'tongkongdaxiaojixingzhuang': '',
            'duigaungfanshe': '',
            'yanqiu4387': '',
            'shili3648': '',
            'shiye5239': '',
            'sejue4128': '',
            'erkuo2749': '',
            'waierdao389': '',
            'rutu358': '',
            'tingli428': '',
            'waixing5287': '',
            'biyishandong': '',
            'biqiangchuxue374': '',
            'tongchang4832': '',
            'yicahngfenmiwu438': '',
            'bidouyatong4378': '',
            'qiwei4367': '',
            'kouchunyanse275': '',
            'kouqiangnianmo327': '',
            'yachi4628': '',
            'yayin5368': '',
            'shetai4389': '',
            'shedaxiao437': '',
            'yanbu3980': '',
            'yanbunianmo346': '',
            'biantaoti4638': '',
            'saixian3658': '',
            'miaoshu4386': ''
          }
        },
        "fqn": "respiratory.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'toufayutoupi',
          'touludaxiaojixingzhuang',
          'touluchutong',
          'toubuyundong',
          'yanjian4739',
          'jiemo3729',
          'gongmo3647',
          'jiaomo3768',
          'tongkongdaxiaojixingzhuang',
          'duigaungfanshe',
          'yanqiu4387',
          'shili3648',
          'shiye5239',
          'sejue4128',
          'erkuo2749',
          'waierdao389',
          'rutu358',
          'tingli428',
          'waixing5287',
          'biyishandong',
          'biqiangchuxue374',
          'tongchang4832',
          'yicahngfenmiwu438',
          'bidouyatong4378',
          'qiwei4367',
          'kouchunyanse275',
          'kouqiangnianmo327',
          'yachi4628',
          'yayin5368',
          'shetai4389',
          'shedaxiao437',
          'yanbu3980',
          'yanbunianmo346',
          'biantaoti4638',
          'saixian3658',
          'miaoshu4386'
        ],
        'hasRecHeader': true
      },
      'jin_table': {
        'elementKey': 'jin_table',
        'pageElementIndex': '2',
        'pageKey': 'respiratory',
        'tableKey': 'jin_table',
        'isTable': true,
        'label': '颈部',
        'addButtonText': '添加颈部评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'jin_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'jin_table_name',
              'jin_table_profession',
              'jin_table_day',
              'jin_table_time'
            ]
          },
          {
            'label': '外形与运动',
            'ehr_list_index': '2',
            'items': [
              'waixingyuyundong'
            ]
          },
          {
            'label': '颈动脉充盈度',
            'ehr_list_index': '3',
            'items': [
              'jingdongmaichongyingdu'
            ]
          },
          {
            'label': '颈静脉搏动',
            'ehr_list_index': '4',
            'items': [
              'jingjingmaibodong473'
            ]
          },
          {
            'label': '颈动脉搏动',
            'ehr_list_index': '5',
            'items': [
              'jingdongmaibodong658'
            ]
          },
          {
            'label': '甲状腺肿大',
            'ehr_list_index': '6',
            'items': [
              'jiazhuangxainzhongda438'
            ]
          },
          {
            'label': '大小',
            'ehr_list_index': '7',
            'items': [
              'daixao5479'
            ]
          },
          {
            'label': '轮廓',
            'ehr_list_index': '8',
            'items': [
              'lunkuo765'
            ]
          },
          {
            'label': '质地',
            'ehr_list_index': '9',
            'items': [
              'zhidi3275'
            ]
          },
          {
            'label': '震颤',
            'ehr_list_index': '10',
            'items': [
              'zhencahn3658'
            ]
          },
          {
            'label': '血管杂音',
            'ehr_list_index': '11',
            'items': [
              'xueguanzayin438'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '12',
            'items': [
              'weizhi4376'
            ]
          },
          {
            'ehr_list_index': '13',
            'items': [
              'miaoshu3754'
            ]
          }
        ],
        'form': {
          'elementKey': 'jin_table',
          'label': '颈部',
          'addButtonText': '添加颈部评估',
          'formOption': 'transpose',
          'formKey': 'jin_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group50',
              'formCss': 'record-header',
              "fqn": "respiratory.ehr_group50",
              'gIndex': '1',
              'gChildren': [
                'jin_table_name',
                'jin_table_profession',
                'jin_table_day',
                'jin_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group51',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group51",
              'gIndex': '2',
              'gChildren': [
                'waixingyuyundong'
              ]
            },
            {
              'elementKey': 'ehr_group52',
              'label': '颈部血管',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group52",
              'gIndex': '3',
              'gChildren': [
                'jingdongmaichongyingdu',
                'jingjingmaibodong473',
                'jingdongmaibodong658'
              ]
            },
            {
              'elementKey': 'ehr_group53',
              'label': '甲状腺',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group53",
              'gIndex': '4',
              'gChildren': [
                'jiazhuangxainzhongda438',
                'daixao5479',
                'lunkuo765',
                'zhidi3275',
                'zhencahn3658',
                'xueguanzayin438'
              ]
            },
            {
              'elementKey': 'ehr_group54',
              'label': '气管',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "respiratory.ehr_group54",
              'gIndex': '5',
              'gChildren': [
                'weizhi4376'
              ]
            },
            {
              'elementKey': 'ehr_group55',
              'formCss': 'grid-left-to-right-3',
              "fqn": "respiratory.ehr_group55",
              'gIndex': '6',
              'gChildren': [
                'miaoshu3754'
              ]
            }
          ],
          'ehr_data': {
            'jin_table_name': '',
            'jin_table_profession': '',
            'jin_table_day': '',
            'jin_table_time': '',
            'waixingyuyundong': '',
            'jingdongmaichongyingdu': '',
            'jingjingmaibodong473': '',
            'jingdongmaibodong658': '',
            'jiazhuangxainzhongda438': '',
            'daixao5479': '',
            'lunkuo765': '',
            'zhidi3275': '',
            'zhencahn3658': '',
            'xueguanzayin438': '',
            'weizhi4376': '',
            'miaoshu3754': ''
          }
        },
        "fqn": "respiratory.table1",
        'tableChildren': [
          'jin_table_id',
          'jin_table_name',
          'jin_table_profession',
          'jin_table_day',
          'jin_table_time',
          'waixingyuyundong',
          'jingdongmaichongyingdu',
          'jingjingmaibodong473',
          'jingdongmaibodong658',
          'jiazhuangxainzhongda438',
          'daixao5479',
          'lunkuo765',
          'zhidi3275',
          'zhencahn3658',
          'xueguanzayin438',
          'weizhi4376',
          'miaoshu3754'
        ],
        'hasRecHeader': true
      }
    }
  },
  'cardiovascular': {
    'pageDataKey': 'cardiovascular',
    'pageTitle': '胸部及乳房评估',
    'pIndex': '16',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'cardiovascular.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'cardiovascular.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'cardiovascular.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'cardiovascular.time'
      },
      {
        'elementKey': 'xiongkuoduichenxing265',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸廓对称性',
        'options': [
          {
            'key': '两侧对称',
            'text': '两侧对称'
          },
          {
            'key': '一侧变形',
            'text': '一侧变形'
          },
          {
            'key': '局部变形',
            'text': '局部变形'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '胸廓对称性',
        'fqn': 'cardiovascular.xiongkuoduichenxing265'
      },
      {
        'elementKey': 'xiongkuoqianhoujing490',
        'formIndex': '1',
        'inputType': 'text',
        'label': '胸廓前后径',
        'tableColumn': '3',
        'tableLabel': '胸廓前后径',
        'fqn': 'cardiovascular.xiongkuoqianhoujing490'
      },
      {
        'elementKey': 'xiongkuooxingzhuang427',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸廓形状',
        'options': [
          {
            'key': '椭圆形',
            'text': '椭圆形'
          },
          {
            'key': '近圆形',
            'text': '近圆形'
          },
          {
            'key': '扁平型',
            'text': '扁平型'
          },
          {
            'key': '桶状胸',
            'text': '桶状胸'
          },
          {
            'key': '鸡胸',
            'text': '鸡胸'
          },
          {
            'key': '佝偻病串珠',
            'text': '佝偻病串珠'
          },
          {
            'key': '肋膈沟',
            'text': '肋膈沟'
          },
          {
            'key': '漏斗胸',
            'text': '漏斗胸'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '胸廓形状',
        'fqn': 'cardiovascular.xiongkuooxingzhuang427'
      },
      {
        'elementKey': 'jizhuxingzhuang369',
        'formIndex': '1',
        'inputType': 'select',
        'label': '脊柱形状',
        'options': [
          {
            'key': '前凸',
            'text': '前凸'
          },
          {
            'key': '后凸',
            'text': '后凸'
          },
          {
            'key': '侧凸',
            'text': '侧凸'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '脊柱形状',
        'fqn': 'cardiovascular.jizhuxingzhuang369'
      },
      {
        'elementKey': 'xiongbujingmai528',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸壁静脉',
        'options': [
          {
            'key': '无明显可见',
            'text': '无明显可见'
          },
          {
            'key': '充盈',
            'text': '充盈'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '胸壁静脉',
        'fqn': 'cardiovascular.xiongbujingmai528'
      },
      {
        'elementKey': 'leijianxi386',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肋间隙',
        'options': [
          {
            'key': '平坦',
            'text': '平坦'
          },
          {
            'key': '凹陷',
            'text': '凹陷'
          },
          {
            'key': '膨隆',
            'text': '膨隆'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '肋间隙',
        'fqn': 'cardiovascular.leijianxi386'
      },
      {
        'elementKey': 'huxiyundonggaibian486',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '呼吸运动改变',
        'options': [
          {
            'key': '胸式呼吸减弱',
            'text': '胸式呼吸减弱'
          },
          {
            'key': '胸式呼吸增强',
            'text': '胸式呼吸增强'
          },
          {
            'key': '腹式呼吸减弱',
            'text': '腹式呼吸减弱'
          },
          {
            'key': '腹式呼吸增强',
            'text': '腹式呼吸增强'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '呼吸运动改变',
        'fqn': 'cardiovascular.huxiyundonggaibian486'
      },
      {
        'elementKey': 'huxikunnan438',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸困难',
        'options': [
          {
            'key': '吸气性',
            'text': '吸气性'
          },
          {
            'key': '呼气性',
            'text': '呼气性'
          },
          {
            'key': '混合性',
            'text': '混合性'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '呼吸困难',
        'fqn': 'cardiovascular.huxikunnan438'
      },
      {
        'elementKey': 'huxipinlv438',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸频率',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '过速',
            'text': '过速'
          },
          {
            'key': '过缓',
            'text': '过缓'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '呼吸频率',
        'fqn': 'cardiovascular.huxipinlv438'
      },
      {
        'elementKey': 'huxishendu639',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸深度',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '过深',
            'text': '过深'
          },
          {
            'key': '过浅',
            'text': '过浅'
          }
        ],
        'tableColumn': '11',
        'tableLabel': '呼吸深度',
        'fqn': 'cardiovascular.huxishendu639'
      },
      {
        'elementKey': 'huxijielv364',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸节律',
        'options': [
          {
            'key': '整齐',
            'text': '整齐'
          },
          {
            'key': '潮式呼吸',
            'text': '潮式呼吸'
          },
          {
            'key': '间停呼吸',
            'text': '间停呼吸'
          },
          {
            'key': '叹息样呼吸',
            'text': '叹息样呼吸'
          }
        ],
        'tableColumn': '12',
        'tableLabel': '呼吸节律',
        'fqn': 'cardiovascular.huxijielv364'
      },
      {
        'elementKey': 'xiongkuokuzhangdu428',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸廓扩张度',
        'options': [
          {
            'key': '双侧一致',
            'text': '双侧一致'
          },
          {
            'key': '左侧增强',
            'text': '左侧增强'
          },
          {
            'key': '左侧减弱',
            'text': '左侧减弱'
          },
          {
            'key': '右侧增强',
            'text': '右侧增强'
          },
          {
            'key': '右侧减弱',
            'text': '右侧减弱'
          }
        ],
        'tableColumn': '13',
        'tableLabel': '胸廓扩张度',
        'fqn': 'cardiovascular.xiongkuokuzhangdu428'
      },
      {
        'elementKey': 'xiongbiyatong386',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸壁压痛',
        'options': [
          {
            'key': '无压痛',
            'text': '无压痛'
          },
          {
            'key': '有压痛',
            'text': '有压痛'
          },
          {
            'key': '叩击痛',
            'text': '叩击痛'
          }
        ],
        'tableColumn': '14',
        'tableLabel': '胸壁压痛',
        'fqn': 'cardiovascular.xiongbiyatong386'
      },
      {
        'elementKey': 'pixiaqizhong385',
        'formIndex': '1',
        'inputType': 'select',
        'label': '皮下气肿',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '15',
        'tableLabel': '皮下气肿',
        'fqn': 'cardiovascular.pixiaqizhong385'
      },
      {
        'elementKey': 'yuyinzhenchan428',
        'formIndex': '1',
        'inputType': 'text',
        'label': '语音震颤',
        'tableColumn': '16',
        'tableLabel': '语音震颤',
        'fqn': 'cardiovascular.yuyinzhenchan428'
      },
      {
        'elementKey': 'xiongmomocagan396',
        'formIndex': '1',
        'inputType': 'select',
        'label': '胸膜摩擦感',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '17',
        'tableLabel': '胸膜摩擦感',
        'fqn': 'cardiovascular.xiongmomocagan396'
      },
      {
        'elementKey': 'kouzhenyin3749',
        'formIndex': '1',
        'inputType': 'select',
        'label': '叩诊音',
        'options': [
          {
            'key': '清音',
            'text': '清音'
          },
          {
            'key': '浊音',
            'text': '浊音'
          },
          {
            'key': '实音',
            'text': '实音'
          },
          {
            'key': '鼓音',
            'text': '鼓音'
          }
        ],
        'tableColumn': '18',
        'tableLabel': '叩诊音',
        'fqn': 'cardiovascular.kouzhenyin3749'
      },
      {
        'elementKey': 'feixiajie374',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肺下界',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '上升',
            'text': '上升'
          },
          {
            'key': '降低',
            'text': '降低'
          }
        ],
        'tableColumn': '19',
        'tableLabel': '肺下界',
        'fqn': 'cardiovascular.feixiajie374'
      },
      {
        'elementKey': 'feixaijieyidongdu274',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肺下界移动度',
        'options': [
          {
            'key': '缩小',
            'text': '缩小'
          },
          {
            'key': '消失',
            'text': '消失'
          }
        ],
        'tableColumn': '20',
        'tableLabel': '肺下界移动度',
        'fqn': 'cardiovascular.feixaijieyidongdu274'
      },
      {
        'elementKey': 'huxiyin5879',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸音',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '异常',
            'text': '异常'
          }
        ],
        'tableColumn': '21',
        'tableLabel': '呼吸音',
        'fqn': 'cardiovascular.huxiyin5879'
      },
      {
        'elementKey': 'luoyin4275',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '啰音',
        'options': [
          {
            'key': '干啰音',
            'text': '干啰音'
          },
          {
            'key': '湿罗音',
            'text': '湿罗音'
          }
        ],
        'tableColumn': '22',
        'tableLabel': '啰音',
        'fqn': 'cardiovascular.luoyin4275'
      },
      {
        'elementKey': 'mocayin3759',
        'formIndex': '1',
        'inputType': 'select',
        'label': '摩擦音',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '23',
        'tableLabel': '摩擦音',
        'fqn': 'cardiovascular.mocayin3759'
      },
      {
        'elementKey': 'comments5830',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '24',
        'tableLabel': '描述',
        'fqn': 'cardiovascular.comments5830'
      },
      {
        'elementKey': 'ruf_table_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'cardiovascular.name'
      },
      {
        'elementKey': 'ruf_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'ruf_table_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'cardiovascular.profession'
      },
      {
        'elementKey': 'ruf_table_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'cardiovascular.day'
      },
      {
        'elementKey': 'ruf_table_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'cardiovascular.time'
      },
      {
        'elementKey': 'waixingduichenxing374',
        'formIndex': '2',
        'inputType': 'select',
        'label': '外形对称性',
        'options': [
          {
            'key': '基本对称',
            'text': '基本对称'
          },
          {
            'key': '一侧明显增大',
            'text': '一侧明显增大'
          },
          {
            'key': '一侧明显缩小',
            'text': '一侧明显缩小'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '外形对称性',
        'fqn': 'cardiovascular.waixingduichenxing374'
      },
      {
        'elementKey': 'pifu4289',
        'formIndex': '2',
        'inputType': 'select',
        'label': '皮肤',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '发红',
            'text': '发红'
          },
          {
            'key': '溃疡',
            'text': '溃疡'
          },
          {
            'key': '水肿',
            'text': '水肿'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '皮肤',
        'fqn': 'cardiovascular.pifu4289'
      },
      {
        'elementKey': 'pifuhuisuo375',
        'formIndex': '2',
        'inputType': 'select',
        'label': '皮肤回缩',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '皮肤回缩',
        'fqn': 'cardiovascular.pifuhuisuo375'
      },
      {
        'elementKey': 'rutou3759',
        'formIndex': '2',
        'inputType': 'select',
        'label': '乳头',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '有分泌物',
            'text': '有分泌物'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '乳头',
        'fqn': 'cardiovascular.rutou3759'
      },
      {
        'elementKey': 'ruyun3659',
        'formIndex': '2',
        'inputType': 'select',
        'label': '乳晕',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '颜色加深',
            'text': '颜色加深'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '乳晕',
        'fqn': 'cardiovascular.ruyun3659'
      },
      {
        'elementKey': 'yewojisuogushangwo',
        'formIndex': '2',
        'inputType': 'select',
        'label': '腋窝及锁骨丄窝',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '红肿',
            'text': '红肿'
          },
          {
            'key': '包块',
            'text': '包块'
          },
          {
            'key': '溃疡',
            'text': '溃疡'
          },
          {
            'key': '瘘管',
            'text': '瘘管'
          },
          {
            'key': '癫痕',
            'text': '癫痕'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '腋窝及锁骨丄窝',
        'fqn': 'cardiovascular.yewojisuogushangwo'
      },
      {
        'elementKey': 'yingduhetanxing326',
        'formIndex': '2',
        'inputType': 'select',
        'label': '硬度和弹性',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '异常',
            'text': '异常'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '硬度和弹性',
        'fqn': 'cardiovascular.yingduhetanxing326'
      },
      {
        'elementKey': 'yatong538',
        'formIndex': '2',
        'inputType': 'select',
        'label': '压痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '压痛',
        'fqn': 'cardiovascular.yatong538'
      },
      {
        'elementKey': 'baokuai329',
        'formIndex': '2',
        'inputType': 'select',
        'label': '包块',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '包块',
        'fqn': 'cardiovascular.baokuai329'
      },
      {
        'elementKey': 'comments5285',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '11',
        'tableLabel': '描述',
        'fqn': 'cardiovascular.comments5285'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'cardiovascular',
        'tableKey': 'table',
        'isTable': true,
        'label': '胸廓及肺脏',
        'addButtonText': '添加胸廓及肺脏评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '胸廓对称性',
            'ehr_list_index': '2',
            'items': [
              'xiongkuoduichenxing265'
            ]
          },
          {
            'label': '胸廓前后径',
            'ehr_list_index': '3',
            'items': [
              'xiongkuoqianhoujing490'
            ]
          },
          {
            'label': '胸廓形状',
            'ehr_list_index': '4',
            'items': [
              'xiongkuooxingzhuang427'
            ]
          },
          {
            'label': '脊柱形状',
            'ehr_list_index': '5',
            'items': [
              'jizhuxingzhuang369'
            ]
          },
          {
            'label': '胸壁静脉',
            'ehr_list_index': '6',
            'items': [
              'xiongbujingmai528'
            ]
          },
          {
            'label': '肋间隙',
            'ehr_list_index': '7',
            'items': [
              'leijianxi386'
            ]
          },
          {
            'label': '呼吸运动改变',
            'ehr_list_index': '8',
            'items': [
              'huxiyundonggaibian486'
            ]
          },
          {
            'label': '呼吸困难',
            'ehr_list_index': '9',
            'items': [
              'huxikunnan438'
            ]
          },
          {
            'label': '呼吸频率',
            'ehr_list_index': '10',
            'items': [
              'huxipinlv438'
            ]
          },
          {
            'label': '呼吸深度',
            'ehr_list_index': '11',
            'items': [
              'huxishendu639'
            ]
          },
          {
            'label': '呼吸节律',
            'ehr_list_index': '12',
            'items': [
              'huxijielv364'
            ]
          },
          {
            'label': '胸廓扩张度',
            'ehr_list_index': '13',
            'items': [
              'xiongkuokuzhangdu428'
            ]
          },
          {
            'label': '胸壁压痛',
            'ehr_list_index': '14',
            'items': [
              'xiongbiyatong386'
            ]
          },
          {
            'label': '皮下气肿',
            'ehr_list_index': '15',
            'items': [
              'pixiaqizhong385'
            ]
          },
          {
            'label': '语音震颤',
            'ehr_list_index': '16',
            'items': [
              'yuyinzhenchan428'
            ]
          },
          {
            'label': '胸膜摩擦感',
            'ehr_list_index': '17',
            'items': [
              'xiongmomocagan396'
            ]
          },
          {
            'label': '叩诊音',
            'ehr_list_index': '18',
            'items': [
              'kouzhenyin3749'
            ]
          },
          {
            'label': '肺下界',
            'ehr_list_index': '19',
            'items': [
              'feixiajie374'
            ]
          },
          {
            'label': '肺下界移动度',
            'ehr_list_index': '20',
            'items': [
              'feixaijieyidongdu274'
            ]
          },
          {
            'label': '呼吸音',
            'ehr_list_index': '21',
            'items': [
              'huxiyin5879'
            ]
          },
          {
            'label': '啰音',
            'ehr_list_index': '22',
            'items': [
              'luoyin4275'
            ]
          },
          {
            'label': '摩擦音',
            'ehr_list_index': '23',
            'items': [
              'mocayin3759'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '24',
            'items': [
              'comments5830'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Cardiovascular assessment",
          "addButtonText": "Add a cardiovascular assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group56',
              'formCss': 'record-header',
              "fqn": "cardiovascular.ehr_group56",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group57',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group57",
              'gIndex': '2',
              'gChildren': [
                'xiongkuoduichenxing265',
                'xiongkuoqianhoujing490',
                'xiongkuooxingzhuang427',
                'jizhuxingzhuang369',
                'xiongbujingmai528',
                'leijianxi386',
                'huxiyundonggaibian486',
                'huxikunnan438',
                'huxipinlv438',
                'huxishendu639',
                'huxijielv364'
              ]
            },
            {
              'elementKey': 'ehr_group58',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group58",
              'gIndex': '3',
              'gChildren': [
                'xiongkuokuzhangdu428',
                'xiongbiyatong386',
                'pixiaqizhong385',
                'yuyinzhenchan428',
                'xiongmomocagan396'
              ]
            },
            {
              'elementKey': 'ehr_group59',
              'label': '叩诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group59",
              'gIndex': '4',
              'gChildren': [
                'kouzhenyin3749',
                'feixiajie374',
                'feixaijieyidongdu274'
              ]
            },
            {
              'elementKey': 'ehr_group60',
              'label': '听诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group60",
              'gIndex': '5',
              'gChildren': [
                'huxiyin5879',
                'luoyin4275',
                'mocayin3759'
              ]
            },
            {
              'elementKey': 'ehr_group61',
              'formCss': 'grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group61",
              'gIndex': '6',
              'gChildren': [
                'comments5830'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'xiongkuoduichenxing265': '',
            'xiongkuoqianhoujing490': '',
            'xiongkuooxingzhuang427': '',
            'jizhuxingzhuang369': '',
            'xiongbujingmai528': '',
            'leijianxi386': '',
            'huxiyundonggaibian486': '',
            'huxikunnan438': '',
            'huxipinlv438': '',
            'huxishendu639': '',
            'huxijielv364': '',
            'xiongkuokuzhangdu428': '',
            'xiongbiyatong386': '',
            'pixiaqizhong385': '',
            'yuyinzhenchan428': '',
            'xiongmomocagan396': '',
            'kouzhenyin3749': '',
            'feixiajie374': '',
            'feixaijieyidongdu274': '',
            'huxiyin5879': '',
            'luoyin4275': '',
            'mocayin3759': '',
            'comments5830': ''
          }
        },
        "fqn": "cardiovascular.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'xiongkuoduichenxing265',
          'xiongkuoqianhoujing490',
          'xiongkuooxingzhuang427',
          'jizhuxingzhuang369',
          'xiongbujingmai528',
          'leijianxi386',
          'huxiyundonggaibian486',
          'huxikunnan438',
          'huxipinlv438',
          'huxishendu639',
          'huxijielv364',
          'xiongkuokuzhangdu428',
          'xiongbiyatong386',
          'pixiaqizhong385',
          'yuyinzhenchan428',
          'xiongmomocagan396',
          'kouzhenyin3749',
          'feixiajie374',
          'feixaijieyidongdu274',
          'huxiyin5879',
          'luoyin4275',
          'mocayin3759',
          'comments5830'
        ],
        'hasRecHeader': true
      },
      'ruf_table': {
        'elementKey': 'ruf_table',
        'pageElementIndex': '2',
        'pageKey': 'cardiovascular',
        'tableKey': 'ruf_table',
        'isTable': true,
        'label': '乳房',
        'addButtonText': '添加乳房评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'ruf_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'ruf_table_name',
              'ruf_table_profession',
              'ruf_table_day',
              'ruf_table_time'
            ]
          },
          {
            'label': '外形对称性',
            'ehr_list_index': '2',
            'items': [
              'waixingduichenxing374'
            ]
          },
          {
            'label': '皮肤',
            'ehr_list_index': '3',
            'items': [
              'pifu4289'
            ]
          },
          {
            'label': '皮肤回缩',
            'ehr_list_index': '4',
            'items': [
              'pifuhuisuo375'
            ]
          },
          {
            'label': '乳头',
            'ehr_list_index': '5',
            'items': [
              'rutou3759'
            ]
          },
          {
            'label': '乳晕',
            'ehr_list_index': '6',
            'items': [
              'ruyun3659'
            ]
          },
          {
            'label': '腋窝及锁骨丄窝',
            'ehr_list_index': '7',
            'items': [
              'yewojisuogushangwo'
            ]
          },
          {
            'label': '硬度和弹性',
            'ehr_list_index': '8',
            'items': [
              'yingduhetanxing326'
            ]
          },
          {
            'label': '压痛',
            'ehr_list_index': '9',
            'items': [
              'yatong538'
            ]
          },
          {
            'label': '包块',
            'ehr_list_index': '10',
            'items': [
              'baokuai329'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '11',
            'items': [
              'comments5285'
            ]
          }
        ],
        'form': {
          'elementKey': 'ruf_table',
          'label': '乳房',
          'addButtonText': '添加乳房评估',
          'formOption': 'transpose',
          'formKey': 'ruf_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group62',
              'formCss': 'record-header',
              "fqn": "cardiovascular.ehr_group62",
              'gIndex': '1',
              'gChildren': [
                'ruf_table_name',
                'ruf_table_profession',
                'ruf_table_day',
                'ruf_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group63',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group63",
              'gIndex': '2',
              'gChildren': [
                'waixingduichenxing374',
                'pifu4289',
                'pifuhuisuo375',
                'rutou3759',
                'ruyun3659',
                'yewojisuogushangwo'
              ]
            },
            {
              'elementKey': 'ehr_group64',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group64",
              'gIndex': '3',
              'gChildren': [
                'yingduhetanxing326',
                'yatong538',
                'baokuai329'
              ]
            },
            {
              'elementKey': 'ehr_group65',
              'formCss': 'grid-left-to-right-3',
              "fqn": "cardiovascular.ehr_group65",
              'gIndex': '4',
              'gChildren': [
                'comments5285'
              ]
            }
          ],
          'ehr_data': {
            'ruf_table_name': '',
            'ruf_table_profession': '',
            'ruf_table_day': '',
            'ruf_table_time': '',
            'waixingduichenxing374': '',
            'pifu4289': '',
            'pifuhuisuo375': '',
            'rutou3759': '',
            'ruyun3659': '',
            'yewojisuogushangwo': '',
            'yingduhetanxing326': '',
            'yatong538': '',
            'baokuai329': '',
            'comments5285': ''
          }
        },
        "fqn": "cardiovascular.table1",
        'tableChildren': [
          'ruf_table_id',
          'ruf_table_name',
          'ruf_table_profession',
          'ruf_table_day',
          'ruf_table_time',
          'waixingduichenxing374',
          'pifu4289',
          'pifuhuisuo375',
          'rutou3759',
          'ruyun3659',
          'yewojisuogushangwo',
          'yingduhetanxing326',
          'yatong538',
          'baokuai329',
          'comments5285'
        ],
        'hasRecHeader': true
      }
    }
  },
  'gastrointestinal': {
    'pageDataKey': 'gastrointestinal',
    'pageTitle': '心脏及血管评估',
    'pIndex': '17',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'gastrointestinal.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'gastrointestinal.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'gastrointestinal.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'gastrointestinal.time'
      },
      {
        'elementKey': 'xinqianquwaixing397',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心前区外形',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '异常隆起',
            'text': '异常隆起'
          },
          {
            'key': '心前区饱满',
            'text': '心前区饱满'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '心前区外形',
        'fqn': 'gastrointestinal.xinqianquwaixing397'
      },
      {
        'elementKey': 'xinjianbodong386',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心尖搏动',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '移位',
            'text': '移位'
          },
          {
            'key': '增强',
            'text': '增强'
          },
          {
            'key': '减弱',
            'text': '减弱'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '心尖搏动',
        'fqn': 'gastrointestinal.xinjianbodong386'
      },
      {
        'elementKey': 'xinqianquyichangbodong86',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心前区异常搏动',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '心前区异常搏动',
        'fqn': 'gastrointestinal.xinqianquyichangbodong86'
      },
      {
        'elementKey': 'xinjianbodong389',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心尖搏动',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '移位',
            'text': '移位'
          },
          {
            'key': '增强',
            'text': '增强'
          },
          {
            'key': '减弱',
            'text': '减弱'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '心尖搏动',
        'fqn': 'gastrointestinal.xinjianbodong389'
      },
      {
        'elementKey': 'zhenchan397',
        'formIndex': '1',
        'inputType': 'select',
        'label': '震颤',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '震颤',
        'fqn': 'gastrointestinal.zhenchan397'
      },
      {
        'elementKey': 'xinbaomocagan492',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心包摩擦感',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '心包摩擦感',
        'fqn': 'gastrointestinal.xinbaomocagan492'
      },
      {
        'elementKey': 'zhuoyinjie694',
        'formIndex': '1',
        'inputType': 'text',
        'label': '浊音界',
        'tableColumn': '8',
        'tableLabel': '浊音界',
        'fqn': 'gastrointestinal.zhuoyinjie694'
      },
      {
        'elementKey': 'xinlv3875',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心率',
        'options': [
          {
            'key': '正常心率',
            'text': '正常心率'
          },
          {
            'key': '心动过速',
            'text': '心动过速'
          },
          {
            'key': '心动过缓',
            'text': '心动过缓'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '心率',
        'fqn': 'gastrointestinal.xinlv3875'
      },
      {
        'elementKey': 'xinlv2750',
        'formIndex': '1',
        'inputType': 'select',
        'label': '心律',
        'options': [
          {
            'key': '节律规整',
            'text': '节律规整'
          },
          {
            'key': '窦性心律不齐',
            'text': '窦性心律不齐'
          },
          {
            'key': '期前收缩',
            'text': '期前收缩'
          },
          {
            'key': '心房颤动',
            'text': '心房颤动'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '心律',
        'fqn': 'gastrointestinal.xinlv2750'
      },
      {
        'elementKey': 'xinyin4980',
        'formIndex': '1',
        'inputType': 'text',
        'label': '心音',
        'tableColumn': '11',
        'tableLabel': '心音',
        'fqn': 'gastrointestinal.xinyin4980'
      },
      {
        'elementKey': 'xinzangzayin8730',
        'formIndex': '1',
        'inputType': 'text',
        'label': '心脏杂音',
        'tableColumn': '12',
        'tableLabel': '心脏杂音',
        'fqn': 'gastrointestinal.xinzangzayin8730'
      },
      {
        'elementKey': 'xinbaomocayin482',
        'formIndex': '1',
        'inputType': 'text',
        'label': '心包摩擦音',
        'tableColumn': '13',
        'tableLabel': '心包摩擦音',
        'fqn': 'gastrointestinal.xinbaomocayin482'
      },
      {
        'elementKey': 'comments3960',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '14',
        'tableLabel': '描述',
        'fqn': 'gastrointestinal.comments3960'
      },
      {
        'elementKey': 'xue_table_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'gastrointestinal.name'
      },
      {
        'elementKey': 'xue_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'xue_table_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'gastrointestinal.profession'
      },
      {
        'elementKey': 'xue_table_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'gastrointestinal.day'
      },
      {
        'elementKey': 'xue_table_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'gastrointestinal.time'
      },
      {
        'elementKey': 'ganjingjingmaihuiliuzzheng386',
        'formIndex': '2',
        'inputType': 'select',
        'label': '肝颈静脉回流征',
        'options': [
          {
            'key': '是',
            'text': '是'
          },
          {
            'key': '否',
            'text': '否'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '肝颈静脉回流征',
        'fqn': 'gastrointestinal.ganjingjingmaihuiliuzzheng386'
      },
      {
        'elementKey': 'maoxixueguanmobongzheng396',
        'formIndex': '2',
        'inputType': 'select',
        'label': '毛细血管搏动征',
        'options': [
          {
            'key': '是',
            'text': '是'
          },
          {
            'key': '否',
            'text': '否'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '毛细血管搏动征',
        'fqn': 'gastrointestinal.maoxixueguanmobongzheng396'
      },
      {
        'elementKey': 'mailv7390',
        'formIndex': '2',
        'inputType': 'select',
        'label': '脉率',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '过快',
            'text': '过快'
          },
          {
            'key': '过慢',
            'text': '过慢'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '脉率',
        'fqn': 'gastrointestinal.mailv7390'
      },
      {
        'elementKey': 'mailv2904',
        'formIndex': '2',
        'inputType': 'select',
        'label': '脉律',
        'options': [
          {
            'key': '规则',
            'text': '规则'
          },
          {
            'key': '不规则',
            'text': '不规则'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '脉律',
        'fqn': 'gastrointestinal.mailv2904'
      },
      {
        'elementKey': 'maiboqiangdu392',
        'formIndex': '2',
        'inputType': 'select',
        'label': '脉搏强度',
        'options': [
          {
            'key': '强',
            'text': '强'
          },
          {
            'key': '弱',
            'text': '弱'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '脉搏强度',
        'fqn': 'gastrointestinal.maiboqiangdu392'
      },
      {
        'elementKey': 'maiboboxing3685',
        'formIndex': '2',
        'inputType': 'select',
        'label': '脉搏波形',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '水冲脉',
            'text': '水冲脉'
          },
          {
            'key': '交替脉',
            'text': '交替脉'
          },
          {
            'key': '奇脉',
            'text': '奇脉'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '脉搏波形',
        'fqn': 'gastrointestinal.maiboboxing3685'
      },
      {
        'elementKey': 'zayin7430',
        'formIndex': '2',
        'inputType': 'checkset',
        'label': '杂音',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '静脉杂音',
            'text': '静脉杂音'
          },
          {
            'key': '动脉杂音',
            'text': '动脉杂音'
          },
          {
            'key': '枪击音',
            'text': '枪击音'
          },
          {
            'key': 'duroziez双重杂音',
            'text': 'Duroziez双重杂音'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '杂音',
        'fqn': 'gastrointestinal.zayin7430'
      },
      {
        'elementKey': 'comments4975',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '9',
        'tableLabel': '描述',
        'fqn': 'gastrointestinal.comments4975'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'gastrointestinal',
        'tableKey': 'table',
        'isTable': true,
        'label': '心脏',
        'addButtonText': '添加心脏评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '心前区外形',
            'ehr_list_index': '2',
            'items': [
              'xinqianquwaixing397'
            ]
          },
          {
            'label': '心尖搏动',
            'ehr_list_index': '3',
            'items': [
              'xinjianbodong386'
            ]
          },
          {
            'label': '心前区异常搏动',
            'ehr_list_index': '4',
            'items': [
              'xinqianquyichangbodong86'
            ]
          },
          {
            'label': '心尖搏动',
            'ehr_list_index': '5',
            'items': [
              'xinjianbodong389'
            ]
          },
          {
            'label': '震颤',
            'ehr_list_index': '6',
            'items': [
              'zhenchan397'
            ]
          },
          {
            'label': '心包摩擦感',
            'ehr_list_index': '7',
            'items': [
              'xinbaomocagan492'
            ]
          },
          {
            'label': '浊音界',
            'ehr_list_index': '8',
            'items': [
              'zhuoyinjie694'
            ]
          },
          {
            'label': '心率',
            'ehr_list_index': '9',
            'items': [
              'xinlv3875'
            ]
          },
          {
            'label': '心律',
            'ehr_list_index': '10',
            'items': [
              'xinlv2750'
            ]
          },
          {
            'label': '心音',
            'ehr_list_index': '11',
            'items': [
              'xinyin4980'
            ]
          },
          {
            'label': '心脏杂音',
            'ehr_list_index': '12',
            'items': [
              'xinzangzayin8730'
            ]
          },
          {
            'label': '心包摩擦音',
            'ehr_list_index': '13',
            'items': [
              'xinbaomocayin482'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '14',
            'items': [
              'comments3960'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Gastrointestinal assessment",
          "addButtonText": "Add a gastrointestinal assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group66',
              'formCss': 'record-header',
              "fqn": "gastrointestinal.ehr_group66",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group67',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group67",
              'gIndex': '2',
              'gChildren': [
                'xinqianquwaixing397',
                'xinjianbodong386',
                'xinqianquyichangbodong86'
              ]
            },
            {
              'elementKey': 'emesis',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.emesis",
              'gIndex': '3',
              'gChildren': [
                'xinjianbodong389',
                'zhenchan397',
                'xinbaomocagan492'
              ]
            },
            {
              'elementKey': 'eatingGroup',
              'label': '叩诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.eatingGroup",
              'gIndex': '4',
              'gChildren': [
                'zhuoyinjie694'
              ]
            },
            {
              'elementKey': 'stool',
              'label': '听诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.stool",
              'gIndex': '5',
              'gChildren': [
                'xinlv3875',
                'xinlv2750',
                'xinyin4980',
                'xinzangzayin8730',
                'xinbaomocayin482'
              ]
            },
            {
              'elementKey': 'ehr_group68',
              'formCss': 'grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group68",
              'gIndex': '6',
              'gChildren': [
                'comments3960'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'xinqianquwaixing397': '',
            'xinjianbodong386': '',
            'xinqianquyichangbodong86': '',
            'xinjianbodong389': '',
            'zhenchan397': '',
            'xinbaomocagan492': '',
            'zhuoyinjie694': '',
            'xinlv3875': '',
            'xinlv2750': '',
            'xinyin4980': '',
            'xinzangzayin8730': '',
            'xinbaomocayin482': '',
            'comments3960': ''
          }
        },
        "fqn": "gastrointestinal.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'xinqianquwaixing397',
          'xinjianbodong386',
          'xinqianquyichangbodong86',
          'xinjianbodong389',
          'zhenchan397',
          'xinbaomocagan492',
          'zhuoyinjie694',
          'xinlv3875',
          'xinlv2750',
          'xinyin4980',
          'xinzangzayin8730',
          'xinbaomocayin482',
          'comments3960'
        ],
        'hasRecHeader': true
      },
      'xue_table': {
        'elementKey': 'xue_table',
        'pageElementIndex': '2',
        'pageKey': 'gastrointestinal',
        'tableKey': 'xue_table',
        'isTable': true,
        'label': '血管',
        'addButtonText': '添加血管评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'xue_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'xue_table_name',
              'xue_table_profession',
              'xue_table_day',
              'xue_table_time'
            ]
          },
          {
            'label': '肝颈静脉回流征',
            'ehr_list_index': '2',
            'items': [
              'ganjingjingmaihuiliuzzheng386'
            ]
          },
          {
            'label': '毛细血管搏动征',
            'ehr_list_index': '3',
            'items': [
              'maoxixueguanmobongzheng396'
            ]
          },
          {
            'label': '脉率',
            'ehr_list_index': '4',
            'items': [
              'mailv7390'
            ]
          },
          {
            'label': '脉律',
            'ehr_list_index': '5',
            'items': [
              'mailv2904'
            ]
          },
          {
            'label': '脉搏强度',
            'ehr_list_index': '6',
            'items': [
              'maiboqiangdu392'
            ]
          },
          {
            'label': '脉搏波形',
            'ehr_list_index': '7',
            'items': [
              'maiboboxing3685'
            ]
          },
          {
            'label': '杂音',
            'ehr_list_index': '8',
            'items': [
              'zayin7430'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '9',
            'items': [
              'comments4975'
            ]
          }
        ],
        'form': {
          'elementKey': 'xue_table',
          'label': '血管',
          'addButtonText': '添加血管评估',
          'formOption': 'transpose',
          'formKey': 'xue_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group69',
              'formCss': 'record-header',
              "fqn": "gastrointestinal.ehr_group69",
              'gIndex': '1',
              'gChildren': [
                'xue_table_name',
                'xue_table_profession',
                'xue_table_day',
                'xue_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group70',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group70",
              'gIndex': '2',
              'gChildren': [
                'ganjingjingmaihuiliuzzheng386',
                'maoxixueguanmobongzheng396'
              ]
            },
            {
              'elementKey': 'ehr_group71',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group71",
              'gIndex': '3',
              'gChildren': [
                'mailv7390',
                'mailv2904',
                'maiboqiangdu392',
                'maiboboxing3685'
              ]
            },
            {
              'elementKey': 'ehr_group72',
              'label': '听诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group72",
              'gIndex': '4',
              'gChildren': [
                'zayin7430'
              ]
            },
            {
              'elementKey': 'ehr_group73',
              'formCss': 'grid-left-to-right-3',
              "fqn": "gastrointestinal.ehr_group73",
              'gIndex': '5',
              'gChildren': [
                'comments4975'
              ]
            }
          ],
          'ehr_data': {
            'xue_table_name': '',
            'xue_table_profession': '',
            'xue_table_day': '',
            'xue_table_time': '',
            'ganjingjingmaihuiliuzzheng386': '',
            'maoxixueguanmobongzheng396': '',
            'mailv7390': '',
            'mailv2904': '',
            'maiboqiangdu392': '',
            'maiboboxing3685': '',
            'zayin7430': '',
            'comments4975': ''
          }
        },
        "fqn": "gastrointestinal.table1",
        'tableChildren': [
          'xue_table_id',
          'xue_table_name',
          'xue_table_profession',
          'xue_table_day',
          'xue_table_time',
          'ganjingjingmaihuiliuzzheng386',
          'maoxixueguanmobongzheng396',
          'mailv7390',
          'mailv2904',
          'maiboqiangdu392',
          'maiboboxing3685',
          'zayin7430',
          'comments4975'
        ],
        'hasRecHeader': true
      }
    }
  },
  'genitourinary': {
    'pageDataKey': 'genitourinary',
    'pageTitle': '腹部评估',
    'pIndex': '18',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'genitourinary.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'genitourinary.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'genitourinary.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'genitourinary.time'
      },
      {
        'elementKey': 'fubuwaixing389',
        'formIndex': '1',
        'inputType': 'select',
        'label': '腹部外形',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '腹部膨隆',
            'text': '腹部膨隆'
          },
          {
            'key': '腹部凹陷',
            'text': '腹部凹陷'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '腹部外形',
        'fqn': 'genitourinary.fubuwaixing389'
      },
      {
        'elementKey': 'huixyundong485',
        'formIndex': '1',
        'inputType': 'select',
        'label': '呼吸运动',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '腹式呼吸运动减弱',
            'text': '腹式呼吸运动减弱'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '呼吸运动',
        'fqn': 'genitourinary.huixyundong485'
      },
      {
        'elementKey': 'fubijingmaiquzhang385',
        'formIndex': '1',
        'inputType': 'select',
        'label': '腹壁静脉曲张',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '腹壁静脉曲张',
        'fqn': 'genitourinary.fubijingmaiquzhang385'
      },
      {
        'elementKey': 'qita7452',
        'formIndex': '1',
        'inputType': 'text',
        'label': '其他',
        'tableColumn': '5',
        'tableLabel': '其他',
        'fqn': 'genitourinary.qita7452'
      },
      {
        'elementKey': 'cahngmignyin385',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肠鸣音',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '活跃',
            'text': '活跃'
          },
          {
            'key': '亢进',
            'text': '亢进'
          },
          {
            'key': '减弱',
            'text': '减弱'
          },
          {
            'key': '消失',
            'text': '消失'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '肠鸣音',
        'fqn': 'genitourinary.cahngmignyin385'
      },
      {
        'elementKey': 'zhenshuiyin375',
        'formIndex': '1',
        'inputType': 'text',
        'label': '振水音',
        'tableColumn': '7',
        'tableLabel': '振水音',
        'fqn': 'genitourinary.zhenshuiyin375'
      },
      {
        'elementKey': 'xueguanzayin374',
        'formIndex': '1',
        'inputType': 'text',
        'label': '血管杂音',
        'tableColumn': '8',
        'tableLabel': '血管杂音',
        'fqn': 'genitourinary.xueguanzayin374'
      },
      {
        'elementKey': 'fubukouzhenyin375',
        'formIndex': '1',
        'inputType': 'select',
        'label': '腹部叩诊音',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '鼓音区扩大',
            'text': '鼓音区扩大'
          },
          {
            'key': '鼓音区缩小',
            'text': '鼓音区缩小'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '腹部叩诊音',
        'fqn': 'genitourinary.fubukouzhenyin375'
      },
      {
        'elementKey': 'yidongxingzhuoyin375',
        'formIndex': '1',
        'inputType': 'select',
        'label': '移动性浊音',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '移动性浊音',
        'fqn': 'genitourinary.yidongxingzhuoyin375'
      },
      {
        'elementKey': 'ganzangzhuoyinjie638',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肝脏浊音界',
        'options': [
          {
            'key': '上移',
            'text': '上移'
          },
          {
            'key': '下移',
            'text': '下移'
          },
          {
            'key': '扩大',
            'text': '扩大'
          },
          {
            'key': '缩小',
            'text': '缩小'
          },
          {
            'key': '消失',
            'text': '消失'
          }
        ],
        'tableColumn': '11',
        'tableLabel': '肝脏浊音界',
        'fqn': 'genitourinary.ganzangzhuoyinjie638'
      },
      {
        'elementKey': 'ganqukoujitong739',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肝区叩击痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '12',
        'tableLabel': '肝区叩击痛',
        'fqn': 'genitourinary.ganqukoujitong739'
      },
      {
        'elementKey': 'shenzangkoujitong630',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肾脏叩击痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '13',
        'tableLabel': '肾脏叩击痛',
        'fqn': 'genitourinary.shenzangkoujitong630'
      },
      {
        'elementKey': 'pangguangchongyingchengdu273',
        'formIndex': '1',
        'inputType': 'text',
        'label': '膀胱充盈程度',
        'tableColumn': '14',
        'tableLabel': '膀胱充盈程度',
        'fqn': 'genitourinary.pangguangchongyingchengdu273'
      },
      {
        'elementKey': 'fubijinzhangdu379',
        'formIndex': '1',
        'inputType': 'select',
        'label': '腹壁紧张度',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '板状腹',
            'text': '板状腹'
          },
          {
            'key': '柔韧腹',
            'text': '柔韧腹'
          },
          {
            'key': '妊娠',
            'text': '妊娠'
          }
        ],
        'tableColumn': '15',
        'tableLabel': '腹壁紧张度',
        'fqn': 'genitourinary.fubijinzhangdu379'
      },
      {
        'elementKey': 'yatong3720',
        'formIndex': '1',
        'inputType': 'select',
        'label': '压痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '16',
        'tableLabel': '压痛',
        'fqn': 'genitourinary.yatong3720'
      },
      {
        'elementKey': 'fantiaotong638',
        'formIndex': '1',
        'inputType': 'select',
        'label': '反跳痛',
        'options': [
          {
            'key': '阳性',
            'text': '阳性'
          },
          {
            'key': '阴性',
            'text': '阴性'
          }
        ],
        'tableColumn': '17',
        'tableLabel': '反跳痛',
        'fqn': 'genitourinary.fantiaotong638'
      },
      {
        'elementKey': 'yebozhenchan380',
        'formIndex': '1',
        'inputType': 'select',
        'label': '液波震颤',
        'options': [
          {
            'key': '阳性',
            'text': '阳性'
          },
          {
            'key': '阴性',
            'text': '阴性'
          }
        ],
        'tableColumn': '18',
        'tableLabel': '液波震颤',
        'fqn': 'genitourinary.yebozhenchan380'
      },
      {
        'elementKey': 'ganzangchuzhen639',
        'formIndex': '1',
        'inputType': 'text',
        'label': '肝脏触诊',
        'tableColumn': '19',
        'tableLabel': '肝脏触诊',
        'fqn': 'genitourinary.ganzangchuzhen639'
      },
      {
        'elementKey': 'pizangchuzhen639',
        'formIndex': '1',
        'inputType': 'text',
        'label': '脾脏触诊',
        'tableColumn': '20',
        'tableLabel': '脾脏触诊',
        'fqn': 'genitourinary.pizangchuzhen639'
      },
      {
        'elementKey': 'dannangchuzhen375',
        'formIndex': '1',
        'inputType': 'text',
        'label': '胆囊触诊',
        'tableColumn': '21',
        'tableLabel': '胆囊触诊',
        'fqn': 'genitourinary.dannangchuzhen375'
      },
      {
        'elementKey': 'comments3298',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '22',
        'tableLabel': '描述',
        'fqn': 'genitourinary.comments3298'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'genitourinary',
        'tableKey': 'table',
        'isTable': true,
        'label': '腹部',
        'addButtonText': '添加腹部评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '腹部外形',
            'ehr_list_index': '2',
            'items': [
              'fubuwaixing389'
            ]
          },
          {
            'label': '呼吸运动',
            'ehr_list_index': '3',
            'items': [
              'huixyundong485'
            ]
          },
          {
            'label': '腹壁静脉曲张',
            'ehr_list_index': '4',
            'items': [
              'fubijingmaiquzhang385'
            ]
          },
          {
            'label': '其他',
            'ehr_list_index': '5',
            'items': [
              'qita7452'
            ]
          },
          {
            'label': '肠鸣音',
            'ehr_list_index': '6',
            'items': [
              'cahngmignyin385'
            ]
          },
          {
            'label': '振水音',
            'ehr_list_index': '7',
            'items': [
              'zhenshuiyin375'
            ]
          },
          {
            'label': '血管杂音',
            'ehr_list_index': '8',
            'items': [
              'xueguanzayin374'
            ]
          },
          {
            'label': '腹部叩诊音',
            'ehr_list_index': '9',
            'items': [
              'fubukouzhenyin375'
            ]
          },
          {
            'label': '移动性浊音',
            'ehr_list_index': '10',
            'items': [
              'yidongxingzhuoyin375'
            ]
          },
          {
            'label': '肝脏浊音界',
            'ehr_list_index': '11',
            'items': [
              'ganzangzhuoyinjie638'
            ]
          },
          {
            'label': '肝区叩击痛',
            'ehr_list_index': '12',
            'items': [
              'ganqukoujitong739'
            ]
          },
          {
            'label': '肾脏叩击痛',
            'ehr_list_index': '13',
            'items': [
              'shenzangkoujitong630'
            ]
          },
          {
            'label': '膀胱充盈程度',
            'ehr_list_index': '14',
            'items': [
              'pangguangchongyingchengdu273'
            ]
          },
          {
            'label': '腹壁紧张度',
            'ehr_list_index': '15',
            'items': [
              'fubijinzhangdu379'
            ]
          },
          {
            'label': '压痛',
            'ehr_list_index': '16',
            'items': [
              'yatong3720'
            ]
          },
          {
            'label': '反跳痛',
            'ehr_list_index': '17',
            'items': [
              'fantiaotong638'
            ]
          },
          {
            'label': '液波震颤',
            'ehr_list_index': '18',
            'items': [
              'yebozhenchan380'
            ]
          },
          {
            'label': '肝脏触诊',
            'ehr_list_index': '19',
            'items': [
              'ganzangchuzhen639'
            ]
          },
          {
            'label': '脾脏触诊',
            'ehr_list_index': '20',
            'items': [
              'pizangchuzhen639'
            ]
          },
          {
            'label': '胆囊触诊',
            'ehr_list_index': '21',
            'items': [
              'dannangchuzhen375'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '22',
            'items': [
              'comments3298'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Genitourinary assessment",
          "addButtonText": "Add a genitourinary assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group74',
              'formCss': 'record-header',
              "fqn": "genitourinary.ehr_group74",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'urinaryGroup2',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "genitourinary.urinaryGroup2",
              'gIndex': '2',
              'gChildren': [
                'fubuwaixing389',
                'huixyundong485',
                'fubijingmaiquzhang385',
                'qita7452'
              ]
            },
            {
              'elementKey': 'foleyGroup',
              'label': '听诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "genitourinary.foleyGroup",
              'gIndex': '3',
              'gChildren': [
                'cahngmignyin385',
                'zhenshuiyin375',
                'xueguanzayin374'
              ]
            },
            {
              'elementKey': 'pelvicGroup',
              'label': '叩诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "genitourinary.pelvicGroup",
              'gIndex': '4',
              'gChildren': [
                'fubukouzhenyin375',
                'yidongxingzhuoyin375',
                'ganzangzhuoyinjie638',
                'ganqukoujitong739',
                'shenzangkoujitong630',
                'pangguangchongyingchengdu273'
              ]
            },
            {
              'elementKey': 'vaginal',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "genitourinary.vaginal",
              'gIndex': '5',
              'gChildren': [
                'fubijinzhangdu379',
                'yatong3720',
                'fantiaotong638',
                'yebozhenchan380',
                'ganzangchuzhen639',
                'pizangchuzhen639',
                'dannangchuzhen375'
              ]
            },
            {
              'elementKey': 'ehr_group75',
              'formCss': 'grid-left-to-right-3',
              "fqn": "genitourinary.ehr_group75",
              'gIndex': '6',
              'gChildren': [
                'comments3298'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'fubuwaixing389': '',
            'huixyundong485': '',
            'fubijingmaiquzhang385': '',
            'qita7452': '',
            'cahngmignyin385': '',
            'zhenshuiyin375': '',
            'xueguanzayin374': '',
            'fubukouzhenyin375': '',
            'yidongxingzhuoyin375': '',
            'ganzangzhuoyinjie638': '',
            'ganqukoujitong739': '',
            'shenzangkoujitong630': '',
            'pangguangchongyingchengdu273': '',
            'fubijinzhangdu379': '',
            'yatong3720': '',
            'fantiaotong638': '',
            'yebozhenchan380': '',
            'ganzangchuzhen639': '',
            'pizangchuzhen639': '',
            'dannangchuzhen375': '',
            'comments3298': ''
          }
        },
        "fqn": "genitourinary.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'fubuwaixing389',
          'huixyundong485',
          'fubijingmaiquzhang385',
          'qita7452',
          'cahngmignyin385',
          'zhenshuiyin375',
          'xueguanzayin374',
          'fubukouzhenyin375',
          'yidongxingzhuoyin375',
          'ganzangzhuoyinjie638',
          'ganqukoujitong739',
          'shenzangkoujitong630',
          'pangguangchongyingchengdu273',
          'fubijinzhangdu379',
          'yatong3720',
          'fantiaotong638',
          'yebozhenchan380',
          'ganzangchuzhen639',
          'pizangchuzhen639',
          'dannangchuzhen375',
          'comments3298'
        ],
        'hasRecHeader': true
      }
    }
  },
  'musculoskeletal': {
    'pageDataKey': 'musculoskeletal',
    'pageTitle': '脊柱四肢关节评估',
    'pIndex': '19',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'musculoskeletal.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'musculoskeletal.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'musculoskeletal.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'musculoskeletal.time'
      },
      {
        'elementKey': 'jizhuwanqudu174',
        'formIndex': '1',
        'inputType': 'select',
        'label': '脊柱弯曲度',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '前凸',
            'text': '前凸'
          },
          {
            'key': '后凸',
            'text': '后凸'
          },
          {
            'key': '侧凸',
            'text': '侧凸'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '脊柱弯曲度',
        'fqn': 'musculoskeletal.jizhuwanqudu174'
      },
      {
        'elementKey': 'jizhuhuodongdu375',
        'formIndex': '1',
        'inputType': 'text',
        'label': '脊柱活动度',
        'tableColumn': '3',
        'tableLabel': '脊柱活动度',
        'fqn': 'musculoskeletal.jizhuhuodongdu375'
      },
      {
        'elementKey': 'jizhuyatong630',
        'formIndex': '1',
        'inputType': 'select',
        'label': '脊柱压痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '脊柱压痛',
        'fqn': 'musculoskeletal.jizhuyatong630'
      },
      {
        'elementKey': 'jizhukoujitong375',
        'formIndex': '1',
        'inputType': 'select',
        'label': '脊柱叩击痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '脊柱叩击痛',
        'fqn': 'musculoskeletal.jizhukoujitong375'
      },
      {
        'elementKey': 'comments3859',
        'assetBase': '?',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '6',
        'tableLabel': '描述',
        'fqn': 'musculoskeletal.comments3859'
      },
      {
        'elementKey': 'sizhi_table_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'musculoskeletal.name'
      },
      {
        'elementKey': 'sizhi_table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'sizhi_table_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'musculoskeletal.profession'
      },
      {
        'elementKey': 'sizhi_table_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'musculoskeletal.day'
      },
      {
        'elementKey': 'sizhi_table_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'musculoskeletal.time'
      },
      {
        'elementKey': 'sizhizhuangtai369',
        'formIndex': '2',
        'inputType': 'select',
        'label': '四肢状态',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '匙状甲',
            'text': '匙状甲'
          },
          {
            'key': '杵状指',
            'text': '杵状指'
          },
          {
            'key': '肢端肥大',
            'text': '肢端肥大'
          },
          {
            'key': '肌肉萎缩',
            'text': '肌肉萎缩'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '四肢状态',
        'fqn': 'musculoskeletal.sizhizhuangtai369'
      },
      {
        'elementKey': 'guanjiezhuangtai379',
        'formIndex': '2',
        'inputType': 'select',
        'label': '关节状态',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '梭形关节',
            'text': '梭形关节'
          },
          {
            'key': '爪形手',
            'text': '爪形手'
          },
          {
            'key': '猿掌',
            'text': '猿掌'
          },
          {
            'key': '方肩',
            'text': '方肩'
          },
          {
            'key': '耸肩',
            'text': '耸肩'
          },
          {
            'key': '膝内翻',
            'text': '膝内翻'
          },
          {
            'key': '膝外翻',
            'text': '膝外翻'
          },
          {
            'key': '膝反张',
            'text': '膝反张'
          },
          {
            'key': '足内翻',
            'text': '足内翻'
          },
          {
            'key': '足外翻',
            'text': '足外翻'
          },
          {
            'key': '扁平足',
            'text': '扁平足'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '关节状态',
        'fqn': 'musculoskeletal.guanjiezhuangtai379'
      },
      {
        'elementKey': 'zhongkuai3729',
        'formIndex': '2',
        'inputType': 'select',
        'label': '肿块',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '4',
        'tableLabel': '肿块',
        'fqn': 'musculoskeletal.zhongkuai3729'
      },
      {
        'elementKey': 'weisuo3750',
        'formIndex': '2',
        'inputType': 'select',
        'label': '萎缩',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '萎缩',
        'fqn': 'musculoskeletal.weisuo3750'
      },
      {
        'elementKey': 'tatong6384',
        'formIndex': '2',
        'inputType': 'select',
        'label': '压痛',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '6',
        'tableLabel': '压痛',
        'fqn': 'musculoskeletal.tatong6384'
      },
      {
        'elementKey': 'huodonghsouxian639',
        'formIndex': '2',
        'inputType': 'select',
        'label': '活动受限',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '活动受限',
        'fqn': 'musculoskeletal.huodonghsouxian639'
      },
      {
        'elementKey': 'mocgan372',
        'formIndex': '2',
        'inputType': 'select',
        'label': '摩擦感',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '摩擦感',
        'fqn': 'musculoskeletal.mocgan372'
      },
      {
        'elementKey': 'yicahngshengiang375',
        'formIndex': '2',
        'inputType': 'select',
        'label': '异常声响',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '9',
        'tableLabel': '异常声响',
        'fqn': 'musculoskeletal.yicahngshengiang375'
      },
      {
        'elementKey': 'comments5375',
        'assetBase': '?',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '10',
        'tableLabel': '描述',
        'fqn': 'musculoskeletal.comments5375'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'musculoskeletal',
        'tableKey': 'table',
        'isTable': true,
        'label': '脊柱',
        'addButtonText': '添加脊柱评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '脊柱弯曲度',
            'ehr_list_index': '2',
            'items': [
              'jizhuwanqudu174'
            ]
          },
          {
            'label': '脊柱活动度',
            'ehr_list_index': '3',
            'items': [
              'jizhuhuodongdu375'
            ]
          },
          {
            'label': '脊柱压痛',
            'ehr_list_index': '4',
            'items': [
              'jizhuyatong630'
            ]
          },
          {
            'label': '脊柱叩击痛',
            'ehr_list_index': '5',
            'items': [
              'jizhukoujitong375'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '6',
            'items': [
              'comments3859'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Musculoskeletal assessment",
          "addButtonText": "Add a musculoskeletal assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group76',
              'formCss': 'record-header',
              "fqn": "musculoskeletal.ehr_group76",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group77',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group77",
              'gIndex': '2',
              'gChildren': [
                'jizhuwanqudu174',
                'jizhuhuodongdu375'
              ]
            },
            {
              'elementKey': 'ehr_group78',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group78",
              'gIndex': '3',
              'gChildren': [
                'jizhuyatong630'
              ]
            },
            {
              'elementKey': 'ehr_group79',
              'label': '叩诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group79",
              'gIndex': '4',
              'gChildren': [
                'jizhukoujitong375'
              ]
            },
            {
              'elementKey': 'ehr_group80',
              'formCss': 'grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group80",
              'gIndex': '5',
              'gChildren': [
                'comments3859'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'jizhuwanqudu174': '',
            'jizhuhuodongdu375': '',
            'jizhuyatong630': '',
            'jizhukoujitong375': '',
            'comments3859': ''
          }
        },
        "fqn": "musculoskeletal.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'jizhuwanqudu174',
          'jizhuhuodongdu375',
          'jizhuyatong630',
          'jizhukoujitong375',
          'comments3859'
        ],
        'hasRecHeader': true
      },
      'sizhi_table': {
        'elementKey': 'sizhi_table',
        'pageElementIndex': '2',
        'pageKey': 'musculoskeletal',
        'tableKey': 'sizhi_table',
        'isTable': true,
        'label': '四肢与关节',
        'addButtonText': '添加四肢与关节评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'sizhi_table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'sizhi_table_name',
              'sizhi_table_profession',
              'sizhi_table_day',
              'sizhi_table_time'
            ]
          },
          {
            'label': '四肢状态',
            'ehr_list_index': '2',
            'items': [
              'sizhizhuangtai369'
            ]
          },
          {
            'label': '关节状态',
            'ehr_list_index': '3',
            'items': [
              'guanjiezhuangtai379'
            ]
          },
          {
            'label': '肿块',
            'ehr_list_index': '4',
            'items': [
              'zhongkuai3729'
            ]
          },
          {
            'label': '萎缩',
            'ehr_list_index': '5',
            'items': [
              'weisuo3750'
            ]
          },
          {
            'label': '压痛',
            'ehr_list_index': '6',
            'items': [
              'tatong6384'
            ]
          },
          {
            'label': '活动受限',
            'ehr_list_index': '7',
            'items': [
              'huodonghsouxian639'
            ]
          },
          {
            'label': '摩擦感',
            'ehr_list_index': '8',
            'items': [
              'mocgan372'
            ]
          },
          {
            'label': '异常声响',
            'ehr_list_index': '9',
            'items': [
              'yicahngshengiang375'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '10',
            'items': [
              'comments5375'
            ]
          }
        ],
        'form': {
          'elementKey': 'sizhi_table',
          'label': '四肢与关节',
          'addButtonText': '添加四肢与关节评估',
          'formOption': 'transpose',
          'formKey': 'sizhi_table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group81',
              'formCss': 'record-header',
              "fqn": "musculoskeletal.ehr_group81",
              'gIndex': '1',
              'gChildren': [
                'sizhi_table_name',
                'sizhi_table_profession',
                'sizhi_table_day',
                'sizhi_table_time'
              ]
            },
            {
              'elementKey': 'ehr_group82',
              'label': '视诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group82",
              'gIndex': '2',
              'gChildren': [
                'sizhizhuangtai369',
                'guanjiezhuangtai379'
              ]
            },
            {
              'elementKey': 'ehr_group83',
              'label': '触诊',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group83",
              'gIndex': '3',
              'gChildren': [
                'zhongkuai3729',
                'weisuo3750',
                'tatong6384'
              ]
            },
            {
              'elementKey': 'ehr_group84',
              'label': '运动检查',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group84",
              'gIndex': '4',
              'gChildren': [
                'huodonghsouxian639',
                'mocgan372',
                'yicahngshengiang375'
              ]
            },
            {
              'elementKey': 'ehr_group85',
              'formCss': 'grid-left-to-right-3',
              "fqn": "musculoskeletal.ehr_group85",
              'gIndex': '5',
              'gChildren': [
                'comments5375'
              ]
            }
          ],
          'ehr_data': {
            'sizhi_table_name': '',
            'sizhi_table_profession': '',
            'sizhi_table_day': '',
            'sizhi_table_time': '',
            'sizhizhuangtai369': '',
            'guanjiezhuangtai379': '',
            'zhongkuai3729': '',
            'weisuo3750': '',
            'tatong6384': '',
            'huodonghsouxian639': '',
            'mocgan372': '',
            'yicahngshengiang375': '',
            'comments5375': ''
          }
        },
        "fqn": "musculoskeletal.table1",
        'tableChildren': [
          'sizhi_table_id',
          'sizhi_table_name',
          'sizhi_table_profession',
          'sizhi_table_day',
          'sizhi_table_time',
          'sizhizhuangtai369',
          'guanjiezhuangtai379',
          'zhongkuai3729',
          'weisuo3750',
          'tatong6384',
          'huodonghsouxian639',
          'mocgan372',
          'yicahngshengiang375',
          'comments5375'
        ],
        'hasRecHeader': true
      }
    }
  },
  'pain': {
    'pageDataKey': 'pain',
    'pageTitle': '疼痛评估',
    'pIndex': '20',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'pain.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'pain.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'pain.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'pain.time'
      },
      {
        'elementKey': 'location',
        'formIndex': '1',
        'inputType': 'text',
        'label': '位置',
        'tableColumn': '2',
        'tableLabel': '位置',
        'fqn': 'pain.location'
      },
      {
        'elementKey': 'painScale',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛强度 (0-10)',
        'suffix': '/10',
        'tableColumn': '3',
        'tableLabel': '疼痛强度 (0-10)',
        'fqn': 'pain.painScale',
        'suffixHtml': '<p>/10</p>',
        'suffixText': '/10'
      },
      {
        'elementKey': 'respiratoryRate',
        'formIndex': '1',
        'inputType': 'text',
        'label': '呼吸频率',
        'tableColumn': '4',
        'tableLabel': '呼吸频率',
        'fqn': 'pain.respiratoryRate'
      },
      {
        'elementKey': 'onset',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛起因',
        'tableColumn': '5',
        'tableLabel': '疼痛起因',
        'fqn': 'pain.onset'
      },
      {
        'elementKey': 'type',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛类型',
        'tableColumn': '6',
        'tableLabel': '疼痛类型',
        'fqn': 'pain.type'
      },
      {
        'elementKey': 'radiation',
        'formIndex': '1',
        'inputType': 'text',
        'label': '辐射范围',
        'tableColumn': '7',
        'tableLabel': '辐射范围',
        'fqn': 'pain.radiation'
      },
      {
        'elementKey': 'comments3980',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '8',
        'tableLabel': '描述',
        'fqn': 'pain.comments3980'
      },
      {
        'elementKey': 'location2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '位置',
        'tableColumn': '9',
        'tableLabel': '位置',
        'fqn': 'pain.location2'
      },
      {
        'elementKey': 'painScale2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛强度 (0-10)',
        'suffix': '/10',
        'tableColumn': '10',
        'tableLabel': '疼痛强度 (0-10)',
        'fqn': 'pain.painScale2',
        'suffixHtml': '<p>/10</p>',
        'suffixText': '/10'
      },
      {
        'elementKey': 'respiratoryRate2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '呼吸频率',
        'tableColumn': '11',
        'tableLabel': '呼吸频率',
        'fqn': 'pain.respiratoryRate2'
      },
      {
        'elementKey': 'onset2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛起因',
        'tableColumn': '12',
        'tableLabel': '疼痛起因',
        'fqn': 'pain.onset2'
      },
      {
        'elementKey': 'type2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '疼痛类型',
        'tableColumn': '13',
        'tableLabel': '疼痛类型',
        'fqn': 'pain.type2'
      },
      {
        'elementKey': 'radiation2',
        'formIndex': '1',
        'inputType': 'text',
        'label': '辐射范围',
        'tableColumn': '14',
        'tableLabel': '辐射范围',
        'fqn': 'pain.radiation2'
      },
      {
        'elementKey': 'comments2385',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '15',
        'tableLabel': '描述',
        'fqn': 'pain.comments2385'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'pain',
        'tableKey': 'table',
        'isTable': true,
        'label': '疼痛评估',
        'addButtonText': '添加疼痛评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '2',
            'items': [
              'location'
            ]
          },
          {
            'label': '疼痛强度 (0-10)',
            'ehr_list_index': '3',
            'items': [
              'painScale'
            ]
          },
          {
            'label': '呼吸频率',
            'ehr_list_index': '4',
            'items': [
              'respiratoryRate'
            ]
          },
          {
            'label': '疼痛起因',
            'ehr_list_index': '5',
            'items': [
              'onset'
            ]
          },
          {
            'label': '疼痛类型',
            'ehr_list_index': '6',
            'items': [
              'type'
            ]
          },
          {
            'label': '辐射范围',
            'ehr_list_index': '7',
            'items': [
              'radiation'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '8',
            'items': [
              'comments3980'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '9',
            'items': [
              'location2'
            ]
          },
          {
            'label': '疼痛强度 (0-10)',
            'ehr_list_index': '10',
            'items': [
              'painScale2'
            ]
          },
          {
            'label': '呼吸频率',
            'ehr_list_index': '11',
            'items': [
              'respiratoryRate2'
            ]
          },
          {
            'label': '疼痛起因',
            'ehr_list_index': '12',
            'items': [
              'onset2'
            ]
          },
          {
            'label': '疼痛类型',
            'ehr_list_index': '13',
            'items': [
              'type2'
            ]
          },
          {
            'label': '辐射范围',
            'ehr_list_index': '14',
            'items': [
              'radiation2'
            ]
          },
          {
            'label': '描述',
            'ehr_list_index': '15',
            'items': [
              'comments2385'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Pain assessment",
          "addButtonText": "Add a pain assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group99',
              'formCss': 'record-header',
              "fqn": "pain.ehr_group99",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group100',
              'label': '原发性疼痛',
              'tableLabel': '原发性疼痛',
              'formCss': 'grid-left-to-right-3',
              "fqn": "pain.ehr_group100",
              'gIndex': '2',
              'gChildren': [
                'location',
                'painScale',
                'respiratoryRate',
                'onset',
                'type',
                'radiation',
                'comments3980'
              ]
            },
            {
              'elementKey': 'ehr_group101',
              'label': '继发性疼痛',
              'tableLabel': '继发性疼痛',
              'formCss': 'grid-left-to-right-3',
              "fqn": "pain.ehr_group101",
              'gIndex': '3',
              'gChildren': [
                'location2',
                'painScale2',
                'respiratoryRate2',
                'onset2',
                'type2',
                'radiation2',
                'comments2385'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'location': '',
            'painScale': '',
            'respiratoryRate': '',
            'onset': '',
            'type': '',
            'radiation': '',
            'comments3980': '',
            'location2': '',
            'painScale2': '',
            'respiratoryRate2': '',
            'onset2': '',
            'type2': '',
            'radiation2': '',
            'comments2385': ''
          }
        },
        "fqn": "pain.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'location',
          'painScale',
          'respiratoryRate',
          'onset',
          'type',
          'radiation',
          'comments3980',
          'location2',
          'painScale2',
          'respiratoryRate2',
          'onset2',
          'type2',
          'radiation2',
          'comments2385'
        ],
        'hasRecHeader': true
      }
    }
  },
  'biopsychosocial': {
    'pageDataKey': 'biopsychosocial',
    'pageTitle': '神经系统评估',
    'pIndex': '21',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'table_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'biopsychosocial.name'
      },
      {
        'elementKey': 'table_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'table_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'biopsychosocial.profession'
      },
      {
        'elementKey': 'table_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'biopsychosocial.day'
      },
      {
        'elementKey': 'table_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'biopsychosocial.time'
      },
      {
        'elementKey': 'qianganjue375',
        'formIndex': '1',
        'inputType': 'text',
        'label': '浅感觉',
        'tableColumn': '2',
        'tableLabel': '浅感觉',
        'fqn': 'biopsychosocial.qianganjue375'
      },
      {
        'elementKey': 'shenganjue3782',
        'formIndex': '1',
        'inputType': 'text',
        'label': '深感觉',
        'tableColumn': '3',
        'tableLabel': '深感觉',
        'fqn': 'biopsychosocial.shenganjue3782'
      },
      {
        'elementKey': 'fuheganjue385',
        'formIndex': '1',
        'inputType': 'text',
        'label': '复合感觉',
        'tableColumn': '4',
        'tableLabel': '复合感觉',
        'fqn': 'biopsychosocial.fuheganjue385'
      },
      {
        'elementKey': 'jili3769',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肌力',
        'options': [
          {
            'key': '0级 完全瘫痪',
            'text': '0级 完全瘫痪'
          },
          {
            'key': '1级 肌肉可收缩，但不能产生动作',
            'text': '1级 肌肉可收缩，但不能产生动作'
          },
          {
            'key': '2级 肢体能在床面上移动，但不能抬离床面',
            'text': '2级 肢体能在床面上移动，但不能抬离床面'
          },
          {
            'key': '3级 肢体能抬离床面，但不能抗阻力',
            'text': '3级 肢体能抬离床面，但不能抗阻力'
          },
          {
            'key': '4级 能做抗阻力动作，但较正常差',
            'text': '4级 能做抗阻力动作，但较正常差'
          },
          {
            'key': '5级 正常肌力',
            'text': '5级 正常肌力'
          }
        ],
        'tableColumn': '5',
        'fqn': 'biopsychosocial.jili3769'
      },
      {
        'elementKey': 'jizahngli3726',
        'formIndex': '1',
        'inputType': 'select',
        'label': '肌张力',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '铅管样肌张力增高',
            'text': '铅管样肌张力增高'
          },
          {
            'key': '折刀样肌张力增高',
            'text': '折刀样肌张力增高'
          },
          {
            'key': '肌张力降低',
            'text': '肌张力降低'
          }
        ],
        'tableColumn': '6',
        'fqn': 'biopsychosocial.jizahngli3726'
      },
      {
        'elementKey': 'busuiyiyundong375',
        'formIndex': '1',
        'inputType': 'select',
        'label': '不随意运动',
        'options': [
          {
            'key': '静止性震颤',
            'text': '静止性震颤'
          },
          {
            'key': '姿势性震颤',
            'text': '姿势性震颤'
          },
          {
            'key': '动作性震颤',
            'text': '动作性震颤'
          },
          {
            'key': '舞蹈样动作',
            'text': '舞蹈样动作'
          },
          {
            'key': '手足搐溺',
            'text': '手足搐溺'
          }
        ],
        'tableColumn': '7',
        'fqn': 'biopsychosocial.busuiyiyundong375'
      },
      {
        'elementKey': 'gongjishitiao374',
        'formIndex': '1',
        'inputType': 'select',
        'label': '共济失调',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '8',
        'fqn': 'biopsychosocial.gongjishitiao374'
      },
      {
        'elementKey': 'shenglifanshe379',
        'formIndex': '1',
        'inputType': 'text',
        'label': '生理反射',
        'tableColumn': '9',
        'fqn': 'biopsychosocial.shenglifanshe379'
      },
      {
        'elementKey': 'binglifanshe379',
        'formIndex': '1',
        'inputType': 'text',
        'label': '病理反射',
        'tableColumn': '10',
        'fqn': 'biopsychosocial.binglifanshe379'
      },
      {
        'elementKey': 'naomocijizheng354',
        'formIndex': '1',
        'inputType': 'text',
        'label': '脑膜刺激征',
        'tableColumn': '11',
        'fqn': 'biopsychosocial.naomocijizheng354'
      },
      {
        'elementKey': 'pifu6472',
        'formIndex': '1',
        'inputType': 'text',
        'label': '皮肤',
        'tableColumn': '12',
        'fqn': 'biopsychosocial.pifu6472'
      },
      {
        'elementKey': 'maofayuzhijia368',
        'formIndex': '1',
        'inputType': 'select',
        'label': '毛发与指甲',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '多毛',
            'text': '多毛'
          },
          {
            'key': '脱发',
            'text': '脱发'
          },
          {
            'key': '指甲变形',
            'text': '指甲变形'
          },
          {
            'key': '指甲变脆',
            'text': '指甲变脆'
          },
          {
            'key': '失去光泽',
            'text': '失去光泽'
          }
        ],
        'tableColumn': '13',
        'fqn': 'biopsychosocial.maofayuzhijia368'
      },
      {
        'elementKey': 'kuoyuejigongneng386',
        'formIndex': '1',
        'inputType': 'select',
        'label': '括约肌功能',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '尿潴留',
            'text': '尿潴留'
          },
          {
            'key': '尿失禁',
            'text': '尿失禁'
          },
          {
            'key': '便秘',
            'text': '便秘'
          },
          {
            'key': '大便失禁',
            'text': '大便失禁'
          }
        ],
        'tableColumn': '14',
        'fqn': 'biopsychosocial.kuoyuejigongneng386'
      },
      {
        'elementKey': 'xinggongneng385',
        'formIndex': '1',
        'inputType': 'select',
        'label': '性功能',
        'options': [
          {
            'key': '正常',
            'text': '正常'
          },
          {
            'key': '性功能障碍',
            'text': '性功能障碍'
          },
          {
            'key': '月经失调',
            'text': '月经失调'
          }
        ],
        'tableColumn': '15',
        'fqn': 'biopsychosocial.xinggongneng385'
      },
      {
        'elementKey': 'pifuhuahenshiyan375',
        'formIndex': '1',
        'inputType': 'text',
        'label': '皮肤划痕实验',
        'tableColumn': '16',
        'fqn': 'biopsychosocial.pifuhuahenshiyan375'
      },
      {
        'elementKey': 'generalComments2759',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '描述',
        'tableColumn': '17',
        'fqn': 'biopsychosocial.generalComments2759'
      }
    ],
    'pageElements': {
      'table': {
        'elementKey': 'table',
        'pageElementIndex': '1',
        'pageKey': 'biopsychosocial',
        'tableKey': 'table',
        'isTable': true,
        'label': '神经系统',
        'addButtonText': '添加神经系统评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'table_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'table_name',
              'table_profession',
              'table_day',
              'table_time'
            ]
          },
          {
            'label': '浅感觉',
            'ehr_list_index': '2',
            'items': [
              'qianganjue375'
            ]
          },
          {
            'label': '深感觉',
            'ehr_list_index': '3',
            'items': [
              'shenganjue3782'
            ]
          },
          {
            'label': '复合感觉',
            'ehr_list_index': '4',
            'items': [
              'fuheganjue385'
            ]
          },
          {
            'ehr_list_index': '5',
            'items': [
              'jili3769'
            ]
          },
          {
            'ehr_list_index': '6',
            'items': [
              'jizahngli3726'
            ]
          },
          {
            'ehr_list_index': '7',
            'items': [
              'busuiyiyundong375'
            ]
          },
          {
            'ehr_list_index': '8',
            'items': [
              'gongjishitiao374'
            ]
          },
          {
            'ehr_list_index': '9',
            'items': [
              'shenglifanshe379'
            ]
          },
          {
            'ehr_list_index': '10',
            'items': [
              'binglifanshe379'
            ]
          },
          {
            'ehr_list_index': '11',
            'items': [
              'naomocijizheng354'
            ]
          },
          {
            'ehr_list_index': '12',
            'items': [
              'pifu6472'
            ]
          },
          {
            'ehr_list_index': '13',
            'items': [
              'maofayuzhijia368'
            ]
          },
          {
            'ehr_list_index': '14',
            'items': [
              'kuoyuejigongneng386'
            ]
          },
          {
            'ehr_list_index': '15',
            'items': [
              'xinggongneng385'
            ]
          },
          {
            'ehr_list_index': '16',
            'items': [
              'pifuhuahenshiyan375'
            ]
          },
          {
            'ehr_list_index': '17',
            'items': [
              'generalComments2759'
            ]
          }
        ],
        'form': {
          'elementKey': 'table',
          "label": "Biopsychosocial assessment",
          "addButtonText": "Add a biopsychosocial assessment",
          'formOption': 'transpose',
          'formKey': 'table',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group102',
              'formCss': 'record-header',
              "fqn": "biopsychosocial.ehr_group102",
              'gIndex': '1',
              'gChildren': [
                'table_name',
                'table_profession',
                'table_day',
                'table_time'
              ]
            },
            {
              'elementKey': 'ehr_group103',
              'label': '感觉功能',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "biopsychosocial.ehr_group103",
              'gIndex': '2',
              'gChildren': [
                'qianganjue375',
                'shenganjue3782',
                'fuheganjue385'
              ]
            },
            {
              'elementKey': 'state',
              'label': '运动功能',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "biopsychosocial.state",
              'gIndex': '3',
              'gChildren': [
                'jili3769',
                'jizahngli3726',
                'busuiyiyundong375',
                'gongjishitiao374'
              ]
            },
            {
              'elementKey': 'support',
              'label': '神经反射',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "biopsychosocial.support",
              'gIndex': '4',
              'gChildren': [
                'shenglifanshe379',
                'binglifanshe379',
                'naomocijizheng354'
              ]
            },
            {
              'elementKey': 'security',
              'label': '自主神经功能',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "biopsychosocial.security",
              'gIndex': '5',
              'gChildren': [
                'pifu6472',
                'maofayuzhijia368',
                'kuoyuejigongneng386',
                'xinggongneng385',
                'pifuhuahenshiyan375'
              ]
            },
            {
              'elementKey': 'ehr_group104',
              'formCss': 'grid-left-to-right-3',
              "fqn": "biopsychosocial.ehr_group104",
              'gIndex': '6',
              'gChildren': [
                'generalComments2759'
              ]
            }
          ],
          'ehr_data': {
            'table_name': '',
            'table_profession': '',
            'table_day': '',
            'table_time': '',
            'qianganjue375': '',
            'shenganjue3782': '',
            'fuheganjue385': '',
            'jili3769': '',
            'jizahngli3726': '',
            'busuiyiyundong375': '',
            'gongjishitiao374': '',
            'shenglifanshe379': '',
            'binglifanshe379': '',
            'naomocijizheng354': '',
            'pifu6472': '',
            'maofayuzhijia368': '',
            'kuoyuejigongneng386': '',
            'xinggongneng385': '',
            'pifuhuahenshiyan375': '',
            'generalComments2759': ''
          }
        },
        "fqn": "biopsychosocial.table",
        'tableChildren': [
          'table_id',
          'table_name',
          'table_profession',
          'table_day',
          'table_time',
          'qianganjue375',
          'shenganjue3782',
          'fuheganjue385',
          'jili3769',
          'jizahngli3726',
          'busuiyiyundong375',
          'gongjishitiao374',
          'shenglifanshe379',
          'binglifanshe379',
          'naomocijizheng354',
          'pifu6472',
          'maofayuzhijia368',
          'kuoyuejigongneng386',
          'xinggongneng385',
          'pifuhuahenshiyan375',
          'generalComments2759'
        ],
        'hasRecHeader': true
      }
    }
  },
  "nonmedOrders": {
    "pageDataKey": "nonmedOrders",
    "pIndex": "22",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "nonmedOrders.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "nonmedOrders.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "nonmedOrders.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "nonmedOrders.time"
      },
      {
        "elementKey": "order",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "nonmedOrders.order"
      },
      {
        "elementKey": "orderedBy",
        "formIndex": "1",
        "inputType": "text",
        "mandatory": "TRUE",
        "tableColumn": "3",
        "fqn": "nonmedOrders.orderedBy"
      },
      {
        "elementKey": "reason",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "nonmedOrders.reason"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "select",
        "mandatory": "TRUE",
        "options": [
          {
            "key": "新开立",
            "text": "新开立"
          },
          {
            "key": "执行中",
            "text": "执行中"
          },
          {
            "key": "作废",
            "text": "作废"
          },
          {
            "key": "完成",
            "text": "完成"
          },
          {
            "key": "停嘱",
            "text": "停嘱"
          }
        ],
        "tableColumn": "5",
        "fqn": "nonmedOrders.status"
      },
      {
        "elementKey": "comment",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "nonmedOrders.comment"
      },
      {
        "elementKey": "dietOrders_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "nonmedOrders.name"
      },
      {
        "elementKey": "dietOrders_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "dietOrders_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "nonmedOrders.profession"
      },
      {
        "elementKey": "dietOrders_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "nonmedOrders.day"
      },
      {
        "elementKey": "dietOrders_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "nonmedOrders.time"
      },
      {
        "elementKey": "diet_regular",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': '常规饮食',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "2",
        "fqn": "nonmedOrders.diet_regular"
      },
      {
        "elementKey": "diet_npo",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': 'NPO',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "3",
        "fqn": "nonmedOrders.diet_npo"
      },
      {
        "elementKey": "diet_heart",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': '低盐低脂饮食',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "4",
        "fqn": "nonmedOrders.diet_heart"
      },
      {
        "elementKey": "diet_diabetic",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': '糖尿病饮食',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "5",
        "fqn": "nonmedOrders.diet_diabetic"
      },
      {
        "elementKey": "diet_fluid",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': '限制液体摄入量',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "6",
        "fqn": "nonmedOrders.diet_fluid"
      },
      {
        "elementKey": "diet_fluid_amount",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "suffix": "mL/24h",
        "tableColumn": "7",
        "fqn": "nonmedOrders.diet_fluid_amount",
        "suffixText": "mL/24h"
      },
      {
        "elementKey": "diet_other",
        "formIndex": "2",
        'inputType': 'checkset',
        'options': [
          {
            'key': '其他饮食',
            'text': '是'
          }
        ],
        "tableLabel": "是",
        "tableColumn": "8",
        "fqn": "nonmedOrders.diet_other"
      },
      {
        "elementKey": "diet_other_explain",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "nonmedOrders.diet_other_explain"
      },
      {
        "elementKey": "diet_comment",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "10",
        "fqn": "nonmedOrders.diet_comment"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "nonmedOrders",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Nonmedication orders",
          "addButtonText": "Add an order",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "nonmedOrders.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup22-1",
              "formCss": "grid-left-to-right-3",
              "fqn": "nonmedOrders.cGroup22-1",
              "gIndex": "2",
              "gChildren": [
                "order",
                "orderedBy",
                "reason",
                "status",
                "comment"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "order": "",
            "orderedBy": "",
            "reason": "",
            "status": "",
            "comment": ""
          }
        },
        "fqn": "nonmedOrders.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "order",
          "orderedBy",
          "reason",
          "status",
          "comment",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      },
      "dietOrders": {
        "elementKey": "dietOrders",
        "pageElementIndex": "2",
        "pageKey": "nonmedOrders",
        "tableKey": "dietOrders",
        "isTable": true,
        "form": {
          "elementKey": "dietOrders",
          "label": "Diet",
          "addButtonText": "Add a diet order",
          "formKey": "dietOrders",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "nonmedOrders.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "dietOrders_name",
                "dietOrders_profession",
                "dietOrders_day",
                "dietOrders_time"
              ]
            },
            {
              "elementKey": "cGroup22-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "nonmedOrders.cGroup22-2",
              "gIndex": "2",
              "gChildren": [
                "diet_regular",
                "diet_npo",
                "diet_heart",
                "diet_diabetic"
              ]
            },
            {
              "elementKey": "cGroup22-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "nonmedOrders.cGroup22-3",
              "gIndex": "3",
              "gChildren": [
                "diet_fluid",
                "diet_fluid_amount"
              ]
            },
            {
              "elementKey": "cGroup22-4",
              "formCss": "grid-left-to-right-3",
              "fqn": "nonmedOrders.cGroup22-4",
              "gIndex": "4",
              "gChildren": [
                "diet_other",
                "diet_other_explain",
                "diet_comment"
              ]
            }
          ],
          "ehr_data": {
            "dietOrders_name": "",
            "dietOrders_profession": "",
            "dietOrders_day": "",
            "dietOrders_time": "",
            "diet_regular": "",
            "diet_npo": "",
            "diet_heart": "",
            "diet_diabetic": "",
            "diet_fluid": "",
            "diet_fluid_amount": "",
            "diet_other": "",
            "diet_other_explain": "",
            "diet_comment": ""
          }
        },
        "fqn": "nonmedOrders.dietOrders",
        "tableChildren": [
          "dietOrders_id",
          "dietOrders_day",
          "dietOrders_time",
          "diet_regular",
          "diet_npo",
          "diet_heart",
          "diet_diabetic",
          "diet_fluid",
          "diet_fluid_amount",
          "diet_other",
          "diet_other_explain",
          "diet_comment",
          "dietOrders_name",
          "dietOrders_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "referrals": {
    "pageDataKey": "referrals",
    "pIndex": "23",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "referrals.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "referrals.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "referrals.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "referrals.time"
      },
      {
        "elementKey": "referralName",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "referrals.referralName"
      },
      {
        "elementKey": "referralProfession",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "referrals.referralProfession"
      },
      {
        "elementKey": "spacer23",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "referrals.spacer23"
      },
      {
        "elementKey": "appointmentDate",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "referrals.appointmentDate"
      },
      {
        "elementKey": "appointmentTime",
        "formIndex": "1",
        "inputType": "visitTime",
        "tableColumn": "5",
        "validation": "time24",
        "fqn": "referrals.appointmentTime"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "有效",
            "text": "有效"
          },
          {
            "key": "已出院",
            "text": "已出院"
          }
        ],
        "tableColumn": "6",
        "fqn": "referrals.status"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "referrals",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Referrals",
          "addButtonText": "Add a referral",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "referrals.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup23-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "referrals.cGroup23-2",
              "gIndex": "2",
              "gChildren": [
                "referralName",
                "referralProfession",
                "spacer23",
                "appointmentDate",
                "appointmentTime",
                "status"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "referralName": "",
            "referralProfession": "",
            "appointmentDate": "",
            "appointmentTime": "",
            "status": ""
          }
        },
        "fqn": "referrals.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "referralName",
          "referralProfession",
          "appointmentDate",
          "appointmentTime",
          "status",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "labRequisitions": {
    "pageDataKey": "labRequisitions",
    "pIndex": "24",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labRequisitions.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labRequisitions.time"
      },
      {
        "elementKey": "patientName",
        "formIndex": "1",
        "inputType": "ehrPatientName",
        "tableColumn": "2",
        "fqn": "labRequisitions.patientName"
      },
      {
        "elementKey": "patientHealthNumber",
        "formIndex": "1",
        "inputType": "ehrPHN",
        "tableColumn": "3",
        "fqn": "labRequisitions.patientHealthNumber"
      },
      {
        "elementKey": "dateOfBirth",
        "formIndex": "1",
        "inputType": "ehrDOB",
        "tableColumn": "4",
        "fqn": "labRequisitions.dateOfBirth"
      },
      {
        "elementKey": "location",
        "formIndex": "1",
        "inputType": "ehrLocation",
        "tableColumn": "5",
        "fqn": "labRequisitions.location"
      },
      {
        "elementKey": "dateRequired",
        "formIndex": "1",
        "inputType": "visitDay",
        "tableColumn": "6",
        "fqn": "labRequisitions.dateRequired"
      },
      {
        "elementKey": "reason",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "7",
        "fqn": "labRequisitions.reason"
      },
      {
        "elementKey": "hematology",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "全血细胞计数",
            "text": "全血细胞计数"
          },
          {
            "key": "全血细胞计数五分类",
            "text": "全血细胞计数五分类"
          },
          {
            "key": "血红蛋白",
            "text": "血红蛋白"
          },
          {
            "key": "血红蛋白 A1-C",
            "text": "血红蛋白 A1-C"
          }
        ],
        "tableColumn": "8",
        "fqn": "labRequisitions.hematology"
      },
      {
        "elementKey": "electrolytes",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "钾 (K)",
            "text": "钾 (K)"
          },
          {
            "key": "钠 (Na)",
            "text": "钠 (Na)"
          },
          {
            "key": "氯化物 (Cl)",
            "text": "氯化物 (Cl)"
          },
          {
            "key": "碳酸氢盐",
            "text": "碳酸氢盐"
          },
          {
            "key": "钙",
            "text": "钙"
          },
          {
            "key": "离子钙",
            "text": "离子钙"
          }
        ],
        "tableColumn": "9",
        "fqn": "labRequisitions.electrolytes"
      },
      {
        "elementKey": "bloodGas",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "动脉血气 (ABG)",
            "text": "动脉血气 (ABG)"
          },
          {
            "key": "静脉血气 (VBG)",
            "text": "静脉血气 (VBG)"
          },
          {
            "key": "胎儿脐带血气分析 (FCBG)",
            "text": "胎儿脐带血气分析 (FCBG)"
          }
        ],
        "tableColumn": "10",
        "fqn": "labRequisitions.bloodGas"
      },
      {
        "elementKey": "renalProfile",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "血尿素氮",
            "text": "血尿素氮"
          },
          {
            "key": "肌酐",
            "text": "肌酐"
          }
        ],
        "tableColumn": "11",
        "fqn": "labRequisitions.renalProfile"
      },
      {
        "elementKey": "liverFunction",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "ast",
            "text": "AST"
          },
          {
            "key": "ggt",
            "text": "GGT"
          },
          {
            "key": "alt",
            "text": "ALT"
          },
          {
            "key": "totalBirilubin",
            "text": "Total Birilubin"
          },
          {
            "key": "directBirilubin",
            "text": "Direct Birilubin"
          }
        ],
        "tableColumn": "12",
        "fqn": "labRequisitions.liverFunction"
      },
      {
        "elementKey": "urineAnalysis",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "r&m",
            "text": "R&M"
          },
          {
            "key": "urineElectrolytes",
            "text": "Urine electrolytes"
          },
          {
            "key": "urineCreatinine",
            "text": "Urine creatinine"
          }
        ],
        "tableColumn": "13",
        "fqn": "labRequisitions.urineAnalysis"
      },
      {
        "elementKey": "microbiologyCulture",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "culture",
            "text": "culture"
          },
          {
            "key": "senstitivity",
            "text": "senstitivity"
          }
        ],
        "tableColumn": "14",
        "fqn": "labRequisitions.microbiologyCulture"
      },
      {
        "elementKey": "microbiologyLocation",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Wound",
            "text": "Wound"
          },
          {
            "key": "Sputum",
            "text": "Sputum"
          },
          {
            "key": "Blood",
            "text": "Blood"
          },
          {
            "key": "Urine",
            "text": "Urine"
          },
          {
            "key": "Stool",
            "text": "Stool"
          }
        ],
        "tableColumn": "15",
        "fqn": "labRequisitions.microbiologyLocation"
      },
      {
        "elementKey": "histologyTissueType",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Skin",
            "text": "Skin"
          },
          {
            "key": "Blood",
            "text": "Blood"
          },
          {
            "key": "Organ",
            "text": "Organ"
          }
        ],
        "tableColumn": "16",
        "fqn": "labRequisitions.histologyTissueType"
      },
      {
        "elementKey": "histologyLocation",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "17",
        "fqn": "labRequisitions.histologyLocation"
      },
      {
        "elementKey": "requisitions_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.name"
      },
      {
        "elementKey": "requisitions_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "requisitions_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.profession"
      },
      {
        "elementKey": "requisitions_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labRequisitions.day"
      },
      {
        "elementKey": "requisitions_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labRequisitions.time"
      },
      {
        "elementKey": "req_id",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "labRequisitions.req_id"
      },
      {
        "elementKey": "req_dateRequired",
        "formIndex": "2",
        "inputType": "visitDay",
        "tableColumn": "2",
        "validation": "visitDay",
        "fqn": "labRequisitions.req_dateRequired"
      },
      {
        "elementKey": "req_schedule",
        "formIndex": "2",
        "inputType": "select",
        "options": [
        /*  {
            "key": "stat",
            "text": "Stat"
          },*/
          {
            "key": "加急",
            "text": "加急"
          },
          {
            "key": "常规",
            "text": "常规"
          }
        ],
        "tableColumn": "3",
        "fqn": "labRequisitions.req_schedule"
      },
      {
        "elementKey": "req_reason",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "labRequisitions.req_reason"
      },
      {
        "elementKey": "req_hematology",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "全血细胞计数",
            "text": "全血细胞计数"
          },
          {
            "key": "全血细胞计数五分类",
            "text": "全血细胞计数五分类"
          }
        ],
        "tableColumn": "5",
        "fqn": "labRequisitions.req_hematology"
      },
      {
        "elementKey": "req_cogagulation",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "纤维蛋白原",
            "text": "纤维蛋白原"
          },
          {
            "key": "D-二聚体",
            "text": "D-二聚体"
          },
          {
            "key": "国际标准化比值 (INR)",
            "text": "国际标准化比值 (INR)"
          },
          {
            "key": "活化部分凝血活酶时间 (PTT)",
            "text": "活化部分凝血活酶时间 (PTT)"
          },
          {
            "key": "部分凝血活酶时间 (PTT)",
            "text": "部分凝血活酶时间 (PTT)"
          }
        ],
        "tableColumn": "6",
        "fqn": "labRequisitions.req_cogagulation"
      },
      {
        "elementKey": "req_tropinin",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "脑钠肽(BNP)",
            "text": "脑钠肽(BNP)"
          },
          {
            "key": "肌钙蛋白 I",
            "text": "肌钙蛋白 I"
          },
          {
            "key": "肌钙蛋白 T (TnT)",
            "text": "肌钙蛋白 T (TnT)"
          },
          {
            "key": "高敏肌钙蛋白 T",
            "text": "高敏肌钙蛋白 T"
          }
        ],
        "tableColumn": "7",
        "fqn": "labRequisitions.req_tropinin"
      },
      {
        "elementKey": "req_electrolytes",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "checkset",
        "options": [
          {
            "key": "白蛋白",
            "text": "白蛋白"
          },
          {
            "key": "碳酸氢盐 (HCO3)",
            "text": "碳酸氢盐 (HCO3)"
          },
          {
            "key": "钙 (Ca)",
            "text": "钙 (Ca)"
          },
          {
            "key": "离子钙",
            "text": "离子钙"
          },
          {
            "key": "氯化物 (Cl)",
            "text": "氯化物 (Cl)"
          },
          {
            "key": "B12",
            "text": "B12"
          },
          {
            "key": "空腹血脂分析 - 总胆固醇、HDL、LDL、甘油三酯",
            "text": "空腹血脂分析 - 总胆固醇、HDL、LDL、甘油三酯"
          },
          {
            "key": "血脂状况 - 总胆固醇、HDL、LDL、甘油三酯",
            "text": "血脂状况 - 总胆固醇、HDL、LDL、甘油三酯"
          },
          {
            "key": "血清肌酐 (sCr)",
            "text": "血清肌酐 (sCr)"
          },
          {
            "key": "肾小球滤过率 (GFR)",
            "text": "肾小球滤过率 (GFR)"
          },
          {
            "key": "肌酸激酶 (CK)",
            "text": "肌酸激酶 (CK)"
          },
          {
            "key": "铁蛋白",
            "text": "铁蛋白"
          },
          {
            "key": "空腹血糖",
            "text": "空腹血糖"
          },
          {
            "key": "随机血糖",
            "text": "随机血糖"
          },
          {
            "key": "血红蛋白 A1-C",
            "text": "血红蛋白 A1-C"
          },
          {
            "key": "乳酸",
            "text": "乳酸"
          },
          {
            "key": "镁 (Mg)",
            "text": "镁 (Mg)"
          },
          {
            "key": "磷酸盐 (PO4)",
            "text": "磷酸盐 (PO4)"
          },
          {
            "key": "钾 (K)",
            "text": "钾 (K)"
          },
          {
            "key": "总蛋白质",
            "text": "总蛋白质"
          },
          {
            "key": "钠 (Na)",
            "text": "钠 (Na)"
          },
          {
            "key": "尿素",
            "text": "尿素"
          }
        ],
        "tableColumn": "8",
        "fqn": "labRequisitions.req_electrolytes"
      },
      {
        "elementKey": "req_liverFunction",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "checkset",
        "options": [
          {
            "key": "丙氨酸转氨酶 (ALT)",
            "text": "丙氨酸转氨酶 (ALT)"
          },
          {
            "key": "碱性磷酸酶 (ALP)",
            "text": "碱性磷酸酶 (ALP)"
          },
          {
            "key": "天冬氨酸氨基转移酶 (AST)",
            "text": "天冬氨酸氨基转移酶 (AST)"
          },
          {
            "key": "直接胆红素",
            "text": "直接胆红素"
          },
          {
            "key": "总胆红素",
            "text": "总胆红素"
          },
          {
            "key": "γ 谷氨酰转移酶 (GGT)",
            "text": "γ 谷氨酰转移酶 (GGT)"
          },
          {
            "key": "乳酸脱氢酶 (LDH)",
            "text": "乳酸脱氢酶 (LDH)"
          },
          {
            "key": "脂肪酶",
            "text": "脂肪酶"
          }
        ],
        "tableColumn": "9",
        "fqn": "labRequisitions.req_liverFunction"
      },
      {
        "elementKey": "req_renalProfile",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "血尿素氮 (BUN)",
            "text": "血尿素氮 (BUN)"
          },
          {
            "key": "肌酐",
            "text": "肌酐"
          }
        ],
        "tableColumn": "10",
        "fqn": "labRequisitions.req_renalProfile"
      },
      {
        "elementKey": "req_bloodGas",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "动脉血气 (ABG)",
            "text": "动脉血气 (ABG)"
          },
          {
            "key": "静脉血气 (VBG)",
            "text": "静脉血气 (VBG)"
          },
          {
            "key": "胎儿脐带血气分析 (FCBG)",
            "text": "胎儿脐带血气分析 (FCBG)"
          }
        ],
        "tableColumn": "11",
        "fqn": "labRequisitions.req_bloodGas"
      },
      {
        "elementKey": "req_urineAnalysis",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "白蛋白/肌酐比值 (ACR)",
            "text": "白蛋白/肌酐比值 (ACR)"
          },
          {
            "key": "尿常规和尿液分析 (R&M)",
            "text": "尿常规和尿液分析 (R&M)"
          },
          {
            "key": "怀孕",
            "text": "怀孕"
          },
          {
            "key": "尿电解质",
            "text": "尿电解质"
          },
          {
            "key": "尿肌酐",
            "text": "尿肌酐"
          }
        ],
        "tableColumn": "12",
        "fqn": "labRequisitions.req_urineAnalysis"
      },
      {
        "elementKey": "req_comments",
        "formIndex": "2",
        "inputType": "textarea",
        "options": [
          {
            "key": "ferritin:=Ferritin glucoseF:=Glucose fasting glucoseR:=Glucose random",
            "text": "ferritin:=Ferritin glucoseF:=Glucose fasting glucoseR:=Glucose random"
          }
        ],
        "tableColumn": "13",
        "fqn": "labRequisitions.req_comments"
      },
      {
        "elementKey": "histologyRequisitions_name",
        "formIndex": "3",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.name"
      },
      {
        "elementKey": "histologyRequisitions_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "histologyRequisitions_profession",
        "formIndex": "3",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.profession"
      },
      {
        "elementKey": "histologyRequisitions_day",
        "formIndex": "3",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labRequisitions.day"
      },
      {
        "elementKey": "histologyRequisitions_time",
        "formIndex": "3",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labRequisitions.time"
      },
      {
        "elementKey": "reqH_id",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "labRequisitions.reqH_id"
      },
      {
        "elementKey": "reqH_dateRequired",
        "formIndex": "3",
        "inputType": "visitDay",
        "tableColumn": "2",
        "validation": "visitDay",
        "fqn": "labRequisitions.reqH_dateRequired"
      },
      {
        "elementKey": "reqH_schedule",
        "formIndex": "3",
        "inputType": "select",
        "options": [
        /*  {
            "key": "stat",
            "text": "Stat"
          },*/
          {
            "key": "加急",
            "text": "加急"
          },
          {
            "key": "常规",
            "text": "常规"
          }
        ],
        "tableColumn": "3",
        "fqn": "labRequisitions.reqH_schedule"
      },
      {
        "elementKey": "reqH_reason",
        "formIndex": "3",
        "formCss": "grid-span-3-columns",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "labRequisitions.reqH_reason"
      },
      {
        "elementKey": "reqH_type",
        "formIndex": "3",
        "inputType": "select",
        "options": [
          {
            "key": "血液",
            "text": "血液"
          },
          {
            "key": "器官",
            "text": "器官"
          },
          {
            "key": "皮肤",
            "text": "皮肤"
          }
        ],
        "tableColumn": "4",
        "fqn": "labRequisitions.reqH_type"
      },
      {
        "elementKey": "reqH_location",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "labRequisitions.reqH_location"
      },
      {
        "elementKey": "reqH_comments",
        "formIndex": "3",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "labRequisitions.reqH_comments"
      },
      {
        "elementKey": "med_image_req_name",
        "formIndex": "4",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.name"
      },
      {
        "elementKey": "med_image_req_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "med_image_req_profession",
        "formIndex": "4",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.profession"
      },
      {
        "elementKey": "med_image_req_day",
        "formIndex": "4",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labRequisitions.day"
      },
      {
        "elementKey": "med_image_req_time",
        "formIndex": "4",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labRequisitions.time"
      },
      {
        "elementKey": "reqMi_id",
        "formIndex": "4",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "labRequisitions.reqMi_id"
      },
      {
        "elementKey": "reqMi_dateRequired",
        "formIndex": "4",
        "inputType": "visitDay",
        "tableColumn": "2",
        "validation": "visitDay",
        "fqn": "labRequisitions.reqMi_dateRequired"
      },
      {
        "elementKey": "reqMi_schedule",
        "formIndex": "4",
        "inputType": "select",
        "options": [
        /*  {
            "key": "stat",
            "text": "Stat"
          },*/
          {
            "key": "加急",
            "text": "加急"
          },
          {
            "key": "常规",
            "text": "常规"
          }
        ],
        "tableColumn": "3",
        "fqn": "labRequisitions.reqMi_schedule"
      },
      {
        "elementKey": "reqMi_reason",
        "formIndex": "4",
        "formCss": "grid-span-3-columns",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "labRequisitions.reqMi_reason"
      },
      {
        "elementKey": "reqMi_type",
        "formIndex": "4",
        "inputType": "select",
        "options": [
          {
            "key": "血管造影",
            "text": "血管造影"
          },
          {
            "key": "超声心动图",
            "text": "超声心动图"
          },
          {
            "key": "心电图",
            "text": "心电图"
          },
          {
            "key": "CT 扫描",
            "text": "CT 扫描"
          },
          {
            "key": "MRI",
            "text": "MRI"
          },
          {
            "key": "核医学",
            "text": "核医学"
          },
          {
            "key": "超声",
            "text": "超声"
          },
          {
            "key": "X光",
            "text": "X光"
          }
        ],
        "tableColumn": "2",
        "fqn": "labRequisitions.reqMi_type"
      },
      {
        "elementKey": "reqMi_Location",
        "formIndex": "4",
        "inputType": "checkset",
        "options": [
          {
            "key": "腹部",
            "text": "腹部"
          },
          {
            "key": "手臂（左）",
            "text": "手臂（左）"
          },
          {
            "key": "手臂（右）",
            "text": "手臂（右）"
          },
          {
            "key": "胸部",
            "text": "胸部"
          },
          {
            "key": "头部",
            "text": "头部"
          },
          {
            "key": "心脏",
            "text": "心脏"
          },
          {
            "key": "颈部",
            "text": "颈部"
          },
          {
            "key": "腿（左）",
            "text": "腿（左）"
          },
          {
            "key": "腿（右）",
            "text": "腿（右）"
          },
          {
            "key": "盆腔",
            "text": "盆腔"
          }
        ],
        "fqn": "labRequisitions.reqMi_Location"
      },
      {
        "elementKey": "reqMi_mobile",
        "formIndex": "4",
        "formCss": "grid-new-subsection",
        "inputType": "checkbox",
        "fqn": "labRequisitions.reqMi_mobile"
      },
      {
        "elementKey": "reqMi_comments",
        "formIndex": "4",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "fqn": "labRequisitions.reqMi_comments"
      },
      {
        "elementKey": "microRequisitions_name",
        "formIndex": "5",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.name"
      },
      {
        "elementKey": "microRequisitions_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "microRequisitions_profession",
        "formIndex": "5",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labRequisitions.profession"
      },
      {
        "elementKey": "microRequisitions_day",
        "formIndex": "5",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labRequisitions.day"
      },
      {
        "elementKey": "microRequisitions_time",
        "formIndex": "5",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labRequisitions.time"
      },
      {
        "elementKey": "reqMicro_id",
        "formIndex": "5",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "labRequisitions.reqMicro_id"
      },
      {
        "elementKey": "reqMicro_dateRequired",
        "formIndex": "5",
        "inputType": "visitDay",
        "tableColumn": "2",
        "validation": "visitDay",
        "fqn": "labRequisitions.reqMicro_dateRequired"
      },
      {
        "elementKey": "reqMi_schedule",
        "formIndex": "5",
        "inputType": "select",
        "options": [
        /*  {
            "key": "stat",
            "text": "Stat"
          },*/
          {
            "key": "加急",
            "text": "加急"
          },
          {
            "key": "常规",
            "text": "常规"
          }
        ],
        "tableColumn": "3",
        "fqn": "labRequisitions.reqMi_schedule"
      },
      {
        "elementKey": "reqMicro_reason",
        "formIndex": "5",
        "formCss": "grid-span-3-columns",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "labRequisitions.reqMicro_reason"
      },
      {
        "elementKey": "reqMicro_culture",
        "formIndex": "5",
        "inputType": "select",
        "options": [
          {
            "key": "培养物",
            "text": "培养物"
          },
          {
            "key": "敏感性",
            "text": "敏感性"
          }
        ],
        "tableColumn": "4",
        "fqn": "labRequisitions.reqMicro_culture"
      },
      {
        "elementKey": "reqMicro_location",
        "formIndex": "5",
        "inputType": "select",
        "options": [
          {
            "key": "血液",
            "text": "血液"
          },
          {
            "key": "痰液",
            "text": "痰液"
          },
          {
            "key": "大便",
            "text": "大便"
          },
          {
            "key": "尿液",
            "text": "尿液"
          },
          {
            "key": "伤口",
            "text": "伤口"
          }
        ],
        "tableColumn": "5",
        "fqn": "labRequisitions.reqMicro_location"
      },
      {
        "elementKey": "reqMicro_comments",
        "formIndex": "5",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "labRequisitions.reqMicro_comments"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "labRequisitions",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Lab requisitions v1",
          "addButtonText": "Add a lab requisition assessment",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labRequisitions.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "demographics",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.demographics",
              "gIndex": "2",
              "gChildren": [
                "patientName",
                "patientHealthNumber",
                "dateOfBirth",
                "location",
                "dateRequired",
                "reason",
                "hematology",
                "electrolytes",
                "bloodGas",
                "renalProfile",
                "liverFunction",
                "urineAnalysis"
              ]
            },
            {
              "elementKey": "microbiology",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.microbiology",
              "gIndex": "3",
              "gChildren": [
                "microbiologyCulture",
                "microbiologyLocation"
              ]
            },
            {
              "elementKey": "histology",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.histology",
              "gIndex": "4",
              "gChildren": [
                "histologyTissueType",
                "histologyLocation"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "patientName": "",
            "patientHealthNumber": "",
            "dateOfBirth": "",
            "location": "",
            "dateRequired": "",
            "reason": "",
            "hematology": "",
            "electrolytes": "",
            "bloodGas": "",
            "renalProfile": "",
            "liverFunction": "",
            "urineAnalysis": "",
            "microbiologyCulture": "",
            "microbiologyLocation": "",
            "histologyTissueType": "",
            "histologyLocation": ""
          }
        },
        "fqn": "labRequisitions.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "patientName",
          "patientHealthNumber",
          "dateOfBirth",
          "location",
          "dateRequired",
          "reason",
          "hematology",
          "electrolytes",
          "bloodGas",
          "renalProfile",
          "liverFunction",
          "urineAnalysis",
          "microbiologyCulture",
          "microbiologyLocation",
          "histologyTissueType",
          "histologyLocation",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      },
      "requisitions": {
        "elementKey": "requisitions",
        "pageElementIndex": "2",
        "pageKey": "labRequisitions",
        "tableKey": "requisitions",
        "isTable": true,
        "form": {
          "elementKey": "requisitions",
          "label": "Lab requisitions",
          "addButtonText": "Add a lab requisition",
          "formOption": "transpose",
          "formKey": "requisitions",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labRequisitions.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "requisitions_name",
                "requisitions_profession",
                "requisitions_day",
                "requisitions_time"
              ]
            },
            {
              "elementKey": "req_notice",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.req_notice",
              "gIndex": "2",
              "gChildren": [
                "req_id",
                "req_dateRequired",
                "req_schedule",
                "req_reason"
              ]
            },
            {
              "elementKey": "cGroup24-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-3",
              "gIndex": "3",
              "gChildren": [
                "req_hematology",
                "req_cogagulation"
              ]
            },
            {
              "elementKey": "cGroup24-4",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-4",
              "gIndex": "4",
              "gChildren": [
                "req_tropinin",
                "req_electrolytes",
                "req_liverFunction",
                "req_renalProfile"
              ]
            },
            {
              "elementKey": "cGroup24-5",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-5",
              "gIndex": "5",
              "gChildren": [
                "req_bloodGas"
              ]
            },
            {
              "elementKey": "cGroup24-6",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-6",
              "gIndex": "6",
              "gChildren": [
                "req_urineAnalysis"
              ]
            },
            {
              "elementKey": "cGroup24-7",
              "formCss": "grid-left-to-right-1",
              "fqn": "labRequisitions.cGroup24-7",
              "gIndex": "7",
              "gChildren": [
                "req_comments"
              ]
            }
          ],
          "ehr_data": {
            "requisitions_name": "",
            "requisitions_profession": "",
            "requisitions_day": "",
            "requisitions_time": "",
            "req_id": "",
            "req_dateRequired": "",
            "req_schedule": "",
            "req_reason": "",
            "req_hematology": "",
            "req_cogagulation": "",
            "req_tropinin": "",
            "req_electrolytes": "",
            "req_liverFunction": "",
            "req_renalProfile": "",
            "req_bloodGas": "",
            "req_urineAnalysis": "",
            "req_comments": ""
          }
        },
        "fqn": "labRequisitions.requisitions",
        "tableChildren": [
          "requisitions_id",
          "requisitions_day",
          "requisitions_time",
          "req_id",
          "req_dateRequired",
          "req_schedule",
          "req_reason",
          "req_hematology",
          "req_cogagulation",
          "req_tropinin",
          "req_electrolytes",
          "req_liverFunction",
          "req_renalProfile",
          "req_bloodGas",
          "req_urineAnalysis",
          "req_comments",
          "requisitions_name",
          "requisitions_profession"
        ],
        "hasRecHeader": true
      },
      "histologyRequisitions": {
        "elementKey": "histologyRequisitions",
        "pageElementIndex": "3",
        "pageKey": "labRequisitions",
        "tableKey": "histologyRequisitions",
        "isTable": true,
        "form": {
          "elementKey": "histologyRequisitions",
          "label": "Histology",
          "addButtonText": "Add a histology requisition",
          "formOption": "transpose",
          "formKey": "histologyRequisitions",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labRequisitions.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "histologyRequisitions_name",
                "histologyRequisitions_profession",
                "histologyRequisitions_day",
                "histologyRequisitions_time"
              ]
            },
            {
              "elementKey": "cGroup24-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-2",
              "gIndex": "2",
              "gChildren": [
                "reqH_id",
                "reqH_dateRequired",
                "reqH_schedule",
                "reqH_reason"
              ]
            },
            {
              "elementKey": "cGroup24-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-3",
              "gIndex": "3",
              "gChildren": [
                "reqH_type",
                "reqH_location",
                "reqH_comments"
              ]
            }
          ],
          "ehr_data": {
            "histologyRequisitions_name": "",
            "histologyRequisitions_profession": "",
            "histologyRequisitions_day": "",
            "histologyRequisitions_time": "",
            "reqH_id": "",
            "reqH_dateRequired": "",
            "reqH_schedule": "",
            "reqH_reason": "",
            "reqH_type": "",
            "reqH_location": "",
            "reqH_comments": ""
          }
        },
        "fqn": "labRequisitions.histologyRequisitions",
        "tableChildren": [
          "histologyRequisitions_id",
          "histologyRequisitions_day",
          "histologyRequisitions_time",
          "reqH_id",
          "reqH_dateRequired",
          "reqH_schedule",
          "reqH_reason",
          "reqH_type",
          "reqH_location",
          "reqH_comments",
          "histologyRequisitions_name",
          "histologyRequisitions_profession"
        ],
        "hasRecHeader": true
      },
      "med_image_req": {
        "elementKey": "med_image_req",
        "pageElementIndex": "4",
        "pageKey": "labRequisitions",
        "tableKey": "med_image_req",
        "isTable": true,
        "form": {
          "elementKey": "med_image_req",
          "label": "Medical Imaging",
          "addButtonText": "Add a medical imaging requisition",
          "formOption": "transpose",
          "formKey": "med_image_req",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labRequisitions.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "med_image_req_name",
                "med_image_req_profession",
                "med_image_req_day",
                "med_image_req_time"
              ]
            },
            {
              "elementKey": "reqMi_notice",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.reqMi_notice",
              "gIndex": "2",
              "gChildren": [
                "reqMi_id",
                "reqMi_dateRequired",
                "reqMi_schedule",
                "reqMi_reason"
              ]
            },
            {
              "elementKey": "cGroup24-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-3",
              "gIndex": "3",
              "gChildren": [
                "reqMi_type",
                "reqMi_Location",
                "reqMi_mobile",
                "reqMi_comments"
              ]
            }
          ],
          "ehr_data": {
            "med_image_req_name": "",
            "med_image_req_profession": "",
            "med_image_req_day": "",
            "med_image_req_time": "",
            "reqMi_id": "",
            "reqMi_dateRequired": "",
            "reqMi_schedule": "",
            "reqMi_reason": "",
            "reqMi_type": "",
            "reqMi_Location": "",
            "reqMi_mobile": "",
            "reqMi_comments": ""
          }
        },
        "fqn": "labRequisitions.med_image_req",
        "tableChildren": [
          "med_image_req_id",
          "med_image_req_day",
          "med_image_req_time",
          "reqMi_id",
          "reqMi_dateRequired",
          "reqMi_schedule",
          "reqMi_reason",
          "reqMi_type",
          "med_image_req_name",
          "med_image_req_profession"
        ],
        "hasRecHeader": true
      },
      "microRequisitions": {
        "elementKey": "microRequisitions",
        "pageElementIndex": "5",
        "pageKey": "labRequisitions",
        "tableKey": "microRequisitions",
        "isTable": true,
        "form": {
          "elementKey": "microRequisitions",
          "label": "Microbiology",
          "addButtonText": "Add a microbiology requisition",
          "formOption": "transpose",
          "formKey": "microRequisitions",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labRequisitions.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "microRequisitions_name",
                "microRequisitions_profession",
                "microRequisitions_day",
                "microRequisitions_time"
              ]
            },
            {
              "elementKey": "cGroup24-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-2",
              "gIndex": "2",
              "gChildren": [
                "reqMicro_id",
                "reqMicro_dateRequired",
                "reqMi_schedule",
                "reqMicro_reason"
              ]
            },
            {
              "elementKey": "cGroup24-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "labRequisitions.cGroup24-3",
              "gIndex": "3",
              "gChildren": [
                "reqMicro_culture",
                "reqMicro_location",
                "reqMicro_comments"
              ]
            }
          ],
          "ehr_data": {
            "microRequisitions_name": "",
            "microRequisitions_profession": "",
            "microRequisitions_day": "",
            "microRequisitions_time": "",
            "reqMicro_id": "",
            "reqMicro_dateRequired": "",
            "reqMi_schedule": "",
            "reqMicro_reason": "",
            "reqMicro_culture": "",
            "reqMicro_location": "",
            "reqMicro_comments": ""
          }
        },
        "fqn": "labRequisitions.microRequisitions",
        "tableChildren": [
          "microRequisitions_id",
          "microRequisitions_day",
          "microRequisitions_time",
          "reqMicro_id",
          "reqMicro_dateRequired",
          "reqMi_schedule",
          "reqMicro_reason",
          "reqMicro_culture",
          "reqMicro_location",
          "reqMicro_comments",
          "microRequisitions_name",
          "microRequisitions_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medicationOrders": {
    "pageDataKey": "medicationOrders",
    "pIndex": "25",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medicationOrders.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medicationOrders.time"
      },
      {
        "elementKey": "medication",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "lookahead",
        "tableColumn": "2",
        "fqn": "medicationOrders.medication"
      },
      {
        "elementKey": "dose",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medicationOrders.dose"
      },
      {
        "elementKey": "route",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Oral",
            "text": "Oral"
          },
          {
            "key": "Inhaler",
            "text": "Inhaler"
          },
          {
            "key": "Intravenous",
            "text": "Intravenous"
          },
          {
            "key": "Intramuscular",
            "text": "Intramuscular"
          },
          {
            "key": "Nasal",
            "text": "Nasal"
          },
          {
            "key": "Nebulized",
            "text": "Nebulized"
          },
          {
            "key": "Subcutaneous",
            "text": "Subcutaneous"
          },
          {
            "key": "Sublingual",
            "text": "Sublingual"
          },
          {
            "key": "Rectal",
            "text": "Rectal"
          },
          {
            "key": "Topical",
            "text": "Topical"
          },
          {
            "key": "Vaginal",
            "text": "Vaginal"
          }
        ],
        "tableColumn": "4",
        "fqn": "medicationOrders.route"
      },
      {
        "elementKey": "startMeds",
        "formIndex": "1",
        "inputType": "visitDay",
        "tableColumn": "5",
        "validation": "visitDay",
        "fqn": "medicationOrders.startMeds"
      },
      {
        "elementKey": "endMeds",
        "formIndex": "1",
        "inputType": "visitDay",
        "tableColumn": "6",
        "validation": "visitDay",
        "fqn": "medicationOrders.endMeds"
      },
      {
        "elementKey": "administration",
        "defaultValue": "sched",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "sched",
            "text": "Scheduled"
          },
          {
            "key": "prn",
            "text": "PRN (as needed)"
          },
          {
            "key": "once",
            "text": "Once"
          },
          {
            "key": "stat",
            "text": "Stat"
          },
          {
            "key": "od",
            "text": "OD (Once a Day)"
          },
          {
            "key": "set",
            "text": "Set times"
          }
        ],
        "tableColumn": "7",
        "fqn": "medicationOrders.administration"
      },
      {
        "elementKey": "scheduled",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "BID / q12h",
            "text": "BID / q12h"
          },
          {
            "key": "TID",
            "text": "TID"
          },
          {
            "key": "q8h",
            "text": "q8h"
          },
          {
            "key": "QID",
            "text": "QID"
          },
          {
            "key": "q6h",
            "text": "q6h"
          },
          {
            "key": "q4h",
            "text": "q4h"
          }
        ],
        "tableColumn": "9",
        "fqn": "medicationOrders.scheduled"
      },
      {
        "elementKey": "prn1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "validation": "time24",
        "fqn": "medicationOrders.prn1"
      },
      {
        "elementKey": "prn2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "11",
        "validation": "time24",
        "fqn": "medicationOrders.prn2"
      },
      {
        "elementKey": "prn3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "12",
        "validation": "time24",
        "fqn": "medicationOrders.prn3"
      },
      {
        "elementKey": "prn4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "13",
        "validation": "time24",
        "fqn": "medicationOrders.prn4"
      },
      {
        "elementKey": "prn5",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "14",
        "validation": "time24",
        "fqn": "medicationOrders.prn5"
      },
      {
        "elementKey": "prn6",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "15",
        "validation": "time24",
        "fqn": "medicationOrders.prn6"
      },
      {
        "elementKey": "once",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medicationOrders.once"
      },
      {
        "elementKey": "stat",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medicationOrders.stat"
      },
      {
        "elementKey": "instructions",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "16",
        "fqn": "medicationOrders.instructions"
      },
      {
        "elementKey": "reason",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "17",
        "fqn": "medicationOrders.reason"
      },
      {
        "elementKey": "notes",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "18",
        "fqn": "medicationOrders.notes"
      },
      {
        "elementKey": "medicationOrdersTable_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.name"
      },
      {
        "elementKey": "medicationOrdersTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "medicationOrdersTable_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.profession"
      },
      {
        "elementKey": "medicationOrdersTable_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medicationOrders.day"
      },
      {
        "elementKey": "medicationOrdersTable_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medicationOrders.time"
      },
      {
        "elementKey": "med_medication",
        "formIndex": "2",
        "inputType": "lookahead",
        "mandatory": "TRUE",
        "tableColumn": "2",
        "fqn": "medicationOrders.med_medication"
      },
      {
        "elementKey": "med_dose",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medicationOrders.med_dose"
      },
      {
        "elementKey": "med_timing",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "cont",
            "text": "连续给药"
          },
          {
            "key": "once",
            "text": "给药一次"
          },
          {
            "key": "prn",
            "text": "PRN (备用医嘱)"
          },
          {
            "key": "sched",
            "text": "按计划给药"
          },
          {
            "key": "set",
            "text": "按次数给药"
          },
          {
            "key": "stat",
            "text": "Stat (立刻给药)"
          }
        ],
        "tableColumn": "4",
        "fqn": "medicationOrders.med_timing"
      },
      {
        "elementKey": "med_route",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "口服",
            "text": "口服"
          },
          {
            "key": "静脉注射",
            "text": "静脉注射"
          },
          {
            "key": "肌肉注射",
            "text": "肌肉注射"
          },
          {
            "key": "皮下注射",
            "text": "皮下注射"
          },
          {
            "key": "吸入给药",
            "text": "吸入给药"
          },
          {
            "key": "鼻腔给药",
            "text": "鼻腔给药"
          },
          {
            "key": "眼内给药",
            "text": "眼内给药"
          },
          {
            "key": "耳内给药",
            "text": "耳内给药"
          },
          {
            "key": "直肠给药",
            "text": "直肠给药"
          },
          {
            "key": "舌下含服",
            "text": "舌下含服"
          }
          /*{
            "key": "经阴道给药",
            "text": "经阴道给药"
          },
          {
            "key": "intra",
            "text": "Intravenous"
          },
          {
            "key": "nasa",
            "text": "Nasal"
          },
          {
            "key": "nebu",
            "text": "Nebulization"
          },
          {
            "key": "ocul",
            "text": "Ocular"
          },
          {
            "key": "otic",
            "text": "Otic"
          },
          {
            "key": "rect",
            "text": "Rectal"
          },
          {
            "key": "subc",
            "text": "Subcutaneous"
          },
          {
            "key": "subl",
            "text": "Sublingual"
          },
          {
            "key": "topical",
            "text": "Topical"
          },
          {
            "key": "tran",
            "text": "Transdermal"
          },
          {
            "key": "vagi",
            "text": "Vaginal"
          }*/
        ],
        "tableColumn": "5",
        "fqn": "medicationOrders.med_route"
      },
      {
        "elementKey": "med_reason",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "6",
        "fqn": "medicationOrders.med_reason"
      },
      {
        "elementKey": "med_injectionLocation",
        "formIndex": "2",
        "formOption": "hideElement",
        "inputType": "select",
        "options": [
          {
            "key": "arterial",
            "text": "Arterial"
          },
          {
            "key": "epidural",
            "text": "Epidural"
          },
          {
            "key": "intramuscular",
            "text": "Intramuscular"
          },
          {
            "key": "intraosseous",
            "text": "Intraosseous"
          },
          {
            "key": "intraperitoneal",
            "text": "Intraperitoneal"
          },
          {
            "key": "intrathecal",
            "text": "Intrathecal"
          },
          {
            "key": "intravenous",
            "text": "Intravenous"
          },
          {
            "key": "subcutaneous",
            "text": "Subcutaneous"
          }
        ],
        "tableColumn": "7",
        "fqn": "medicationOrders.med_injectionLocation"
      },
      {
        "elementKey": "med_alert",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "临时医嘱",
            "text": "临时医嘱"
          },
          {
            "key": "长期医嘱",
            "text": "长期医嘱"
          },
        /*  {
            "key": "confusion",
            "text": "Potential medication name confusion"
          },
          {
            "key": "second",
            "text": "Second signature required"
          }*/
        ],
        "tableColumn": "8",
        "fqn": "medicationOrders.med_alert"
      },
      {
        "elementKey": "med_status",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "已处方",
            "text": "已处方"
          },
          {
            "key": "已核对",
            "text": "已核对"
          },
          {
            "key": "已配药",
            "text": "已配药"
          },
          {
            "key": "已安排",
            "text": "已安排"
          },
          {
            "key": "重新生效",
            "text": "重新生效"
          },
          {
            "key": "处方错误",
            "text": "处方错误"
          }
        ],
        "tableColumn": "9",
        "fqn": "medicationOrders.med_status"
      },
      {
        "elementKey": "med_scheduled",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "OD",
            "text": "OD"
          },
          {
            "key": "BID",
            "text": "BID"
          },
          {
            "key": "TID",
            "text": "TID"
          },
          {
            "key": "QID",
            "text": "QID"
          },
          {
            "key": "Q12H",
            "text": "Q12H"
          },
          {
            "key": "Q8H",
            "text": "Q8H"
          },
          {
            "key": "Q6H",
            "text": "Q6H"
          },
          {
            "key": "Q4H",
            "text": "Q4H"
          },
          {
            "key": "Q2H",
            "text": "Q2H"
          },
          {
            "key": "Q1H",
            "text": "Q1H"
          }
        ],
        "tableColumn": "9",
        "fqn": "medicationOrders.med_scheduled"
      },
      {
        "elementKey": "med_prnMaxDosage",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medicationOrders.med_prnMaxDosage"
      },
      {
        "elementKey": "med_time1",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "11",
        "validation": "time24",
        "fqn": "medicationOrders.med_time1"
      },
      {
        "elementKey": "med_time2",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "12",
        "validation": "time24",
        "fqn": "medicationOrders.med_time2"
      },
      {
        "elementKey": "med_time3",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "13",
        "validation": "time24",
        "fqn": "medicationOrders.med_time3"
      },
      {
        "elementKey": "med_time4",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "14",
        "validation": "time24",
        "fqn": "medicationOrders.med_time4"
      },
      {
        "elementKey": "med_time5",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "15",
        "validation": "time24",
        "fqn": "medicationOrders.med_time5"
      },
      {
        "elementKey": "med_time6",
        "dependentOn": "onChange:med_scheduled",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "16",
        "validation": "time24",
        "fqn": "medicationOrders.med_time6"
      },
      {
        "elementKey": "med_continuous_description",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "17",
        "fqn": "medicationOrders.med_continuous_description"
      },
      {
        "elementKey": "med_instructions",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "18",
        "fqn": "medicationOrders.med_instructions"
      },
      {
        "elementKey": "med_aux_data",
        "formIndex": "2",
        "formOption": "hideElement",
        "inputType": "aux_data",
        "fqn": "medicationOrders.med_aux_data"
      },
      {
        "elementKey": "medicationOrderAux_name",
        "formIndex": "3",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.name"
      },
      {
        "elementKey": "medicationOrderAux_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "medicationOrderAux_profession",
        "formIndex": "3",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicationOrders.profession"
      },
      {
        "elementKey": "medicationOrderAux_day",
        "formIndex": "3",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medicationOrders.day"
      },
      {
        "elementKey": "medicationOrderAux_time",
        "formIndex": "3",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medicationOrders.time"
      },
      {
        "elementKey": "aux_med_dose",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "1",
        "mainKey": "med_dose",
        "fqn": "medicationOrders.aux_med_dose"
      },
      {
        "elementKey": "aux_med_status",
        "formIndex": "3",
        "inputType": "select",
        "options": [
          {
            "key": "appr",
            "text": "Approved"
          },
          {
            "key": "disp",
            "text": "Dispensed"
          },
          {
            "key": "admi",
            "text": "Administered"
          },
          {
            "key": "sche",
            "text": "Scheduled"
          },
          {
            "key": "hold",
            "text": "On Hold"
          },
          {
            "key": "dose",
            "text": "Dose Change"
          },
          {
            "key": "disc",
            "text": "Discontinued"
          },
          {
            "key": "canc",
            "text": "Cancelled"
          },
          {
            "key": "expi",
            "text": "Expired"
          },
          {
            "key": "reac",
            "text": "Reactivated"
          },
          {
            "key": "erro",
            "text": "Error"
          }
        ],
        "tableColumn": "2",
        "mainKey": "med_status",
        "fqn": "medicationOrders.aux_med_status"
      },
      {
        "elementKey": "aux_med_prnMaxDosage",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "2",
        "mainKey": "med_prnMaxDosage",
        "fqn": "medicationOrders.aux_med_prnMaxDosage"
      },
      {
        "elementKey": "aux_med_reason",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "1",
        "mainKey": "med_reason",
        "fqn": "medicationOrders.aux_med_reason"
      },
      {
        "elementKey": "aux_med_instructions",
        "formIndex": "3",
        "inputType": "textarea",
        "tableColumn": "1",
        "mainKey": "med_instructions",
        "fqn": "medicationOrders.aux_med_instructions"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "medicationOrders",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Medication orders v1",
          "addButtonText": "Add a v1 medication order",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medicationOrders.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "group1",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.group1",
              "gIndex": "2",
              "gChildren": [
                "medication",
                "dose",
                "route",
                "startMeds",
                "endMeds"
              ]
            },
            {
              "elementKey": "adminGroup",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.adminGroup",
              "gIndex": "3",
              "gChildren": [
                "administration"
              ]
            },
            {
              "elementKey": "schedGroup",
              "dependentOn": "visble:administration=sched,prn",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.schedGroup",
              "gIndex": "4",
              "gChildren": [
                "scheduled"
              ]
            },
            {
              "elementKey": "prnGroup",
              "dependentOn": "visble:administration=set",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.prnGroup",
              "gIndex": "5",
              "gChildren": [
                "prn1",
                "prn2",
                "prn3",
                "prn4",
                "prn5",
                "prn6"
              ]
            },
            {
              "elementKey": "onceGroup",
              "dependentOn": "visble:administration=once",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.onceGroup",
              "gIndex": "6",
              "gChildren": [
                "once"
              ]
            },
            {
              "elementKey": "statGroup",
              "dependentOn": "visble:administration=stat",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.statGroup",
              "gIndex": "7",
              "gChildren": [
                "stat"
              ]
            },
            {
              "elementKey": "group2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.group2",
              "gIndex": "8",
              "gChildren": [
                "instructions",
                "reason",
                "notes"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "medication": "",
            "dose": "",
            "route": "",
            "startMeds": "",
            "endMeds": "",
            "administration": "sched",
            "scheduled": "",
            "prn1": "",
            "prn2": "",
            "prn3": "",
            "prn4": "",
            "prn5": "",
            "prn6": "",
            "once": "",
            "stat": "",
            "instructions": "",
            "reason": "",
            "notes": ""
          }
        },
        "fqn": "medicationOrders.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "medication",
          "dose",
          "route",
          "startMeds",
          "endMeds",
          "administration",
          "scheduled",
          "prn1",
          "prn2",
          "prn3",
          "prn4",
          "prn5",
          "prn6",
          "once",
          "stat",
          "instructions",
          "reason",
          "notes",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      },
      "medicationOrdersTable": {
        "elementKey": "medicationOrdersTable",
        "pageElementIndex": "2",
        "pageKey": "medicationOrders",
        "tableKey": "medicationOrdersTable",
        "isTable": true,
        "tableAction": "medAdminRec.marTable",
        "auxTableRef": "medicationOrderAux",
        "form": {
          "elementKey": "medicationOrdersTable",
          "label": "Medication orders",
          "addButtonText": "Add a medication order",
          "formKey": "medicationOrdersTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medicationOrders.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "medicationOrdersTable_name",
                "medicationOrdersTable_profession",
                "medicationOrdersTable_day",
                "medicationOrdersTable_time"
              ]
            },
            {
              "elementKey": "med_group_main",
              "formCss": "grid-left-to-right-1",
              "fqn": "medicationOrders.med_group_main",
              "gIndex": "2",
              "gChildren": [
                "med_medication",
                "med_dose",
                "med_timing",
                "med_route",
                "med_reason",
                "med_injectionLocation",
                "med_alert",
                "med_status"
              ]
            },
            {
              "elementKey": "med_group_with_times",
              "dependentOn": "visble:med_timing=prn,sched,set",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.med_group_with_times",
              "gIndex": "3",
              "gChildren": [
                "med_scheduled"
              ]
            },
            {
              "elementKey": "med_prn_group",
              "dependentOn": "visble:med_timing=prn",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.med_prn_group",
              "gIndex": "4",
              "gChildren": [
                "med_prnMaxDosage"
              ]
            },
            {
              "elementKey": "med_group_times",
              "dependentOn": "visble:med_timing=prn,sched,set",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.med_group_times",
              "gIndex": "5",
              "gChildren": [
                "med_time1",
                "med_time2",
                "med_time3",
                "med_time4",
                "med_time5",
                "med_time6"
              ]
            },
            {
              "elementKey": "med_group_infusion_c1",
              "dependentOn": "visble:med_timing=cont",
              "formCss": "grid-left-to-right-1",
              "fqn": "medicationOrders.med_group_infusion_c1",
              "gIndex": "6",
              "gChildren": [
                "med_continuous_description"
              ]
            },
            {
              "elementKey": "med_group_notes",
              "formCss": "grid-left-to-right-1",
              "fqn": "medicationOrders.med_group_notes",
              "gIndex": "7",
              "gChildren": [
                "med_instructions",
                "med_aux_data"
              ]
            }
          ],
          "ehr_data": {
            "medicationOrdersTable_name": "",
            "medicationOrdersTable_profession": "",
            "medicationOrdersTable_day": "",
            "medicationOrdersTable_time": "",
            "med_medication": "",
            "med_dose": "",
            "med_timing": "",
            "med_route": "",
            "med_reason": "",
            "med_injectionLocation": "",
            "med_alert": "",
            "med_status": "",
            "med_scheduled": "",
            "med_prnMaxDosage": "",
            "med_time1": "",
            "med_time2": "",
            "med_time3": "",
            "med_time4": "",
            "med_time5": "",
            "med_time6": "",
            "med_continuous_description": "",
            "med_instructions": ""
          }
        },
        "fqn": "medicationOrders.medicationOrdersTable",
        "tableActionType": "hideButton",
        "taTargetPageKey": "medAdminRec",
        "taTargetTableKey": "marTable",
        "taSourcePageKey": "medicationOrders",
        "taSourceTableKey": "medicationOrdersTable",
        "tableChildren": [
          "medicationOrdersTable_id",
          "medicationOrdersTable_day",
          "medicationOrdersTable_time",
          "med_medication",
          "med_dose",
          "med_timing",
          "med_route",
          "med_reason",
          "med_injectionLocation",
          "med_alert",
          "med_status",
          "med_scheduled",
          "med_prnMaxDosage",
          "med_time1",
          "med_time2",
          "med_time3",
          "med_time4",
          "med_time5",
          "med_time6",
          "med_continuous_description",
          "med_instructions",
          "medicationOrdersTable_name",
          "medicationOrdersTable_profession"
        ],
        "hasRecHeader": true
      },
      "medicationOrderAux": {
        "elementKey": "medicationOrderAux",
        "pageElementIndex": "3",
        "pageKey": "medicationOrders",
        "tableKey": "medicationOrderAux",
        "mainTableKey": "medicationOrdersTable",
        "isTable": true,
        "isAuxTable": true,
        "form": {
          "elementKey": "medicationOrderAux",
          "label": "Change Med Order",
          "addButtonText": "Update Medication Order",
          "formKey": "medicationOrderAux",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medicationOrders.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "medicationOrderAux_name",
                "medicationOrderAux_profession",
                "medicationOrderAux_day",
                "medicationOrderAux_time"
              ]
            },
            {
              "elementKey": "aux_med_group_main",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicationOrders.aux_med_group_main",
              "gIndex": "2",
              "gChildren": [
                "aux_med_dose",
                "aux_med_status",
                "aux_med_prnMaxDosage"
              ]
            },
            {
              "elementKey": "aux_med_group_notes",
              "formCss": "grid-left-to-right-1",
              "fqn": "medicationOrders.aux_med_group_notes",
              "gIndex": "3",
              "gChildren": [
                "aux_med_reason",
                "aux_med_instructions"
              ]
            }
          ],
          "ehr_data": {
            "medicationOrderAux_name": "",
            "medicationOrderAux_profession": "",
            "medicationOrderAux_day": "",
            "medicationOrderAux_time": "",
            "aux_med_dose": "",
            "aux_med_status": "",
            "aux_med_prnMaxDosage": "",
            "aux_med_reason": "",
            "aux_med_instructions": ""
          }
        },
        "fqn": "medicationOrders.medicationOrdersTable.medicationOrderAux",
        "tableChildren": [
          "medicationOrderAux_id",
          "medicationOrderAux_day",
          "medicationOrderAux_time",
          "aux_med_dose",
          "aux_med_status",
          "aux_med_prnMaxDosage",
          "aux_med_reason",
          "aux_med_instructions",
          "medicationOrderAux_name",
          "medicationOrderAux_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medAdminRec": {
    "pageDataKey": "medAdminRec",
    "pIndex": "26",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "medStatus",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Active",
            "text": "Active"
          },
          {
            "key": "Hold",
            "text": "Hold"
          },
          {
            "key": "Discontinued",
            "text": "Discontinued"
          }
        ],
        "tableColumn": "1",
        "fqn": "medAdminRec.medStatus"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "adminStatus",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Administered",
            "text": "Administered"
          },
          {
            "key": "Refused",
            "text": "Refused"
          },
          {
            "key": "Missed",
            "text": "Missed"
          },
          {
            "key": "Skipped",
            "text": "Skipped"
          }
        ],
        "tableColumn": "2",
        "fqn": "medAdminRec.adminStatus"
      },
      {
        "elementKey": "medication",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medAdminRec.medication"
      },
      {
        "elementKey": "route",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medAdminRec.route"
      },
      {
        "elementKey": "todayPlan",
        "formIndex": "1",
        "inputType": "text",
        "options": [
          {
            "key": "[Today's day] plan",
            "text": "[Today's day] plan"
          }
        ],
        "tableColumn": "5",
        "fqn": "medAdminRec.todayPlan"
      },
      {
        "elementKey": "todayGiven",
        "formIndex": "1",
        "inputType": "text",
        "options": [
          {
            "key": "[Today's day] given",
            "text": "[Today's day] given"
          }
        ],
        "tableColumn": "6",
        "fqn": "medAdminRec.todayGiven"
      },
      {
        "elementKey": "administeredBy",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "7",
        "fqn": "medAdminRec.administeredBy"
      },
      {
        "elementKey": "adminProfession",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "8",
        "fqn": "medAdminRec.adminProfession"
      },
      {
        "elementKey": "administeredTime",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medAdminRec.administeredTime"
      },
      {
        "elementKey": "marTable_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medAdminRec.name"
      },
      {
        "elementKey": "marTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "marTable_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medAdminRec.profession"
      },
      {
        "elementKey": "marTable_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medAdminRec.day"
      },
      {
        "elementKey": "marTable_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medAdminRec.time"
      },
      {
        "elementKey": "med_order_embedded",
        "formIndex": "2",
        "embedRef": "medicationOrders.medicationOrdersTable",
        "inputType": "ehr_embedded",
        "passToFunction": "[mo_medOrder mo_medDetails mo_alert mo_route mo_timing mo_medInstructions mo_medReason mo_id]",
        "tableColumn": "13",
        "fqn": "medAdminRec.med_order_embedded"
      },
      {
        "elementKey": "mo_medOrder",
        "calculationType": "medOrderSummary(medicationOrders,medicationOrdersTable)",
        "formIndex": "2",
        "formCss": "grid-span-4-columns",
        "inputType": "calculatedValue",
        "tableColumn": "2",
        "fqn": "medAdminRec.mo_medOrder"
      },
      {
        "elementKey": "mo_medDetails",
        "calculationType": "medOrderDetails(medicationOrders,medicationOrdersTable)",
        "formIndex": "2",
        "formCss": "grid-span-4-columns",
        "inputType": "calculatedValue",
        "fqn": "medAdminRec.mo_medDetails"
      },
      {
        "elementKey": "mo_medInstructions",
        "calculationType": "embedValue(medicationOrders, medicationOrdersTable, med_instructions)",
        "formIndex": "2",
        "formCss": "grid-span-4-columns",
        "inputType": "calculatedValue",
        "fqn": "medAdminRec.mo_medInstructions"
      },
      {
        "elementKey": "mo_medReason",
        "calculationType": "embedValue(medicationOrders, medicationOrdersTable, med_reason)",
        "formIndex": "2",
        "formCss": "grid-span-4-columns",
        "inputType": "calculatedValue",
        "fqn": "medAdminRec.mo_medReason"
      },
      {
        "elementKey": "mo_route",
        "calculationType": "medAdminRoute(medicationOrders, medicationOrdersTable, med_route)",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "3",
        "fqn": "medAdminRec.mo_route"
      },
      {
        "elementKey": "mo_timing",
        "calculationType": "medAdminTiming(medicationOrders, medicationOrdersTable, med_timing)",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "4",
        "fqn": "medAdminRec.mo_timing"
      },
      {
        "elementKey": "mo_schedDay",
        "formIndex": "2",
        "formCss": "visit-day",
        "formOption": "elementViewOnly",
        "inputType": "visitDay",
        "tableColumn": "7",
        "fqn": "medAdminRec.mo_schedDay"
      },
      {
        "elementKey": "mo_schedTime",
        "formIndex": "2",
        "formCss": "visit-time",
        "formOption": "elementViewOnly",
        "inputType": "visitTime",
        "tableColumn": "8",
        "fqn": "medAdminRec.mo_schedTime"
      },
      {
        "elementKey": "mo_id",
        "calculationType": "embedValue(medicationOrders, medicationOrdersTable, medicationOrdersTable_id)",
        "formIndex": "2",
        "formOption": "hideElement",
        "inputType": "calculatedValue",
        "fqn": "medAdminRec.mo_id"
      },
      {
        "elementKey": "mo_alert",
        "calculationType": "medAdminAlert(medicationOrders,medicationOrdersTable)",
        "formIndex": "2",
        "formCss": "grid-span-4-columns",
        "inputType": "calculatedValue",
        "tableColumn": "11",
        "fqn": "medAdminRec.mo_alert"
      },
      {
        "elementKey": "mar_status",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "已给药",
            "text": "已给药"
          },
          {
            "key": "等待给药",
            "text": "等待给药"
          },
          {
            "key": "拒绝给药",
            "text": "拒绝给药"
          },
          {
            "key": "错过给药",
            "text": "错过给药"
          },
          {
            "key": "跳过给药",
            "text": "跳过给药"
          }
        ],
        "tableColumn": "4",
        "fqn": "medAdminRec.mar_status"
      },
      {
        "elementKey": "mar_dose",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medAdminRec.mar_dose"
      },
      {
        "elementKey": "mar_spacer4",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "medAdminRec.mar_spacer4"
      },
      {
        "elementKey": "mar_route",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "oral",
            "text": "口服"
          },
          {
            "key": "bucc",
            "text": "静脉注射"
          },
          {
            "key": "epidural",
            "text": "肌肉注射"
          },
          {
            "key": "impl",
            "text": "皮下注射"
          },
          {
            "key": "inha",
            "text": "吸入给药"
          },
          {
            "key": "inje",
            "text": "鼻腔给药"
          },
          {
            "key": "intradermal",
            "text": "耳内给药"
          },
          {
            "key": "intramuscular",
            "text": "直肠给药"
          },
          {
            "key": "intraosseous",
            "text": "舌下含服"
          },
          {
            "key": "intraperitoneal",
            "text": "经阴道给药"
          },
          /*{
            "key": "intrathecal",
            "text": "Intrathecal"
          },*/
          {
            "key": "intra",
            "text": "眼内给药"
          }
          /*{
            "key": "nasa",
            "text": "Nasal"
          },
          {
            "key": "nebu",
            "text": "Nebulization"
          },
          {
            "key": "ocul",
            "text": "Ocular"
          },
          {
            "key": "otic",
            "text": "Otic"
          },
          {
            "key": "rect",
            "text": "Rectal"
          },
          {
            "key": "subc",
            "text": "Subcutaneous"
          },
          {
            "key": "subl",
            "text": "Sublingual"
          },
          {
            "key": "topical",
            "text": "Topical"
          },
          {
            "key": "tran",
            "text": "Transdermal"
          },
          {
            "key": "vagi",
            "text": "Vaginal"
          }*/
        ],
        "tableColumn": "6",
        "fqn": "medAdminRec.mar_route"
      },
      {
        "elementKey": "mar_event_day",
        "formIndex": "2",
        "formCss": "visit-day",
        "inputType": "visitDay",
        "tableColumn": "9",
        "validation": "visitDay",
        "fqn": "medAdminRec.mar_event_day"
      },
      {
        "elementKey": "mar_event_time",
        "formIndex": "2",
        "formCss": "visit-time",
        "inputType": "visitTime",
        "tableColumn": "10",
        "validation": "time24",
        "fqn": "medAdminRec.mar_event_time"
      },
      {
        "elementKey": "mar_location",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "12",
        "fqn": "medAdminRec.mar_location"
      },
      {
        "elementKey": "mar_secSigName",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "13",
        "fqn": "medAdminRec.mar_secSigName"
      },
      {
        "elementKey": "mar_barCodeCheck",
        "formIndex": "2",
        "formOption": "elementViewOnly",
        "inputType": "checkbox",
        "tableColumn": "14",
        "fqn": "medAdminRec.mar_barCodeCheck"
      },
      {
        "elementKey": "mar_comments",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "15",
        "fqn": "medAdminRec.mar_comments"
      },
      {
        "elementKey": "mar_internal",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "16",
        "fqn": "medAdminRec.mar_internal"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "medAdminRec",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Medication administration records v1",
          "addButtonText": "Administered",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "medGroup1",
              "formCss": "grid-left-to-right-3",
              "fqn": "medAdminRec.medGroup1",
              "gIndex": "1",
              "gChildren": [
                "medStatus",
                "adminStatus",
                "medication",
                "route",
                "todayPlan",
                "todayGiven",
                "administeredBy",
                "adminProfession",
                "administeredTime"
              ]
            }
          ],
          "ehr_data": {
            "medStatus": "",
            "adminStatus": "",
            "medication": "",
            "route": "",
            "todayPlan": "",
            "todayGiven": "",
            "administeredBy": "",
            "adminProfession": "",
            "administeredTime": ""
          }
        },
        "fqn": "medAdminRec.table",
        "tableChildren": [
          "table_id",
          "medication",
          "route",
          "todayPlan",
          "todayGiven",
          "administeredBy",
          "adminProfession",
          "administeredTime",
          "medStatus",
          "adminStatus"
        ]
      },
      "marTable": {
        "elementKey": "marTable",
        "pageElementIndex": "2",
        "pageKey": "medAdminRec",
        "tableKey": "marTable",
        "isTable": true,
        "form": {
          "elementKey": "marTable",
          "label": "Medication administration records",
          "addButtonText": "NONE",
          "formKey": "marTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medAdminRec.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "marTable_name",
                "marTable_profession",
                "marTable_day",
                "marTable_time"
              ]
            },
            {
              "elementKey": "medGroup1",
              "formOption": "hideGroup",
              "formCss": "grid-left-to-right-3",
              "fqn": "medAdminRec.medGroup1",
              "gIndex": "2",
              "gChildren": [
                "med_order_embedded"
              ]
            },
            {
              "elementKey": "medGroup2",
              "formCss": "embedded-data grid-left-to-right-4",
              "fqn": "medAdminRec.medGroup2",
              "gIndex": "3",
              "gChildren": [
                "mo_medOrder",
                "mo_medDetails",
                "mo_medInstructions",
                "mo_medReason",
                "mo_route",
                "mo_timing",
                "mo_schedDay",
                "mo_schedTime",
                "mo_id",
                "mo_alert"
              ]
            },
            {
              "elementKey": "medGroup3",
              "formCss": "grid-left-to-right-3",
              "fqn": "medAdminRec.medGroup3",
              "gIndex": "4",
              "gChildren": [
                "mar_status",
                "mar_dose",
                "mar_spacer4",
                "mar_route",
                "mar_event_day",
                "mar_event_time",
                "mar_location",
                "mar_secSigName",
                "mar_barCodeCheck"
              ]
            },
            {
              "elementKey": "mar_group_notes",
              "formCss": "grid-left-to-right-1",
              "fqn": "medAdminRec.mar_group_notes",
              "gIndex": "5",
              "gChildren": [
                "mar_comments",
                "mar_internal"
              ]
            }
          ],
          "ehr_data": {
            "marTable_name": "",
            "marTable_profession": "",
            "marTable_day": "",
            "marTable_time": "",
            "med_order_embedded": "",
            "mo_medOrder": "",
            "mo_medDetails": "",
            "mo_medInstructions": "",
            "mo_medReason": "",
            "mo_route": "",
            "mo_timing": "",
            "mo_schedDay": "",
            "mo_schedTime": "",
            "mo_id": "",
            "mo_alert": "",
            "mar_status": "",
            "mar_dose": "",
            "mar_route": "",
            "mar_event_day": "",
            "mar_event_time": "",
            "mar_location": "",
            "mar_secSigName": "",
            "mar_barCodeCheck": "",
            "mar_comments": "",
            "mar_internal": ""
          }
        },
        "fqn": "medAdminRec.marTable",
        "tableChildren": [
          "marTable_id",
          "marTable_day",
          "marTable_time",
          "med_order_embedded",
          "mo_medOrder",
          "mo_route",
          "mo_timing",
          "mo_schedDay",
          "mo_schedTime",
          "mo_alert",
          "mar_status",
          "mar_dose",
          "mar_route",
          "mar_event_day",
          "mar_event_time",
          "mar_location",
          "mar_secSigName",
          /*"mar_barCodeCheck",*/
          "mar_comments",
          "mar_internal",
          "marTable_name",
          "marTable_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "dischargeSummary": {
    "pageDataKey": "dischargeSummary",
    "pIndex": "27",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "admissionDay",
        /*"defaultValue": "0",*/
        "formIndex": "1",
        "inputType": "visitDay",
        "validation": "visitDay",
        "fqn": "dischargeSummary.admissionDay"
      },
      {
        "elementKey": "dischargingPhysician/np",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "田永昌 医生",
            "text": "田永昌 医生"
          },
          {
            "key": "朱力行 医生",
            "text": "朱力行 医生"
          },
          {
            "key": "魏建明 医生",
            "text": "魏建明 医生"
          },
          {
            "key": "殷建章 医生",
            "text": "殷建章 医生"
          },
          {
            "key": "江飞羽 医生",
            "text": "江飞羽 医生"
          },
          {
            "key": "秦安民 医生",
            "text": "秦安民 医生"
          },
          {
            "key": "梁弘光 医生",
            "text": "梁弘光 医生"
          }
        ],
        "fqn": "dischargeSummary.dischargingPhysician/np"
      },
      {
        "elementKey": "spacer27",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "dischargeSummary.spacer27"
      },
      {
        "elementKey": "clinicalSummary",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "textarea",
        "fqn": "dischargeSummary.clinicalSummary"
      },
      {
        "elementKey": "dischargeDiagnosis",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "fqn": "dischargeSummary.dischargeDiagnosis"
      },
      {
        "elementKey": "dischargeEducation",
        "formIndex": "2",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "fqn": "dischargeSummary.dischargeEducation"
      },
      {
        "elementKey": "spacer28",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "dischargeSummary.spacer28"
      },
      {
        "elementKey": "dischargeDay",
        "formIndex": "2",
        "inputType": "visitDay",
        "validation": "visitDay",
        "fqn": "dischargeSummary.dischargeDay"
      },
      {
        "elementKey": "dischargeTime",
        "formIndex": "2",
        "inputType": "visitTime",
        "validation": "time24",
        "fqn": "dischargeSummary.dischargeTime"
      },
      {
        "elementKey": "dischargedTo",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "准予出院",
            "text": "准予出院"
          },
          {
            "key": "自动出院",
            "text": "自动出院"
          },
          {
            "key": "转科或转院",
            "text": "转科或转院"
          }
        ],
        "fqn": "dischargeSummary.dischargedTo"
      },
      {
        "elementKey": "transferedTo",
        "formIndex": "2",
        "inputType": "text",
        "fqn": "dischargeSummary.transferedTo"
      },
      {
        "elementKey": "leavingBy",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "步行",
            "text": "步行"
          },
          {
            "key": "轮椅",
            "text": "轮椅"
          },
          {
            "key": "平车",
            "text": "平车"
          },
          {
            "key": "家人接走",
            "text": "家人接走"
          }
        ],
        "fqn": "dischargeSummary.leavingBy"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "dischargeSummary.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup27-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "dischargeSummary.cGroup27-1",
            "gIndex": "1",
            "gChildren": [
              "admissionDay",
              "dischargingPhysician/np",
              "spacer27",
              "clinicalSummary"
            ]
          }
        ]
      },
      "pageForm2": {
        "elementKey": "pageForm2",
        "pageElementIndex": "2",
        "formKey": "pageForm2",
        "fqn": "dischargeSummary.pageForm2",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup27-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "dischargeSummary.cGroup27-1",
            "gIndex": "1",
            "gChildren": [
              "dischargeDiagnosis",
              "dischargeEducation",
              "spacer28",
              "dischargeDay",
              "dischargeTime",
              "dischargedTo",
              "transferedTo",
              "leavingBy"
            ]
          }
        ]
      }
    }
  },
  "billing": {
    "pageDataKey": "billing",
    "pIndex": "28",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "paymentType",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "医保",
            "text": "医保"
          },
          {
            "key": "自费",
            "text": "自费"
          },
          {
            "key": "其他",
            "text": "其他"
          }
        ],
        "fqn": "billing.paymentType"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "billing.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup28-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "billing.cGroup28-1",
            "gIndex": "1",
            "gChildren": [
              "paymentType"
            ]
          }
        ]
      }
    }
  },
  "progressNotes": {
    "pageDataKey": "progressNotes",
    "pIndex": "29",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "progressNotes.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "progressNotes.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "progressNotes.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "progressNotes.time"
      },
      {
        "elementKey": "note",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "2",
        "fqn": "progressNotes.note"
      },
      {
        "elementKey": "sbar_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "progressNotes.name"
      },
      {
        "elementKey": "sbar_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "sbar_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "progressNotes.profession"
      },
      {
        "elementKey": "sbar_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "progressNotes.day"
      },
      {
        "elementKey": "sbar_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "progressNotes.time"
      },
      {
        "elementKey": "situation",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "2",
        "fqn": "progressNotes.situation"
      },
      {
        "elementKey": "background",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "progressNotes.background"
      },
      {
        "elementKey": "assessment",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "4",
        "fqn": "progressNotes.assessment"
      },
      {
        "elementKey": "recommendation",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "5",
        "fqn": "progressNotes.recommendation"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "progressNotes",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Progress notes",
          "addButtonText": "Add an encounter note",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "progressNotes.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup29-2",
              "formCss": "grid-left-to-right-1",
              "fqn": "progressNotes.cGroup29-2",
              "gIndex": "2",
              "gChildren": [
                "note"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "note": ""
          }
        },
        "fqn": "progressNotes.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "note",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      },
      "sbar": {
        "elementKey": "sbar",
        "pageElementIndex": "2",
        "pageKey": "progressNotes",
        "tableKey": "sbar",
        "isTable": true,
        "form": {
          "elementKey": "sbar",
          "label": "SBAR notes",
          "addButtonText": "Add a SBAR note",
          "formKey": "sbar",
          "ehr_groups": [
            {
              "elementKey": "sbarHdr",
              "formCss": "record-header",
              "fqn": "progressNotes.sbarHdr",
              "gIndex": "1",
              "gChildren": [
                "sbar_name",
                "sbar_profession",
                "sbar_day",
                "sbar_time"
              ]
            },
            {
              "elementKey": "sbarNotes",
              "formCss": "grid-left-to-right-1",
              "fqn": "progressNotes.sbarNotes",
              "gIndex": "2",
              "gChildren": [
                "situation",
                "background",
                "assessment",
                "recommendation"
              ]
            }
          ],
          "ehr_data": {
            "sbar_name": "",
            "sbar_profession": "",
            "sbar_day": "",
            "sbar_time": "",
            "situation": "",
            "background": "",
            "assessment": "",
            "recommendation": ""
          }
        },
        "fqn": "progressNotes.sbar",
        "tableChildren": [
          "sbar_id",
          "sbar_day",
          "sbar_time",
          "situation",
          "background",
          "assessment",
          "recommendation",
          "sbar_name",
          "sbar_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "carePlan": {
    "pageDataKey": "carePlan",
    "pIndex": "30",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "carePlan.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "carePlan.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "carePlan.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "carePlan.time"
      },
      {
        "elementKey": "clinical",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "2",
        "fqn": "carePlan.clinical"
      },
      {
        "elementKey": "goal",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "carePlan.goal"
      },
      {
        "elementKey": "interventions",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "4",
        "fqn": "carePlan.interventions"
      },
      {
        "elementKey": "evaluation",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "5",
        "fqn": "carePlan.evaluation"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "进行中",
            "text": "进行中"
          },
          {
            "key": "已完成",
            "text": "已完成"
          }
        ],
        "tableColumn": "6",
        "fqn": "carePlan.status"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "carePlan",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Care plan",
          "addButtonText": "Add a care plan",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "carePlan.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup30-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "carePlan.cGroup30-2",
              "gIndex": "2",
              "gChildren": [
                "clinical",
                "goal",
                "interventions",
                "evaluation",
                "status"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "clinical": "",
            "goal": "",
            "interventions": "",
            "evaluation": "",
            "status": ""
          }
        },
        "fqn": "carePlan.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "clinical",
          "goal",
          "interventions",
          "evaluation",
          "status",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "consults": {
    "pageDataKey": "consults",
    "pIndex": "31",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "consults.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "consults.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "consults.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "consults.time"
      },
      {
        "elementKey": "consultReport",
        "formIndex": "1",
        "inputType": "ehrFile",
        "tableColumn": "2",
        "fqn": "consults.consultReport"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "consults.comments"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "consults",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Consults",
          "addButtonText": "Add a consultation report",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "consults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup31-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "consults.cGroup31-2",
              "gIndex": "2",
              "gChildren": [
                "consultReport",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "consultReport": "",
            "comments": ""
          }
        },
        "fqn": "consults.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "consultReport",
          "comments",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "labResults": {
    "pageDataKey": "labResults",
    "pIndex": "32",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labResults.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labResults.time"
      },
      {
        "elementKey": "laboratoryReport",
        "formIndex": "1",
        "inputType": "ehrFile",
        "tableColumn": "2",
        "fqn": "labResults.laboratoryReport"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "labResults.comments"
      },
      {
        "elementKey": "labResultGeneral_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.name"
      },
      {
        "elementKey": "labResultGeneral_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "labResultGeneral_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.profession"
      },
      {
        "elementKey": "labResultGeneral_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labResults.day"
      },
      {
        "elementKey": "labResultGeneral_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labResults.time"
      },
      {
        "elementKey": "urinOrderPhys",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "labResults.urinOrderPhys"
      },
      {
        "elementKey": "lrh_HCO3",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "21–32 mmol/L",
        "tableColumn": "3",
        "fqn": "labResults.lrh_HCO3",
        "suffixText": "21–32 mmol/L"
      },
      {
        "elementKey": "lrh_Ca",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "2.12–2.52 mmol/L",
        "tableColumn": "4",
        "fqn": "labResults.lrh_Ca",
        "suffixText": "2.12–2.52 mmol/L"
      },
      {
        "elementKey": "lrh_Ca_Ion",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "1.14–1.28 mmol/L",
        "tableColumn": "5",
        "fqn": "labResults.lrh_Ca_Ion",
        "suffixText": "1.14–1.28 mmol/L"
      },
      {
        "elementKey": "lrh_CO2",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "23–28 mmol/L",
        "tableColumn": "6",
        "fqn": "labResults.lrh_CO2",
        "suffixText": "23–28 mmol/L"
      },
      {
        "elementKey": "lrh_chloride",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "98 - 107 mmol/L",
        "tableColumn": "7",
        "fqn": "labResults.lrh_chloride",
        "suffixText": "98 - 107 mmol/L"
      },
      {
        "elementKey": "lrh_ck",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "F: 30 - 190 IU/L-NL-M: 30 - 250 IU/L",
        "tableColumn": "8",
        "fqn": "labResults.lrh_ck",
        "suffixText": "F: 30 - 190 IU/L\nM: 30 - 250 IU/L"
      },
      {
        "elementKey": "lrh_glucose",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "3.9 - 5.6 mmol/L (fasting)",
        "tableColumn": "9",
        "fqn": "labResults.lrh_glucose",
        "suffixText": "3.9 - 5.6 mmol/L (空腹)"
      },
      {
        "elementKey": "lrh_Mg",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "0.74–1.03 mmol/L",
        "tableColumn": "10",
        "fqn": "labResults.lrh_Mg",
        "suffixText": "0.74–1.03 mmol/L"
      },
      {
        "elementKey": "lrh_PO4",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "0.81–1.58 mmol/L",
        "tableColumn": "11",
        "fqn": "labResults.lrh_PO4",
        "suffixText": "0.81–1.58 mmol/L"
      },
      {
        "elementKey": "lrh_K",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "3.5–5.1 mmol/L",
        "tableColumn": "12",
        "fqn": "labResults.lrh_K",
        "suffixText": "3.5–5.1 mmol/L"
      },
      {
        "elementKey": "lrh_Na",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "136–146 mmol/L",
        "tableColumn": "13",
        "fqn": "labResults.lrh_Na",
        "suffixText": "136–146 mmol/L"
      },
      {
        "elementKey": "lrh_bun",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "F: 22–75 µmol/L-NL-M: 49–93 µmol/L",
        "tableColumn": "14",
        "fqn": "labResults.lrh_bun",
        "suffixText": "F: 22–75 µmol/L\nM: 49–93 µmol/L"
      },
      {
        "elementKey": "lrh_cr",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "F: 22–75 µmol/L-NL-M: 49–93 µmol/L",
        "tableColumn": "15",
        "fqn": "labResults.lrh_cr",
        "suffixText": "F: 22–75 µmol/L\nM: 49–93 µmol/L"
      },
      {
        "elementKey": "lrh_egfr",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "> 60 mL/min",
        "tableColumn": "16",
        "fqn": "labResults.lrh_egfr",
        "suffixText": "> 60 mL/min"
      },
      {
        "elementKey": "lrh_alt",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "17–63 IU/L",
        "tableColumn": "17",
        "fqn": "labResults.lrh_alt",
        "suffixText": "17–63 IU/L"
      },
      {
        "elementKey": "lrh_ast",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "15–37 IU/L",
        "tableColumn": "18",
        "fqn": "labResults.lrh_ast",
        "suffixText": "15–37 IU/L"
      },
      {
        "elementKey": "lrh_alp",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "50–136 IU/L",
        "tableColumn": "19",
        "fqn": "labResults.lrh_alp",
        "suffixText": "50–136 IU/L"
      },
      {
        "elementKey": "lrh_bilirubinD",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "2–9 µmol/L",
        "tableColumn": "20",
        "fqn": "labResults.lrh_bilirubinD",
        "suffixText": "2–9 µmol/L"
      },
      {
        "elementKey": "lrh_bilirubinT",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "3–17 µmol/L",
        "tableColumn": "21",
        "fqn": "labResults.lrh_bilirubinT",
        "suffixText": "3–17 µmol/L"
      },
      {
        "elementKey": "lrh_ggt",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "F: 5.0–55.0 IU/L-NL-M: 15.0–85.0 IU/L",
        "tableColumn": "22",
        "fqn": "labResults.lrh_ggt",
        "suffixText": "F: 5.0–55.0 IU/L\nM: 15.0–85.0 IU/L"
      },
      {
        "elementKey": "lrh_ldh",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "100–205 IU/L",
        "tableColumn": "23",
        "fqn": "labResults.lrh_ldh",
        "suffixText": "100–205 IU/L"
      },
      {
        "elementKey": "lrh_lipase",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "73–393 IU/L",
        "tableColumn": "24",
        "fqn": "labResults.lrh_lipase",
        "suffixText": "73–393 IU/L"
      },
      {
        "elementKey": "lrh_abg_hco3",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "22–27 mmol/L",
        "tableColumn": "25",
        "fqn": "labResults.lrh_abg_hco3",
        "suffixText": "22–27 mmol/L"
      },
      {
        "elementKey": "lrh_abg_o2sat",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "96-100%",
        "tableColumn": "26",
        "fqn": "labResults.lrh_abg_o2sat",
        "suffixText": "96-100%"
      },
      {
        "elementKey": "lrh_abg_pco2",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "32–45 mm Hg",
        "tableColumn": "27",
        "fqn": "labResults.lrh_abg_pco2",
        "suffixText": "32–45 mm Hg"
      },
      {
        "elementKey": "lrh_po2",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "83–116 mm Hg",
        "tableColumn": "28",
        "fqn": "labResults.lrh_po2",
        "suffixText": "83–116 mm Hg"
      },
      {
        "elementKey": "lrh_abg_ph",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "7.38–7.46",
        "tableColumn": "29",
        "fqn": "labResults.lrh_abg_ph",
        "suffixText": "7.38–7.46"
      },
      {
        "elementKey": "lru_bnp",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "≤ 50 pg/mL",
        "tableColumn": "30",
        "fqn": "labResults.lru_bnp",
        "suffixText": "≤ 50 pg/mL"
      },
      {
        "elementKey": "lrh_lrtrop_I",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "< 0.03 ng/mL",
        "tableColumn": "31",
        "fqn": "labResults.lrh_lrtrop_I",
        "suffixText": "< 0.03 ng/mL"
      },
      {
        "elementKey": "lrh_lrtrop_T",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "< 0.1 ng/mL",
        "tableColumn": "32",
        "fqn": "labResults.lrh_lrtrop_T",
        "suffixText": "< 0.1 ng/mL"
      },
      {
        "elementKey": "lrh_lrtrop_H",
        "formIndex": "2",
        "inputType": "lab_result",
        "suffix": "F: < 14 ng/L-NL-M: < 22 ng/L",
        "tableColumn": "33",
        "fqn": "labResults.lrh_lrtrop_H",
        "suffixText": "F: < 14 ng/L\nM: < 22 ng/L"
      },
      {
        "elementKey": "lrh_bloodGroup",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "A-",
            "text": "A-"
          },
          {
            "key": "A+",
            "text": "A+"
          },
          {
            "key": "B-",
            "text": "B-"
          },
          {
            "key": "B+",
            "text": "B+"
          },
          {
            "key": "AB-",
            "text": "AB-"
          },
          {
            "key": "AB+",
            "text": "AB+"
          },
          {
            "key": "O-",
            "text": "O-"
          },
          {
            "key": "O+",
            "text": "O+"
          }
        ],
        "tableColumn": "34",
        "fqn": "labResults.lrh_bloodGroup"
      },
      {
        "elementKey": "lrh_antibody",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "35",
        "fqn": "labResults.lrh_antibody"
      },
      {
        "elementKey": "lrh_gen_comments",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "36",
        "fqn": "labResults.lrh_gen_comments"
      },
      {
        "elementKey": "labResultHematology_name",
        "formIndex": "3",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.name"
      },
      {
        "elementKey": "labResultHematology_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "labResultHematology_profession",
        "formIndex": "3",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.profession"
      },
      {
        "elementKey": "labResultHematology_day",
        "formIndex": "3",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labResults.day"
      },
      {
        "elementKey": "labResultHematology_time",
        "formIndex": "3",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labResults.time"
      },
      {
        "elementKey": "lrh_HbA1C",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "4.8%–6.0%",
        "tableColumn": "2",
        "fqn": "labResults.lrh_HbA1C",
        "suffixText": "4.8%–6.0%"
      },
      {
        "elementKey": "lrh_Hgb",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "115-155 g/L",
        "tableColumn": "3",
        "fqn": "labResults.lrh_Hgb",
        "suffixText": "115-155 g/L"
      },
      {
        "elementKey": "lrh_hct",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "0.42 – 0.52 L/L",
        "tableColumn": "4",
        "fqn": "labResults.lrh_hct",
        "suffixText": "0.42 – 0.52 L/L"
      },
      {
        "elementKey": "lrh_platelets",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "130-380 x 10^9 /L",
        "tableColumn": "5",
        "fqn": "labResults.lrh_platelets",
        "suffixText": "130-380 x 10^9 /L"
      },
      {
        "elementKey": "lrh_rbc",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "3.50-5.00 x 10^12 /L",
        "tableColumn": "6",
        "fqn": "labResults.lrh_rbc",
        "suffixText": "3.50-5.00 x 10^12 /L"
      },
      {
        "elementKey": "lrh_wbc",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "3.5–10.5 × 10^9/L",
        "tableColumn": "7",
        "fqn": "labResults.lrh_wbc",
        "suffixText": "3.5–10.5 × 10^9/L"
      },
      {
        "elementKey": "lrh_basophils",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "0-0.1 x 10^9 /L",
        "tableColumn": "8",
        "fqn": "labResults.lrh_basophils",
        "suffixText": "0-0.1 x 10^9 /L"
      },
      {
        "elementKey": "lrh_eosinophils",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "0-0.5 x 10^9 /L",
        "tableColumn": "9",
        "fqn": "labResults.lrh_eosinophils",
        "suffixText": "0-0.5 x 10^9 /L"
      },
      {
        "elementKey": "lrh_lymphocytes",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "F: 0.8-3.3 x 10^9 /L-NL-M: 0.8–3.5 × 10^9/L",
        "tableColumn": "10",
        "fqn": "labResults.lrh_lymphocytes",
        "suffixText": "F: 0.8-3.3 x 10^9 /L\nM: 0.8–3.5 × 10^9/L"
      },
      {
        "elementKey": "lrh_monocytes",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "0.1-1.0 x 10^9 /L",
        "tableColumn": "11",
        "fqn": "labResults.lrh_monocytes",
        "suffixText": "0.1-1.0 x 10^9 /L"
      },
      {
        "elementKey": "lrh_neutrophils",
        "formIndex": "3",
        "inputType": "lab_result",
        "suffix": "2.0-7.5 x 10^9 /L",
        "tableColumn": "12",
        "fqn": "labResults.lrh_neutrophils",
        "suffixText": "2.0-7.5 x 10^9 /L"
      },
      {
        "elementKey": "lrh_comments",
        "formIndex": "3",
        "inputType": "textarea",
        "tableColumn": "13",
        "fqn": "labResults.lrh_comments"
      },
      {
        "elementKey": "labResultCoagulation_name",
        "formIndex": "4",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.name"
      },
      {
        "elementKey": "labResultCoagulation_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "labResultCoagulation_profession",
        "formIndex": "4",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.profession"
      },
      {
        "elementKey": "labResultCoagulation_day",
        "formIndex": "4",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labResults.day"
      },
      {
        "elementKey": "labResultCoagulation_time",
        "formIndex": "4",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labResults.time"
      },
      {
        "elementKey": "coag_fib",
        "formIndex": "4",
        "inputType": "lab_result",
        "suffix": "2-4 g/L",
        "tableColumn": "2",
        "fqn": "labResults.coag_fib",
        "suffixText": "2-4 g/L"
      },
      {
        "elementKey": "coag_ddimer",
        "formIndex": "4",
        "inputType": "lab_result",
        "tableColumn": "3",
        "fqn": "labResults.coag_ddimer"
      },
      {
        "elementKey": "coag_inr",
        "formIndex": "4",
        "inputType": "lab_result",
        "suffix": "0.9-1.2",
        "tableColumn": "4",
        "fqn": "labResults.coag_inr",
        "suffixText": "0.9-1.2"
      },
      {
        "elementKey": "coag_ptt",
        "formIndex": "4",
        "inputType": "lab_result",
        "tableColumn": "5",
        "fqn": "labResults.coag_ptt"
      },
      {
        "elementKey": "coag_appt",
        "formIndex": "4",
        "inputType": "lab_result",
        "suffix": "22-30 s",
        "tableColumn": "6",
        "fqn": "labResults.coag_appt",
        "suffixText": "22-30 s"
      },
      {
        "elementKey": "coag_comments",
        "formIndex": "4",
        "inputType": "textarea",
        "tableColumn": "7",
        "fqn": "labResults.coag_comments"
      },
      {
        "elementKey": "labResultUrine_name",
        "formIndex": "5",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.name"
      },
      {
        "elementKey": "labResultUrine_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "labResultUrine_profession",
        "formIndex": "5",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "labResults.profession"
      },
      {
        "elementKey": "labResultUrine_day",
        "formIndex": "5",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "labResults.day"
      },
      {
        "elementKey": "labResultUrine_time",
        "formIndex": "5",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "labResults.time"
      },
      {
        "elementKey": "lru_calrity",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "Normal: Clear",
        "tableColumn": "2",
        "fqn": "labResults.lru_calrity",
        "suffixText": "正常: 清晰透明"
      },
      {
        "elementKey": "lru_colour",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "Normal: Pale to dark yellow",
        "tableColumn": "3",
        "fqn": "labResults.lru_colour",
        "suffixText": "正常: 浅黄至深黄"
      },
      {
        "elementKey": "lru_ordour",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "Slightly \"nutty\" odour",
        "tableColumn": "4",
        "fqn": "labResults.lru_ordour",
        "suffixText": "微弱的气味，略有氨味"
      },
      {
        "elementKey": "lru_gravity",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "1.005–1.030",
        "tableColumn": "5",
        "fqn": "labResults.lru_gravity",
        "suffixText": "1.005–1.030"
      },
      {
        "elementKey": "lru_albumin",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "< 30mg",
        "tableColumn": "5",
        "fqn": "labResults.lru_albumin",
        "suffixText": "< 30mg"
      },
      {
        "elementKey": "lru_acr",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "≤ 2.0 g/mol Cr",
        "tableColumn": "6",
        "fqn": "labResults.lru_acr",
        "suffixText": "≤ 2.0 g/mol Cr"
      },
      {
        "elementKey": "lru_aldosterone",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "13.9–52.6 nmol/24 hour",
        "tableColumn": "7",
        "fqn": "labResults.lru_aldosterone",
        "suffixText": "13.9–52.6 nmol/24 小时"
      },
      {
        "elementKey": "lru_calcium",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "< 6.2 mmol/d",
        "tableColumn": "8",
        "fqn": "labResults.lru_calcium",
        "suffixText": "< 6.2 mmol/d"
      },
      {
        "elementKey": "lru_catecholamines",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "< 591 nmol/m2/24 hours",
        "tableColumn": "9",
        "fqn": "labResults.lru_catecholamines",
        "suffixText": "< 591 nmol/m2/24 小时"
      },
      {
        "elementKey": "lru_cortisol",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "< 248 nmol/24 hours",
        "tableColumn": "10",
        "fqn": "labResults.lru_cortisol",
        "suffixText": "< 248 nmol/24 小时"
      },
      {
        "elementKey": "lru_glucose",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "60–830 mcmol/L",
        "tableColumn": "11",
        "fqn": "labResults.lru_glucose",
        "suffixText": "60–830 mcmol/L"
      },
      {
        "elementKey": "lru_ketones",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "none",
        "tableColumn": "12",
        "fqn": "labResults.lru_ketones",
        "suffixText": "无"
      },
      {
        "elementKey": "lru_leukocyte",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "13",
        "fqn": "labResults.lru_leukocyte"
      },
      {
        "elementKey": "lru_nitrates",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "14",
        "fqn": "labResults.lru_nitrates"
      },
      {
        "elementKey": "lru_osmolality",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "38–1400 mOsm/kg H2O",
        "tableColumn": "15",
        "fqn": "labResults.lru_osmolality",
        "suffixText": "38–1400 mOsm/kg H2O"
      },
      {
        "elementKey": "lru_ph",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "5.0–8.5",
        "tableColumn": "16",
        "fqn": "labResults.lru_ph",
        "suffixText": "5.0–8.5"
      },
      {
        "elementKey": "lru_phosphate",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "Adult: 1.0-1.5 mmol/L-NL-Child: 1.3-2.3 mmol/L",
        "tableColumn": "17",
        "fqn": "labResults.lru_phosphate",
        "suffixText": "成人: 1.0-1.5 mmol/L\n儿童: 1.3-2.3 mmol/L"
      },
      {
        "elementKey": "lru_protein",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "None",
        "tableColumn": "18",
        "fqn": "labResults.lru_protein",
        "suffixText": "无"
      },
      {
        "elementKey": "lru_sodium",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "40-220 mmol/d",
        "tableColumn": "19",
        "fqn": "labResults.lru_sodium",
        "suffixText": "40-220 mmol/d"
      },
      {
        "elementKey": "lru_speccificGravity",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "1.005–1.030",
        "tableColumn": "20",
        "fqn": "labResults.lru_speccificGravity",
        "suffixText": "1.005–1.030"
      },
      {
        "elementKey": "lru_uricAcid",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "1.48–4.43 mmol/24 hours",
        "tableColumn": "21",
        "fqn": "labResults.lru_uricAcid",
        "suffixText": "1.48–4.43 mmol/24 小时"
      },
      {
        "elementKey": "lru_urobilinogen",
        "formIndex": "5",
        "inputType": "lab_result",
        "suffix": "≤ 16.0 µmol/L",
        "tableColumn": "22",
        "fqn": "labResults.lru_urobilinogen",
        "suffixText": "≤ 16.0 µmol/L"
      },
      {
        "elementKey": "lru_casts",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "23",
        "fqn": "labResults.lru_casts"
      },
      {
        "elementKey": "lru_crystals",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "24",
        "fqn": "labResults.lru_crystals"
      },
      {
        "elementKey": "lru_epitehlial",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "25",
        "fqn": "labResults.lru_epitehlial"
      },
      {
        "elementKey": "lru_rbc",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "26",
        "fqn": "labResults.lru_rbc"
      },
      {
        "elementKey": "lru_wbc",
        "formIndex": "5",
        "inputType": "lab_result",
        "tableColumn": "27",
        "fqn": "labResults.lru_wbc"
      },
      {
        "elementKey": "lruComments",
        "formIndex": "5",
        "inputType": "textarea",
        "tableColumn": "28",
        "fqn": "labResults.lruComments"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "labResults",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Laboratory reports v1",
          "addButtonText": "Add a laboratory report",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labResults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup32-1",
              "formCss": "grid-left-to-right-3",
              "fqn": "labResults.cGroup32-1",
              "gIndex": "2",
              "gChildren": [
                "laboratoryReport",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "laboratoryReport": "",
            "comments": ""
          }
        },
        "fqn": "labResults.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "laboratoryReport",
          "comments",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      },
      "labResultGeneral": {
        "elementKey": "labResultGeneral",
        "pageElementIndex": "2",
        "pageKey": "labResults",
        "tableKey": "labResultGeneral",
        "isTable": true,
        "form": {
          "elementKey": "labResultGeneral",
          "label": "General",
          "addButtonText": "Create a laboratory report",
          "formOption": "transpose",
          "formKey": "labResultGeneral",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labResults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "labResultGeneral_name",
                "labResultGeneral_profession",
                "labResultGeneral_day",
                "labResultGeneral_time"
              ]
            },
            {
              "elementKey": "cAccGroup",
              "formCss": "grid-left-to-right-3",
              "fqn": "labResults.cAccGroup",
              "gIndex": "2",
              "gChildren": [
                "urinOrderPhys"
              ]
            },
            {
              "elementKey": "cChemGroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cChemGroup",
              "gIndex": "3",
              "gChildren": [
                "lrh_HCO3",
                "lrh_Ca",
                "lrh_Ca_Ion",
                "lrh_CO2",
                "lrh_chloride",
                "lrh_ck",
                "lrh_glucose",
                "lrh_Mg",
                "lrh_PO4",
                "lrh_K",
                "lrh_Na"
              ]
            },
            {
              "elementKey": "cRenalGroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cRenalGroup",
              "gIndex": "4",
              "gChildren": [
                "lrh_bun",
                "lrh_cr",
                "lrh_egfr"
              ]
            },
            {
              "elementKey": "cLiverGroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cLiverGroup",
              "gIndex": "5",
              "gChildren": [
                "lrh_alt",
                "lrh_ast",
                "lrh_alp",
                "lrh_bilirubinD",
                "lrh_bilirubinT",
                "lrh_ggt",
                "lrh_ldh",
                "lrh_lipase"
              ]
            },
            {
              "elementKey": "cBloodGasGroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cBloodGasGroup",
              "gIndex": "6",
              "gChildren": [
                "lrh_abg_hco3",
                "lrh_abg_o2sat",
                "lrh_abg_pco2",
                "lrh_po2",
                "lrh_abg_ph"
              ]
            },
            {
              "elementKey": "cCardiac",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cCardiac",
              "gIndex": "7",
              "gChildren": [
                "lru_bnp",
                "lrh_lrtrop_I",
                "lrh_lrtrop_T",
                "lrh_lrtrop_H"
              ]
            },
            {
              "elementKey": "cGroup32-6",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-6",
              "gIndex": "8",
              "gChildren": [
                "lrh_bloodGroup",
                "lrh_antibody"
              ]
            },
            {
              "elementKey": "cGroup32-7",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-7",
              "gIndex": "9",
              "gChildren": [
                "lrh_gen_comments"
              ]
            }
          ],
          "ehr_data": {
            "labResultGeneral_name": "",
            "labResultGeneral_profession": "",
            "labResultGeneral_day": "",
            "labResultGeneral_time": "",
            "urinOrderPhys": "",
            "lrh_HCO3": "",
            "lrh_Ca": "",
            "lrh_Ca_Ion": "",
            "lrh_CO2": "",
            "lrh_chloride": "",
            "lrh_ck": "",
            "lrh_glucose": "",
            "lrh_Mg": "",
            "lrh_PO4": "",
            "lrh_K": "",
            "lrh_Na": "",
            "lrh_bun": "",
            "lrh_cr": "",
            "lrh_egfr": "",
            "lrh_alt": "",
            "lrh_ast": "",
            "lrh_alp": "",
            "lrh_bilirubinD": "",
            "lrh_bilirubinT": "",
            "lrh_ggt": "",
            "lrh_ldh": "",
            "lrh_lipase": "",
            "lrh_abg_hco3": "",
            "lrh_abg_o2sat": "",
            "lrh_abg_pco2": "",
            "lrh_po2": "",
            "lrh_abg_ph": "",
            "lru_bnp": "",
            "lrh_lrtrop_I": "",
            "lrh_lrtrop_T": "",
            "lrh_lrtrop_H": "",
            "lrh_bloodGroup": "",
            "lrh_antibody": "",
            "lrh_gen_comments": ""
          }
        },
        "fqn": "labResults.labResultGeneral",
        "tableChildren": [
          "labResultGeneral_id",
          "labResultGeneral_day",
          "labResultGeneral_time",
          "urinOrderPhys",
          "lrh_HCO3",
          "lrh_Ca",
          "lrh_Ca_Ion",
          "lrh_CO2",
          "lrh_chloride",
          "lrh_ck",
          "lrh_glucose",
          "lrh_Mg",
          "lrh_PO4",
          "lrh_K",
          "lrh_Na",
          "lrh_bun",
          "lrh_cr",
          "lrh_egfr",
          "lrh_alt",
          "lrh_ast",
          "lrh_alp",
          "lrh_bilirubinD",
          "lrh_bilirubinT",
          "lrh_ggt",
          "lrh_ldh",
          "lrh_lipase",
          "lrh_abg_hco3",
          "lrh_abg_o2sat",
          "lrh_abg_pco2",
          "lrh_po2",
          "lrh_abg_ph",
          "lru_bnp",
          "lrh_lrtrop_I",
          "lrh_lrtrop_T",
          "lrh_lrtrop_H",
          "lrh_bloodGroup",
          "lrh_antibody",
          "lrh_gen_comments",
          "labResultGeneral_name",
          "labResultGeneral_profession"
        ],
        "hasRecHeader": true
      },
      "labResultHematology": {
        "elementKey": "labResultHematology",
        "pageElementIndex": "3",
        "pageKey": "labResults",
        "tableKey": "labResultHematology",
        "isTable": true,
        "form": {
          "elementKey": "labResultHematology",
          "label": "Hematology",
          "addButtonText": "Create a hematology laboratory report",
          "formOption": "transpose",
          "formKey": "labResultHematology",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labResults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "labResultHematology_name",
                "labResultHematology_profession",
                "labResultHematology_day",
                "labResultHematology_time"
              ]
            },
            {
              "elementKey": "cGroup32-12",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-12",
              "gIndex": "2",
              "gChildren": [
                "lrh_HbA1C",
                "lrh_Hgb",
                "lrh_hct",
                "lrh_platelets",
                "lrh_rbc",
                "lrh_wbc"
              ]
            },
            {
              "elementKey": "cGroup32-11",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-11",
              "gIndex": "3",
              "gChildren": [
                "lrh_basophils",
                "lrh_eosinophils",
                "lrh_lymphocytes",
                "lrh_monocytes",
                "lrh_neutrophils"
              ]
            },
            {
              "elementKey": "cGroup32-9",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-9",
              "gIndex": "4",
              "gChildren": [
                "lrh_comments"
              ]
            }
          ],
          "ehr_data": {
            "labResultHematology_name": "",
            "labResultHematology_profession": "",
            "labResultHematology_day": "",
            "labResultHematology_time": "",
            "lrh_HbA1C": "",
            "lrh_Hgb": "",
            "lrh_hct": "",
            "lrh_platelets": "",
            "lrh_rbc": "",
            "lrh_wbc": "",
            "lrh_basophils": "",
            "lrh_eosinophils": "",
            "lrh_lymphocytes": "",
            "lrh_monocytes": "",
            "lrh_neutrophils": "",
            "lrh_comments": ""
          }
        },
        "fqn": "labResults.labResultHematology",
        "tableChildren": [
          "labResultHematology_id",
          "labResultHematology_day",
          "labResultHematology_time",
          "lrh_HbA1C",
          "lrh_Hgb",
          "lrh_hct",
          "lrh_platelets",
          "lrh_rbc",
          "lrh_wbc",
          "lrh_basophils",
          "lrh_eosinophils",
          "lrh_lymphocytes",
          "lrh_monocytes",
          "lrh_neutrophils",
          "lrh_comments",
          "labResultHematology_name",
          "labResultHematology_profession"
        ],
        "hasRecHeader": true
      },
      "labResultCoagulation": {
        "elementKey": "labResultCoagulation",
        "pageElementIndex": "4",
        "pageKey": "labResults",
        "tableKey": "labResultCoagulation",
        "isTable": true,
        "form": {
          "elementKey": "labResultCoagulation",
          "label": "Coagulation",
          "addButtonText": "Create a coagulation laboratory report",
          "formOption": "transpose",
          "formKey": "labResultCoagulation",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labResults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "labResultCoagulation_name",
                "labResultCoagulation_profession",
                "labResultCoagulation_day",
                "labResultCoagulation_time"
              ]
            },
            {
              "elementKey": "cGroup32-8",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-8",
              "gIndex": "2",
              "gChildren": [
                "coag_fib",
                "coag_ddimer",
                "coag_inr",
                "coag_ptt",
                "coag_appt"
              ]
            },
            {
              "elementKey": "cGroup32-7",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-7",
              "gIndex": "3",
              "gChildren": [
                "coag_comments"
              ]
            }
          ],
          "ehr_data": {
            "labResultCoagulation_name": "",
            "labResultCoagulation_profession": "",
            "labResultCoagulation_day": "",
            "labResultCoagulation_time": "",
            "coag_fib": "",
            "coag_ddimer": "",
            "coag_inr": "",
            "coag_ptt": "",
            "coag_appt": "",
            "coag_comments": ""
          }
        },
        "fqn": "labResults.labResultCoagulation",
        "tableChildren": [
          "labResultCoagulation_id",
          "labResultCoagulation_day",
          "labResultCoagulation_time",
          "coag_fib",
          "coag_ddimer",
          "coag_inr",
          "coag_ptt",
          "coag_appt",
          "coag_comments",
          "labResultCoagulation_name",
          "labResultCoagulation_profession"
        ],
        "hasRecHeader": true
      },
      "labResultUrine": {
        "elementKey": "labResultUrine",
        "pageElementIndex": "5",
        "pageKey": "labResults",
        "tableKey": "labResultUrine",
        "isTable": true,
        "form": {
          "elementKey": "labResultUrine",
          "label": "Urine analysis",
          "addButtonText": "Create a urinary laboratory report",
          "formOption": "transpose",
          "formKey": "labResultUrine",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "labResults.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "labResultUrine_name",
                "labResultUrine_profession",
                "labResultUrine_day",
                "labResultUrine_time"
              ]
            },
            {
              "elementKey": "cGroup32-2",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-2",
              "gIndex": "2",
              "gChildren": [
                "lru_calrity",
                "lru_colour",
                "lru_ordour",
                "lru_gravity"
              ]
            },
            {
              "elementKey": "cGroup32-3",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-3",
              "gIndex": "3",
              "gChildren": [
                "lru_albumin",
                "lru_acr",
                "lru_aldosterone",
                "lru_calcium",
                "lru_catecholamines",
                "lru_cortisol",
                "lru_glucose",
                "lru_ketones",
                "lru_leukocyte",
                "lru_nitrates",
                "lru_osmolality",
                "lru_ph",
                "lru_phosphate",
                "lru_protein",
                "lru_sodium",
                "lru_speccificGravity",
                "lru_uricAcid",
                "lru_urobilinogen"
              ]
            },
            {
              "elementKey": "cGroup32-4",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-4",
              "gIndex": "4",
              "gChildren": [
                "lru_casts",
                "lru_crystals",
                "lru_epitehlial",
                "lru_rbc",
                "lru_wbc"
              ]
            },
            {
              "elementKey": "cGroup32-5",
              "formCss": "grid-left-to-right-1",
              "fqn": "labResults.cGroup32-5",
              "gIndex": "5",
              "gChildren": [
                "lruComments"
              ]
            }
          ],
          "ehr_data": {
            "labResultUrine_name": "",
            "labResultUrine_profession": "",
            "labResultUrine_day": "",
            "labResultUrine_time": "",
            "lru_calrity": "",
            "lru_colour": "",
            "lru_ordour": "",
            "lru_gravity": "",
            "lru_albumin": "",
            "lru_acr": "",
            "lru_aldosterone": "",
            "lru_calcium": "",
            "lru_catecholamines": "",
            "lru_cortisol": "",
            "lru_glucose": "",
            "lru_ketones": "",
            "lru_leukocyte": "",
            "lru_nitrates": "",
            "lru_osmolality": "",
            "lru_ph": "",
            "lru_phosphate": "",
            "lru_protein": "",
            "lru_sodium": "",
            "lru_speccificGravity": "",
            "lru_uricAcid": "",
            "lru_urobilinogen": "",
            "lru_casts": "",
            "lru_crystals": "",
            "lru_epitehlial": "",
            "lru_rbc": "",
            "lru_wbc": "",
            "lruComments": ""
          }
        },
        "fqn": "labResults.labResultUrine",
        "tableChildren": [
          "labResultUrine_id",
          "labResultUrine_day",
          "labResultUrine_time",
          "lru_calrity",
          "lru_colour",
          "lru_ordour",
          "lru_gravity",
          "lru_albumin",
          "lru_acr",
          "lru_aldosterone",
          "lru_calcium",
          "lru_catecholamines",
          "lru_cortisol",
          "lru_glucose",
          "lru_ketones",
          "lru_leukocyte",
          "lru_nitrates",
          "lru_osmolality",
          "lru_ph",
          "lru_phosphate",
          "lru_protein",
          "lru_sodium",
          "lru_speccificGravity",
          "lru_uricAcid",
          "lru_urobilinogen",
          "lru_casts",
          "lru_crystals",
          "lru_epitehlial",
          "lru_rbc",
          "lru_wbc",
          "lruComments",
          "labResultUrine_name",
          "labResultUrine_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medicalImaging": {
    "pageDataKey": "medicalImaging",
    "pIndex": "33",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicalImaging.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medicalImaging.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medicalImaging.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medicalImaging.time"
      },
      {
        "elementKey": "laboratoryReport",
        "formIndex": "1",
        "inputType": "ehrFile",
        "tableColumn": "2",
        "fqn": "medicalImaging.laboratoryReport"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "medicalImaging.comments"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "medicalImaging",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Medical imaging",
          "addButtonText": "Add a report or image",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medicalImaging.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup33-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medicalImaging.cGroup33-2",
              "gIndex": "2",
              "gChildren": [
                "laboratoryReport",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "laboratoryReport": "",
            "comments": ""
          }
        },
        "fqn": "medicalImaging.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "laboratoryReport",
          "comments",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "operationReports": {
    "pageDataKey": "operationReports",
    "pIndex": "34",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "operationReports.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "operationReports.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "operationReports.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "operationReports.time"
      },
      {
        "elementKey": "laboratoryReport",
        "formIndex": "1",
        "inputType": "ehrFile",
        "tableColumn": "2",
        "fqn": "operationReports.laboratoryReport"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "operationReports.comments"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "operationReports",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Operative and anaesthesia records",
          "addButtonText": "Add a report",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "operationReports.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup34-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "operationReports.cGroup34-2",
              "gIndex": "2",
              "gChildren": [
                "laboratoryReport",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "laboratoryReport": "",
            "comments": ""
          }
        },
        "fqn": "operationReports.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "laboratoryReport",
          "comments",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "assessmentTools": {
    "pageDataKey": "assessmentTools",
    "pIndex": "35",
    "formOption": "formOption",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "admissions1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "the-canadian-ed-triage-and-acuity-scale.gif",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.admissions1_1"
      },
      {
        "elementKey": "admissions2_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "patient-health-questionnaire.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.admissions2_1"
      },
      {
        "elementKey": "admissions3_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "audit.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.admissions3_1"
      },
      {
        "elementKey": "painAssessment1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "opqrstuv.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.painAssessment1_1"
      },
      {
        "elementKey": "painAssessment2_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "numeric-rating-scale.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.painAssessment2_1"
      },
      {
        "elementKey": "neuroAssessment1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "glasgow-coma-scale.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.neuroAssessment1_1"
      },
      {
        "elementKey": "neuroAssessment2_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "mini-mental-health-screen.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.neuroAssessment2_1"
      },
      {
        "elementKey": "neuroAssessment3_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "richmond-agitation-sedation-scale-rass.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.neuroAssessment3_1"
      },
      {
        "elementKey": "fallRisk1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "fall-risk-assessment-tool.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.fallRisk1_1"
      },
      {
        "elementKey": "fallRisk2_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "risk-for-falls.png",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.fallRisk2_1"
      },
      {
        "elementKey": "notes1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "consultation-form.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.notes1_1"
      },
      {
        "elementKey": "educational1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "copd-assessment-test.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.educational1_1"
      },
      {
        "elementKey": "educational2_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "bristol-stool-chart.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.educational2_1"
      },
      {
        "elementKey": "wound1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "braden-scale.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.wound1_1"
      },
      {
        "elementKey": "medication1_1",
        "assetBase": "standardized-assessment-tools",
        "assetName": "best_possible_medication.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "fqn": "assessmentTools.medication1_1"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "assessmentTools.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup35-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "assessmentTools.cGroup35-1",
            "gIndex": "1",
            "gChildren": [
              {
                "elementKey": "admissions",
                "fqn": "assessmentTools.admissions",
                "sgChildren": [
                  "admissions1_1",
                  "admissions2_1",
                  "admissions3_1"
                ]
              },
              {
                "elementKey": "painAssessment",
                "fqn": "assessmentTools.painAssessment",
                "sgChildren": [
                  "painAssessment1_1",
                  "painAssessment2_1"
                ]
              },
              {
                "elementKey": "neuroAssessment",
                "fqn": "assessmentTools.neuroAssessment",
                "sgChildren": [
                  "neuroAssessment1_1",
                  "neuroAssessment2_1",
                  "neuroAssessment3_1"
                ]
              },
              {
                "elementKey": "fallRisk",
                "fqn": "assessmentTools.fallRisk",
                "sgChildren": [
                  "fallRisk1_1",
                  "fallRisk2_1"
                ]
              },
              {
                "elementKey": "notes",
                "fqn": "assessmentTools.notes",
                "sgChildren": [
                  "notes1_1"
                ]
              },
              {
                "elementKey": "educational",
                "fqn": "assessmentTools.educational",
                "sgChildren": [
                  "educational1_1",
                  "educational2_1"
                ]
              },
              {
                "elementKey": "wound",
                "fqn": "assessmentTools.wound",
                "sgChildren": [
                  "wound1_1"
                ]
              },
              {
                "elementKey": "medication",
                "fqn": "assessmentTools.medication",
                "sgChildren": [
                  "medication1_1"
                ]
              }
            ]
          }
        ]
      }
    }
  },
  "codeLookup": {
    "pageDataKey": "codeLookup",
    "pIndex": "36",
    "hasGridForm": true,
    "pageChildren": [
      {
        "elementKey": "snomed",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "codeLookup.snomed"
      },
      {
        "elementKey": "icf",
        "formIndex": "1",
        "inputType": "text",
        "fqn": "codeLookup.icf"
      }
    ],
    "pageElements": {
      "pageForm": {
        "elementKey": "pageForm",
        "pageElementIndex": "1",
        "formKey": "pageForm",
        "fqn": "codeLookup.pageForm",
        "isPageForm": true,
        "ehr_groups": [
          {
            "elementKey": "cGroup36-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "codeLookup.cGroup36-1",
            "gIndex": "1",
            "gChildren": [
              "snomed",
              "icf"
            ]
          }
        ]
      }
    }
  },
  "diagnosticCodes": {
    "pageDataKey": "diagnosticCodes",
    "pIndex": "37",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "diagnosticCodes.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "diagnosticCodes.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "diagnosticCodes.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "diagnosticCodes.time"
      },
      {
        "elementKey": "icd10",
        "assetBase": "static-databases",
        "assetName": "icd-10-medical-diagnosis-codes.pdf",
        "formIndex": "1",
        "inputType": "assetLink",
        "tableColumn": "2",
        "fqn": "diagnosticCodes.icd10"
      },
      {
        "elementKey": "condition",
        "formIndex": "1",
        "inputType": "text",
        "mandatory": "yes",
        "tableColumn": "3",
        "fqn": "diagnosticCodes.condition"
      },
      {
        "elementKey": "code",
        "formIndex": "1",
        "inputType": "text",
        "mandatory": "yes",
        "tableColumn": "4",
        "fqn": "diagnosticCodes.code"
      },
      {
        "elementKey": "type",
        "formIndex": "1",
        "inputType": "select",
        "mandatory": "yes",
        "options": [
          {
            "key": "M",
            "text": "M"
          },
          {
            "key": "1",
            "text": "1"
          },
          {
            "key": "2",
            "text": "2"
          },
          {
            "key": "3",
            "text": "3"
          }
        ],
        "tableColumn": "5",
        "fqn": "diagnosticCodes.type"
      },
      {
        "elementKey": "prefix",
        "formIndex": "1",
        "inputType": "text",
        "mandatory": "no",
        "tableColumn": "6",
        "fqn": "diagnosticCodes.prefix"
      },
      {
        "elementKey": "cluster",
        "formIndex": "1",
        "inputType": "text",
        "mandatory": "no",
        "tableColumn": "7",
        "fqn": "diagnosticCodes.cluster"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "diagnosticCodes",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Diagnostic codes (ICD-10)",
          "addButtonText": "Add a diagnostic code",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "diagnosticCodes.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup37-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "diagnosticCodes.cGroup37-2",
              "gIndex": "2",
              "gChildren": [
                "icd10",
                "condition",
                "code",
                "type",
                "prefix",
                "cluster"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "condition": "",
            "code": "",
            "type": "",
            "prefix": "",
            "cluster": ""
          }
        },
        "fqn": "diagnosticCodes.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "icd10",
          "condition",
          "code",
          "type",
          "prefix",
          "cluster",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "interventionCodes": {
    "pageDataKey": "interventionCodes",
    "pIndex": "38",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "interventionCodes.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "interventionCodes.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "interventionCodes.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "interventionCodes.time"
      },
      {
        "elementKey": "intervention",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "interventionCodes.intervention"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "interventionCodes.status"
      },
      {
        "elementKey": "laterally",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "interventionCodes.laterally"
      },
      {
        "elementKey": "extent",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "interventionCodes.extent"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "interventionCodes",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Intervention codes",
          "addButtonText": "Add an intervention code",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "interventionCodes.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup38-2",
              "formCss": "grid-left-to-right-1",
              "fqn": "interventionCodes.cGroup38-2",
              "gIndex": "2",
              "gChildren": [
                "intervention",
                "status",
                "laterally",
                "extent"
              ]
            },
            {
              "elementKey": "label",
              "formCss": "grid-left-to-right-3",
              "fqn": "interventionCodes.label",
              "gIndex": "3",
              "gChildren": []
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "intervention": "",
            "status": "",
            "laterally": "",
            "extent": ""
          }
        },
        "fqn": "interventionCodes.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "intervention",
          "status",
          "laterally",
          "extent",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "caseMixGroup": {
    "pageDataKey": "caseMixGroup",
    "pIndex": "39",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "caseMixGroup.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "caseMixGroup.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "caseMixGroup.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "caseMixGroup.time"
      },
      {
        "elementKey": "clinicalCategory",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "caseMixGroup.clinicalCategory"
      },
      {
        "elementKey": "caseMixNumber",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "caseMixGroup.caseMixNumber"
      },
      {
        "elementKey": "resourceIntensity",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "caseMixGroup.resourceIntensity"
      },
      {
        "elementKey": "expectedStay",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "caseMixGroup.expectedStay"
      },
      {
        "elementKey": "analysis",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "caseMixGroup.analysis"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "caseMixGroup",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Case mix group",
          "addButtonText": "Add a case mix group",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "caseMixGroup.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup39-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "caseMixGroup.cGroup39-2",
              "gIndex": "2",
              "gChildren": [
                "clinicalCategory",
                "caseMixNumber",
                "resourceIntensity",
                "expectedStay",
                "analysis"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "clinicalCategory": "",
            "caseMixNumber": "",
            "resourceIntensity": "",
            "expectedStay": "",
            "analysis": ""
          }
        },
        "fqn": "caseMixGroup.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "clinicalCategory",
          "caseMixNumber",
          "resourceIntensity",
          "expectedStay",
          "analysis",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  'integumentaryAssessment': {
    'pageDataKey': 'integumentaryAssessment',
    'pageTitle': '伤口评估',
    'pIndex': '42',
    'hasGridTable': true,
    'pageChildren': [
      {
        'elementKey': 'tableIncision_name',
        'formIndex': '1',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.name'
      },
      {
        'elementKey': 'tableIncision_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'tableIncision_profession',
        'formIndex': '1',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.profession'
      },
      {
        'elementKey': 'tableIncision_day',
        'formIndex': '1',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.day'
      },
      {
        'elementKey': 'tableIncision_time',
        'formIndex': '1',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.time'
      },
      {
        'elementKey': 'incisionLabel',
        'formIndex': '1',
        'inputType': 'select',
        'label': '切口标签',
        'options': [
          {
            'key': '切口 1',
            'text': '切口 1'
          },
          {
            'key': '切口 2',
            'text': '切口 2'
          },
          {
            'key': '切口 3',
            'text': '切口 3'
          },
          {
            'key': '切口 4',
            'text': '切口 4'
          }
        ],
        'tableColumn': '2',
        'fqn': 'integumentaryAssessment.incisionLabel'
      },
      {
        'elementKey': 'incisionLocation',
        'formIndex': '1',
        'inputType': 'select',
        'label': '切口位置',
        'options': [
          {
            'key': '头部',
            'text': '头部'
          },
          {
            'key': '面部',
            'text': '面部'
          },
          {
            'key': '脖子',
            'text': '脖子'
          },
          {
            'key': '胸部',
            'text': '胸部'
          },
          {
            'key': '手臂 (左)',
            'text': '手臂 (左)'
          },
          {
            'key': '手臂 (右)',
            'text': '手臂 (右)'
          },
          {
            'key': '手 (左)',
            'text': '手 (左)'
          },
          {
            'key': '手 (右)',
            'text': '手 (右)'
          },
          {
            'key': '腹部',
            'text': '腹部'
          },
          {
            'key': '背部',
            'text': '背部'
          },
          {
            'key': '盆腔',
            'text': '盆腔'
          },
          {
            'key': '臀部',
            'text': '臀部'
          },
          {
            'key': '腿 (左)',
            'text': '腿 (左)'
          },
          {
            'key': '腿 (右)',
            'text': '腿 (右)'
          },
          {
            'key': '脚 (左)',
            'text': '脚 (左)'
          },
          {
            'key': '脚 (右)',
            'text': '脚 (右)'
          }
        ],
        'tableColumn': '3',
        'fqn': 'integumentaryAssessment.incisionLocation'
      },
      {
        'elementKey': 'postOpDay',
        'formIndex': '1',
        'inputType': 'text',
        'label': '手术时间',
        'tableColumn': '4',
        'fqn': 'integumentaryAssessment.postOpDay'
      },
      {
        'elementKey': 'opProcedure',
        'formIndex': '1',
        'inputType': 'text',
        'label': '手术描述',
        'tableColumn': '5',
        'fqn': 'integumentaryAssessment.opProcedure'
      },
      {
        'elementKey': 'closureMethod',
        'formIndex': '1',
        'inputType': 'checkset',
        'label': '关闭方式',
        'options': [
          {
            'key': '缝合线',
            'text': '缝合线'
          },
          {
            'key': '皮肤吻合器',
            'text': '皮肤吻合器'
          },
          {
            'key': '保留缝线',
            'text': '保留缝线'
          },
          {
            'key': '手术胶水',
            'text': '手术胶水'
          },
          {
            'key': '免缝胶带',
            'text': '免缝胶带'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '6',
        'fqn': 'integumentaryAssessment.closureMethod'
      },
      {
        'elementKey': 'incisionProfileImage',
        'formIndex': '1',
        'inputType': 'ehrFile',
        'label': '图片',
        'tableColumn': '7',
        'fqn': 'integumentaryAssessment.incisionProfileImage'
      },
      {
        'elementKey': 'incisionProfileComments',
        'formIndex': '1',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '备注',
        'tableColumn': '8',
        'fqn': 'integumentaryAssessment.incisionProfileComments'
      },
      {
        'elementKey': 'tableIncisionAssessment_name',
        'formIndex': '2',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.name'
      },
      {
        'elementKey': 'tableIncisionAssessment_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'tableIncisionAssessment_profession',
        'formIndex': '2',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.profession'
      },
      {
        'elementKey': 'tableIncisionAssessment_day',
        'formIndex': '2',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.day'
      },
      {
        'elementKey': 'tableIncisionAssessment_time',
        'formIndex': '2',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.time'
      },
      {
        'elementKey': 'incisionLabel',
        'formIndex': '2',
        'helperText': '输入切口标签 1 到 4',
        'inputType': 'select',
        'label': '切口标签',
        'options': [
          {
            'key': '切口 1',
            'text': '切口 1'
          },
          {
            'key': '切口 2',
            'text': '切口 2'
          },
          {
            'key': '切口 3',
            'text': '切口 3'
          },
          {
            'key': '切口 4',
            'text': '切口 4'
          }
        ],
        'tableColumn': '2',
        'fqn': 'integumentaryAssessment.incisionLabel',
        'helperHtml': '<p>输入切口标签 1 到 4</p>'
      },
      {
        'elementKey': 'incisionStatus',
        'formIndex': '2',
        'inputType': 'select',
        'label': '切口状态',
        'options': [
          {
            'key': '接近上皮化',
            'text': '接近上皮化'
          },
          {
            'key': '完全上皮化',
            'text': '完全上皮化'
          },
          {
            'key': '脆弱',
            'text': '脆弱'
          },
          {
            'key': '裂开',
            'text': '裂开'
          }
        ],
        'tableColumn': '3',
        'fqn': 'integumentaryAssessment.incisionStatus'
      },
      {
        'elementKey': 'incisionStatusImage',
        'formIndex': '2',
        'inputType': 'ehrFile',
        'label': '图片',
        'tableColumn': '4',
        'fqn': 'integumentaryAssessment.incisionStatusImage'
      },
      {
        'elementKey': 'closureChanges',
        'formIndex': '2',
        'inputType': 'text',
        'label': '切口变化',
        'tableColumn': '5',
        'fqn': 'integumentaryAssessment.closureChanges'
      },
      {
        'elementKey': 'exudateAmount',
        'formIndex': '2',
        'inputType': 'checkset',
        'label': '渗液量',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '少量',
            'text': '少量'
          },
          {
            'key': '中等',
            'text': '中等'
          },
          {
            'key': '大量',
            'text': '大量'
          }
        ],
        'tableColumn': '6',
        'fqn': 'integumentaryAssessment.exudateAmount'
      },
      {
        'elementKey': 'exudateType',
        'formIndex': '2',
        'inputType': 'checkset',
        'label': '渗液类型',
        'options': [
          {
            'key': '浆液样',
            'text': '浆液样'
          },
          {
            'key': '清水样',
            'text': '清水样'
          },
          {
            'key': '脓性',
            'text': '脓性'
          },
          {
            'key': '血性',
            'text': '血性'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '7',
        'fqn': 'integumentaryAssessment.exudateType'
      },
      {
        'elementKey': 'periIncisionSkin',
        'formIndex': '2',
        'inputType': 'checkset',
        'label': '切口周围皮肤',
        'options': [
          {
            'key': '完整',
            'text': '完整'
          },
          {
            'key': '浸渍',
            'text': '浸渍'
          },
          {
            'key': '水疱 红斑小于2cm',
            'text': '水疱 红斑小于2cm'
          },
          {
            'key': '红斑大于2cm',
            'text': '红斑大于2cm'
          },
          {
            'key': '硬结小于2cm',
            'text': '硬结小于2cm'
          },
          {
            'key': '硬结大于2cm',
            'text': '硬结大于2cm'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '8',
        'fqn': 'integumentaryAssessment.periIncisionSkin'
      },
      {
        'elementKey': 'periIncisionSkinOther',
        'formIndex': '2',
        'inputType': 'text',
        'label': '其他',
        'tableColumn': '9',
        'fqn': 'integumentaryAssessment.periIncisionSkinOther'
      },
      {
        'elementKey': 'incisionStatusComments',
        'formIndex': '2',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '备注',
        'tableColumn': '10',
        'tableLabel': '备注',
        'fqn': 'integumentaryAssessment.incisionStatusComments'
      },
      {
        'elementKey': 'tableWound_name',
        'formIndex': '3',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.name'
      },
      {
        'elementKey': 'tableWound_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'tableWound_profession',
        'formIndex': '3',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.profession'
      },
      {
        'elementKey': 'tableWound_day',
        'formIndex': '3',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.day'
      },
      {
        'elementKey': 'tableWound_time',
        'formIndex': '3',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.time'
      },
      {
        'elementKey': 'woundLocation',
        'formIndex': '3',
        'inputType': 'select',
        'label': '伤口位置',
        'options': [
          {
            'key': '头部',
            'text': '头部'
          },
          {
            'key': '面部',
            'text': '面部'
          },
          {
            'key': '脖子',
            'text': '脖子'
          },
          {
            'key': '胸部',
            'text': '胸部'
          },
          {
            'key': '手臂 (左)',
            'text': '手臂 (左)'
          },
          {
            'key': '手臂 (右)',
            'text': '手臂 (右)'
          },
          {
            'key': '手 (左)',
            'text': '手 (左)'
          },
          {
            'key': '手 (右)',
            'text': '手 (右)'
          },
          {
            'key': '腹部',
            'text': '腹部'
          },
          {
            'key': '背部',
            'text': '背部'
          },
          {
            'key': '盆腔',
            'text': '盆腔'
          },
          {
            'key': '臀部',
            'text': '臀部'
          },
          {
            'key': '腿 (左)',
            'text': '腿 (左)'
          },
          {
            'key': '腿 (右)',
            'text': '腿 (右)'
          },
          {
            'key': '脚 (左)',
            'text': '脚 (左)'
          },
          {
            'key': '脚 (右)',
            'text': '脚 (右)'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '伤口位置',
        'fqn': 'integumentaryAssessment.woundLocation'
      },
      {
        'elementKey': 'woundLabel',
        'formIndex': '3',
        'inputType': 'select',
        'label': '伤口标签',
        'options': [
          {
            'key': '伤口 A',
            'text': '伤口 A'
          },
          {
            'key': '伤口 B',
            'text': '伤口 B'
          },
          {
            'key': '伤口 C',
            'text': '伤口 C'
          },
          {
            'key': '伤口 D',
            'text': '伤口 D'
          }
        ],
        'tableColumn': '3',
        'tableLabel': '伤口标签',
        'fqn': 'integumentaryAssessment.woundLabel'
      },
      {
        'elementKey': 'woundDayOnset',
        'formIndex': '3',
        'inputType': 'text',
        'label': '伤口出现时间',
        'tableColumn': '4',
        'tableLabel': '伤口出现时间',
        'fqn': 'integumentaryAssessment.woundDayOnset'
      },
      {
        'elementKey': 'stageType',
        'formIndex': '3',
        'helperText': '压疮 Ⅰ期 红润期– 皮肤完整，部分皮肤出现红斑，按压不变色。\n压疮 Ⅱ期 炎性浸润期– 部分皮肤缺损，形成溃疡、形成开放性溃疡。\n压疮 Ⅲ期 浅溃疡期– 全层皮肤缺损，可见皮下脂肪。\n压疮 Ⅳ期 深溃疡期– 全层皮肤缺损，有肌腱、肌肉暴露。\n压疮 不可分期 – 缺损皮肤底部有腐肉或焦痂。\n压疮 可疑深部组织损伤期 – 皮肤出现紫色，有充血水疱，有疼痛、糜烂等症状。\n手术伤口裂开: 切口已裂开，无法进行手术闭合；伤口必须通过肉芽愈合\n皮肤撕裂: 皮肤撕裂是由剪切、摩擦或钝力对皮肤造成的创伤造成的。考虑风险因素（即：高龄、行动不便、水分/营养不足、跌倒）\n脓肿: 急性感染过程中，组织、器官或体腔内，因病变组织坏死、液化而出现的局限性脓液积聚。可以切开并引流。\n烧伤: 由热源、化学源、电源和辐射源造成的组织损失/损伤。\n静脉溃疡: 由瓣膜功能障碍、深静脉完全或部分阻塞和/或小腿肌肉泵障碍导致静脉功能不全而引起。\n动脉溃疡: 由动脉血流中断导致中度/重度组织缺血引起。\n动静脉混合性溃疡: 由静脉功能不全和动脉血流中断引起。\n糖尿病足神经性溃疡: 由神经病变、足部结构变化和/或与糖尿病（或酗酒、肾功能衰竭、艾滋病毒、梅毒晚期、创伤和手术）相关的血流改变引起。',
        'inputType': 'checkset',
        'label': '伤口类型',
        'options': [
          {
            'key': '压疮 ⅰ期',
            'text': '压疮 Ⅰ期'
          },
          {
            'key': '压疮 ⅱ期',
            'text': '压疮 Ⅱ期'
          },
          {
            'key': '压疮 ⅲ期',
            'text': '压疮 Ⅲ期'
          },
          {
            'key': '压疮 ⅳ期',
            'text': '压疮 Ⅳ期'
          },
          {
            'key': '压疮 不可分期',
            'text': '压疮 不可分期'
          },
          {
            'key': '压疮 可疑深部组织损伤期',
            'text': '压疮 可疑深部组织损伤期'
          },
          {
            'key': '手术伤口裂开',
            'text': '手术伤口裂开'
          },
          {
            'key': '皮肤撕裂',
            'text': '皮肤撕裂'
          },
          {
            'key': '脓肿',
            'text': '脓肿'
          },
          {
            'key': '烧伤',
            'text': '烧伤'
          },
          {
            'key': '静脉性溃疡',
            'text': '静脉性溃疡'
          },
          {
            'key': '动脉溃疡',
            'text': '动脉溃疡'
          },
          {
            'key': '动静脉混合性溃疡',
            'text': '动静脉混合性溃疡'
          },
          {
            'key': '糖尿病足神经性溃疡',
            'text': '糖尿病足神经性溃疡'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '5',
        'tableLabel': '伤口类型',
        'fqn': 'integumentaryAssessment.stageType',
        'helperHtml': '<p>压疮 Ⅰ期 红润期– 皮肤完整，部分皮肤出现红斑，按压不变色。</p>\n<p>压疮 Ⅱ期 炎性浸润期– 部分皮肤缺损，形成溃疡、形成开放性溃疡。</p>\n<p>压疮 Ⅲ期 浅溃疡期– 全层皮肤缺损，可见皮下脂肪。</p>\n<p>压疮 Ⅳ期 深溃疡期– 全层皮肤缺损，有肌腱、肌肉暴露。</p>\n<p>压疮 不可分期 – 缺损皮肤底部有腐肉或焦痂。</p>\n<p>压疮 可疑深部组织损伤期 – 皮肤出现紫色，有充血水疱，有疼痛、糜烂等症状。</p>\n<p>手术伤口裂开: 切口已裂开，无法进行手术闭合；伤口必须通过肉芽愈合</p>\n<p>皮肤撕裂: 皮肤撕裂是由剪切、摩擦或钝力对皮肤造成的创伤造成的。考虑风险因素（即：高龄、行动不便、水分/营养不足、跌倒）</p>\n<p>脓肿: 急性感染过程中，组织、器官或体腔内，因病变组织坏死、液化而出现的局限性脓液积聚。可以切开并引流。</p>\n<p>烧伤: 由热源、化学源、电源和辐射源造成的组织损失/损伤。</p>\n<p>静脉溃疡: 由瓣膜功能障碍、深静脉完全或部分阻塞和/或小腿肌肉泵障碍导致静脉功能不全而引起。</p>\n<p>动脉溃疡: 由动脉血流中断导致中度/重度组织缺血引起。</p>\n<p>动静脉混合性溃疡: 由静脉功能不全和动脉血流中断引起。</p>\n<p>糖尿病足神经性溃疡: 由神经病变、足部结构变化和/或与糖尿病（或酗酒、肾功能衰竭、艾滋病毒、梅毒晚期、创伤和手术）相关的血流改变引起。</p>'
      },
      {
        'elementKey': 'stageOther',
        'dependentOn': 'visble:stageType=other',
        'formIndex': '3',
        'inputType': 'text',
        'label': '其他',
        'tableColumn': '6',
        'tableLabel': '其他',
        'fqn': 'integumentaryAssessment.stageOther'
      },
      {
        'elementKey': 'goalOfCare',
        'formIndex': '3',
        'inputType': 'select',
        'label': '护理目标',
        'options': [
          {
            'key': '治愈',
            'text': '治愈'
          },
          {
            'key': '维持',
            'text': '维持'
          },
          {
            'key': '继续观察/管理',
            'text': '继续观察/管理'
          }
        ],
        'tableColumn': '7',
        'tableLabel': '护理目标',
        'fqn': 'integumentaryAssessment.goalOfCare'
      },
      {
        'elementKey': 'woundProfileImage',
        'formIndex': '3',
        'inputType': 'ehrFile',
        'label': '图片',
        'tableColumn': '8',
        'tableLabel': '图片',
        'fqn': 'integumentaryAssessment.woundProfileImage'
      },
      {
        'elementKey': 'woundProfileComments',
        'formIndex': '3',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '备注',
        'tableColumn': '9',
        'tableLabel': '备注',
        'fqn': 'integumentaryAssessment.woundProfileComments'
      },
      {
        'elementKey': 'tableWoundAssessment_name',
        'formIndex': '4',
        'inputType': 'practitionerName',
        'label': '姓名',
        'tableColumn': '1',
        'tableLabel': '记录',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.name'
      },
      {
        'elementKey': 'tableWoundAssessment_id',
        'inputType': 'generatedId',
        'tableColumn': 0,
        'label': 'Row id',
        'tableCss': 'row-id'
      },
      {
        'elementKey': 'tableWoundAssessment_profession',
        'formIndex': '4',
        'inputType': 'practitionerProfession',
        'label': '职称',
        'tableColumn': '1',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.profession'
      },
      {
        'elementKey': 'tableWoundAssessment_day',
        'formIndex': '4',
        'inputType': 'visitDay',
        'label': '住院天数',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'visitDay',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.day'
      },
      {
        'elementKey': 'tableWoundAssessment_time',
        'formIndex': '4',
        'inputType': 'visitTime',
        'label': '时间',
        'mandatory': true,
        'tableColumn': '1',
        'validation': 'time24',
        'recHeader': true,
        'fqn': 'integumentaryAssessment.time'
      },
      {
        'elementKey': 'woundLabel',
        'formIndex': '4',
        'inputType': 'select',
        'label': '伤口标签',
        'options': [
          {
            'key': '伤口 A',
            'text': '伤口 A'
          },
          {
            'key': '伤口 B',
            'text': '伤口 B'
          },
          {
            'key': '伤口 C',
            'text': '伤口 C'
          },
          {
            'key': '伤口 D',
            'text': '伤口 D'
          }
        ],
        'tableColumn': '2',
        'tableLabel': '伤口标签',
        'fqn': 'integumentaryAssessment.woundLabel'
      },
      {
        'elementKey': 'woundAssessmentImage',
        'formIndex': '4',
        'inputType': 'ehrFile',
        'label': '图片',
        'tableColumn': '3',
        'tableLabel': '图片',
        'fqn': 'integumentaryAssessment.woundAssessmentImage'
      },
      {
        'elementKey': 'length',
        'formIndex': '4',
        'inputType': 'text',
        'label': '长度 cm.mm',
        'tableColumn': '4',
        'tableLabel': '长度 cm.mm',
        'fqn': 'integumentaryAssessment.length'
      },
      {
        'elementKey': 'width',
        'formIndex': '4',
        'inputType': 'text',
        'label': '宽度 cm.mm',
        'tableColumn': '5',
        'tableLabel': '宽度 cm.mm',
        'fqn': 'integumentaryAssessment.width'
      },
      {
        'elementKey': 'depth',
        'formIndex': '4',
        'inputType': 'text',
        'label': '深度 cm.mm',
        'tableColumn': '6',
        'tableLabel': '深度 cm.mm',
        'fqn': 'integumentaryAssessment.depth'
      },
      {
        'elementKey': 'sinusDepth1',
        'formIndex': '4',
        'inputType': 'text',
        'label': '窦道/瘘管 1 深度 cm.mm',
        'tableColumn': '7',
        'tableLabel': '窦道/瘘管 1 深度 cm.mm',
        'fqn': 'integumentaryAssessment.sinusDepth1'
      },
      {
        'elementKey': 'sinusDepthLocation1',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '8',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.sinusDepthLocation1'
      },
      {
        'elementKey': 'spacer91',
        'formIndex': '4',
        'inputType': 'spacer',
        'fqn': 'integumentaryAssessment.spacer91'
      },
      {
        'elementKey': 'sinusDepth2',
        'formIndex': '4',
        'inputType': 'text',
        'label': '窦道/瘘管 2 深度 cm.mm',
        'tableColumn': '9',
        'tableLabel': '窦道/瘘管 2 深度 cm.mm',
        'fqn': 'integumentaryAssessment.sinusDepth2'
      },
      {
        'elementKey': 'sinusDepthLocation2',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '10',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.sinusDepthLocation2'
      },
      {
        'elementKey': 'spacer92',
        'formIndex': '4',
        'inputType': 'spacer',
        'fqn': 'integumentaryAssessment.spacer92'
      },
      {
        'elementKey': 'underminingDepth1',
        'formIndex': '4',
        'inputType': 'text',
        'label': '潜行 1 深度 cm.mm',
        'tableColumn': '11',
        'tableLabel': '潜行 1 深度 cm.mm',
        'fqn': 'integumentaryAssessment.underminingDepth1'
      },
      {
        'elementKey': 'underminingDepthLocation1A',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '12',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.underminingDepthLocation1A'
      },
      {
        'elementKey': 'underminingDepthLocation1B',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '13',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.underminingDepthLocation1B'
      },
      {
        'elementKey': 'underminingDepth2',
        'formIndex': '4',
        'inputType': 'text',
        'label': '潜行 2 深度 cm.mm',
        'tableColumn': '14',
        'tableLabel': '潜行 2 深度 cm.mm',
        'fqn': 'integumentaryAssessment.underminingDepth2'
      },
      {
        'elementKey': 'underminingDepthLocation2A',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '15',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.underminingDepthLocation2A'
      },
      {
        'elementKey': 'underminingDepthLocation2B',
        'formIndex': '4',
        'inputType': 'select',
        'label': '位置',
        'options': [
          {
            'key': '1',
            'text': '1'
          },
          {
            'key': '2',
            'text': '2'
          },
          {
            'key': '3',
            'text': '3'
          },
          {
            'key': '4',
            'text': '4'
          },
          {
            'key': '5',
            'text': '5'
          },
          {
            'key': '6',
            'text': '6'
          },
          {
            'key': '7',
            'text': '7'
          },
          {
            'key': '8',
            'text': '8'
          },
          {
            'key': '9',
            'text': '9'
          },
          {
            'key': '10',
            'text': '10'
          },
          {
            'key': '11',
            'text': '11'
          },
          {
            'key': '12',
            'text': '12'
          }
        ],
        'tableColumn': '16',
        'tableLabel': '位置',
        'fqn': 'integumentaryAssessment.underminingDepthLocation2B'
      },
      {
        'elementKey': 'pinkred',
        'formIndex': '4',
        'helperText': '浅表粉色/红色区域',
        'inputType': 'select',
        'label': '% 表面 (粉红色/红色)',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '17',
        'tableLabel': '% 表面 (粉红色/红色)',
        'fqn': 'integumentaryAssessment.pinkred',
        'helperHtml': '<p>浅表粉色/红色区域</p>'
      },
      {
        'elementKey': 'granulation',
        'formIndex': '4',
        'helperText': '肉芽组织：坚硬、红色、湿润、颗粒状的健康组织',
        'inputType': 'select',
        'label': '% 肉芽组织',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '18',
        'tableLabel': '% 肉芽组织',
        'fqn': 'integumentaryAssessment.granulation',
        'helperHtml': '<p>肉芽组织：坚硬、红色、湿润、颗粒状的健康组织</p>'
      },
      {
        'elementKey': 'nongranulated',
        'formIndex': '4',
        'helperText': '上皮组织',
        'inputType': 'select',
        'label': '% 上皮组织',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '19',
        'tableLabel': '% 上皮组织',
        'fqn': 'integumentaryAssessment.nongranulated',
        'helperHtml': '<p>上皮组织</p>'
      },
      {
        'elementKey': 'slough',
        'formIndex': '4',
        'helperText': '腐肉：潮湿或湿润、松散或牢固附着、黄色至棕色死亡组织',
        'inputType': 'select',
        'label': '% 腐肉',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '20',
        'tableLabel': '% 腐肉',
        'fqn': 'integumentaryAssessment.slough',
        'helperHtml': '<p>腐肉：潮湿或湿润、松散或牢固附着、黄色至棕色死亡组织</p>'
      },
      {
        'elementKey': 'escharBoggy',
        'formIndex': '4',
        'helperText': '焦痂（柔软）：柔软的坏死组织，颜色为黑色、棕色、灰色或棕褐色。它可能牢固或松散地附着在伤口边缘和伤口基部；可能存在波动和渗液。',
        'inputType': 'select',
        'label': '% 焦痂 (柔软)',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '21',
        'tableLabel': '% 焦痂 (柔软)',
        'fqn': 'integumentaryAssessment.escharBoggy',
        'helperHtml': '<p>焦痂（柔软）：柔软的坏死组织，颜色为黑色、棕色、灰色或棕褐色。它可能牢固或松散地附着在伤口边缘和伤口基部；可能存在波动和渗液。</p>'
      },
      {
        'elementKey': 'escharStable',
        'formIndex': '4',
        'helperText': '焦痂（干燥、稳定）：坚硬、干燥的坏死组织，无水肿、红斑或波动。颜色为黑色或棕色，附着在伤口边缘和伤口基部',
        'inputType': 'select',
        'label': '% 焦痂 (干燥, 稳定)',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '22',
        'tableLabel': '% 焦痂 (干燥, 稳定)',
        'fqn': 'integumentaryAssessment.escharStable',
        'helperHtml': '<p>焦痂（干燥、稳定）：坚硬、干燥的坏死组织，无水肿、红斑或波动。颜色为黑色或棕色，附着在伤口边缘和伤口基部</p>'
      },
      {
        'elementKey': 'foreign',
        'formIndex': '4',
        'helperText': '异物：缝线、五金件等',
        'inputType': 'select',
        'label': '% 异物',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '23',
        'tableLabel': '% 异物',
        'fqn': 'integumentaryAssessment.foreign',
        'helperHtml': '<p>异物：缝线、五金件等</p>'
      },
      {
        'elementKey': 'underlying',
        'formIndex': '4',
        'helperText': '底层结构：筋膜、肌腱、骨骼',
        'inputType': 'select',
        'label': '% 底层结构',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '24',
        'tableLabel': '% 底层结构',
        'fqn': 'integumentaryAssessment.underlying',
        'helperHtml': '<p>底层结构：筋膜、肌腱、骨骼</p>'
      },
      {
        'elementKey': 'notVisible',
        'formIndex': '4',
        'helperText': '不可见：无法看到的部分或全部开放伤口床',
        'inputType': 'select',
        'label': '% 不可见部分',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '25',
        'tableLabel': '% 不可见部分',
        'fqn': 'integumentaryAssessment.notVisible',
        'helperHtml': '<p>不可见：无法看到的部分或全部开放伤口床</p>'
      },
      {
        'elementKey': 'other',
        'formIndex': '4',
        'inputType': 'select',
        'label': '% 其他',
        'options': [
          {
            'key': '10',
            'text': '10%'
          },
          {
            'key': '20',
            'text': '20%'
          },
          {
            'key': '30',
            'text': '30%'
          },
          {
            'key': '40',
            'text': '40%'
          },
          {
            'key': '50',
            'text': '50%'
          },
          {
            'key': '60',
            'text': '60%'
          },
          {
            'key': '70',
            'text': '70%'
          },
          {
            'key': '80',
            'text': '80%'
          },
          {
            'key': '90',
            'text': '90%'
          },
          {
            'key': '100',
            'text': '100%'
          }
        ],
        'passToFunction': 'woundBedCalculation',
        'tableColumn': '26',
        'tableLabel': '% 其他',
        'fqn': 'integumentaryAssessment.other'
      },
      {
        'elementKey': 'woundBedCalculation',
        'calculationType': 'sum',
        'defaultValue': '0',
        'formIndex': '4',
        'inputType': 'calculatedValue',
        'label': '伤口床计算',
        'tableColumn': '27',
        'tableLabel': '伤口床计算',
        'validation': 'range(0,100)',
        'fqn': 'integumentaryAssessment.woundBedCalculation'
      },
      {
        'elementKey': 'exudateAmount',
        'formIndex': '4',
        'inputType': 'select',
        'label': '渗液量',
        'options': [
          {
            'key': '无',
            'text': '无'
          },
          {
            'key': '少量',
            'text': '少量'
          },
          {
            'key': '中等',
            'text': '中等'
          },
          {
            'key': '大量',
            'text': '大量'
          }
        ],
        'tableColumn': '28',
        'tableLabel': '渗液量',
        'fqn': 'integumentaryAssessment.exudateAmount'
      },
      {
        'elementKey': 'exudateType',
        'formIndex': '4',
        'inputType': 'checkset',
        'label': '渗液类型',
        'options': [
          {
            'key': '浆液样',
            'text': '浆液样'
          },
          {
            'key': '清水样',
            'text': '清水样'
          },
          {
            'key': '脓性',
            'text': '脓性'
          },
          {
            'key': '血性',
            'text': '血性'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '29',
        'tableLabel': '渗液类型',
        'fqn': 'integumentaryAssessment.exudateType'
      },
      {
        'elementKey': 'odour',
        'formIndex': '4',
        'inputType': 'select',
        'label': '清洁后有无异味',
        'options': [
          {
            'key': '有',
            'text': '有'
          },
          {
            'key': '无',
            'text': '无'
          }
        ],
        'tableColumn': '30',
        'tableLabel': '清洁后有无异味',
        'fqn': 'integumentaryAssessment.odour'
      },
      {
        'elementKey': 'woundEdge',
        'formIndex': '4',
        'inputType': 'checkset',
        'label': '伤口边缘',
        'options': [
          {
            'key': '边缘上皮化',
            'text': '边缘上皮化'
          },
          {
            'key': '边缘附着良好',
            'text': '边缘附着良好'
          },
          {
            'key': '边缘附着不良',
            'text': '边缘附着不良'
          },
          {
            'key': '边界清晰',
            'text': '边界清晰'
          },
          {
            'key': '不规则边缘',
            'text': '不规则边缘'
          },
          {
            'key': '卷边',
            'text': '卷边'
          }
        ],
        'tableColumn': '31',
        'tableLabel': '伤口边缘',
        'fqn': 'integumentaryAssessment.woundEdge'
      },
      {
        'elementKey': 'periWoundSkin',
        'formIndex': '4',
        'inputType': 'checkset',
        'label': '伤口周围皮肤',
        'options': [
          {
            'key': '完整',
            'text': '完整'
          },
          {
            'key': '浸渍',
            'text': '浸渍'
          },
          {
            'key': '湿软',
            'text': '湿软'
          },
          {
            'key': '表皮剥脱',
            'text': '表皮剥脱'
          },
          {
            'key': '红斑 (小于2cm)',
            'text': '红斑 (小于2cm)'
          },
          {
            'key': '硬结 (小于2cm)',
            'text': '硬结 (小于2cm)'
          },
          {
            'key': '硬结 (大于2cm)',
            'text': '硬结 (大于2cm)'
          },
          {
            'key': '红斑 (大于2cm)',
            'text': '红斑 (大于2cm)'
          },
          {
            'key': '暗红/紫色',
            'text': '暗红/紫色'
          },
          {
            'key': '其他',
            'text': '其他'
          }
        ],
        'tableColumn': '32',
        'tableLabel': '伤口周围皮肤',
        'fqn': 'integumentaryAssessment.periWoundSkin'
      },
      {
        'elementKey': 'woundPain',
        'formIndex': '4',
        'helperText': '依据总分 10 分的疼痛量表评分',
        'inputType': 'text',
        'label': '伤口疼痛',
        'suffix': '/10',
        'tableColumn': '33',
        'tableLabel': '伤口疼痛',
        'validation': 'range(0,10)',
        'fqn': 'integumentaryAssessment.woundPain',
        'helperHtml': '<p>依据总分 10 分的疼痛量表评分</p>',
        'suffixHtml': '<p>/10</p>',
        'suffixText': '/10'
      },
      {
        'elementKey': 'packingOut',
        'formIndex': '4',
        'inputType': 'text',
        'label': '伤口处理',
        'tableColumn': '34',
        'tableLabel': '伤口处理',
        'fqn': 'integumentaryAssessment.packingOut'
      },
      {
        'elementKey': 'packinIn',
        'formIndex': '4',
        'inputType': 'text',
        'label': '敷料选择',
        'tableColumn': '35',
        'tableLabel': '敷料选择',
        'fqn': 'integumentaryAssessment.packinIn'
      },
      {
        'elementKey': 'spacer98',
        'formIndex': '4',
        'inputType': 'spacer',
        'fqn': 'integumentaryAssessment.spacer98'
      },
      {
        'elementKey': 'treatmentComplete',
        'formIndex': '4',
        'inputType': 'select',
        'label': '按照护理计划进行治疗',
        'options': [
          {
            'key': '是',
            'text': '是'
          },
          {
            'key': '否',
            'text': '否'
          }
        ],
        'tableColumn': '36',
        'tableLabel': '按照护理计划进行治疗',
        'fqn': 'integumentaryAssessment.treatmentComplete'
      },
      {
        'elementKey': 'woundAssessmentComments',
        'formIndex': '4',
        'formCss': 'grid-span-3-columns',
        'inputType': 'textarea',
        'label': '备注',
        'tableColumn': '37',
        'tableLabel': '备注',
        'fqn': 'integumentaryAssessment.woundAssessmentComments'
      }
    ],
    'pageElements': {
      'tableIncision': {
        'elementKey': 'tableIncision',
        'pageElementIndex': '1',
        'pageKey': 'integumentaryAssessment',
        'tableKey': 'tableIncision',
        'isTable': true,
        'label': '切口概况',
        'addButtonText': '添加切口概况',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'tableIncision_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'tableIncision_name',
              'tableIncision_profession',
              'tableIncision_day',
              'tableIncision_time'
            ]
          },
          {
            'ehr_list_index': '2',
            'items': [
              'incisionLabel'
            ]
          },
          {
            'ehr_list_index': '3',
            'items': [
              'incisionLocation'
            ]
          },
          {
            'ehr_list_index': '4',
            'items': [
              'postOpDay'
            ]
          },
          {
            'ehr_list_index': '5',
            'items': [
              'opProcedure'
            ]
          },
          {
            'ehr_list_index': '6',
            'items': [
              'closureMethod'
            ]
          },
          {
            'ehr_list_index': '7',
            'items': [
              'incisionProfileImage'
            ]
          },
          {
            'ehr_list_index': '8',
            'items': [
              'incisionProfileComments'
            ]
          }
        ],
        'form': {
          'elementKey': 'tableIncision',
          "label": "Incision profile",
          "addButtonText": "Add an incision profile",
          'formKey': 'tableIncision',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group86',
              'formCss': 'record-header',
              "fqn": "integumentaryAssessment.ehr_group86",
              'gIndex': '1',
              'gChildren': [
                'tableIncision_name',
                'tableIncision_profession',
                'tableIncision_day',
                'tableIncision_time'
              ]
            },
            {
              'elementKey': 'incisionLocationGroup',
              'label': '切口位置',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.incisionLocationGroup",
              'gIndex': '2',
              'gChildren': [
                'incisionLabel',
                'incisionLocation',
                'postOpDay',
                'opProcedure',
                'closureMethod',
                'incisionProfileImage',
                'incisionProfileComments'
              ]
            }
          ],
          'ehr_data': {
            'tableIncision_name': '',
            'tableIncision_profession': '',
            'tableIncision_day': '',
            'tableIncision_time': '',
            'incisionLabel': '',
            'incisionLocation': '',
            'postOpDay': '',
            'opProcedure': '',
            'closureMethod': '',
            'incisionProfileImage': '',
            'incisionProfileComments': ''
          }
        },
        "fqn": "integumentaryAssessment.tableIncision",
        'tableChildren': [
          'tableIncision_id',
          'tableIncision_name',
          'tableIncision_profession',
          'tableIncision_day',
          'tableIncision_time',
          'incisionLabel',
          'incisionLocation',
          'postOpDay',
          'opProcedure',
          'closureMethod',
          'incisionProfileImage',
          'incisionProfileComments'
        ],
        'hasRecHeader': true
      },
      'tableIncisionAssessment': {
        'elementKey': 'tableIncisionAssessment',
        'pageElementIndex': '2',
        'pageKey': 'integumentaryAssessment',
        'tableKey': 'tableIncisionAssessment',
        'isTable': true,
        'label': '切口评估',
        'addButtonText': '添加切口评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'tableIncisionAssessment_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'tableIncisionAssessment_name',
              'tableIncisionAssessment_profession',
              'tableIncisionAssessment_day',
              'tableIncisionAssessment_time'
            ]
          },
          {
            'ehr_list_index': '2',
            'items': [
              'incisionLabel'
            ]
          },
          {
            'ehr_list_index': '3',
            'items': [
              'incisionStatus'
            ]
          },
          {
            'ehr_list_index': '4',
            'items': [
              'incisionStatusImage'
            ]
          },
          {
            'ehr_list_index': '5',
            'items': [
              'closureChanges'
            ]
          },
          {
            'ehr_list_index': '6',
            'items': [
              'exudateAmount'
            ]
          },
          {
            'ehr_list_index': '7',
            'items': [
              'exudateType'
            ]
          },
          {
            'ehr_list_index': '8',
            'items': [
              'periIncisionSkin'
            ]
          },
          {
            'ehr_list_index': '9',
            'items': [
              'periIncisionSkinOther'
            ]
          },
          {
            'label': '备注',
            'ehr_list_index': '10',
            'items': [
              'incisionStatusComments'
            ]
          }
        ],
        'form': {
          'elementKey': 'tableIncisionAssessment',
          "label": "Incision assessment",
          "addButtonText": "Add an incision assessment",
          'formOption': 'transpose',
          'formKey': 'tableIncisionAssessment',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group87',
              'formCss': 'record-header',
              "fqn": "integumentaryAssessment.ehr_group87",
              'gIndex': '1',
              'gChildren': [
                'tableIncisionAssessment_name',
                'tableIncisionAssessment_profession',
                'tableIncisionAssessment_day',
                'tableIncisionAssessment_time'
              ]
            },
            {
              'elementKey': 'incisionStatusGroup',
              'label': '切口',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.incisionStatusGroup",
              'gIndex': '2',
              'gChildren': [
                'incisionLabel',
                'incisionStatus',
                'incisionStatusImage',
                'closureChanges'
              ]
            },
            {
              'elementKey': 'exudateGroup',
              'label': '渗液',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.exudateGroup",
              'gIndex': '3',
              'gChildren': [
                'exudateAmount',
                'exudateType'
              ]
            },
            {
              'elementKey': 'periIncisionGroup',
              'label': '切口周围皮肤',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.periIncisionGroup",
              'gIndex': '4',
              'gChildren': [
                'periIncisionSkin',
                'periIncisionSkinOther',
                'incisionStatusComments'
              ]
            }
          ],
          'ehr_data': {
            'tableIncisionAssessment_name': '',
            'tableIncisionAssessment_profession': '',
            'tableIncisionAssessment_day': '',
            'tableIncisionAssessment_time': '',
            'incisionLabel': '',
            'incisionStatus': '',
            'incisionStatusImage': '',
            'closureChanges': '',
            'exudateAmount': '',
            'exudateType': '',
            'periIncisionSkin': '',
            'periIncisionSkinOther': '',
            'incisionStatusComments': ''
          }
        },
        "fqn": "integumentaryAssessment.tableIncisionAssessment",
        'tableChildren': [
          'tableIncisionAssessment_id',
          'tableIncisionAssessment_name',
          'tableIncisionAssessment_profession',
          'tableIncisionAssessment_day',
          'tableIncisionAssessment_time',
          'incisionLabel',
          'incisionStatus',
          'incisionStatusImage',
          'closureChanges',
          'exudateAmount',
          'exudateType',
          'periIncisionSkin',
          'periIncisionSkinOther',
          'incisionStatusComments'
        ],
        'hasRecHeader': true
      },
      'tableWound': {
        'elementKey': 'tableWound',
        'pageElementIndex': '3',
        'pageKey': 'integumentaryAssessment',
        'tableKey': 'tableWound',
        'isTable': true,
        'label': '伤口概况',
        'addButtonText': '添加伤口概况',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'tableWound_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'tableWound_name',
              'tableWound_profession',
              'tableWound_day',
              'tableWound_time'
            ]
          },
          {
            'label': '伤口位置',
            'ehr_list_index': '2',
            'items': [
              'woundLocation'
            ]
          },
          {
            'label': '伤口标签',
            'ehr_list_index': '3',
            'items': [
              'woundLabel'
            ]
          },
          {
            'label': '伤口出现时间',
            'ehr_list_index': '4',
            'items': [
              'woundDayOnset'
            ]
          },
          {
            'label': '伤口类型',
            'ehr_list_index': '5',
            'items': [
              'stageType'
            ]
          },
          {
            'label': '其他',
            'ehr_list_index': '6',
            'items': [
              'stageOther'
            ]
          },
          {
            'label': '护理目标',
            'ehr_list_index': '7',
            'items': [
              'goalOfCare'
            ]
          },
          {
            'label': '图片',
            'ehr_list_index': '8',
            'items': [
              'woundProfileImage'
            ]
          },
          {
            'label': '备注',
            'ehr_list_index': '9',
            'items': [
              'woundProfileComments'
            ]
          }
        ],
        'form': {
          'elementKey': 'tableWound',
          "label": "Wound profile",
          "addButtonText": "Add a wound profile",
          'formKey': 'tableWound',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group88',
              'formCss': 'record-header',
              "fqn": "integumentaryAssessment.ehr_group88",
              'gIndex': '1',
              'gChildren': [
                'tableWound_name',
                'tableWound_profession',
                'tableWound_day',
                'tableWound_time'
              ]
            },
            {
              'elementKey': 'ehr_group89',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group89",
              'gIndex': '2',
              'gChildren': [
                'woundLocation',
                'woundLabel',
                'woundDayOnset'
              ]
            },
            {
              'elementKey': 'woundTypeGroup',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.woundTypeGroup",
              'gIndex': '3',
              'gChildren': [
                'stageType',
                'stageOther',
                'goalOfCare',
                'woundProfileImage',
                'woundProfileComments'
              ]
            }
          ],
          'ehr_data': {
            'tableWound_name': '',
            'tableWound_profession': '',
            'tableWound_day': '',
            'tableWound_time': '',
            'woundLocation': '',
            'woundLabel': '',
            'woundDayOnset': '',
            'stageType': '',
            'stageOther': '',
            'goalOfCare': '',
            'woundProfileImage': '',
            'woundProfileComments': ''
          }
        },
        "fqn": "integumentaryAssessment.tableWound",
        'tableChildren': [
          'tableWound_id',
          'tableWound_name',
          'tableWound_profession',
          'tableWound_day',
          'tableWound_time',
          'woundLocation',
          'woundLabel',
          'woundDayOnset',
          'stageType',
          'stageOther',
          'goalOfCare',
          'woundProfileImage',
          'woundProfileComments'
        ],
        'hasRecHeader': true
      },
      'tableWoundAssessment': {
        'elementKey': 'tableWoundAssessment',
        'pageElementIndex': '4',
        'pageKey': 'integumentaryAssessment',
        'tableKey': 'tableWoundAssessment',
        'isTable': true,
        'label': '伤口评估',
        'addButtonText': '添加伤口评估',
        'ehr_list': [
          {
            'label': 'Row id',
            'tableCss': 'row-id',
            'ehr_list_index': 0,
            'items': [
              'tableWoundAssessment_id'
            ]
          },
          {
            'label': '记录',
            'ehr_list_index': '1',
            'items': [
              'tableWoundAssessment_name',
              'tableWoundAssessment_profession',
              'tableWoundAssessment_day',
              'tableWoundAssessment_time'
            ]
          },
          {
            'label': '伤口标签',
            'ehr_list_index': '2',
            'items': [
              'woundLabel'
            ]
          },
          {
            'label': '图片',
            'ehr_list_index': '3',
            'items': [
              'woundAssessmentImage'
            ]
          },
          {
            'label': '长度 cm.mm',
            'ehr_list_index': '4',
            'items': [
              'length'
            ]
          },
          {
            'label': '宽度 cm.mm',
            'ehr_list_index': '5',
            'items': [
              'width'
            ]
          },
          {
            'label': '深度 cm.mm',
            'ehr_list_index': '6',
            'items': [
              'depth'
            ]
          },
          {
            'label': '窦道/瘘管 1 深度 cm.mm',
            'ehr_list_index': '7',
            'items': [
              'sinusDepth1'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '8',
            'items': [
              'sinusDepthLocation1'
            ]
          },
          {
            'label': '窦道/瘘管 2 深度 cm.mm',
            'ehr_list_index': '9',
            'items': [
              'sinusDepth2'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '10',
            'items': [
              'sinusDepthLocation2'
            ]
          },
          {
            'label': '潜行 1 深度 cm.mm',
            'ehr_list_index': '11',
            'items': [
              'underminingDepth1'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '12',
            'items': [
              'underminingDepthLocation1A'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '13',
            'items': [
              'underminingDepthLocation1B'
            ]
          },
          {
            'label': '潜行 2 深度 cm.mm',
            'ehr_list_index': '14',
            'items': [
              'underminingDepth2'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '15',
            'items': [
              'underminingDepthLocation2A'
            ]
          },
          {
            'label': '位置',
            'ehr_list_index': '16',
            'items': [
              'underminingDepthLocation2B'
            ]
          },
          {
            'label': '% 表面 (粉红色/红色)',
            'ehr_list_index': '17',
            'items': [
              'pinkred'
            ]
          },
          {
            'label': '% 肉芽组织',
            'ehr_list_index': '18',
            'items': [
              'granulation'
            ]
          },
          {
            'label': '% 上皮组织',
            'ehr_list_index': '19',
            'items': [
              'nongranulated'
            ]
          },
          {
            'label': '% 腐肉',
            'ehr_list_index': '20',
            'items': [
              'slough'
            ]
          },
          {
            'label': '% 焦痂 (柔软)',
            'ehr_list_index': '21',
            'items': [
              'escharBoggy'
            ]
          },
          {
            'label': '% 焦痂 (干燥, 稳定)',
            'ehr_list_index': '22',
            'items': [
              'escharStable'
            ]
          },
          {
            'label': '% 异物',
            'ehr_list_index': '23',
            'items': [
              'foreign'
            ]
          },
          {
            'label': '% 底层结构',
            'ehr_list_index': '24',
            'items': [
              'underlying'
            ]
          },
          {
            'label': '% 不可见部分',
            'ehr_list_index': '25',
            'items': [
              'notVisible'
            ]
          },
          {
            'label': '% 其他',
            'ehr_list_index': '26',
            'items': [
              'other'
            ]
          },
          {
            'label': '伤口床计算',
            'ehr_list_index': '27',
            'items': [
              'woundBedCalculation'
            ]
          },
          {
            'label': '渗液量',
            'ehr_list_index': '28',
            'items': [
              'exudateAmount'
            ]
          },
          {
            'label': '渗液类型',
            'ehr_list_index': '29',
            'items': [
              'exudateType'
            ]
          },
          {
            'label': '清洁后有无异味',
            'ehr_list_index': '30',
            'items': [
              'odour'
            ]
          },
          {
            'label': '伤口边缘',
            'ehr_list_index': '31',
            'items': [
              'woundEdge'
            ]
          },
          {
            'label': '伤口周围皮肤',
            'ehr_list_index': '32',
            'items': [
              'periWoundSkin'
            ]
          },
          {
            'label': '伤口疼痛',
            'ehr_list_index': '33',
            'items': [
              'woundPain'
            ]
          },
          {
            'label': '伤口处理',
            'ehr_list_index': '34',
            'items': [
              'packingOut'
            ]
          },
          {
            'label': '敷料选择',
            'ehr_list_index': '35',
            'items': [
              'packinIn'
            ]
          },
          {
            'label': '按照护理计划进行治疗',
            'ehr_list_index': '36',
            'items': [
              'treatmentComplete'
            ]
          },
          {
            'label': '备注',
            'ehr_list_index': '37',
            'items': [
              'woundAssessmentComments'
            ]
          }
        ],
        'form': {
          'elementKey': 'tableWoundAssessment',
          "label": "Wound assessment",
          "addButtonText": "Add a wound assessment",
          'formOption': 'transpose',
          'formKey': 'tableWoundAssessment',
          'ehr_groups': [
            {
              'elementKey': 'ehr_group90',
              'formCss': 'record-header',
              "fqn": "integumentaryAssessment.ehr_group90",
              'gIndex': '1',
              'gChildren': [
                'tableWoundAssessment_name',
                'tableWoundAssessment_profession',
                'tableWoundAssessment_day',
                'tableWoundAssessment_time'
              ]
            },
            {
              'elementKey': 'woundMeasureGroup',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.woundMeasureGroup",
              'gIndex': '2',
              'gChildren': [
                'woundLabel',
                'woundAssessmentImage'
              ]
            },
            {
              'elementKey': 'woundMeasureGroup',
              'label': '伤口测量',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.woundMeasureGroup",
              'gIndex': '3',
              'gChildren': [
                'length',
                'width',
                'depth',
                'sinusDepth1',
                'sinusDepthLocation1',
                'spacer91',
                'sinusDepth2',
                'sinusDepthLocation2',
                'spacer92',
                'underminingDepth1',
                'underminingDepthLocation1A',
                'underminingDepthLocation1B',
                'underminingDepth2',
                'underminingDepthLocation2A',
                'underminingDepthLocation2B'
              ]
            },
            {
              'elementKey': 'woundBed',
              'label': '伤口床',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.woundBed",
              'gIndex': '4',
              'gChildren': [
                'pinkred',
                'granulation',
                'nongranulated',
                'slough',
                'escharBoggy',
                'escharStable',
                'foreign',
                'underlying',
                'notVisible',
                'other',
                'woundBedCalculation'
              ]
            },
            {
              'elementKey': 'ehr_group93',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group93",
              'gIndex': '5',
              'gChildren': [
                'exudateAmount',
                'exudateType'
              ]
            },
            {
              'elementKey': 'ehr_group94',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group94",
              'gIndex': '6',
              'gChildren': [
                'odour'
              ]
            },
            {
              'elementKey': 'ehr_group95',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group95",
              'gIndex': '7',
              'gChildren': [
                'woundEdge',
                'periWoundSkin'
              ]
            },
            {
              'elementKey': 'ehr_group96',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group96",
              'gIndex': '8',
              'gChildren': [
                'woundPain'
              ]
            },
            {
              'elementKey': 'ehr_group97',
              'label': '治疗',
              'formCss': 'section-divider grid-left-to-right-3',
              "fqn": "integumentaryAssessment.ehr_group97",
              'gIndex': '9',
              'gChildren': [
                'packingOut',
                'packinIn',
                'spacer98',
                'treatmentComplete',
                'woundAssessmentComments'
              ]
            }
          ],
          'ehr_data': {
            'tableWoundAssessment_name': '',
            'tableWoundAssessment_profession': '',
            'tableWoundAssessment_day': '',
            'tableWoundAssessment_time': '',
            'woundLabel': '',
            'woundAssessmentImage': '',
            'length': '',
            'width': '',
            'depth': '',
            'sinusDepth1': '',
            'sinusDepthLocation1': '',
            'sinusDepth2': '',
            'sinusDepthLocation2': '',
            'underminingDepth1': '',
            'underminingDepthLocation1A': '',
            'underminingDepthLocation1B': '',
            'underminingDepth2': '',
            'underminingDepthLocation2A': '',
            'underminingDepthLocation2B': '',
            'pinkred': '',
            'granulation': '',
            'nongranulated': '',
            'slough': '',
            'escharBoggy': '',
            'escharStable': '',
            'foreign': '',
            'underlying': '',
            'notVisible': '',
            'other': '',
            'woundBedCalculation': '0',
            'exudateAmount': '',
            'exudateType': '',
            'odour': '',
            'woundEdge': '',
            'periWoundSkin': '',
            'woundPain': '',
            'packingOut': '',
            'packinIn': '',
            'treatmentComplete': '',
            'woundAssessmentComments': ''
          }
        },
        "fqn": "integumentaryAssessment.tableWoundAssessment",
        'tableChildren': [
          'tableWoundAssessment_id',
          'tableWoundAssessment_name',
          'tableWoundAssessment_profession',
          'tableWoundAssessment_day',
          'tableWoundAssessment_time',
          'woundLabel',
          'woundAssessmentImage',
          'length',
          'width',
          'depth',
          'sinusDepth1',
          'sinusDepthLocation1',
          'sinusDepth2',
          'sinusDepthLocation2',
          'underminingDepth1',
          'underminingDepthLocation1A',
          'underminingDepthLocation1B',
          'underminingDepth2',
          'underminingDepthLocation2A',
          'underminingDepthLocation2B',
          'pinkred',
          'granulation',
          'nongranulated',
          'slough',
          'escharBoggy',
          'escharStable',
          'foreign',
          'underlying',
          'notVisible',
          'other',
          'woundBedCalculation',
          'exudateAmount',
          'exudateType',
          'odour',
          'woundEdge',
          'periWoundSkin',
          'woundPain',
          'packingOut',
          'packinIn',
          'treatmentComplete',
          'woundAssessmentComments'
        ],
        'hasRecHeader': true
      }
    }
  },
  "hematology": {
    "pageDataKey": "hematology",
    "pIndex": "43",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "tableCbcAnalysis_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "hematology.name"
      },
      {
        "elementKey": "tableCbcAnalysis_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableCbcAnalysis_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "hematology.profession"
      },
      {
        "elementKey": "tableCbcAnalysis_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "hematology.day"
      },
      {
        "elementKey": "tableCbcAnalysis_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "hematology.time"
      },
      {
        "elementKey": "wbc",
        "formIndex": "1",
        "inputType": "number",
        "mandatory": "TRUE",
        "suffix": "10^9/L",
        "tableColumn": "2",
        "validation": "numeric",
        "fqn": "hematology.wbc",
        "suffixText": "10^9/L"
      },
      {
        "elementKey": "wbc-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "3",
        "fqn": "hematology.wbc-interp"
      },
      {
        "elementKey": "spacer1",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer1"
      },
      {
        "elementKey": "rbc",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "10^12/L",
        "tableColumn": "4",
        "validation": "numeric",
        "fqn": "hematology.rbc",
        "suffixText": "10^12/L"
      },
      {
        "elementKey": "rbc-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "5",
        "fqn": "hematology.rbc-interp"
      },
      {
        "elementKey": "spacer2",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer2"
      },
      {
        "elementKey": "hgb",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "g/L",
        "tableColumn": "6",
        "validation": "numeric",
        "fqn": "hematology.hgb",
        "suffixText": "g/L"
      },
      {
        "elementKey": "hgb-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "7",
        "fqn": "hematology.hgb-interp"
      },
      {
        "elementKey": "spacer3",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer3"
      },
      {
        "elementKey": "HCT",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "L/L",
        "tableColumn": "8",
        "validation": "numeric",
        "fqn": "hematology.HCT",
        "suffixText": "L/L"
      },
      {
        "elementKey": "HCT-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "9",
        "fqn": "hematology.HCT-interp"
      },
      {
        "elementKey": "spacer4",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer4"
      },
      {
        "elementKey": "MCV",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "fL",
        "tableColumn": "10",
        "validation": "numeric",
        "fqn": "hematology.MCV",
        "suffixText": "fL"
      },
      {
        "elementKey": "MCV-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "11",
        "fqn": "hematology.MCV-interp"
      },
      {
        "elementKey": "spacer5",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer5"
      },
      {
        "elementKey": "MCH",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "pg",
        "tableColumn": "12",
        "validation": "numeric",
        "fqn": "hematology.MCH",
        "suffixText": "pg"
      },
      {
        "elementKey": "MCH-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "13",
        "fqn": "hematology.MCH-interp"
      },
      {
        "elementKey": "spacer6",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer6"
      },
      {
        "elementKey": "MCHC",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "g/L",
        "tableColumn": "14",
        "validation": "numeric",
        "fqn": "hematology.MCHC",
        "suffixText": "g/L"
      },
      {
        "elementKey": "MCHC-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "15",
        "fqn": "hematology.MCHC-interp"
      },
      {
        "elementKey": "spacer7",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer7"
      },
      {
        "elementKey": "RDW",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "%",
        "tableColumn": "16",
        "validation": "numeric",
        "fqn": "hematology.RDW",
        "suffixText": "%"
      },
      {
        "elementKey": "RDW-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "17",
        "fqn": "hematology.RDW-interp"
      },
      {
        "elementKey": "spacer8",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer8"
      },
      {
        "elementKey": "PLT",
        "formIndex": "1",
        "inputType": "number",
        "mandatory": "TRUE",
        "suffix": "10^9/L",
        "tableColumn": "18",
        "validation": "numeric",
        "fqn": "hematology.PLT",
        "suffixText": "10^9/L"
      },
      {
        "elementKey": "PLT-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "19",
        "fqn": "hematology.PLT-interp"
      },
      {
        "elementKey": "spacer9",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer9"
      },
      {
        "elementKey": "MPV",
        "formIndex": "1",
        "inputType": "number",
        "suffix": "fL",
        "tableColumn": "20",
        "validation": "numeric",
        "fqn": "hematology.MPV",
        "suffixText": "fL"
      },
      {
        "elementKey": "MPV-interp",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "low",
            "text": "low"
          },
          {
            "key": "normal",
            "text": "normal"
          },
          {
            "key": "high",
            "text": "high"
          }
        ],
        "tableColumn": "21",
        "fqn": "hematology.MPV-interp"
      },
      {
        "elementKey": "spacer10",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "hematology.spacer10"
      },
      {
        "elementKey": "cbcScatterPlot",
        "formIndex": "1",
        "formCss": "grid-new-subsection",
        "inputType": "ehrFile",
        "tableColumn": "22",
        "fqn": "hematology.cbcScatterPlot"
      },
      {
        "elementKey": "comments",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "textarea",
        "tableColumn": "23",
        "fqn": "hematology.comments"
      },
      {
        "elementKey": "tablePbfReview_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "hematology.name"
      },
      {
        "elementKey": "tablePbfReview_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tablePbfReview_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "hematology.profession"
      },
      {
        "elementKey": "tablePbfReview_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "hematology.day"
      },
      {
        "elementKey": "tablePbfReview_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "hematology.time"
      },
      {
        "elementKey": "bloodAnalysis",
        "formIndex": "2",
        "embedRef": "hematology.tableCbcAnalysis",
        "inputType": "ehr_embedded",
        "passToFunction": "[wbcAnalysis, pltAnalysis, cellCntSegNeutrophilAbs, cellCntBandCellsAbs, cellCntLymphAbs, cellCntMonoAbs, cellCntEosinAbs, cellCntBasoAbs, cellCntMetaAbs, cellCntMyeloAbs, cellCntPromyeloAbs, cellCntBlastAbs, cellCntReactiveLymphsAbs, cellCntOtherAbs]",
        "tableColumn": "2",
        "fqn": "hematology.bloodAnalysis"
      },
      {
        "elementKey": "actionsTaken",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "hematology.actionsTaken"
      },
      {
        "elementKey": "scanComments",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "textarea",
        "tableColumn": "4",
        "fqn": "hematology.scanComments"
      },
      {
        "elementKey": "wbc-1",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "5",
        "validation": "numeric",
        "fqn": "hematology.wbc-1"
      },
      {
        "elementKey": "wbc-2",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "6",
        "validation": "numeric",
        "fqn": "hematology.wbc-2"
      },
      {
        "elementKey": "wbc-3",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "7",
        "validation": "numeric",
        "fqn": "hematology.wbc-3"
      },
      {
        "elementKey": "wbc-4",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "8",
        "validation": "numeric",
        "fqn": "hematology.wbc-4"
      },
      {
        "elementKey": "wbc-5",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "9",
        "validation": "numeric",
        "fqn": "hematology.wbc-5"
      },
      {
        "elementKey": "wbc-6",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "10",
        "validation": "numeric",
        "fqn": "hematology.wbc-6"
      },
      {
        "elementKey": "wbc-7",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "11",
        "validation": "numeric",
        "fqn": "hematology.wbc-7"
      },
      {
        "elementKey": "wbc-8",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "12",
        "validation": "numeric",
        "fqn": "hematology.wbc-8"
      },
      {
        "elementKey": "wbc-9",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "13",
        "validation": "numeric",
        "fqn": "hematology.wbc-9"
      },
      {
        "elementKey": "wbc-10",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcAverage",
        "tableColumn": "14",
        "validation": "numeric",
        "fqn": "hematology.wbc-10"
      },
      {
        "elementKey": "wbcFieldFactor",
        "defaultValue": "1.8",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "wbcEstimate",
        "tableColumn": "15",
        "validation": "numeric",
        "fqn": "hematology.wbcFieldFactor"
      },
      {
        "elementKey": "wbcAverage",
        "calculationType": "average",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "wbcEstimate",
        "tableColumn": "16",
        "validation": "numeric",
        "fqn": "hematology.wbcAverage"
      },
      {
        "elementKey": "wbcEstimate",
        "calculationType": "product",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "17",
        "validation": "numeric",
        "fqn": "hematology.wbcEstimate"
      },
      {
        "elementKey": "wbcAnalysis",
        "calculationType": "embedValue(hematology,tableCbcAnalysis,wbc)",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "[wbcLowRange, wbcHighRange]",
        "tableColumn": "18",
        "validation": "numeric",
        "fqn": "hematology.wbcAnalysis"
      },
      {
        "elementKey": "wbcLowRange",
        "calculationType": "multiplyBy(0.75, 1)",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "19",
        "validation": "numeric",
        "fqn": "hematology.wbcLowRange"
      },
      {
        "elementKey": "wbcHighRange",
        "calculationType": "multiplyBy(1.25, 1)",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "20",
        "validation": "numeric",
        "fqn": "hematology.wbcHighRange"
      },
      {
        "elementKey": "wbcComment",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "21",
        "fqn": "hematology.wbcComment"
      },
      {
        "elementKey": "wbcCorrectedWbc",
        "formIndex": "2",
        "inputType": "number",
        "tableColumn": "22",
        "fqn": "hematology.wbcCorrectedWbc"
      },
      {
        "elementKey": "plt-1",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "22",
        "validation": "numeric",
        "fqn": "hematology.plt-1"
      },
      {
        "elementKey": "plt-2",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "23",
        "validation": "numeric",
        "fqn": "hematology.plt-2"
      },
      {
        "elementKey": "plt-3",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "24",
        "validation": "numeric",
        "fqn": "hematology.plt-3"
      },
      {
        "elementKey": "plt-4",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "25",
        "validation": "numeric",
        "fqn": "hematology.plt-4"
      },
      {
        "elementKey": "plt-5",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "26",
        "validation": "numeric",
        "fqn": "hematology.plt-5"
      },
      {
        "elementKey": "plt-6",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "27",
        "validation": "numeric",
        "fqn": "hematology.plt-6"
      },
      {
        "elementKey": "plt-7",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "28",
        "validation": "numeric",
        "fqn": "hematology.plt-7"
      },
      {
        "elementKey": "plt-8",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "29",
        "validation": "numeric",
        "fqn": "hematology.plt-8"
      },
      {
        "elementKey": "plt-9",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "30",
        "validation": "numeric",
        "fqn": "hematology.plt-9"
      },
      {
        "elementKey": "plt-10",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltAverage",
        "tableColumn": "31",
        "validation": "numeric",
        "fqn": "hematology.plt-10"
      },
      {
        "elementKey": "pltFieldFactor",
        "defaultValue": "11.1",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "pltEstimate",
        "tableColumn": "32",
        "validation": "numeric",
        "fqn": "hematology.pltFieldFactor"
      },
      {
        "elementKey": "pltAverage",
        "calculationType": "average",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "pltEstimate",
        "tableColumn": "33",
        "validation": "numeric",
        "fqn": "hematology.pltAverage"
      },
      {
        "elementKey": "pltEstimate",
        "calculationType": "product",
        "decimals": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "34",
        "validation": "numeric",
        "fqn": "hematology.pltEstimate"
      },
      {
        "elementKey": "pltAnalysis",
        "calculationType": "embedValue(hematology,tableCbcAnalysis,PLT)",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "[pltLowRange, pltHighRange]",
        "tableColumn": "35",
        "validation": "numeric",
        "fqn": "hematology.pltAnalysis"
      },
      {
        "elementKey": "pltLowRange",
        "calculationType": "multiplyBy(0.75, 0)",
        "decimals": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "36",
        "validation": "numeric",
        "fqn": "hematology.pltLowRange"
      },
      {
        "elementKey": "pltHighRange",
        "calculationType": "multiplyBy(1.25, 0)",
        "decimals": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "37",
        "validation": "numeric",
        "fqn": "hematology.pltHighRange"
      },
      {
        "elementKey": "pltComment",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "38",
        "fqn": "hematology.pltComment"
      },
      {
        "elementKey": "pltNormalMorphology",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "39",
        "fqn": "hematology.pltNormalMorphology"
      },
      {
        "elementKey": "pltMorphology",
        "formIndex": "2",
        "formCss": "form_label_short",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "Hypogranular",
            "text": "Hypogranular"
          },
          {
            "key": "Large or Giant Forms",
            "text": "Large or Giant Forms"
          },
          {
            "key": "Megakaryocytic Fragments",
            "text": "Megakaryocytic Fragments"
          },
          {
            "key": "Platelet Clumps",
            "text": "Platelet Clumps"
          },
          {
            "key": "Platelet Satellitism",
            "text": "Platelet Satellitism"
          },
          {
            "key": "Dysplastic",
            "text": "Dysplastic"
          }
        ],
        "tableColumn": "40",
        "fqn": "hematology.pltMorphology"
      },
      {
        "elementKey": "label43-7-1",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "validation": "numeric",
        "fqn": "hematology.label43-7-1"
      },
      {
        "elementKey": "label43-7-2",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "validation": "numeric",
        "fqn": "hematology.label43-7-2"
      },
      {
        "elementKey": "label43-7-3",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "validation": "numeric",
        "fqn": "hematology.label43-7-3"
      },
      {
        "elementKey": "label43-7-4",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-4"
      },
      {
        "elementKey": "cellCntSegNeutrophil",
        "formIndex": "2",
        "inputType": "number",
        "mandatory": "TRUE",
        "passToFunction": "[ cellCntTotal cellCntSegNeutrophilAbs]",
        "tableColumn": "41",
        "validation": "numeric",
        "fqn": "hematology.cellCntSegNeutrophil"
      },
      {
        "elementKey": "cellCntSegNeutrophilAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "42",
        "validation": "numeric",
        "fqn": "hematology.cellCntSegNeutrophilAbs"
      },
      {
        "elementKey": "label43-7-5",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-5"
      },
      {
        "elementKey": "cellCntBandCells",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntBandCellsAbs ]",
        "tableColumn": "43",
        "validation": "numeric",
        "fqn": "hematology.cellCntBandCells"
      },
      {
        "elementKey": "cellCntBandCellsAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "44",
        "validation": "numeric",
        "fqn": "hematology.cellCntBandCellsAbs"
      },
      {
        "elementKey": "label43-7-6",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-6"
      },
      {
        "elementKey": "cellCntLymph",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntLymphAbs ]",
        "tableColumn": "45",
        "validation": "numeric",
        "fqn": "hematology.cellCntLymph"
      },
      {
        "elementKey": "cellCntLymphAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "46",
        "validation": "numeric",
        "fqn": "hematology.cellCntLymphAbs"
      },
      {
        "elementKey": "label43-7-7",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-7"
      },
      {
        "elementKey": "cellCntMono",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntMonoAbs ]",
        "tableColumn": "47",
        "validation": "numeric",
        "fqn": "hematology.cellCntMono"
      },
      {
        "elementKey": "cellCntMonoAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "48",
        "validation": "numeric",
        "fqn": "hematology.cellCntMonoAbs"
      },
      {
        "elementKey": "label43-7-8",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-8"
      },
      {
        "elementKey": "cellCntEosin",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntEosinAbs ]",
        "tableColumn": "49",
        "validation": "numeric",
        "fqn": "hematology.cellCntEosin"
      },
      {
        "elementKey": "cellCntEosinAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "50",
        "validation": "numeric",
        "fqn": "hematology.cellCntEosinAbs"
      },
      {
        "elementKey": "label43-7-9",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-9"
      },
      {
        "elementKey": "cellCntBaso",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntBasoAbs ]",
        "tableColumn": "51",
        "validation": "numeric",
        "fqn": "hematology.cellCntBaso"
      },
      {
        "elementKey": "cellCntBasoAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "52",
        "validation": "numeric",
        "fqn": "hematology.cellCntBasoAbs"
      },
      {
        "elementKey": "label43-7-10",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-10"
      },
      {
        "elementKey": "cellCntMeta",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntMetaAbs ]",
        "tableColumn": "53",
        "validation": "numeric",
        "fqn": "hematology.cellCntMeta"
      },
      {
        "elementKey": "cellCntMetaAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "54",
        "validation": "numeric",
        "fqn": "hematology.cellCntMetaAbs"
      },
      {
        "elementKey": "label43-7-11",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-11"
      },
      {
        "elementKey": "cellCntMyelo",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntMyeloAbs ]",
        "tableColumn": "55",
        "validation": "numeric",
        "fqn": "hematology.cellCntMyelo"
      },
      {
        "elementKey": "cellCntMyeloAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "56",
        "validation": "numeric",
        "fqn": "hematology.cellCntMyeloAbs"
      },
      {
        "elementKey": "label43-7-12",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-12"
      },
      {
        "elementKey": "cellCntPromyelo",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntPromyeloAbs ]",
        "tableColumn": "57",
        "validation": "numeric",
        "fqn": "hematology.cellCntPromyelo"
      },
      {
        "elementKey": "cellCntPromyeloAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "58",
        "validation": "numeric",
        "fqn": "hematology.cellCntPromyeloAbs"
      },
      {
        "elementKey": "label43-7-13",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-13"
      },
      {
        "elementKey": "cellCntBlast",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntBlastAbs ]",
        "tableColumn": "59",
        "validation": "numeric",
        "fqn": "hematology.cellCntBlast"
      },
      {
        "elementKey": "cellCntBlastAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "defaultValue": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "60",
        "validation": "numeric",
        "fqn": "hematology.cellCntBlastAbs"
      },
      {
        "elementKey": "label43-7-15",
        "formIndex": "2",
        "inputType": "formTableText",
        "validation": "numeric",
        "fqn": "hematology.label43-7-15"
      },
      {
        "elementKey": "cellCntOther",
        "formIndex": "2",
        "inputType": "number",
        "passToFunction": "[ cellCntTotal cellCntOtherAbs ]",
        "tableColumn": "61",
        "validation": "numeric",
        "fqn": "hematology.cellCntOther"
      },
      {
        "elementKey": "cellCntOtherAbs",
        "calculationType": "wbcAbs",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "passToFunction": "cellCntAbsTotal",
        "tableColumn": "62",
        "validation": "numeric",
        "fqn": "hematology.cellCntOtherAbs"
      },
      {
        "elementKey": "cellCntOtherSpecify",
        "formIndex": "2",
        "formCss": "form-table-span-2",
        "inputType": "text",
        "tableColumn": "63",
        "fqn": "hematology.cellCntOtherSpecify"
      },
      {
        "elementKey": "spacer11",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "hematology.spacer11"
      },
      {
        "elementKey": "label43-7-16",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "hematology.label43-7-16"
      },
      {
        "elementKey": "cellCntTotal",
        "calculationType": "sum",
        "decimals": "0",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "64",
        "fqn": "hematology.cellCntTotal"
      },
      {
        "elementKey": "cellCntAbsTotal",
        "calculationType": "sum",
        "decimals": "1",
        "formIndex": "2",
        "inputType": "calculatedValue",
        "tableColumn": "65",
        "fqn": "hematology.cellCntAbsTotal"
      },
      {
        "elementKey": "cellCntNRBCs",
        "formIndex": "2",
        "formCss": "field_full_line input-element-small",
        "inputType": "number",
        "tableColumn": "66",
        "fqn": "hematology.cellCntNRBCs"
      },
      {
        "elementKey": "cellCntComment",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "67",
        "fqn": "hematology.cellCntComment"
      },
      {
        "elementKey": "wbcmNormalMorphology",
        "formIndex": "2",
        "formCss": "wbcmNormalMorphology",
        "inputType": "checkbox",
        "tableColumn": "68",
        "fqn": "hematology.wbcmNormalMorphology"
      },
      {
        "elementKey": "wbcmAbnormalMorphology",
        "formIndex": "2",
        "formCss": "wbcmAbnormalMorphology",
        "inputType": "checkbox",
        "tableColumn": "69",
        "fqn": "hematology.wbcmAbnormalMorphology"
      },
      {
        "elementKey": "wbcmAbnormalTypeMorphology",
        "formIndex": "2",
        "formCss": "wbcmAbnormalTypeMorphology",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "Immature Cells",
            "text": "Immature Cells"
          },
          {
            "key": "Hypersegmented Neutrophils",
            "text": "Hypersegmented Neutrophils"
          },
          {
            "key": "Dysplastic Cells",
            "text": "Dysplastic Cells"
          },
          {
            "key": "Pelger-Huët Cells",
            "text": "Pelger-Huët Cells"
          },
          {
            "key": "Auer Rods",
            "text": "Auer Rods"
          },
          {
            "key": "Smudge Cells",
            "text": "Smudge Cells"
          },
          {
            "key": "Reactive Lymphocytes",
            "text": "Reactive Lymphocytes"
          },
          {
            "key": "Disintegrated Cells",
            "text": "Disintegrated Cells"
          }
        ],
        "tableColumn": "70",
        "fqn": "hematology.wbcmAbnormalTypeMorphology"
      },
      {
        "elementKey": "wbcmToxicTypeMorphology",
        "formIndex": "2",
        "formCss": "wbcmToxicTypeMorphology",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "Döhle Bodies",
            "text": "Döhle Bodies"
          },
          {
            "key": "Toxic Granulation",
            "text": "Toxic Granulation"
          },
          {
            "key": "Toxic Vacuolation",
            "text": "Toxic Vacuolation"
          }
        ],
        "tableColumn": "71",
        "fqn": "hematology.wbcmToxicTypeMorphology"
      },
      {
        "elementKey": "rcmNormal",
        "formIndex": "2",
        "formCss": "rcm-one-part",
        "inputType": "checkbox",
        "tableColumn": "72",
        "fqn": "hematology.rcmNormal"
      },
      {
        "elementKey": "rcmConsistent",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "73",
        "fqn": "hematology.rcmConsistent"
      },
      {
        "elementKey": "rcmMcvMchc",
        "formIndex": "2",
        "formCss": "rcm-two-part-2 rcmMcvMchc",
        "inputType": "text",
        "tableColumn": "74",
        "fqn": "hematology.rcmMcvMchc"
      },
      {
        "elementKey": "rcmAnisocytosis",
        "formIndex": "2",
        "formCss": "rcm-one-part-indented",
        "inputType": "checkbox",
        "tableColumn": "75",
        "fqn": "hematology.rcmAnisocytosis"
      },
      {
        "elementKey": "rcmPolychromasia",
        "formIndex": "2",
        "formCss": "rcm-one-part-indented",
        "inputType": "checkbox",
        "tableColumn": "76",
        "fqn": "hematology.rcmPolychromasia"
      },
      {
        "elementKey": "rcmRouleaux",
        "formIndex": "2",
        "formCss": "rcm-one-part-indented",
        "inputType": "checkbox",
        "tableColumn": "77",
        "fqn": "hematology.rcmRouleaux"
      },
      {
        "elementKey": "rcmAcanthocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "78",
        "fqn": "hematology.rcmAcanthocytes"
      },
      {
        "elementKey": "rcmAcanthocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "79",
        "fqn": "hematology.rcmAcanthocytesGrading"
      },
      {
        "elementKey": "rcmCodocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "80",
        "fqn": "hematology.rcmCodocytes"
      },
      {
        "elementKey": "rcmCodocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "81",
        "fqn": "hematology.rcmCodocytesGrading"
      },
      {
        "elementKey": "rcmDacryocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "82",
        "fqn": "hematology.rcmDacryocytes"
      },
      {
        "elementKey": "rcmDacryocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "83",
        "fqn": "hematology.rcmDacryocytesGrading"
      },
      {
        "elementKey": "rcmDrepanocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "84",
        "fqn": "hematology.rcmDrepanocytes"
      },
      {
        "elementKey": "rcmDrepanocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "85",
        "fqn": "hematology.rcmDrepanocytesGrading"
      },
      {
        "elementKey": "rcmEchinocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "86",
        "fqn": "hematology.rcmEchinocytes"
      },
      {
        "elementKey": "rcmEchinocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "87",
        "fqn": "hematology.rcmEchinocytesGrading"
      },
      {
        "elementKey": "rcmOvalocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "88",
        "fqn": "hematology.rcmOvalocytes"
      },
      {
        "elementKey": "rcmOvalocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "89",
        "fqn": "hematology.rcmOvalocytesGrading"
      },
      {
        "elementKey": "rcmMacroOvalocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "90",
        "fqn": "hematology.rcmMacroOvalocytes"
      },
      {
        "elementKey": "rcmMacroOvalocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "91",
        "fqn": "hematology.rcmMacroOvalocytesGrading"
      },
      {
        "elementKey": "rcmStomatocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "92",
        "fqn": "hematology.rcmStomatocytes"
      },
      {
        "elementKey": "rcmStomatocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "93",
        "fqn": "hematology.rcmStomatocytesGrading"
      },
      {
        "elementKey": "rcmSpherocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "94",
        "fqn": "hematology.rcmSpherocytes"
      },
      {
        "elementKey": "rcmSpherocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "95",
        "fqn": "hematology.rcmSpherocytesGrading"
      },
      {
        "elementKey": "rcmICC",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "96",
        "fqn": "hematology.rcmICC"
      },
      {
        "elementKey": "rcmICCGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "97",
        "fqn": "hematology.rcmICCGrading"
      },
      {
        "elementKey": "rcmICCSchistocytes",
        "formIndex": "2",
        "formCss": "rcm-two-part-1",
        "inputType": "checkbox",
        "tableColumn": "98",
        "fqn": "hematology.rcmICCSchistocytes"
      },
      {
        "elementKey": "rcmICCSchistocytesGrading",
        "formIndex": "2",
        "formCss": "rcm-two-part-2",
        "inputType": "select",
        "options": [
          {
            "key": "1+",
            "text": "1+"
          },
          {
            "key": "2+",
            "text": "2+"
          },
          {
            "key": "3+",
            "text": "3+"
          },
          {
            "key": "4+",
            "text": "4+"
          }
        ],
        "tableColumn": "99",
        "fqn": "hematology.rcmICCSchistocytesGrading"
      },
      {
        "elementKey": "rcmBasophilicStippling",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "100",
        "fqn": "hematology.rcmBasophilicStippling"
      },
      {
        "elementKey": "rcmHowellJollyBodies",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "101",
        "fqn": "hematology.rcmHowellJollyBodies"
      },
      {
        "elementKey": "rcmPappenheimerBodies",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "102",
        "fqn": "hematology.rcmPappenheimerBodies"
      },
      {
        "elementKey": "rcmMalariaType",
        "formIndex": "2",
        "formCss": "form_label_short, rcmMalariaType",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "Gametocyte",
            "text": "Gametocyte"
          },
          {
            "key": "Ring Form/Trophozoite",
            "text": "Ring Form/Trophozoite"
          },
          {
            "key": "Schizont",
            "text": "Schizont"
          }
        ],
        "tableColumn": "103",
        "fqn": "hematology.rcmMalariaType"
      },
      {
        "elementKey": "rbcComments",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "104",
        "fqn": "hematology.rbcComments"
      },
      {
        "elementKey": "referralToHema",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "105",
        "fqn": "hematology.referralToHema"
      },
      {
        "elementKey": "explain",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "106",
        "fqn": "hematology.explain"
      }
    ],
    "pageElements": {
      "tableCbcAnalysis": {
        "elementKey": "tableCbcAnalysis",
        "pageElementIndex": "1",
        "pageKey": "hematology",
        "tableKey": "tableCbcAnalysis",
        "isTable": true,
        "tableAction": "hematology.tablePbfReview",
        "form": {
          "elementKey": "tableCbcAnalysis",
          "label": "CBC analysis",
          "addButtonText": "Add a CBC Report",
          "formKey": "tableCbcAnalysis",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "hematology.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableCbcAnalysis_name",
                "tableCbcAnalysis_profession",
                "tableCbcAnalysis_day",
                "tableCbcAnalysis_time"
              ]
            },
            {
              "elementKey": "cGroup43-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "hematology.cGroup43-2",
              "gIndex": "2",
              "gChildren": [
                "wbc",
                "wbc-interp",
                "spacer1",
                "rbc",
                "rbc-interp",
                "spacer2",
                "hgb",
                "hgb-interp",
                "spacer3",
                "HCT",
                "HCT-interp",
                "spacer4",
                "MCV",
                "MCV-interp",
                "spacer5",
                "MCH",
                "MCH-interp",
                "spacer6",
                "MCHC",
                "MCHC-interp",
                "spacer7",
                "RDW",
                "RDW-interp",
                "spacer8",
                "PLT",
                "PLT-interp",
                "spacer9",
                "MPV",
                "MPV-interp",
                "spacer10"
              ]
            },
            {
              "elementKey": "cGroup43-3",
              "formCss": "grid-left-to-right-3",
              "fqn": "hematology.cGroup43-3",
              "gIndex": "3",
              "gChildren": [
                "cbcScatterPlot",
                "comments"
              ]
            }
          ],
          "ehr_data": {
            "tableCbcAnalysis_name": "",
            "tableCbcAnalysis_profession": "",
            "tableCbcAnalysis_day": "",
            "tableCbcAnalysis_time": "",
            "wbc": "",
            "wbc-interp": "",
            "rbc": "",
            "rbc-interp": "",
            "hgb": "",
            "hgb-interp": "",
            "HCT": "",
            "HCT-interp": "",
            "MCV": "",
            "MCV-interp": "",
            "MCH": "",
            "MCH-interp": "",
            "MCHC": "",
            "MCHC-interp": "",
            "RDW": "",
            "RDW-interp": "",
            "PLT": "",
            "PLT-interp": "",
            "MPV": "",
            "MPV-interp": "",
            "cbcScatterPlot": "",
            "comments": ""
          }
        },
        "fqn": "hematology.tableCbcAnalysis",
        "tableActionType": "openDialog",
        "taTargetPageKey": "hematology",
        "taTargetTableKey": "tablePbfReview",
        "taSourcePageKey": "hematology",
        "taSourceTableKey": "tableCbcAnalysis",
        "tableChildren": [
          "tableCbcAnalysis_id",
          "tableCbcAnalysis_day",
          "tableCbcAnalysis_time",
          "wbc",
          "wbc-interp",
          "rbc",
          "rbc-interp",
          "hgb",
          "hgb-interp",
          "HCT",
          "HCT-interp",
          "MCV",
          "MCV-interp",
          "MCH",
          "MCH-interp",
          "MCHC",
          "MCHC-interp",
          "RDW",
          "RDW-interp",
          "PLT",
          "PLT-interp",
          "MPV",
          "MPV-interp",
          "cbcScatterPlot",
          "comments",
          "tableCbcAnalysis_name",
          "tableCbcAnalysis_profession"
        ],
        "hasRecHeader": true
      },
      "tablePbfReview": {
        "elementKey": "tablePbfReview",
        "pageElementIndex": "2",
        "pageKey": "hematology",
        "tableKey": "tablePbfReview",
        "isTable": true,
        "form": {
          "elementKey": "tablePbfReview",
          "label": "PBF Review",
          "addButtonText": "NONE",
          "formKey": "tablePbfReview",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "hematology.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tablePbfReview_name",
                "tablePbfReview_profession",
                "tablePbfReview_day",
                "tablePbfReview_time"
              ]
            },
            {
              "elementKey": "cGroup43-2",
              "formCss": "grid-left-to-right-1",
              "fqn": "hematology.cGroup43-2",
              "gIndex": "2",
              "gChildren": [
                "bloodAnalysis"
              ]
            },
            {
              "elementKey": "cGroup43-3",
              "formCss": "grid-left-to-right-1",
              "fqn": "hematology.cGroup43-3",
              "gIndex": "3",
              "gChildren": [
                "actionsTaken",
                "scanComments"
              ]
            },
            {
              "elementKey": "cGroup43-4",
              "formCss": "grid-left-to-right-4",
              "fqn": "hematology.cGroup43-4",
              "gIndex": "4",
              "gChildren": [
                "wbc-1",
                "wbc-2",
                "wbc-3",
                "wbc-4",
                "wbc-5",
                "wbc-6",
                "wbc-7",
                "wbc-8",
                "wbc-9",
                "wbc-10",
                "wbcFieldFactor",
                "wbcAverage",
                "wbcEstimate",
                "wbcAnalysis",
                "wbcLowRange",
                "wbcHighRange",
                "wbcComment",
                "wbcCorrectedWbc"
              ]
            },
            {
              "elementKey": "cGroup43-5",
              "formCss": "grid-left-to-right-4",
              "fqn": "hematology.cGroup43-5",
              "gIndex": "5",
              "gChildren": [
                "plt-1",
                "plt-2",
                "plt-3",
                "plt-4",
                "plt-5",
                "plt-6",
                "plt-7",
                "plt-8",
                "plt-9",
                "plt-10",
                "pltFieldFactor",
                "pltAverage",
                "pltEstimate",
                "pltAnalysis",
                "pltLowRange",
                "pltHighRange",
                "pltComment"
              ]
            },
            {
              "elementKey": "cGroup43-6",
              "formCss": "grid-left-to-right-3",
              "fqn": "hematology.cGroup43-6",
              "gIndex": "6",
              "gChildren": [
                "pltNormalMorphology",
                "pltMorphology"
              ]
            },
            {
              "elementKey": "cGroup43-7",
              "formCss": "form-table-group wbc-differential-group",
              "fqn": "hematology.cGroup43-7",
              "gIndex": "7",
              "gChildren": [
                "label43-7-1",
                "label43-7-2",
                "label43-7-3",
                "label43-7-4",
                "cellCntSegNeutrophil",
                "cellCntSegNeutrophilAbs",
                "label43-7-5",
                "cellCntBandCells",
                "cellCntBandCellsAbs",
                "label43-7-6",
                "cellCntLymph",
                "cellCntLymphAbs",
                "label43-7-7",
                "cellCntMono",
                "cellCntMonoAbs",
                "label43-7-8",
                "cellCntEosin",
                "cellCntEosinAbs",
                "label43-7-9",
                "cellCntBaso",
                "cellCntBasoAbs",
                "label43-7-10",
                "cellCntMeta",
                "cellCntMetaAbs",
                "label43-7-11",
                "cellCntMyelo",
                "cellCntMyeloAbs",
                "label43-7-12",
                "cellCntPromyelo",
                "cellCntPromyeloAbs",
                "label43-7-13",
                "cellCntBlast",
                "cellCntBlastAbs",
                "label43-7-15",
                "cellCntOther",
                "cellCntOtherAbs",
                "cellCntOtherSpecify",
                "spacer11",
                "label43-7-16",
                "cellCntTotal",
                "cellCntAbsTotal"
              ]
            },
            {
              "elementKey": "cGroup43-8",
              "formCss": "grid-left-to-right-1",
              "fqn": "hematology.cGroup43-8",
              "gIndex": "8",
              "gChildren": [
                "cellCntNRBCs",
                "cellCntComment"
              ]
            },
            {
              "elementKey": "cGroup43-9",
              "formCss": "grid-left-to-right-custom wbcMorphologyGroup",
              "fqn": "hematology.cGroup43-9",
              "gIndex": "9",
              "gChildren": [
                "wbcmNormalMorphology",
                "wbcmAbnormalMorphology",
                "wbcmAbnormalTypeMorphology",
                "wbcmToxicTypeMorphology"
              ]
            },
            {
              "elementKey": "cGroup43-10",
              "formCss": "rcm-morphology-group",
              "fqn": "hematology.cGroup43-10",
              "gIndex": "10",
              "gChildren": [
                "rcmNormal",
                "rcmConsistent",
                "rcmMcvMchc",
                "rcmAnisocytosis",
                "rcmPolychromasia",
                "rcmRouleaux",
                "rcmAcanthocytes",
                "rcmAcanthocytesGrading",
                "rcmCodocytes",
                "rcmCodocytesGrading",
                "rcmDacryocytes",
                "rcmDacryocytesGrading",
                "rcmDrepanocytes",
                "rcmDrepanocytesGrading",
                "rcmEchinocytes",
                "rcmEchinocytesGrading",
                "rcmOvalocytes",
                "rcmOvalocytesGrading",
                "rcmMacroOvalocytes",
                "rcmMacroOvalocytesGrading",
                "rcmStomatocytes",
                "rcmStomatocytesGrading",
                "rcmSpherocytes",
                "rcmSpherocytesGrading",
                "rcmICC",
                "rcmICCGrading",
                "rcmICCSchistocytes",
                "rcmICCSchistocytesGrading"
              ]
            },
            {
              "elementKey": "cGroup43-11",
              "formCss": "grid-left-to-right-3",
              "fqn": "hematology.cGroup43-11",
              "gIndex": "11",
              "gChildren": [
                "rcmBasophilicStippling",
                "rcmHowellJollyBodies",
                "rcmPappenheimerBodies",
                "rcmMalariaType"
              ]
            },
            {
              "elementKey": "cGroup43-12",
              "formCss": "grid-left-to-right-1",
              "fqn": "hematology.cGroup43-12",
              "gIndex": "12",
              "gChildren": [
                "rbcComments"
              ]
            },
            {
              "elementKey": "cGroup43-13",
              "formCss": "grid-left-to-right-1",
              "fqn": "hematology.cGroup43-13",
              "gIndex": "13",
              "gChildren": [
                "referralToHema",
                "explain"
              ]
            }
          ],
          "ehr_data": {
            "tablePbfReview_name": "",
            "tablePbfReview_profession": "",
            "tablePbfReview_day": "",
            "tablePbfReview_time": "",
            "bloodAnalysis": "",
            "actionsTaken": "",
            "scanComments": "",
            "wbc-1": "",
            "wbc-2": "",
            "wbc-3": "",
            "wbc-4": "",
            "wbc-5": "",
            "wbc-6": "",
            "wbc-7": "",
            "wbc-8": "",
            "wbc-9": "",
            "wbc-10": "",
            "wbcFieldFactor": "1.8",
            "wbcAverage": "",
            "wbcEstimate": "",
            "wbcAnalysis": "",
            "wbcLowRange": "",
            "wbcHighRange": "",
            "wbcComment": "",
            "wbcCorrectedWbc": "",
            "plt-1": "",
            "plt-2": "",
            "plt-3": "",
            "plt-4": "",
            "plt-5": "",
            "plt-6": "",
            "plt-7": "",
            "plt-8": "",
            "plt-9": "",
            "plt-10": "",
            "pltFieldFactor": "11.1",
            "pltAverage": "",
            "pltEstimate": "",
            "pltAnalysis": "",
            "pltLowRange": "",
            "pltHighRange": "",
            "pltComment": "",
            "pltNormalMorphology": "",
            "pltMorphology": "",
            "cellCntSegNeutrophil": "",
            "cellCntSegNeutrophilAbs": "",
            "cellCntBandCells": "",
            "cellCntBandCellsAbs": "",
            "cellCntLymph": "",
            "cellCntLymphAbs": "",
            "cellCntMono": "",
            "cellCntMonoAbs": "",
            "cellCntEosin": "",
            "cellCntEosinAbs": "",
            "cellCntBaso": "",
            "cellCntBasoAbs": "",
            "cellCntMeta": "0",
            "cellCntMetaAbs": "0",
            "cellCntMyelo": "0",
            "cellCntMyeloAbs": "0",
            "cellCntPromyelo": "0",
            "cellCntPromyeloAbs": "0",
            "cellCntBlast": "0",
            "cellCntBlastAbs": "0",
            "cellCntOther": "",
            "cellCntOtherAbs": "",
            "cellCntOtherSpecify": "",
            "cellCntTotal": "",
            "cellCntAbsTotal": "",
            "cellCntNRBCs": "",
            "cellCntComment": "",
            "wbcmNormalMorphology": "",
            "wbcmAbnormalMorphology": "",
            "wbcmAbnormalTypeMorphology": "",
            "wbcmToxicTypeMorphology": "",
            "rcmNormal": "",
            "rcmConsistent": "",
            "rcmMcvMchc": "",
            "rcmAnisocytosis": "",
            "rcmPolychromasia": "",
            "rcmRouleaux": "",
            "rcmAcanthocytes": "",
            "rcmAcanthocytesGrading": "",
            "rcmCodocytes": "",
            "rcmCodocytesGrading": "",
            "rcmDacryocytes": "",
            "rcmDacryocytesGrading": "",
            "rcmDrepanocytes": "",
            "rcmDrepanocytesGrading": "",
            "rcmEchinocytes": "",
            "rcmEchinocytesGrading": "",
            "rcmOvalocytes": "",
            "rcmOvalocytesGrading": "",
            "rcmMacroOvalocytes": "",
            "rcmMacroOvalocytesGrading": "",
            "rcmStomatocytes": "",
            "rcmStomatocytesGrading": "",
            "rcmSpherocytes": "",
            "rcmSpherocytesGrading": "",
            "rcmICC": "",
            "rcmICCGrading": "",
            "rcmICCSchistocytes": "",
            "rcmICCSchistocytesGrading": "",
            "rcmBasophilicStippling": "",
            "rcmHowellJollyBodies": "",
            "rcmPappenheimerBodies": "",
            "rcmMalariaType": "",
            "rbcComments": "",
            "referralToHema": "",
            "explain": ""
          }
        },
        "fqn": "hematology.tablePbfReview",
        "tableChildren": [
          "tablePbfReview_id",
          "tablePbfReview_day",
          "tablePbfReview_time",
          "bloodAnalysis",
          "actionsTaken",
          "scanComments",
          "wbc-1",
          "wbc-2",
          "wbc-3",
          "wbc-4",
          "wbc-5",
          "wbc-6",
          "wbc-7",
          "wbc-8",
          "wbc-9",
          "wbc-10",
          "wbcFieldFactor",
          "wbcAverage",
          "wbcEstimate",
          "wbcAnalysis",
          "wbcLowRange",
          "wbcHighRange",
          "wbcComment",
          "wbcCorrectedWbc",
          "plt-1",
          "plt-2",
          "plt-3",
          "plt-4",
          "plt-5",
          "plt-6",
          "plt-7",
          "plt-8",
          "plt-9",
          "plt-10",
          "pltFieldFactor",
          "pltAverage",
          "pltEstimate",
          "pltAnalysis",
          "pltLowRange",
          "pltHighRange",
          "pltComment",
          "pltNormalMorphology",
          "pltMorphology",
          "cellCntSegNeutrophil",
          "cellCntSegNeutrophilAbs",
          "cellCntBandCells",
          "cellCntBandCellsAbs",
          "cellCntLymph",
          "cellCntLymphAbs",
          "cellCntMono",
          "cellCntMonoAbs",
          "cellCntEosin",
          "cellCntEosinAbs",
          "cellCntBaso",
          "cellCntBasoAbs",
          "cellCntMeta",
          "cellCntMetaAbs",
          "cellCntMyelo",
          "cellCntMyeloAbs",
          "cellCntPromyelo",
          "cellCntPromyeloAbs",
          "cellCntBlast",
          "cellCntBlastAbs",
          "cellCntOther",
          "cellCntOtherAbs",
          "cellCntOtherSpecify",
          "cellCntTotal",
          "cellCntAbsTotal",
          "cellCntNRBCs",
          "cellCntComment",
          "wbcmNormalMorphology",
          "wbcmAbnormalMorphology",
          "wbcmAbnormalTypeMorphology",
          "wbcmToxicTypeMorphology",
          "rcmNormal",
          "rcmConsistent",
          "rcmMcvMchc",
          "rcmAnisocytosis",
          "rcmPolychromasia",
          "rcmRouleaux",
          "rcmAcanthocytes",
          "rcmAcanthocytesGrading",
          "rcmCodocytes",
          "rcmCodocytesGrading",
          "rcmDacryocytes",
          "rcmDacryocytesGrading",
          "rcmDrepanocytes",
          "rcmDrepanocytesGrading",
          "rcmEchinocytes",
          "rcmEchinocytesGrading",
          "rcmOvalocytes",
          "rcmOvalocytesGrading",
          "rcmMacroOvalocytes",
          "rcmMacroOvalocytesGrading",
          "rcmStomatocytes",
          "rcmStomatocytesGrading",
          "rcmSpherocytes",
          "rcmSpherocytesGrading",
          "rcmICC",
          "rcmICCGrading",
          "rcmICCSchistocytes",
          "rcmICCSchistocytesGrading",
          "rcmBasophilicStippling",
          "rcmHowellJollyBodies",
          "rcmPappenheimerBodies",
          "rcmMalariaType",
          "rbcComments",
          "referralToHema",
          "explain",
          "tablePbfReview_name",
          "tablePbfReview_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "dishargeplan": {
    "pageDataKey": "dishargeplan",
    "pIndex": "44",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "table_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "dishargeplan.name"
      },
      {
        "elementKey": "table_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "table_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "dishargeplan.profession"
      },
      {
        "elementKey": "table_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "dishargeplan.day"
      },
      {
        "elementKey": "table_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "dishargeplan.time"
      },
      {
        "elementKey": "goal",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "dishargeplan.goal"
      },
      {
        "elementKey": "interventions",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "3",
        "fqn": "dishargeplan.interventions"
      },
      {
        "elementKey": "evaluation",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "4",
        "fqn": "dishargeplan.evaluation"
      },
      {
        "elementKey": "status",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "进行中",
            "text": "进行中"
          },
          {
            "key": "已完成",
            "text": "已完成"
          }
        ],
        "tableColumn": "5",
        "fqn": "dishargeplan.status"
      }
    ],
    "pageElements": {
      "table": {
        "elementKey": "table",
        "pageElementIndex": "1",
        "pageKey": "dishargeplan",
        "tableKey": "table",
        "isTable": true,
        "form": {
          "elementKey": "table",
          "label": "Discharge plan",
          "addButtonText": "Add a discharge planning note",
          "formKey": "table",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "dishargeplan.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "table_name",
                "table_profession",
                "table_day",
                "table_time"
              ]
            },
            {
              "elementKey": "cGroup44-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "dishargeplan.cGroup44-2",
              "gIndex": "2",
              "gChildren": [
                "goal",
                "interventions",
                "evaluation",
                "status"
              ]
            }
          ],
          "ehr_data": {
            "table_name": "",
            "table_profession": "",
            "table_day": "",
            "table_time": "",
            "goal": "",
            "interventions": "",
            "evaluation": "",
            "status": ""
          }
        },
        "fqn": "dishargeplan.table",
        "tableChildren": [
          "table_id",
          "table_day",
          "table_time",
          "goal",
          "interventions",
          "evaluation",
          "status",
          "table_name",
          "table_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medLabChemistry": {
    "pageDataKey": "medLabChemistry",
    "pIndex": "45",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "tableQcChemistry_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabChemistry.name"
      },
      {
        "elementKey": "tableQcChemistry_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableQcChemistry_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabChemistry.profession"
      },
      {
        "elementKey": "tableQcChemistry_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabChemistry.day"
      },
      {
        "elementKey": "tableQcChemistry_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabChemistry.time"
      },
      {
        "elementKey": "label55-11",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-11"
      },
      {
        "elementKey": "label55-12",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-12"
      },
      {
        "elementKey": "chemQcAllAcceptable",
        "formIndex": "1",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "89",
        "fqn": "medLabChemistry.chemQcAllAcceptable"
      },
      {
        "elementKey": "label55-13",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-13"
      },
      {
        "elementKey": "chemQcAllDuplicatesAcceptable",
        "formIndex": "1",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "90",
        "fqn": "medLabChemistry.chemQcAllDuplicatesAcceptable"
      },
      {
        "elementKey": "label55-14",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-14"
      },
      {
        "elementKey": "chemQcAllPlausible",
        "formIndex": "1",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "91",
        "fqn": "medLabChemistry.chemQcAllPlausible"
      },
      {
        "elementKey": "label55-15",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-15"
      },
      {
        "elementKey": "chemQcAllCanBeReported",
        "formIndex": "1",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "92",
        "fqn": "medLabChemistry.chemQcAllCanBeReported"
      },
      {
        "elementKey": "label55-16",
        "formIndex": "1",
        "formCss": "grid-new-subsection grid-span-2-columns no-border",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-16"
      },
      {
        "elementKey": "label55-17",
        "formIndex": "1",
        "formCss": "grid-new-subsection grid-span-2-columns no-border",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-17"
      },
      {
        "elementKey": "label55-18",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label55-18"
      },
      {
        "elementKey": "label55-19",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label55-19"
      },
      {
        "elementKey": "label55-20",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label55-20"
      },
      {
        "elementKey": "label55-21",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label55-21"
      },
      {
        "elementKey": "label55-22",
        "formIndex": "1",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-22"
      },
      {
        "elementKey": "chmQcAnalyte1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "93",
        "fqn": "medLabChemistry.chmQcAnalyte1"
      },
      {
        "elementKey": "chmQcLevel1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "94",
        "fqn": "medLabChemistry.chmQcLevel1"
      },
      {
        "elementKey": "chmQcRule1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "95",
        "fqn": "medLabChemistry.chmQcRule1"
      },
      {
        "elementKey": "chmQcSeRe1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "96",
        "fqn": "medLabChemistry.chmQcSeRe1"
      },
      {
        "elementKey": "chmQcDescribe1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "97",
        "fqn": "medLabChemistry.chmQcDescribe1"
      },
      {
        "elementKey": "chmQcAnalyte2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "98",
        "fqn": "medLabChemistry.chmQcAnalyte2"
      },
      {
        "elementKey": "chmQcLevel2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "99",
        "fqn": "medLabChemistry.chmQcLevel2"
      },
      {
        "elementKey": "chmQcRule2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "100",
        "fqn": "medLabChemistry.chmQcRule2"
      },
      {
        "elementKey": "chmQcSeRe2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "101",
        "fqn": "medLabChemistry.chmQcSeRe2"
      },
      {
        "elementKey": "chmQcDescribe2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "102",
        "fqn": "medLabChemistry.chmQcDescribe2"
      },
      {
        "elementKey": "chmQcAnalyte3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "103",
        "fqn": "medLabChemistry.chmQcAnalyte3"
      },
      {
        "elementKey": "chmQcLevel3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "104",
        "fqn": "medLabChemistry.chmQcLevel3"
      },
      {
        "elementKey": "chmQcRule3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "105",
        "fqn": "medLabChemistry.chmQcRule3"
      },
      {
        "elementKey": "chmQcSeRe3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "106",
        "fqn": "medLabChemistry.chmQcSeRe3"
      },
      {
        "elementKey": "chmQcDescribe3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "107",
        "fqn": "medLabChemistry.chmQcDescribe3"
      },
      {
        "elementKey": "chmQcAnalyte4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "108",
        "fqn": "medLabChemistry.chmQcAnalyte4"
      },
      {
        "elementKey": "chmQcLevel4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "109",
        "fqn": "medLabChemistry.chmQcLevel4"
      },
      {
        "elementKey": "chmQcRule4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "110",
        "fqn": "medLabChemistry.chmQcRule4"
      },
      {
        "elementKey": "chmQcSeRe4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "111",
        "fqn": "medLabChemistry.chmQcSeRe4"
      },
      {
        "elementKey": "chmQcDescribe4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "112",
        "fqn": "medLabChemistry.chmQcDescribe4"
      },
      {
        "elementKey": "label55-112",
        "formIndex": "1",
        "formCss": "grid-new-subsection grid-span-2-columns no-border",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-112"
      },
      {
        "elementKey": "label55-113",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-113"
      },
      {
        "elementKey": "label55-114",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-114"
      },
      {
        "elementKey": "cqcPendAnal_1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "113",
        "fqn": "medLabChemistry.cqcPendAnal_1"
      },
      {
        "elementKey": "cqcPendResult_1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "114",
        "fqn": "medLabChemistry.cqcPendResult_1"
      },
      {
        "elementKey": "cqcPendAnal_2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "115",
        "fqn": "medLabChemistry.cqcPendAnal_2"
      },
      {
        "elementKey": "cqcPendResult_2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "116",
        "fqn": "medLabChemistry.cqcPendResult_2"
      },
      {
        "elementKey": "cqcPendAnal_3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "117",
        "fqn": "medLabChemistry.cqcPendAnal_3"
      },
      {
        "elementKey": "cqcPendResult_3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "118",
        "fqn": "medLabChemistry.cqcPendResult_3"
      },
      {
        "elementKey": "cqcPendAnal_4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "119",
        "fqn": "medLabChemistry.cqcPendAnal_4"
      },
      {
        "elementKey": "cqcPendResult_4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "120",
        "fqn": "medLabChemistry.cqcPendResult_4"
      },
      {
        "elementKey": "tableChem_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabChemistry.name"
      },
      {
        "elementKey": "tableChem_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableChem_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabChemistry.profession"
      },
      {
        "elementKey": "tableChem_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabChemistry.day"
      },
      {
        "elementKey": "tableChem_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabChemistry.time"
      },
      {
        "elementKey": "chemAccession",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabChemistry.chemAccession"
      },
      {
        "elementKey": "chemCollDay",
        "defaultValue": "Today",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabChemistry.chemCollDay"
      },
      {
        "elementKey": "chemCollTime",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabChemistry.chemCollTime"
      },
      {
        "elementKey": "urinOrderPhys",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabChemistry.urinOrderPhys"
      },
      {
        "elementKey": "label45-3-1",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "options": [
          {
            "key": "Clear",
            "text": "Clear"
          },
          {
            "key": "Hazy",
            "text": "Hazy"
          },
          {
            "key": "Cloudy",
            "text": "Cloudy"
          },
          {
            "key": "Turbid",
            "text": "Turbid"
          }
        ],
        "fqn": "medLabChemistry.label45-3-1"
      },
      {
        "elementKey": "label45-3-2",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "options": [
          {
            "key": "Colorless",
            "text": "Colorless"
          },
          {
            "key": "Pale yellow",
            "text": "Pale yellow"
          },
          {
            "key": "Yellow",
            "text": "Yellow"
          },
          {
            "key": "Amber",
            "text": "Amber"
          },
          {
            "key": "Orange",
            "text": "Orange"
          },
          {
            "key": "Red",
            "text": "Red"
          },
          {
            "key": "Pink",
            "text": "Pink"
          },
          {
            "key": "Brown",
            "text": "Brown"
          },
          {
            "key": "Green",
            "text": "Green"
          }
        ],
        "fqn": "medLabChemistry.label45-3-2"
      },
      {
        "elementKey": "label45-3-3",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label45-3-3"
      },
      {
        "elementKey": "label45-3-4",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabChemistry.label45-3-4"
      },
      {
        "elementKey": "label45-3-5",
        "formIndex": "2",
        "inputType": "formTableText",
        "suffix": "mmol/L",
        "fqn": "medLabChemistry.label45-3-5",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "chemSodium",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "6",
        "fqn": "medLabChemistry.chemSodium"
      },
      {
        "elementKey": "chemSodiumFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "7",
        "fqn": "medLabChemistry.chemSodiumFlag"
      },
      {
        "elementKey": "chemSodiumRange",
        "defaultValue": "135 – 145 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "8",
        "fqn": "medLabChemistry.chemSodiumRange"
      },
      {
        "elementKey": "label45-3-7",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-7"
      },
      {
        "elementKey": "chemPotassium",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medLabChemistry.chemPotassium"
      },
      {
        "elementKey": "chemPotassiumFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "10",
        "fqn": "medLabChemistry.chemPotassiumFlag"
      },
      {
        "elementKey": "chemPotassiumRange",
        "defaultValue": "3.5–5.1 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "11",
        "fqn": "medLabChemistry.chemPotassiumRange"
      },
      {
        "elementKey": "label45-3-9",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-9"
      },
      {
        "elementKey": "chemChloride",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "12",
        "fqn": "medLabChemistry.chemChloride"
      },
      {
        "elementKey": "chemChlorideFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "13",
        "fqn": "medLabChemistry.chemChlorideFlag"
      },
      {
        "elementKey": "chemChlorideRange",
        "defaultValue": "95 – 107 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "14",
        "fqn": "medLabChemistry.chemChlorideRange"
      },
      {
        "elementKey": "label45-3-11",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-11"
      },
      {
        "elementKey": "chemtCO2",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "15",
        "fqn": "medLabChemistry.chemtCO2"
      },
      {
        "elementKey": "chemtCO2Flag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "16",
        "fqn": "medLabChemistry.chemtCO2Flag"
      },
      {
        "elementKey": "chemtCO2Range",
        "defaultValue": "22 – 31 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "17",
        "fqn": "medLabChemistry.chemtCO2Range"
      },
      {
        "elementKey": "label45-3-13",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-13"
      },
      {
        "elementKey": "chemAnionGap",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "18",
        "fqn": "medLabChemistry.chemAnionGap"
      },
      {
        "elementKey": "chemAnionGapFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "19",
        "fqn": "medLabChemistry.chemAnionGapFlag"
      },
      {
        "elementKey": "chemAnionGapRange",
        "defaultValue": "8 – 16 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "20",
        "fqn": "medLabChemistry.chemAnionGapRange"
      },
      {
        "elementKey": "label45-3-15",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-15"
      },
      {
        "elementKey": "chemOsmalityMeasured",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "21",
        "fqn": "medLabChemistry.chemOsmalityMeasured"
      },
      {
        "elementKey": "chemOsmalityMeasuredFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "22",
        "fqn": "medLabChemistry.chemOsmalityMeasuredFlag"
      },
      {
        "elementKey": "chemOsmalityMeasuredRange",
        "defaultValue": "275 – 295 mOsm/kg",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "23",
        "fqn": "medLabChemistry.chemOsmalityMeasuredRange"
      },
      {
        "elementKey": "label45-3-17",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-17"
      },
      {
        "elementKey": "chemOsmalityCalculated",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "24",
        "fqn": "medLabChemistry.chemOsmalityCalculated"
      },
      {
        "elementKey": "chemOsmalityCalculatedFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "25",
        "fqn": "medLabChemistry.chemOsmalityCalculatedFlag"
      },
      {
        "elementKey": "chemOsmalityCalculatedRange",
        "defaultValue": "275 – 295 mOsm/kg",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "26",
        "fqn": "medLabChemistry.chemOsmalityCalculatedRange"
      },
      {
        "elementKey": "label45-3-19",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-19"
      },
      {
        "elementKey": "chemOsmolarGap",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "27",
        "fqn": "medLabChemistry.chemOsmolarGap"
      },
      {
        "elementKey": "chemOsmolarGapFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "28",
        "fqn": "medLabChemistry.chemOsmolarGapFlag"
      },
      {
        "elementKey": "chemOsmolarGapRange",
        "defaultValue": "≤ 10 mOsm/kg",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "29",
        "fqn": "medLabChemistry.chemOsmolarGapRange"
      },
      {
        "elementKey": "label45-3-22",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-22"
      },
      {
        "elementKey": "chemGlugoseFastingRandom",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "30",
        "fqn": "medLabChemistry.chemGlugoseFastingRandom"
      },
      {
        "elementKey": "chemGlugoseFastingRandomFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "31",
        "fqn": "medLabChemistry.chemGlugoseFastingRandomFlag"
      },
      {
        "elementKey": "chemGlugoseFastingRandomRange",
        "defaultValue": "F(3.6-6.0) R(3.6-11.0)mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "32",
        "fqn": "medLabChemistry.chemGlugoseFastingRandomRange"
      },
      {
        "elementKey": "label45-3-24",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-24"
      },
      {
        "elementKey": "chemCreatinine",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "33",
        "fqn": "medLabChemistry.chemCreatinine"
      },
      {
        "elementKey": "chemCreatinineFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "34",
        "fqn": "medLabChemistry.chemCreatinineFlag"
      },
      {
        "elementKey": "chemCreatinineRange",
        "defaultValue": "M(60-115) F(40-95) umol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "35",
        "fqn": "medLabChemistry.chemCreatinineRange"
      },
      {
        "elementKey": "label45-3-26",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label45-3-26"
      },
      {
        "elementKey": "chemUreaBun",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "36",
        "fqn": "medLabChemistry.chemUreaBun"
      },
      {
        "elementKey": "chemUreaBunFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "37",
        "fqn": "medLabChemistry.chemUreaBunFlag"
      },
      {
        "elementKey": "chemUreaBunRange",
        "defaultValue": "2.0 – 8.2 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "38",
        "fqn": "medLabChemistry.chemUreaBunRange"
      },
      {
        "elementKey": "label55-1",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-1"
      },
      {
        "elementKey": "chemCalcium",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "39",
        "fqn": "medLabChemistry.chemCalcium"
      },
      {
        "elementKey": "chemCalciumFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "40",
        "fqn": "medLabChemistry.chemCalciumFlag"
      },
      {
        "elementKey": "chemCalciumRange",
        "defaultValue": "2.10 – 2.55 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "41",
        "fqn": "medLabChemistry.chemCalciumRange"
      },
      {
        "elementKey": "label55-3",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-3"
      },
      {
        "elementKey": "chemMagnesium",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "42",
        "fqn": "medLabChemistry.chemMagnesium"
      },
      {
        "elementKey": "chemMagnesiumFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "43",
        "fqn": "medLabChemistry.chemMagnesiumFlag"
      },
      {
        "elementKey": "chemMagnesiumRange",
        "defaultValue": "0.70 – 1.10 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "44",
        "fqn": "medLabChemistry.chemMagnesiumRange"
      },
      {
        "elementKey": "label55-5",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-5"
      },
      {
        "elementKey": "chemPhosphorus",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "45",
        "fqn": "medLabChemistry.chemPhosphorus"
      },
      {
        "elementKey": "chemPhosphorusFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "46",
        "fqn": "medLabChemistry.chemPhosphorusFlag"
      },
      {
        "elementKey": "chemPhosphorusRange",
        "defaultValue": "0.80 – 1.45 mmol/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "47",
        "fqn": "medLabChemistry.chemPhosphorusRange"
      },
      {
        "elementKey": "label55-7",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-7"
      },
      {
        "elementKey": "chemTotalProtein",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "48",
        "fqn": "medLabChemistry.chemTotalProtein"
      },
      {
        "elementKey": "chemTotalProteinFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "49",
        "fqn": "medLabChemistry.chemTotalProteinFlag"
      },
      {
        "elementKey": "chemTotalProteinRange",
        "defaultValue": "62 – 82 g/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "50",
        "fqn": "medLabChemistry.chemTotalProteinRange"
      },
      {
        "elementKey": "label55-9",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabChemistry.label55-9"
      },
      {
        "elementKey": "chemAlbumin",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "51",
        "fqn": "medLabChemistry.chemAlbumin"
      },
      {
        "elementKey": "chemAlbuminFlag",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "52",
        "fqn": "medLabChemistry.chemAlbuminFlag"
      },
      {
        "elementKey": "chemAlbuminRange",
        "defaultValue": "34 – 50 g/L",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "53",
        "fqn": "medLabChemistry.chemAlbuminRange"
      },
      {
        "elementKey": "chemOpt1Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "54",
        "fqn": "medLabChemistry.chemOpt1Analyte"
      },
      {
        "elementKey": "chemOpt1Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "55",
        "fqn": "medLabChemistry.chemOpt1Result"
      },
      {
        "elementKey": "chemOpt1Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "56",
        "fqn": "medLabChemistry.chemOpt1Type"
      },
      {
        "elementKey": "chemOpt1RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "57",
        "fqn": "medLabChemistry.chemOpt1RI"
      },
      {
        "elementKey": "chemOpt2Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "58",
        "fqn": "medLabChemistry.chemOpt2Analyte"
      },
      {
        "elementKey": "chemOpt2Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "59",
        "fqn": "medLabChemistry.chemOpt2Result"
      },
      {
        "elementKey": "chemOpt2Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "60",
        "fqn": "medLabChemistry.chemOpt2Type"
      },
      {
        "elementKey": "chemOpt2RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "61",
        "fqn": "medLabChemistry.chemOpt2RI"
      },
      {
        "elementKey": "chemOpt3Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "62",
        "fqn": "medLabChemistry.chemOpt3Analyte"
      },
      {
        "elementKey": "chemOpt3Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "63",
        "fqn": "medLabChemistry.chemOpt3Result"
      },
      {
        "elementKey": "chemOpt3Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "64",
        "fqn": "medLabChemistry.chemOpt3Type"
      },
      {
        "elementKey": "chemOpt3RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "65",
        "fqn": "medLabChemistry.chemOpt3RI"
      },
      {
        "elementKey": "chemOpt4Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "66",
        "fqn": "medLabChemistry.chemOpt4Analyte"
      },
      {
        "elementKey": "chemOpt4Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "67",
        "fqn": "medLabChemistry.chemOpt4Result"
      },
      {
        "elementKey": "chemOpt4Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "68",
        "fqn": "medLabChemistry.chemOpt4Type"
      },
      {
        "elementKey": "chemOpt4RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "69",
        "fqn": "medLabChemistry.chemOpt4RI"
      },
      {
        "elementKey": "chemOpt5Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "70",
        "fqn": "medLabChemistry.chemOpt5Analyte"
      },
      {
        "elementKey": "chemOpt5Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "71",
        "fqn": "medLabChemistry.chemOpt5Result"
      },
      {
        "elementKey": "chemOpt5Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "72",
        "fqn": "medLabChemistry.chemOpt5Type"
      },
      {
        "elementKey": "chemOpt5RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "73",
        "fqn": "medLabChemistry.chemOpt5RI"
      },
      {
        "elementKey": "chemOpt6Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "74",
        "fqn": "medLabChemistry.chemOpt6Analyte"
      },
      {
        "elementKey": "chemOpt6Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "75",
        "fqn": "medLabChemistry.chemOpt6Result"
      },
      {
        "elementKey": "chemOpt6Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "76",
        "fqn": "medLabChemistry.chemOpt6Type"
      },
      {
        "elementKey": "chemOpt6RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "77",
        "fqn": "medLabChemistry.chemOpt6RI"
      },
      {
        "elementKey": "chemOpt7Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "78",
        "fqn": "medLabChemistry.chemOpt7Analyte"
      },
      {
        "elementKey": "chemOpt7Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "79",
        "fqn": "medLabChemistry.chemOpt7Result"
      },
      {
        "elementKey": "chemOpt7Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "80",
        "fqn": "medLabChemistry.chemOpt7Type"
      },
      {
        "elementKey": "chemOpt7RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "81",
        "fqn": "medLabChemistry.chemOpt7RI"
      },
      {
        "elementKey": "chemOpt8Analyte",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "82",
        "fqn": "medLabChemistry.chemOpt8Analyte"
      },
      {
        "elementKey": "chemOpt8Result",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "83",
        "fqn": "medLabChemistry.chemOpt8Result"
      },
      {
        "elementKey": "chemOpt8Type",
        "formIndex": "2",
        "formCss": "chem-results-lhclch",
        "inputType": "radioset",
        "options": [
          {
            "key": "CL",
            "text": "CL"
          },
          {
            "key": "L",
            "text": "L"
          },
          {
            "key": "H",
            "text": "H"
          },
          {
            "key": "CH",
            "text": "CH"
          }
        ],
        "tableColumn": "84",
        "fqn": "medLabChemistry.chemOpt8Type"
      },
      {
        "elementKey": "chemOpt8RI",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "85",
        "fqn": "medLabChemistry.chemOpt8RI"
      },
      {
        "elementKey": "urnInternal",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "86",
        "fqn": "medLabChemistry.urnInternal"
      },
      {
        "elementKey": "chemSpecimumType",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "serum",
            "text": "Serum"
          },
          {
            "key": "urinine",
            "text": "Urinine"
          },
          {
            "key": "wholeBlood",
            "text": "Whole blood"
          }
        ],
        "tableColumn": "87",
        "fqn": "medLabChemistry.chemSpecimumType"
      },
      {
        "elementKey": "chemSerumAppearance",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "88",
        "fqn": "medLabChemistry.chemSerumAppearance"
      },
      {
        "elementKey": "chemInterpLabel",
        "formIndex": "2",
        "inputType": "form_label",
        "fqn": "medLabChemistry.chemInterpLabel"
      },
      {
        "elementKey": "chemInterpValue",
        "formIndex": "2",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "89",
        "fqn": "medLabChemistry.chemInterpValue"
      },
      {
        "elementKey": "label55-40",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-40"
      },
      {
        "elementKey": "chemInterpReason",
        "formIndex": "2",
        "formCss": "grid-span-2-columns",
        "inputType": "text",
        "tableColumn": "90",
        "fqn": "medLabChemistry.chemInterpReason"
      },
      {
        "elementKey": "chemPhLabel",
        "formIndex": "2",
        "inputType": "form_label",
        "fqn": "medLabChemistry.chemPhLabel"
      },
      {
        "elementKey": "chemPhValue",
        "formIndex": "2",
        "inputType": "radioset",
        "options": [
          {
            "key": "y",
            "text": "Yes"
          },
          {
            "key": "n",
            "text": "No"
          },
          {
            "key": "na",
            "text": "N/A"
          }
        ],
        "tableColumn": "91",
        "fqn": "medLabChemistry.chemPhValue"
      },
      {
        "elementKey": "label55-41",
        "formIndex": "2",
        "formCss": "grid-new-subsection grid-span-2-columns no-border",
        "inputType": "form_label",
        "fqn": "medLabChemistry.label55-41"
      }
    ],
    "pageElements": {
      "tableQcChemistry": {
        "elementKey": "tableQcChemistry",
        "pageElementIndex": "1",
        "pageKey": "medLabChemistry",
        "tableKey": "tableQcChemistry",
        "isTable": true,
        "form": {
          "elementKey": "tableQcChemistry",
          "label": "Chemistry QC",
          "addButtonText": "Create a chemistry QC report",
          "formKey": "tableQcChemistry",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabChemistry.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableQcChemistry_name",
                "tableQcChemistry_profession",
                "tableQcChemistry_day",
                "tableQcChemistry_time"
              ]
            },
            {
              "elementKey": "cGroup45-6",
              "formCss": "chem-qc-assay form-table-group",
              "fqn": "medLabChemistry.cGroup45-6",
              "gIndex": "2",
              "gChildren": [
                "label55-11",
                "label55-12",
                "chemQcAllAcceptable",
                "label55-13",
                "chemQcAllDuplicatesAcceptable",
                "label55-14",
                "chemQcAllPlausible",
                "label55-15",
                "chemQcAllCanBeReported",
                "label55-16",
                "label55-17"
              ]
            },
            {
              "elementKey": "cGroup45-7",
              "formCss": "chem-qc-trouble form-table-group",
              "fqn": "medLabChemistry.cGroup45-7",
              "gIndex": "3",
              "gChildren": [
                "label55-18",
                "label55-19",
                "label55-20",
                "label55-21",
                "label55-22",
                "chmQcAnalyte1",
                "chmQcLevel1",
                "chmQcRule1",
                "chmQcSeRe1",
                "chmQcDescribe1",
                "chmQcAnalyte2",
                "chmQcLevel2",
                "chmQcRule2",
                "chmQcSeRe2",
                "chmQcDescribe2",
                "chmQcAnalyte3",
                "chmQcLevel3",
                "chmQcRule3",
                "chmQcSeRe3",
                "chmQcDescribe3",
                "chmQcAnalyte4",
                "chmQcLevel4",
                "chmQcRule4",
                "chmQcSeRe4",
                "chmQcDescribe4"
              ]
            },
            {
              "elementKey": "cGroup46-5",
              "formCss": "med-lab-urin-qc-pending-table form-table-group",
              "fqn": "medLabChemistry.cGroup46-5",
              "gIndex": "4",
              "gChildren": [
                "label55-112",
                "label55-113",
                "label55-114",
                "cqcPendAnal_1",
                "cqcPendResult_1",
                "cqcPendAnal_2",
                "cqcPendResult_2",
                "cqcPendAnal_3",
                "cqcPendResult_3",
                "cqcPendAnal_4",
                "cqcPendResult_4"
              ]
            }
          ],
          "ehr_data": {
            "tableQcChemistry_name": "",
            "tableQcChemistry_profession": "",
            "tableQcChemistry_day": "",
            "tableQcChemistry_time": "",
            "chemQcAllAcceptable": "",
            "chemQcAllDuplicatesAcceptable": "",
            "chemQcAllPlausible": "",
            "chemQcAllCanBeReported": "",
            "chmQcAnalyte1": "",
            "chmQcLevel1": "",
            "chmQcRule1": "",
            "chmQcSeRe1": "",
            "chmQcDescribe1": "",
            "chmQcAnalyte2": "",
            "chmQcLevel2": "",
            "chmQcRule2": "",
            "chmQcSeRe2": "",
            "chmQcDescribe2": "",
            "chmQcAnalyte3": "",
            "chmQcLevel3": "",
            "chmQcRule3": "",
            "chmQcSeRe3": "",
            "chmQcDescribe3": "",
            "chmQcAnalyte4": "",
            "chmQcLevel4": "",
            "chmQcRule4": "",
            "chmQcSeRe4": "",
            "chmQcDescribe4": "",
            "cqcPendAnal_1": "",
            "cqcPendResult_1": "",
            "cqcPendAnal_2": "",
            "cqcPendResult_2": "",
            "cqcPendAnal_3": "",
            "cqcPendResult_3": "",
            "cqcPendAnal_4": "",
            "cqcPendResult_4": ""
          }
        },
        "fqn": "medLabChemistry.tableQcChemistry",
        "tableChildren": [
          "tableQcChemistry_id",
          "tableQcChemistry_day",
          "tableQcChemistry_time",
          "chemQcAllAcceptable",
          "chemQcAllDuplicatesAcceptable",
          "chemQcAllPlausible",
          "chemQcAllCanBeReported",
          "chmQcAnalyte1",
          "chmQcLevel1",
          "chmQcRule1",
          "chmQcSeRe1",
          "chmQcDescribe1",
          "chmQcAnalyte2",
          "chmQcLevel2",
          "chmQcRule2",
          "chmQcSeRe2",
          "chmQcDescribe2",
          "chmQcAnalyte3",
          "chmQcLevel3",
          "chmQcRule3",
          "chmQcSeRe3",
          "chmQcDescribe3",
          "chmQcAnalyte4",
          "chmQcLevel4",
          "chmQcRule4",
          "chmQcSeRe4",
          "chmQcDescribe4",
          "cqcPendAnal_1",
          "cqcPendResult_1",
          "cqcPendAnal_2",
          "cqcPendResult_2",
          "cqcPendAnal_3",
          "cqcPendResult_3",
          "cqcPendAnal_4",
          "cqcPendResult_4",
          "tableQcChemistry_name",
          "tableQcChemistry_profession"
        ],
        "hasRecHeader": true
      },
      "tableChem": {
        "elementKey": "tableChem",
        "pageElementIndex": "2",
        "pageKey": "medLabChemistry",
        "tableKey": "tableChem",
        "isTable": true,
        "form": {
          "elementKey": "tableChem",
          "label": "Chemistry analysis",
          "addButtonText": "Create a chemistry lab report",
          "formKey": "tableChem",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabChemistry.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableChem_name",
                "tableChem_profession",
                "tableChem_day",
                "tableChem_time"
              ]
            },
            {
              "elementKey": "cGroup45-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabChemistry.cGroup45-2",
              "gIndex": "2",
              "gChildren": [
                "chemAccession",
                "chemCollDay",
                "chemCollTime",
                "urinOrderPhys"
              ]
            },
            {
              "elementKey": "cGroup45-3",
              "formCss": "chem-results-table form-table-group",
              "fqn": "medLabChemistry.cGroup45-3",
              "gIndex": "3",
              "gChildren": [
                "label45-3-1",
                "label45-3-2",
                "label45-3-3",
                "label45-3-4",
                "label45-3-5",
                "chemSodium",
                "chemSodiumFlag",
                "chemSodiumRange",
                "label45-3-7",
                "chemPotassium",
                "chemPotassiumFlag",
                "chemPotassiumRange",
                "label45-3-9",
                "chemChloride",
                "chemChlorideFlag",
                "chemChlorideRange",
                "label45-3-11",
                "chemtCO2",
                "chemtCO2Flag",
                "chemtCO2Range",
                "label45-3-13",
                "chemAnionGap",
                "chemAnionGapFlag",
                "chemAnionGapRange",
                "label45-3-15",
                "chemOsmalityMeasured",
                "chemOsmalityMeasuredFlag",
                "chemOsmalityMeasuredRange",
                "label45-3-17",
                "chemOsmalityCalculated",
                "chemOsmalityCalculatedFlag",
                "chemOsmalityCalculatedRange",
                "label45-3-19",
                "chemOsmolarGap",
                "chemOsmolarGapFlag",
                "chemOsmolarGapRange",
                "label45-3-22",
                "chemGlugoseFastingRandom",
                "chemGlugoseFastingRandomFlag",
                "chemGlugoseFastingRandomRange",
                "label45-3-24",
                "chemCreatinine",
                "chemCreatinineFlag",
                "chemCreatinineRange",
                "label45-3-26",
                "chemUreaBun",
                "chemUreaBunFlag",
                "chemUreaBunRange",
                "label55-1",
                "chemCalcium",
                "chemCalciumFlag",
                "chemCalciumRange",
                "label55-3",
                "chemMagnesium",
                "chemMagnesiumFlag",
                "chemMagnesiumRange",
                "label55-5",
                "chemPhosphorus",
                "chemPhosphorusFlag",
                "chemPhosphorusRange",
                "label55-7",
                "chemTotalProtein",
                "chemTotalProteinFlag",
                "chemTotalProteinRange",
                "label55-9",
                "chemAlbumin",
                "chemAlbuminFlag",
                "chemAlbuminRange",
                "chemOpt1Analyte",
                "chemOpt1Result",
                "chemOpt1Type",
                "chemOpt1RI",
                "chemOpt2Analyte",
                "chemOpt2Result",
                "chemOpt2Type",
                "chemOpt2RI",
                "chemOpt3Analyte",
                "chemOpt3Result",
                "chemOpt3Type",
                "chemOpt3RI",
                "chemOpt4Analyte",
                "chemOpt4Result",
                "chemOpt4Type",
                "chemOpt4RI",
                "chemOpt5Analyte",
                "chemOpt5Result",
                "chemOpt5Type",
                "chemOpt5RI",
                "chemOpt6Analyte",
                "chemOpt6Result",
                "chemOpt6Type",
                "chemOpt6RI",
                "chemOpt7Analyte",
                "chemOpt7Result",
                "chemOpt7Type",
                "chemOpt7RI",
                "chemOpt8Analyte",
                "chemOpt8Result",
                "chemOpt8Type",
                "chemOpt8RI"
              ]
            },
            {
              "elementKey": "cGroup45-4",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabChemistry.cGroup45-4",
              "gIndex": "4",
              "gChildren": [
                "urnInternal"
              ]
            },
            {
              "elementKey": "cGroup45-5",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabChemistry.cGroup45-5",
              "gIndex": "5",
              "gChildren": [
                "chemSpecimumType",
                "chemSerumAppearance"
              ]
            },
            {
              "elementKey": "cGroup45-4",
              "formCss": "grid-left-to-right-2",
              "fqn": "medLabChemistry.cGroup45-4",
              "gIndex": "6",
              "gChildren": [
                "chemInterpLabel",
                "chemInterpValue",
                "label55-40",
                "chemInterpReason"
              ]
            },
            {
              "elementKey": "cGroup45-4",
              "formCss": "grid-left-to-right-2",
              "fqn": "medLabChemistry.cGroup45-4",
              "gIndex": "7",
              "gChildren": [
                "chemPhLabel",
                "chemPhValue",
                "label55-41"
              ]
            }
          ],
          "ehr_data": {
            "tableChem_name": "",
            "tableChem_profession": "",
            "tableChem_day": "",
            "tableChem_time": "",
            "chemAccession": "",
            "chemCollDay": "Today",
            "chemCollTime": "",
            "urinOrderPhys": "",
            "chemSodium": "",
            "chemSodiumFlag": "",
            "chemSodiumRange": "135 – 145 mmol/L",
            "chemPotassium": "",
            "chemPotassiumFlag": "",
            "chemPotassiumRange": "3.5–5.1 mmol/L",
            "chemChloride": "",
            "chemChlorideFlag": "",
            "chemChlorideRange": "95 – 107 mmol/L",
            "chemtCO2": "",
            "chemtCO2Flag": "",
            "chemtCO2Range": "22 – 31 mmol/L",
            "chemAnionGap": "",
            "chemAnionGapFlag": "",
            "chemAnionGapRange": "8 – 16 mmol/L",
            "chemOsmalityMeasured": "",
            "chemOsmalityMeasuredFlag": "",
            "chemOsmalityMeasuredRange": "275 – 295 mOsm/kg",
            "chemOsmalityCalculated": "",
            "chemOsmalityCalculatedFlag": "",
            "chemOsmalityCalculatedRange": "275 – 295 mOsm/kg",
            "chemOsmolarGap": "",
            "chemOsmolarGapFlag": "",
            "chemOsmolarGapRange": "≤ 10 mOsm/kg",
            "chemGlugoseFastingRandom": "",
            "chemGlugoseFastingRandomFlag": "",
            "chemGlugoseFastingRandomRange": "F(3.6-6.0) R(3.6-11.0)mmol/L",
            "chemCreatinine": "",
            "chemCreatinineFlag": "",
            "chemCreatinineRange": "M(60-115) F(40-95) umol/L",
            "chemUreaBun": "",
            "chemUreaBunFlag": "",
            "chemUreaBunRange": "2.0 – 8.2 mmol/L",
            "chemCalcium": "",
            "chemCalciumFlag": "",
            "chemCalciumRange": "2.10 – 2.55 mmol/L",
            "chemMagnesium": "",
            "chemMagnesiumFlag": "",
            "chemMagnesiumRange": "0.70 – 1.10 mmol/L",
            "chemPhosphorus": "",
            "chemPhosphorusFlag": "",
            "chemPhosphorusRange": "0.80 – 1.45 mmol/L",
            "chemTotalProtein": "",
            "chemTotalProteinFlag": "",
            "chemTotalProteinRange": "62 – 82 g/L",
            "chemAlbumin": "",
            "chemAlbuminFlag": "",
            "chemAlbuminRange": "34 – 50 g/L",
            "chemOpt1Analyte": "",
            "chemOpt1Result": "",
            "chemOpt1Type": "",
            "chemOpt1RI": "",
            "chemOpt2Analyte": "",
            "chemOpt2Result": "",
            "chemOpt2Type": "",
            "chemOpt2RI": "",
            "chemOpt3Analyte": "",
            "chemOpt3Result": "",
            "chemOpt3Type": "",
            "chemOpt3RI": "",
            "chemOpt4Analyte": "",
            "chemOpt4Result": "",
            "chemOpt4Type": "",
            "chemOpt4RI": "",
            "chemOpt5Analyte": "",
            "chemOpt5Result": "",
            "chemOpt5Type": "",
            "chemOpt5RI": "",
            "chemOpt6Analyte": "",
            "chemOpt6Result": "",
            "chemOpt6Type": "",
            "chemOpt6RI": "",
            "chemOpt7Analyte": "",
            "chemOpt7Result": "",
            "chemOpt7Type": "",
            "chemOpt7RI": "",
            "chemOpt8Analyte": "",
            "chemOpt8Result": "",
            "chemOpt8Type": "",
            "chemOpt8RI": "",
            "urnInternal": "",
            "chemSpecimumType": "",
            "chemSerumAppearance": "",
            "chemInterpValue": "",
            "chemInterpReason": "",
            "chemPhValue": ""
          }
        },
        "fqn": "medLabChemistry.tableChem",
        "tableChildren": [
          "tableChem_id",
          "tableChem_day",
          "tableChem_time",
          "chemAccession",
          "chemCollDay",
          "chemCollTime",
          "urinOrderPhys",
          "chemSodium",
          "chemSodiumFlag",
          "chemSodiumRange",
          "chemPotassium",
          "chemPotassiumFlag",
          "chemPotassiumRange",
          "chemChloride",
          "chemChlorideFlag",
          "chemChlorideRange",
          "chemtCO2",
          "chemtCO2Flag",
          "chemtCO2Range",
          "chemAnionGap",
          "chemAnionGapFlag",
          "chemAnionGapRange",
          "chemOsmalityMeasured",
          "chemOsmalityMeasuredFlag",
          "chemOsmalityMeasuredRange",
          "chemOsmalityCalculated",
          "chemOsmalityCalculatedFlag",
          "chemOsmalityCalculatedRange",
          "chemOsmolarGap",
          "chemOsmolarGapFlag",
          "chemOsmolarGapRange",
          "chemGlugoseFastingRandom",
          "chemGlugoseFastingRandomFlag",
          "chemGlugoseFastingRandomRange",
          "chemCreatinine",
          "chemCreatinineFlag",
          "chemCreatinineRange",
          "chemUreaBun",
          "chemUreaBunFlag",
          "chemUreaBunRange",
          "chemCalcium",
          "chemCalciumFlag",
          "chemCalciumRange",
          "chemMagnesium",
          "chemMagnesiumFlag",
          "chemMagnesiumRange",
          "chemPhosphorus",
          "chemPhosphorusFlag",
          "chemPhosphorusRange",
          "chemTotalProtein",
          "chemTotalProteinFlag",
          "chemTotalProteinRange",
          "chemAlbumin",
          "chemAlbuminFlag",
          "chemAlbuminRange",
          "chemOpt1Analyte",
          "chemOpt1Result",
          "chemOpt1Type",
          "chemOpt1RI",
          "chemOpt2Analyte",
          "chemOpt2Result",
          "chemOpt2Type",
          "chemOpt2RI",
          "chemOpt3Analyte",
          "chemOpt3Result",
          "chemOpt3Type",
          "chemOpt3RI",
          "chemOpt4Analyte",
          "chemOpt4Result",
          "chemOpt4Type",
          "chemOpt4RI",
          "chemOpt5Analyte",
          "chemOpt5Result",
          "chemOpt5Type",
          "chemOpt5RI",
          "chemOpt6Analyte",
          "chemOpt6Result",
          "chemOpt6Type",
          "chemOpt6RI",
          "chemOpt7Analyte",
          "chemOpt7Result",
          "chemOpt7Type",
          "chemOpt7RI",
          "chemOpt8Analyte",
          "chemOpt8Result",
          "chemOpt8Type",
          "chemOpt8RI",
          "urnInternal",
          "chemSpecimumType",
          "chemSerumAppearance",
          "chemInterpValue",
          "chemInterpReason",
          "chemPhValue",
          "tableChem_name",
          "tableChem_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medLabUrinalysis": {
    "pageDataKey": "medLabUrinalysis",
    "pIndex": "46",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "tableUrinalysis_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabUrinalysis.name"
      },
      {
        "elementKey": "tableUrinalysis_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableUrinalysis_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabUrinalysis.profession"
      },
      {
        "elementKey": "tableUrinalysis_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabUrinalysis.day"
      },
      {
        "elementKey": "tableUrinalysis_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabUrinalysis.time"
      },
      {
        "elementKey": "urinAccession",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabUrinalysis.urinAccession"
      },
      {
        "elementKey": "urinCollDay",
        "defaultValue": "Today",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabUrinalysis.urinCollDay"
      },
      {
        "elementKey": "urinCollTime",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabUrinalysis.urinCollTime"
      },
      {
        "elementKey": "urinOrderPhys",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabUrinalysis.urinOrderPhys"
      },
      {
        "elementKey": "urinTransparency",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Clear",
            "text": "Clear"
          },
          {
            "key": "Hazy",
            "text": "Hazy"
          },
          {
            "key": "Cloudy",
            "text": "Cloudy"
          },
          {
            "key": "Turbid",
            "text": "Turbid"
          }
        ],
        "tableColumn": "5",
        "fqn": "medLabUrinalysis.urinTransparency"
      },
      {
        "elementKey": "urinColour",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Colorless",
            "text": "Colorless"
          },
          {
            "key": "Pale yellow",
            "text": "Pale yellow"
          },
          {
            "key": "Yellow",
            "text": "Yellow"
          },
          {
            "key": "Amber",
            "text": "Amber"
          },
          {
            "key": "Orange",
            "text": "Orange"
          },
          {
            "key": "Red",
            "text": "Red"
          },
          {
            "key": "Pink",
            "text": "Pink"
          },
          {
            "key": "Brown",
            "text": "Brown"
          },
          {
            "key": "Green",
            "text": "Green"
          }
        ],
        "tableColumn": "6",
        "fqn": "medLabUrinalysis.urinColour"
      },
      {
        "elementKey": "label55-25",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-25"
      },
      {
        "elementKey": "label55-26",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-26"
      },
      {
        "elementKey": "label55-27",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-27"
      },
      {
        "elementKey": "label55-28",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-28"
      },
      {
        "elementKey": "label55-29",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-29"
      },
      {
        "elementKey": "uchemManGlucose",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "mmol/L",
        "tableColumn": "7",
        "fqn": "medLabUrinalysis.uchemManGlucose",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "uchemAutoGlucose",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "mmol/L",
        "tableColumn": "8",
        "fqn": "medLabUrinalysis.uchemAutoGlucose",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "label55-30",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-30"
      },
      {
        "elementKey": "label55-31",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-31"
      },
      {
        "elementKey": "uchemManBilirubin",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medLabUrinalysis.uchemManBilirubin"
      },
      {
        "elementKey": "uchemAutoBilirubin",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medLabUrinalysis.uchemAutoBilirubin"
      },
      {
        "elementKey": "label55-32",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-32"
      },
      {
        "elementKey": "label55-33",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-33"
      },
      {
        "elementKey": "uchemManKetone",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "mmol/L",
        "tableColumn": "11",
        "fqn": "medLabUrinalysis.uchemManKetone",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "uchemAutoKetone",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "mmol/L",
        "tableColumn": "12",
        "fqn": "medLabUrinalysis.uchemAutoKetone",
        "suffixText": "mmol/L"
      },
      {
        "elementKey": "label55-34",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-34"
      },
      {
        "elementKey": "label55-35",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-35"
      },
      {
        "elementKey": "uchemManSpecificGravity",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "13",
        "fqn": "medLabUrinalysis.uchemManSpecificGravity"
      },
      {
        "elementKey": "uchemAutoSpecificGravity",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "14",
        "fqn": "medLabUrinalysis.uchemAutoSpecificGravity"
      },
      {
        "elementKey": "label55-36",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-36"
      },
      {
        "elementKey": "label55-37",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-37"
      },
      {
        "elementKey": "uchemManBlood",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "15",
        "fqn": "medLabUrinalysis.uchemManBlood"
      },
      {
        "elementKey": "uchemAutoBlood",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "Ery/μL",
        "tableColumn": "16",
        "fqn": "medLabUrinalysis.uchemAutoBlood",
        "suffixText": "Ery/μL"
      },
      {
        "elementKey": "label55-38",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-38"
      },
      {
        "elementKey": "label55-39",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-39"
      },
      {
        "elementKey": "uchemManpH",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "17",
        "fqn": "medLabUrinalysis.uchemManpH"
      },
      {
        "elementKey": "uchemAutopH",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "18",
        "fqn": "medLabUrinalysis.uchemAutopH"
      },
      {
        "elementKey": "label55-40",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-40"
      },
      {
        "elementKey": "label55-41",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-41"
      },
      {
        "elementKey": "uchemManProtein",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "g/L",
        "tableColumn": "19",
        "fqn": "medLabUrinalysis.uchemManProtein",
        "suffixText": "g/L"
      },
      {
        "elementKey": "uchemAutoProtein",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "g/L",
        "tableColumn": "20",
        "fqn": "medLabUrinalysis.uchemAutoProtein",
        "suffixText": "g/L"
      },
      {
        "elementKey": "label55-42",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-42"
      },
      {
        "elementKey": "label55-43",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-43"
      },
      {
        "elementKey": "uchemManUrobilinogen",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "μmol/L",
        "tableColumn": "21",
        "fqn": "medLabUrinalysis.uchemManUrobilinogen",
        "suffixText": "μmol/L"
      },
      {
        "elementKey": "uchemAutoUrobilinogen",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "μmol/L",
        "tableColumn": "22",
        "fqn": "medLabUrinalysis.uchemAutoUrobilinogen",
        "suffixText": "μmol/L"
      },
      {
        "elementKey": "label55-44",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-44"
      },
      {
        "elementKey": "label55-45",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-45"
      },
      {
        "elementKey": "uchemManNitrite",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "23",
        "fqn": "medLabUrinalysis.uchemManNitrite"
      },
      {
        "elementKey": "uchemAutoNitrite",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "24",
        "fqn": "medLabUrinalysis.uchemAutoNitrite"
      },
      {
        "elementKey": "label55-46",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-46"
      },
      {
        "elementKey": "label55-47",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-47"
      },
      {
        "elementKey": "uchemManLeukocytes",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "25",
        "fqn": "medLabUrinalysis.uchemManLeukocytes"
      },
      {
        "elementKey": "uchemAutoLeukocytes",
        "formIndex": "2",
        "inputType": "text",
        "suffix": "Leu/μL",
        "tableColumn": "26",
        "fqn": "medLabUrinalysis.uchemAutoLeukocytes",
        "suffixText": "Leu/μL"
      },
      {
        "elementKey": "label55-48",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-48"
      },
      {
        "elementKey": "uchemMicroIndicater",
        "defaultValue": "TRUE",
        "formIndex": "2",
        "inputType": "checkbox",
        "tableColumn": "27",
        "fqn": "medLabUrinalysis.uchemMicroIndicater"
      },
      {
        "elementKey": "urnDiscMA",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "28",
        "fqn": "medLabUrinalysis.urnDiscMA"
      },
      {
        "elementKey": "uchemInstructorlComment",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "29",
        "fqn": "medLabUrinalysis.uchemInstructorlComment"
      },
      {
        "elementKey": "uchemSulfosalicylic",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "negative",
            "text": "negative"
          },
          {
            "key": "trace",
            "text": "trace"
          },
          {
            "key": "+1",
            "text": "+1"
          },
          {
            "key": "+2",
            "text": "+2"
          },
          {
            "key": "+3",
            "text": "+3"
          },
          {
            "key": "+4",
            "text": "+4"
          }
        ],
        "tableColumn": "30",
        "fqn": "medLabUrinalysis.uchemSulfosalicylic"
      },
      {
        "elementKey": "uchemIcotest",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "negative",
            "text": "negative"
          },
          {
            "key": "positive",
            "text": "positive"
          }
        ],
        "tableColumn": "31",
        "fqn": "medLabUrinalysis.uchemIcotest"
      },
      {
        "elementKey": "label55-49",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-49"
      },
      {
        "elementKey": "label55-50",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-50"
      },
      {
        "elementKey": "label55-51",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-51"
      },
      {
        "elementKey": "label55-52",
        "formIndex": "2",
        "inputType": "formTableHeader",
        "fqn": "medLabUrinalysis.label55-52"
      },
      {
        "elementKey": "label55-53",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-53"
      },
      {
        "elementKey": "umicroWBC",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "0-1",
            "text": "0-1"
          },
          {
            "key": "2-5",
            "text": "2-5"
          },
          {
            "key": "6-10",
            "text": "6-10"
          },
          {
            "key": "11-20",
            "text": "11-20"
          },
          {
            "key": "21-50",
            "text": "21-50"
          },
          {
            "key": "50+",
            "text": "50+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "32",
        "fqn": "medLabUrinalysis.umicroWBC"
      },
      {
        "elementKey": "spacer12",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer12"
      },
      {
        "elementKey": "label55-54",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-54"
      },
      {
        "elementKey": "label55-55",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-55"
      },
      {
        "elementKey": "umicroRBC",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "1",
            "text": "0-1"
          },
          {
            "key": "5",
            "text": "2-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "20",
            "text": "11-20"
          },
          {
            "key": "50",
            "text": "21-50"
          },
          {
            "key": "plus",
            "text": "50+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "33",
        "fqn": "medLabUrinalysis.umicroRBC"
      },
      {
        "elementKey": "spacer13",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer13"
      },
      {
        "elementKey": "label55-56",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-56"
      },
      {
        "elementKey": "label55-57",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-57"
      },
      {
        "elementKey": "umicroEpithelial",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "2",
            "text": "0-2"
          },
          {
            "key": "5",
            "text": "3-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "plus",
            "text": "10+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "34",
        "fqn": "medLabUrinalysis.umicroEpithelial"
      },
      {
        "elementKey": "umicroTypesEpithelial Cells",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "squamous",
            "text": "Squamous"
          },
          {
            "key": "transitional",
            "text": "Transitional"
          },
          {
            "key": "rte",
            "text": "RTE"
          }
        ],
        "tableColumn": "35",
        "fqn": "medLabUrinalysis.umicroTypesEpithelial Cells"
      },
      {
        "elementKey": "label55-58",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-58"
      },
      {
        "elementKey": "label55-59",
        "formIndex": "2",
        "formCss": "form-table-span-4-rows",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-59"
      },
      {
        "elementKey": "umicrocCastsResults1",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "2",
            "text": "0-2"
          },
          {
            "key": "5",
            "text": "3-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "plus",
            "text": "10+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "36",
        "fqn": "medLabUrinalysis.umicrocCastsResults1"
      },
      {
        "elementKey": "umicrocCastsType1",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Hyaline",
            "text": "Hyaline"
          },
          {
            "key": "Granular",
            "text": "Granular"
          },
          {
            "key": "Epithelial",
            "text": "Epithelial"
          },
          {
            "key": "RBC",
            "text": "RBC"
          },
          {
            "key": "WBC",
            "text": "WBC"
          },
          {
            "key": "Waxy",
            "text": "Waxy"
          },
          {
            "key": "Fatty",
            "text": "Fatty"
          }
        ],
        "tableColumn": "37",
        "fqn": "medLabUrinalysis.umicrocCastsType1"
      },
      {
        "elementKey": "label55-60",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-60"
      },
      {
        "elementKey": "umicrocCastsResults2",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "2",
            "text": "0-2"
          },
          {
            "key": "5",
            "text": "3-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "plus",
            "text": "10+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "38",
        "fqn": "medLabUrinalysis.umicrocCastsResults2"
      },
      {
        "elementKey": "umicrocCastsType2",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Hyaline",
            "text": "Hyaline"
          },
          {
            "key": "Granular",
            "text": "Granular"
          },
          {
            "key": "Epithelial",
            "text": "Epithelial"
          },
          {
            "key": "RBC",
            "text": "RBC"
          },
          {
            "key": "WBC",
            "text": "WBC"
          },
          {
            "key": "Waxy",
            "text": "Waxy"
          },
          {
            "key": "Fatty",
            "text": "Fatty"
          }
        ],
        "tableColumn": "39",
        "fqn": "medLabUrinalysis.umicrocCastsType2"
      },
      {
        "elementKey": "label55-61",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-61"
      },
      {
        "elementKey": "umicrocCastsResults3",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "2",
            "text": "0-2"
          },
          {
            "key": "5",
            "text": "3-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "plus",
            "text": "10+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "40",
        "fqn": "medLabUrinalysis.umicrocCastsResults3"
      },
      {
        "elementKey": "umicrocCastsType3",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Hyaline",
            "text": "Hyaline"
          },
          {
            "key": "Granular",
            "text": "Granular"
          },
          {
            "key": "Epithelial",
            "text": "Epithelial"
          },
          {
            "key": "RBC",
            "text": "RBC"
          },
          {
            "key": "WBC",
            "text": "WBC"
          },
          {
            "key": "Waxy",
            "text": "Waxy"
          },
          {
            "key": "Fatty",
            "text": "Fatty"
          }
        ],
        "tableColumn": "41",
        "fqn": "medLabUrinalysis.umicrocCastsType3"
      },
      {
        "elementKey": "label55-62",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-62"
      },
      {
        "elementKey": "umicrocCastsResults4",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "2",
            "text": "0-2"
          },
          {
            "key": "5",
            "text": "3-5"
          },
          {
            "key": "10",
            "text": "6-10"
          },
          {
            "key": "plus",
            "text": "10+"
          },
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "42",
        "fqn": "medLabUrinalysis.umicrocCastsResults4"
      },
      {
        "elementKey": "umicrocCastsType4",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "Hyaline",
            "text": "Hyaline"
          },
          {
            "key": "Granular",
            "text": "Granular"
          },
          {
            "key": "Epithelial",
            "text": "Epithelial"
          },
          {
            "key": "RBC",
            "text": "RBC"
          },
          {
            "key": "WBC",
            "text": "WBC"
          },
          {
            "key": "Waxy",
            "text": "Waxy"
          },
          {
            "key": "Fatty",
            "text": "Fatty"
          }
        ],
        "tableColumn": "43",
        "fqn": "medLabUrinalysis.umicrocCastsType4"
      },
      {
        "elementKey": "label55-63",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-63"
      },
      {
        "elementKey": "label55-64",
        "formIndex": "2",
        "formCss": "form-table-span-2-rows",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-64"
      },
      {
        "elementKey": "umicroCrystalsNonePresent",
        "formIndex": "2",
        "formCss": "form-table-span-3",
        "inputType": "checkbox",
        "tableColumn": "44",
        "fqn": "medLabUrinalysis.umicroCrystalsNonePresent"
      },
      {
        "elementKey": "umicroCrystalsPresent",
        "formIndex": "2",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "calciumOxalate",
            "text": "Calcium Oxalate"
          },
          {
            "key": "calciumCarbonate",
            "text": "Calcium carbonate"
          },
          {
            "key": "uricAcid",
            "text": "Uric Acid"
          },
          {
            "key": "calciumPhosphate",
            "text": "Calcium Phosphate"
          },
          {
            "key": "triplePhosphate",
            "text": "Triple Phosphate"
          },
          {
            "key": "amorphousUrates",
            "text": "Amorphous Urates"
          },
          {
            "key": "amorphousPhosphates",
            "text": "Amorphous Phosphates"
          },
          {
            "key": "cystine",
            "text": "Cystine"
          },
          {
            "key": "tyrosine",
            "text": "Tyrosine"
          },
          {
            "key": "cholesterol",
            "text": "Cholesterol"
          },
          {
            "key": "ammoniumBiurate",
            "text": "Ammonium Biurate"
          },
          {
            "key": "hippuricAcid",
            "text": "Hippuric Acid"
          },
          {
            "key": "bilirubin",
            "text": "Bilirubin"
          },
          {
            "key": "leucine",
            "text": "Leucine"
          },
          {
            "key": "sulfonamides",
            "text": "Sulfonamides"
          }
        ],
        "tableColumn": "45",
        "fqn": "medLabUrinalysis.umicroCrystalsPresent"
      },
      {
        "elementKey": "umicroCrystalsObscures",
        "formIndex": "2",
        "inputType": "boxcheckset",
        "options": [
          {
            "key": "calciumOxalate",
            "text": "Calcium Oxalate"
          },
          {
            "key": "calciumCarbonate",
            "text": "Calcium carbonate"
          },
          {
            "key": "uricAcid",
            "text": "Uric Acid"
          },
          {
            "key": "calciumPhosphate",
            "text": "Calcium Phosphate"
          },
          {
            "key": "triplePhosphate",
            "text": "Triple Phosphate"
          },
          {
            "key": "amorphousUrates",
            "text": "Amorphous Urates"
          },
          {
            "key": "amorphousPhosphates",
            "text": "Amorphous Phosphates"
          },
          {
            "key": "cystine",
            "text": "Cystine"
          },
          {
            "key": "tyrosine",
            "text": "Tyrosine"
          },
          {
            "key": "cholesterol",
            "text": "Cholesterol"
          },
          {
            "key": "ammoniumBiurate",
            "text": "Ammonium Biurate"
          },
          {
            "key": "hippuricAcid",
            "text": "Hippuric Acid"
          },
          {
            "key": "bilirubin",
            "text": "Bilirubin"
          },
          {
            "key": "leucine",
            "text": "Leucine"
          },
          {
            "key": "sulfonamides",
            "text": "Sulfonamides"
          }
        ],
        "tableColumn": "46",
        "fqn": "medLabUrinalysis.umicroCrystalsObscures"
      },
      {
        "elementKey": "label55-65",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-65"
      },
      {
        "elementKey": "label55-66",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-66"
      },
      {
        "elementKey": "umicroYeast",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "47",
        "fqn": "medLabUrinalysis.umicroYeast"
      },
      {
        "elementKey": "label55-67",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-67"
      },
      {
        "elementKey": "label55-68",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-68"
      },
      {
        "elementKey": "label55-69",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-69"
      },
      {
        "elementKey": "umicroBacteria",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "none",
            "text": "None seen"
          },
          {
            "key": "present",
            "text": "Present"
          },
          {
            "key": "field",
            "text": "Obscures field"
          }
        ],
        "tableColumn": "48",
        "fqn": "medLabUrinalysis.umicroBacteria"
      },
      {
        "elementKey": "label55-70",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-70"
      },
      {
        "elementKey": "label55-71",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-71"
      },
      {
        "elementKey": "label55-72",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-72"
      },
      {
        "elementKey": "umicroOther",
        "formIndex": "2",
        "inputType": "checkset",
        "options": [
          {
            "key": "mucous",
            "text": "Mucous"
          },
          {
            "key": "sperm",
            "text": "Sperm"
          }
        ],
        "tableColumn": "49",
        "fqn": "medLabUrinalysis.umicroOther"
      },
      {
        "elementKey": "spacer14",
        "formIndex": "2",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer14"
      },
      {
        "elementKey": "label55-73",
        "formIndex": "2",
        "inputType": "formTableText",
        "fqn": "medLabUrinalysis.label55-73"
      },
      {
        "elementKey": "urnInternal",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "50",
        "fqn": "medLabUrinalysis.urnInternal"
      },
      {
        "elementKey": "urnDiscCE",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "51",
        "fqn": "medLabUrinalysis.urnDiscCE"
      },
      {
        "elementKey": "urnInstructor2",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "52",
        "fqn": "medLabUrinalysis.urnInstructor2"
      },
      {
        "elementKey": "tableQcUrinalysis_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabUrinalysis.name"
      },
      {
        "elementKey": "tableQcUrinalysis_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableQcUrinalysis_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabUrinalysis.profession"
      },
      {
        "elementKey": "tableQcUrinalysis_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabUrinalysis.day"
      },
      {
        "elementKey": "tableQcUrinalysis_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabUrinalysis.time"
      },
      {
        "elementKey": "uqcManOrClinitek",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "radioset",
        "options": [
          {
            "key": "Manual",
            "text": "Manual"
          },
          {
            "key": "Clinitek",
            "text": "Clinitek"
          }
        ],
        "passToFunction": "[uqcQcType uqcAbnormalRefRangeGlucose  uqcAbnormalRefRangeBilirubin uqcAbnormalRefRangeKetone uqcAbnormalRefRangeBlood uqcAbnormalRefRangeProtein uqcAbnormalRefRangeLeukocytes]",
        "tableColumn": "2",
        "fqn": "medLabUrinalysis.uqcManOrClinitek"
      },
      {
        "elementKey": "uqcMultistixLotNum",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabUrinalysis.uqcMultistixLotNum"
      },
      {
        "elementKey": "uqcMultistixExp",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabUrinalysis.uqcMultistixExp"
      },
      {
        "elementKey": "uqcQcType",
        "calculationType": "text",
        "formIndex": "1",
        "formCss": "form-table-span-7 form-table-center-content header-3",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": "Manual reading of QC using Multistix 10G"
          },
          {
            "key": "clinitek",
            "text": "Clinitek reading of QC using Multistix 10G"
          }
        ],
        "tableColumn": "5",
        "fqn": "medLabUrinalysis.uqcQcType"
      },
      {
        "elementKey": "label55-74",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-74"
      },
      {
        "elementKey": "label55-75",
        "formIndex": "1",
        "formCss": "form-table-span-3 form-table-center-content",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-75"
      },
      {
        "elementKey": "label55-76",
        "formIndex": "1",
        "formCss": "form-table-span-3 form-table-center-content",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-76"
      },
      {
        "elementKey": "label55-77",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-77"
      },
      {
        "elementKey": "uqcNormalLotNum",
        "formIndex": "1",
        "formCss": "form-table-span-3",
        "inputType": "text",
        "tableColumn": "6",
        "fqn": "medLabUrinalysis.uqcNormalLotNum"
      },
      {
        "elementKey": "uqcAbnormalLotNum",
        "formIndex": "1",
        "formCss": "form-table-span-3",
        "inputType": "text",
        "tableColumn": "7",
        "fqn": "medLabUrinalysis.uqcAbnormalLotNum"
      },
      {
        "elementKey": "label55-78",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-78"
      },
      {
        "elementKey": "uqcNormalExpDate",
        "formIndex": "1",
        "formCss": "form-table-span-3",
        "inputType": "text",
        "tableColumn": "8",
        "fqn": "medLabUrinalysis.uqcNormalExpDate"
      },
      {
        "elementKey": "uqcAbnormalExpDate",
        "formIndex": "1",
        "formCss": "form-table-span-3",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medLabUrinalysis.uqcAbnormalExpDate"
      },
      {
        "elementKey": "spacer15",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer15"
      },
      {
        "elementKey": "label55-79",
        "formIndex": "1",
        "formCss": "form-table-span-2 form-table-center-content",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-79"
      },
      {
        "elementKey": "label55-80",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-80"
      },
      {
        "elementKey": "label55-81",
        "formIndex": "1",
        "formCss": "form-table-span-2 form-table-center-content",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-81"
      },
      {
        "elementKey": "label55-82",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-82"
      },
      {
        "elementKey": "spacer16",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer16"
      },
      {
        "elementKey": "label55-83",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-83"
      },
      {
        "elementKey": "label55-84",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-84"
      },
      {
        "elementKey": "spacer17",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer17"
      },
      {
        "elementKey": "label55-85",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-85"
      },
      {
        "elementKey": "label55-86",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-86"
      },
      {
        "elementKey": "spacer18",
        "formIndex": "1",
        "inputType": "spacer",
        "fqn": "medLabUrinalysis.spacer18"
      },
      {
        "elementKey": "label55-87",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-87"
      },
      {
        "elementKey": "uqcNormalRunGlucose",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medLabUrinalysis.uqcNormalRunGlucose"
      },
      {
        "elementKey": "uqcNormalRepeatGlucose",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "11",
        "fqn": "medLabUrinalysis.uqcNormalRepeatGlucose"
      },
      {
        "elementKey": "label55-88",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-88"
      },
      {
        "elementKey": "uqcAbnormalRunGlucose",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "12",
        "fqn": "medLabUrinalysis.uqcAbnormalRunGlucose"
      },
      {
        "elementKey": "uqcAbnormalRepeatGlucose",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "13",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatGlucose"
      },
      {
        "elementKey": "uqcAbnormalRefRangeGlucose",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": ">= 5.5 mmol/L"
          },
          {
            "key": "clinitek",
            "text": ">= 3 mmol/L"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeGlucose"
      },
      {
        "elementKey": "label55-89",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-89"
      },
      {
        "elementKey": "uqcNormalRunBilirubin",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "14",
        "fqn": "medLabUrinalysis.uqcNormalRunBilirubin"
      },
      {
        "elementKey": "uqcNormalRepeatBilirubin",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "15",
        "fqn": "medLabUrinalysis.uqcNormalRepeatBilirubin"
      },
      {
        "elementKey": "label55-90",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-90"
      },
      {
        "elementKey": "uqcAbnormalRunBilirubin",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "16",
        "fqn": "medLabUrinalysis.uqcAbnormalRunBilirubin"
      },
      {
        "elementKey": "uqcAbnormalRepeatBilirubin",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "17",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatBilirubin"
      },
      {
        "elementKey": "uqcAbnormalRefRangeBilirubin",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": "Small to Large"
          },
          {
            "key": "clinitek",
            "text": ">= Small"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeBilirubin"
      },
      {
        "elementKey": "label55-91",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-91"
      },
      {
        "elementKey": "uqcNormalRunKetone",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "18",
        "fqn": "medLabUrinalysis.uqcNormalRunKetone"
      },
      {
        "elementKey": "uqcNormalRepeatKetone",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "19",
        "fqn": "medLabUrinalysis.uqcNormalRepeatKetone"
      },
      {
        "elementKey": "label55-92",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-92"
      },
      {
        "elementKey": "uqcAbnormalRunKetone",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "20",
        "fqn": "medLabUrinalysis.uqcAbnormalRunKetone"
      },
      {
        "elementKey": "uqcAbnormalRepeatKetone",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "21",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatKetone"
      },
      {
        "elementKey": "uqcAbnormalRefRangeKetone",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": ">= 0.5 mmol/L"
          },
          {
            "key": "clinitek",
            "text": ">= trace mmol/L"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeKetone"
      },
      {
        "elementKey": "label55-93",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-93"
      },
      {
        "elementKey": "uqcNormalRunSpecific Gravity",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "22",
        "fqn": "medLabUrinalysis.uqcNormalRunSpecific Gravity"
      },
      {
        "elementKey": "uqcNormalRepeatSpecific Gravity",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "23",
        "fqn": "medLabUrinalysis.uqcNormalRepeatSpecific Gravity"
      },
      {
        "elementKey": "label55-94",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-94"
      },
      {
        "elementKey": "uqcAbnormalRunSpecific Gravity",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "24",
        "fqn": "medLabUrinalysis.uqcAbnormalRunSpecific Gravity"
      },
      {
        "elementKey": "uqcAbnormalRepeatSpecific Gravity",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "25",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatSpecific Gravity"
      },
      {
        "elementKey": "label55-95",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-95"
      },
      {
        "elementKey": "label55-96",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-96"
      },
      {
        "elementKey": "uqcNormalRunBlood",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "26",
        "fqn": "medLabUrinalysis.uqcNormalRunBlood"
      },
      {
        "elementKey": "uqcNormalRepeatBlood",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "27",
        "fqn": "medLabUrinalysis.uqcNormalRepeatBlood"
      },
      {
        "elementKey": "label55-97",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-97"
      },
      {
        "elementKey": "uqcAbnormalRunBlood",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "28",
        "fqn": "medLabUrinalysis.uqcAbnormalRunBlood"
      },
      {
        "elementKey": "uqcAbnormalRepeatBlood",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "29",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatBlood"
      },
      {
        "elementKey": "uqcAbnormalRefRangeBlood",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": ">= Small"
          },
          {
            "key": "clinitek",
            "text": ">= 25 Ery/μL"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeBlood"
      },
      {
        "elementKey": "label55-98",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-98"
      },
      {
        "elementKey": "uqcNormalRunpH",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "30",
        "fqn": "medLabUrinalysis.uqcNormalRunpH"
      },
      {
        "elementKey": "uqcNormalRepeatpH",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "31",
        "fqn": "medLabUrinalysis.uqcNormalRepeatpH"
      },
      {
        "elementKey": "label55-99",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-99"
      },
      {
        "elementKey": "uqcAbnormalRunpH",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "32",
        "fqn": "medLabUrinalysis.uqcAbnormalRunpH"
      },
      {
        "elementKey": "uqcAbnormalRepeatpH",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "33",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatpH"
      },
      {
        "elementKey": "label55-100",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-100"
      },
      {
        "elementKey": "label55-101",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-101"
      },
      {
        "elementKey": "uqcNormalRunProtein",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "34",
        "fqn": "medLabUrinalysis.uqcNormalRunProtein"
      },
      {
        "elementKey": "uqcNormalRepeatProtein",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "35",
        "fqn": "medLabUrinalysis.uqcNormalRepeatProtein"
      },
      {
        "elementKey": "label55-102",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-102"
      },
      {
        "elementKey": "uqcAbnormalRunProtein",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "36",
        "fqn": "medLabUrinalysis.uqcAbnormalRunProtein"
      },
      {
        "elementKey": "uqcAbnormalRepeatProtein",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "37",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatProtein"
      },
      {
        "elementKey": "uqcAbnormalRefRangeProtein",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": ">= 0.3 g/L"
          },
          {
            "key": "clinitek",
            "text": ">= 1.0 g/L"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeProtein"
      },
      {
        "elementKey": "label55-103",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-103"
      },
      {
        "elementKey": "uqcNormalRunUrobilinogen",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "38",
        "fqn": "medLabUrinalysis.uqcNormalRunUrobilinogen"
      },
      {
        "elementKey": "uqcNormalRepeatUrobilinogen",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "39",
        "fqn": "medLabUrinalysis.uqcNormalRepeatUrobilinogen"
      },
      {
        "elementKey": "label55-104",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-104"
      },
      {
        "elementKey": "uqcAbnormalRunUrobilinogen",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "40",
        "fqn": "medLabUrinalysis.uqcAbnormalRunUrobilinogen"
      },
      {
        "elementKey": "uqcAbnormalRepeatUrobilinogen",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "41",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatUrobilinogen"
      },
      {
        "elementKey": "label55-105",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-105"
      },
      {
        "elementKey": "label55-106",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-106"
      },
      {
        "elementKey": "uqcNormalRunNitrite",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "42",
        "fqn": "medLabUrinalysis.uqcNormalRunNitrite"
      },
      {
        "elementKey": "uqcNormalRepeatNitrite",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "43",
        "fqn": "medLabUrinalysis.uqcNormalRepeatNitrite"
      },
      {
        "elementKey": "label55-107",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-107"
      },
      {
        "elementKey": "uqcAbnormalRunNitrite",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "44",
        "fqn": "medLabUrinalysis.uqcAbnormalRunNitrite"
      },
      {
        "elementKey": "uqcAbnormalRepeatNitrite",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "45",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatNitrite"
      },
      {
        "elementKey": "label55-108",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-108"
      },
      {
        "elementKey": "label55-109",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-109"
      },
      {
        "elementKey": "uqcNormalRunLeukocytes",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "46",
        "fqn": "medLabUrinalysis.uqcNormalRunLeukocytes"
      },
      {
        "elementKey": "uqcNormalRepeatLeukocytes",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "47",
        "fqn": "medLabUrinalysis.uqcNormalRepeatLeukocytes"
      },
      {
        "elementKey": "label55-110",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-110"
      },
      {
        "elementKey": "uqcAbnormalRunLeukocytes",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "48",
        "fqn": "medLabUrinalysis.uqcAbnormalRunLeukocytes"
      },
      {
        "elementKey": "uqcAbnormalRepeatLeukocytes",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "49",
        "fqn": "medLabUrinalysis.uqcAbnormalRepeatLeukocytes"
      },
      {
        "elementKey": "uqcAbnormalRefRangeLeukocytes",
        "calculationType": "text",
        "formIndex": "1",
        "inputType": "calculatedText",
        "options": [
          {
            "key": "manual",
            "text": ">= Small"
          },
          {
            "key": "clinitek",
            "text": ">= 70 Leu//μL"
          }
        ],
        "fqn": "medLabUrinalysis.uqcAbnormalRefRangeLeukocytes"
      },
      {
        "elementKey": "uqcPerformedBy",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "50",
        "fqn": "medLabUrinalysis.uqcPerformedBy"
      },
      {
        "elementKey": "label55-111",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-111"
      },
      {
        "elementKey": "uqcSomeQcUnacceptable",
        "formIndex": "1",
        "formCss": "uqcSomeQcUnacceptable",
        "inputType": "radioset",
        "options": [
          {
            "key": "Yes",
            "text": "Yes"
          },
          {
            "key": "No",
            "text": "No"
          }
        ],
        "tableColumn": "51",
        "fqn": "medLabUrinalysis.uqcSomeQcUnacceptable"
      },
      {
        "elementKey": "label55-112",
        "formIndex": "1",
        "formCss": "grid-span-2-columns",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-112"
      },
      {
        "elementKey": "label55-113",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-113"
      },
      {
        "elementKey": "label55-114",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabUrinalysis.label55-114"
      },
      {
        "elementKey": "uqcPendAnal_1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "52",
        "fqn": "medLabUrinalysis.uqcPendAnal_1"
      },
      {
        "elementKey": "uqcPendResult_1",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "53",
        "fqn": "medLabUrinalysis.uqcPendResult_1"
      },
      {
        "elementKey": "uqcPendAnal_2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "54",
        "fqn": "medLabUrinalysis.uqcPendAnal_2"
      },
      {
        "elementKey": "uqcPendResult_2",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "55",
        "fqn": "medLabUrinalysis.uqcPendResult_2"
      },
      {
        "elementKey": "uqcPendAnal_3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "56",
        "fqn": "medLabUrinalysis.uqcPendAnal_3"
      },
      {
        "elementKey": "uqcPendResult_3",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "57",
        "fqn": "medLabUrinalysis.uqcPendResult_3"
      },
      {
        "elementKey": "uqcPendAnal_4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "58",
        "fqn": "medLabUrinalysis.uqcPendAnal_4"
      },
      {
        "elementKey": "uqcPendResult_4",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "59",
        "fqn": "medLabUrinalysis.uqcPendResult_4"
      }
    ],
    "pageElements": {
      "tableQcUrinalysis": {
        "elementKey": "tableQcUrinalysis",
        "pageElementIndex": "1",
        "pageKey": "medLabUrinalysis",
        "tableKey": "tableQcUrinalysis",
        "isTable": true,
        "form": {
          "elementKey": "tableQcUrinalysis",
          "label": "Urinalysis QC",
          "addButtonText": "Create a urinalysis QC report",
          "formKey": "tableQcUrinalysis",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabUrinalysis.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableQcUrinalysis_name",
                "tableQcUrinalysis_profession",
                "tableQcUrinalysis_day",
                "tableQcUrinalysis_time"
              ]
            },
            {
              "elementKey": "cGroup46-2",
              "formCss": "grid-left-to-right-2",
              "fqn": "medLabUrinalysis.cGroup46-2",
              "gIndex": "2",
              "gChildren": [
                "uqcManOrClinitek",
                "uqcMultistixLotNum",
                "uqcMultistixExp"
              ]
            },
            {
              "elementKey": "cGroup46-3",
              "formCss": "med-lab-urin-qc-table form-table-group",
              "fqn": "medLabUrinalysis.cGroup46-3",
              "gIndex": "3",
              "gChildren": [
                "uqcQcType",
                "label55-74",
                "label55-75",
                "label55-76",
                "label55-77",
                "uqcNormalLotNum",
                "uqcAbnormalLotNum",
                "label55-78",
                "uqcNormalExpDate",
                "uqcAbnormalExpDate",
                "spacer15",
                "label55-79",
                "label55-80",
                "label55-81",
                "label55-82",
                "spacer16",
                "label55-83",
                "label55-84",
                "spacer17",
                "label55-85",
                "label55-86",
                "spacer18",
                "label55-87",
                "uqcNormalRunGlucose",
                "uqcNormalRepeatGlucose",
                "label55-88",
                "uqcAbnormalRunGlucose",
                "uqcAbnormalRepeatGlucose",
                "uqcAbnormalRefRangeGlucose",
                "label55-89",
                "uqcNormalRunBilirubin",
                "uqcNormalRepeatBilirubin",
                "label55-90",
                "uqcAbnormalRunBilirubin",
                "uqcAbnormalRepeatBilirubin",
                "uqcAbnormalRefRangeBilirubin",
                "label55-91",
                "uqcNormalRunKetone",
                "uqcNormalRepeatKetone",
                "label55-92",
                "uqcAbnormalRunKetone",
                "uqcAbnormalRepeatKetone",
                "uqcAbnormalRefRangeKetone",
                "label55-93",
                "uqcNormalRunSpecific Gravity",
                "uqcNormalRepeatSpecific Gravity",
                "label55-94",
                "uqcAbnormalRunSpecific Gravity",
                "uqcAbnormalRepeatSpecific Gravity",
                "label55-95",
                "label55-96",
                "uqcNormalRunBlood",
                "uqcNormalRepeatBlood",
                "label55-97",
                "uqcAbnormalRunBlood",
                "uqcAbnormalRepeatBlood",
                "uqcAbnormalRefRangeBlood",
                "label55-98",
                "uqcNormalRunpH",
                "uqcNormalRepeatpH",
                "label55-99",
                "uqcAbnormalRunpH",
                "uqcAbnormalRepeatpH",
                "label55-100",
                "label55-101",
                "uqcNormalRunProtein",
                "uqcNormalRepeatProtein",
                "label55-102",
                "uqcAbnormalRunProtein",
                "uqcAbnormalRepeatProtein",
                "uqcAbnormalRefRangeProtein",
                "label55-103",
                "uqcNormalRunUrobilinogen",
                "uqcNormalRepeatUrobilinogen",
                "label55-104",
                "uqcAbnormalRunUrobilinogen",
                "uqcAbnormalRepeatUrobilinogen",
                "label55-105",
                "label55-106",
                "uqcNormalRunNitrite",
                "uqcNormalRepeatNitrite",
                "label55-107",
                "uqcAbnormalRunNitrite",
                "uqcAbnormalRepeatNitrite",
                "label55-108",
                "label55-109",
                "uqcNormalRunLeukocytes",
                "uqcNormalRepeatLeukocytes",
                "label55-110",
                "uqcAbnormalRunLeukocytes",
                "uqcAbnormalRepeatLeukocytes",
                "uqcAbnormalRefRangeLeukocytes"
              ]
            },
            {
              "elementKey": "cGroup46-4",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabUrinalysis.cGroup46-4",
              "gIndex": "4",
              "gChildren": [
                "uqcPerformedBy",
                "label55-111",
                "uqcSomeQcUnacceptable"
              ]
            },
            {
              "elementKey": "cGroup46-5",
              "formCss": "med-lab-urin-qc-pending-table form-table-group",
              "fqn": "medLabUrinalysis.cGroup46-5",
              "gIndex": "5",
              "gChildren": [
                "label55-112",
                "label55-113",
                "label55-114",
                "uqcPendAnal_1",
                "uqcPendResult_1",
                "uqcPendAnal_2",
                "uqcPendResult_2",
                "uqcPendAnal_3",
                "uqcPendResult_3",
                "uqcPendAnal_4",
                "uqcPendResult_4"
              ]
            }
          ],
          "ehr_data": {
            "tableQcUrinalysis_name": "",
            "tableQcUrinalysis_profession": "",
            "tableQcUrinalysis_day": "",
            "tableQcUrinalysis_time": "",
            "uqcManOrClinitek": "",
            "uqcMultistixLotNum": "",
            "uqcMultistixExp": "",
            "uqcQcType": "",
            "uqcNormalLotNum": "",
            "uqcAbnormalLotNum": "",
            "uqcNormalExpDate": "",
            "uqcAbnormalExpDate": "",
            "uqcNormalRunGlucose": "",
            "uqcNormalRepeatGlucose": "",
            "uqcAbnormalRunGlucose": "",
            "uqcAbnormalRepeatGlucose": "",
            "uqcAbnormalRefRangeGlucose": "",
            "uqcNormalRunBilirubin": "",
            "uqcNormalRepeatBilirubin": "",
            "uqcAbnormalRunBilirubin": "",
            "uqcAbnormalRepeatBilirubin": "",
            "uqcAbnormalRefRangeBilirubin": "",
            "uqcNormalRunKetone": "",
            "uqcNormalRepeatKetone": "",
            "uqcAbnormalRunKetone": "",
            "uqcAbnormalRepeatKetone": "",
            "uqcAbnormalRefRangeKetone": "",
            "uqcNormalRunSpecific Gravity": "",
            "uqcNormalRepeatSpecific Gravity": "",
            "uqcAbnormalRunSpecific Gravity": "",
            "uqcAbnormalRepeatSpecific Gravity": "",
            "uqcNormalRunBlood": "",
            "uqcNormalRepeatBlood": "",
            "uqcAbnormalRunBlood": "",
            "uqcAbnormalRepeatBlood": "",
            "uqcAbnormalRefRangeBlood": "",
            "uqcNormalRunpH": "",
            "uqcNormalRepeatpH": "",
            "uqcAbnormalRunpH": "",
            "uqcAbnormalRepeatpH": "",
            "uqcNormalRunProtein": "",
            "uqcNormalRepeatProtein": "",
            "uqcAbnormalRunProtein": "",
            "uqcAbnormalRepeatProtein": "",
            "uqcAbnormalRefRangeProtein": "",
            "uqcNormalRunUrobilinogen": "",
            "uqcNormalRepeatUrobilinogen": "",
            "uqcAbnormalRunUrobilinogen": "",
            "uqcAbnormalRepeatUrobilinogen": "",
            "uqcNormalRunNitrite": "",
            "uqcNormalRepeatNitrite": "",
            "uqcAbnormalRunNitrite": "",
            "uqcAbnormalRepeatNitrite": "",
            "uqcNormalRunLeukocytes": "",
            "uqcNormalRepeatLeukocytes": "",
            "uqcAbnormalRunLeukocytes": "",
            "uqcAbnormalRepeatLeukocytes": "",
            "uqcAbnormalRefRangeLeukocytes": "",
            "uqcPerformedBy": "",
            "uqcSomeQcUnacceptable": "",
            "uqcPendAnal_1": "",
            "uqcPendResult_1": "",
            "uqcPendAnal_2": "",
            "uqcPendResult_2": "",
            "uqcPendAnal_3": "",
            "uqcPendResult_3": "",
            "uqcPendAnal_4": "",
            "uqcPendResult_4": ""
          }
        },
        "fqn": "medLabUrinalysis.tableQcUrinalysis",
        "tableChildren": [
          "tableQcUrinalysis_id",
          "tableQcUrinalysis_day",
          "tableQcUrinalysis_time",
          "uqcManOrClinitek",
          "uqcMultistixLotNum",
          "uqcMultistixExp",
          "uqcQcType",
          "uqcNormalLotNum",
          "uqcAbnormalLotNum",
          "uqcNormalExpDate",
          "uqcAbnormalExpDate",
          "uqcNormalRunGlucose",
          "uqcNormalRepeatGlucose",
          "uqcAbnormalRunGlucose",
          "uqcAbnormalRepeatGlucose",
          "uqcNormalRunBilirubin",
          "uqcNormalRepeatBilirubin",
          "uqcAbnormalRunBilirubin",
          "uqcAbnormalRepeatBilirubin",
          "uqcNormalRunKetone",
          "uqcNormalRepeatKetone",
          "uqcAbnormalRunKetone",
          "uqcAbnormalRepeatKetone",
          "uqcNormalRunSpecific Gravity",
          "uqcNormalRepeatSpecific Gravity",
          "uqcAbnormalRunSpecific Gravity",
          "uqcAbnormalRepeatSpecific Gravity",
          "uqcNormalRunBlood",
          "uqcNormalRepeatBlood",
          "uqcAbnormalRunBlood",
          "uqcAbnormalRepeatBlood",
          "uqcNormalRunpH",
          "uqcNormalRepeatpH",
          "uqcAbnormalRunpH",
          "uqcAbnormalRepeatpH",
          "uqcNormalRunProtein",
          "uqcNormalRepeatProtein",
          "uqcAbnormalRunProtein",
          "uqcAbnormalRepeatProtein",
          "uqcNormalRunUrobilinogen",
          "uqcNormalRepeatUrobilinogen",
          "uqcAbnormalRunUrobilinogen",
          "uqcAbnormalRepeatUrobilinogen",
          "uqcNormalRunNitrite",
          "uqcNormalRepeatNitrite",
          "uqcAbnormalRunNitrite",
          "uqcAbnormalRepeatNitrite",
          "uqcNormalRunLeukocytes",
          "uqcNormalRepeatLeukocytes",
          "uqcAbnormalRunLeukocytes",
          "uqcAbnormalRepeatLeukocytes",
          "uqcPerformedBy",
          "uqcSomeQcUnacceptable",
          "uqcPendAnal_1",
          "uqcPendResult_1",
          "uqcPendAnal_2",
          "uqcPendResult_2",
          "uqcPendAnal_3",
          "uqcPendResult_3",
          "uqcPendAnal_4",
          "uqcPendResult_4",
          "tableQcUrinalysis_name",
          "tableQcUrinalysis_profession"
        ],
        "hasRecHeader": true
      },
      "tableUrinalysis": {
        "elementKey": "tableUrinalysis",
        "pageElementIndex": "2",
        "pageKey": "medLabUrinalysis",
        "tableKey": "tableUrinalysis",
        "isTable": true,
        "form": {
          "elementKey": "tableUrinalysis",
          "label": "Urinalysis",
          "addButtonText": "Create a urinalysis report",
          "formKey": "tableUrinalysis",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabUrinalysis.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableUrinalysis_name",
                "tableUrinalysis_profession",
                "tableUrinalysis_day",
                "tableUrinalysis_time"
              ]
            },
            {
              "elementKey": "cGroup46-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabUrinalysis.cGroup46-2",
              "gIndex": "2",
              "gChildren": [
                "urinAccession",
                "urinCollDay",
                "urinCollTime",
                "urinOrderPhys"
              ]
            },
            {
              "elementKey": "cGroup46-3",
              "formCss": "grid-left-to-right-2",
              "fqn": "medLabUrinalysis.cGroup46-3",
              "gIndex": "3",
              "gChildren": [
                "urinTransparency",
                "urinColour"
              ]
            },
            {
              "elementKey": "cGroup46-4",
              "formCss": "urin-macro-exam form-table-group",
              "fqn": "medLabUrinalysis.cGroup46-4",
              "gIndex": "4",
              "gChildren": [
                "label55-25",
                "label55-26",
                "label55-27",
                "label55-28",
                "label55-29",
                "uchemManGlucose",
                "uchemAutoGlucose",
                "label55-30",
                "label55-31",
                "uchemManBilirubin",
                "uchemAutoBilirubin",
                "label55-32",
                "label55-33",
                "uchemManKetone",
                "uchemAutoKetone",
                "label55-34",
                "label55-35",
                "uchemManSpecificGravity",
                "uchemAutoSpecificGravity",
                "label55-36",
                "label55-37",
                "uchemManBlood",
                "uchemAutoBlood",
                "label55-38",
                "label55-39",
                "uchemManpH",
                "uchemAutopH",
                "label55-40",
                "label55-41",
                "uchemManProtein",
                "uchemAutoProtein",
                "label55-42",
                "label55-43",
                "uchemManUrobilinogen",
                "uchemAutoUrobilinogen",
                "label55-44",
                "label55-45",
                "uchemManNitrite",
                "uchemAutoNitrite",
                "label55-46",
                "label55-47",
                "uchemManLeukocytes",
                "uchemAutoLeukocytes",
                "label55-48"
              ]
            },
            {
              "elementKey": "cGroup46-5",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabUrinalysis.cGroup46-5",
              "gIndex": "5",
              "gChildren": [
                "uchemMicroIndicater",
                "urnDiscMA",
                "uchemInstructorlComment"
              ]
            },
            {
              "elementKey": "cGroup46-6",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabUrinalysis.cGroup46-6",
              "gIndex": "6",
              "gChildren": [
                "uchemSulfosalicylic",
                "uchemIcotest"
              ]
            },
            {
              "elementKey": "cGroup46-7",
              "formCss": "med-lab-urin-micro form-table-group",
              "fqn": "medLabUrinalysis.cGroup46-7",
              "gIndex": "7",
              "gChildren": [
                "label55-49",
                "label55-50",
                "label55-51",
                "label55-52",
                "label55-53",
                "umicroWBC",
                "spacer12",
                "label55-54",
                "label55-55",
                "umicroRBC",
                "spacer13",
                "label55-56",
                "label55-57",
                "umicroEpithelial",
                "umicroTypesEpithelial Cells",
                "label55-58",
                "label55-59",
                "umicrocCastsResults1",
                "umicrocCastsType1",
                "label55-60",
                "umicrocCastsResults2",
                "umicrocCastsType2",
                "label55-61",
                "umicrocCastsResults3",
                "umicrocCastsType3",
                "label55-62",
                "umicrocCastsResults4",
                "umicrocCastsType4",
                "label55-63",
                "label55-64",
                "umicroCrystalsNonePresent",
                "umicroCrystalsPresent",
                "umicroCrystalsObscures",
                "label55-65",
                "label55-66",
                "umicroYeast",
                "label55-67",
                "label55-68",
                "label55-69",
                "umicroBacteria",
                "label55-70",
                "label55-71",
                "label55-72",
                "umicroOther",
                "spacer14",
                "label55-73"
              ]
            },
            {
              "elementKey": "cGroup46-8",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabUrinalysis.cGroup46-8",
              "gIndex": "8",
              "gChildren": [
                "urnInternal",
                "urnDiscCE",
                "urnInstructor2"
              ]
            }
          ],
          "ehr_data": {
            "tableUrinalysis_name": "",
            "tableUrinalysis_profession": "",
            "tableUrinalysis_day": "",
            "tableUrinalysis_time": "",
            "urinAccession": "",
            "urinCollDay": "Today",
            "urinCollTime": "",
            "urinOrderPhys": "",
            "urinTransparency": "",
            "urinColour": "",
            "uchemManGlucose": "",
            "uchemAutoGlucose": "",
            "uchemManBilirubin": "",
            "uchemAutoBilirubin": "",
            "uchemManKetone": "",
            "uchemAutoKetone": "",
            "uchemManSpecificGravity": "",
            "uchemAutoSpecificGravity": "",
            "uchemManBlood": "",
            "uchemAutoBlood": "",
            "uchemManpH": "",
            "uchemAutopH": "",
            "uchemManProtein": "",
            "uchemAutoProtein": "",
            "uchemManUrobilinogen": "",
            "uchemAutoUrobilinogen": "",
            "uchemManNitrite": "",
            "uchemAutoNitrite": "",
            "uchemManLeukocytes": "",
            "uchemAutoLeukocytes": "",
            "uchemMicroIndicater": "TRUE",
            "urnDiscMA": "",
            "uchemInstructorlComment": "",
            "uchemSulfosalicylic": "",
            "uchemIcotest": "",
            "umicroWBC": "",
            "umicroRBC": "",
            "umicroEpithelial": "",
            "umicroTypesEpithelial Cells": "",
            "umicrocCastsResults1": "",
            "umicrocCastsType1": "",
            "umicrocCastsResults2": "",
            "umicrocCastsType2": "",
            "umicrocCastsResults3": "",
            "umicrocCastsType3": "",
            "umicrocCastsResults4": "",
            "umicrocCastsType4": "",
            "umicroCrystalsNonePresent": "",
            "umicroCrystalsPresent": "",
            "umicroCrystalsObscures": "",
            "umicroYeast": "",
            "umicroBacteria": "",
            "umicroOther": "",
            "urnInternal": "",
            "urnDiscCE": "",
            "urnInstructor2": ""
          }
        },
        "fqn": "medLabUrinalysis.tableUrinalysis",
        "tableChildren": [
          "tableUrinalysis_id",
          "tableUrinalysis_day",
          "tableUrinalysis_time",
          "urinAccession",
          "urinCollDay",
          "urinCollTime",
          "urinOrderPhys",
          "urinTransparency",
          "urinColour",
          "uchemManGlucose",
          "uchemAutoGlucose",
          "uchemManBilirubin",
          "uchemAutoBilirubin",
          "uchemManKetone",
          "uchemAutoKetone",
          "uchemManSpecificGravity",
          "uchemAutoSpecificGravity",
          "uchemManBlood",
          "uchemAutoBlood",
          "uchemManpH",
          "uchemAutopH",
          "uchemManProtein",
          "uchemAutoProtein",
          "uchemManUrobilinogen",
          "uchemAutoUrobilinogen",
          "uchemManNitrite",
          "uchemAutoNitrite",
          "uchemManLeukocytes",
          "uchemAutoLeukocytes",
          "uchemMicroIndicater",
          "urnDiscMA",
          "uchemInstructorlComment",
          "uchemSulfosalicylic",
          "uchemIcotest",
          "umicroWBC",
          "umicroRBC",
          "umicroEpithelial",
          "umicroTypesEpithelial Cells",
          "umicrocCastsResults1",
          "umicrocCastsType1",
          "umicrocCastsResults2",
          "umicrocCastsType2",
          "umicrocCastsResults3",
          "umicrocCastsType3",
          "umicrocCastsResults4",
          "umicrocCastsType4",
          "umicroCrystalsNonePresent",
          "umicroCrystalsPresent",
          "umicroCrystalsObscures",
          "umicroYeast",
          "umicroBacteria",
          "umicroOther",
          "urnInternal",
          "urnDiscCE",
          "urnInstructor2",
          "tableUrinalysis_name",
          "tableUrinalysis_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "orderSets": {
    "pageDataKey": "orderSets",
    "pIndex": "48",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "insulinOrderSets_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "orderSets.name"
      },
      {
        "elementKey": "insulinOrderSets_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "insulinOrderSets_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "orderSets.profession"
      },
      {
        "elementKey": "insulinOrderSets_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "orderSets.day"
      },
      {
        "elementKey": "insulinOrderSets_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "orderSets.time"
      },
      {
        "elementKey": "insulinOrderSetForm",
        "formIndex": "1",
        "formOption": "insulin-orders",
        "inputType": "custom_form",
        "tableColumn": "2",
        "fqn": "orderSets.insulinOrderSetForm"
      },
      {
        "elementKey": "preprintOrderSet_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "orderSets.name"
      },
      {
        "elementKey": "preprintOrderSet_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "preprintOrderSet_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "orderSets.profession"
      },
      {
        "elementKey": "preprintOrderSet_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "orderSets.day"
      },
      {
        "elementKey": "preprintOrderSet_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "orderSets.time"
      },
      {
        "elementKey": "preOrderSetFile",
        "formIndex": "2",
        "inputType": "ehrFile",
        "tableColumn": "2",
        "fqn": "orderSets.preOrderSetFile"
      }
    ],
    "pageElements": {
      "insulinOrderSets": {
        "elementKey": "insulinOrderSets",
        "pageElementIndex": "1",
        "pageKey": "orderSets",
        "tableKey": "insulinOrderSets",
        "isTable": true,
        "form": {
          "elementKey": "insulinOrderSets",
          "label": "Insulin",
          "addButtonText": "Add an Insulin Order Set",
          "formKey": "insulinOrderSets",
          "ehr_groups": [
            {
              "elementKey": "cGroup48-1",
              "formCss": "record-header",
              "fqn": "orderSets.cGroup48-1",
              "gIndex": "1",
              "gChildren": [
                "insulinOrderSets_name",
                "insulinOrderSets_profession",
                "insulinOrderSets_day",
                "insulinOrderSets_time"
              ]
            },
            {
              "elementKey": "cGroup48-2",
              "formCss": "grid-left-to-right-1",
              "fqn": "orderSets.cGroup48-2",
              "gIndex": "2",
              "gChildren": [
                "insulinOrderSetForm"
              ]
            }
          ],
          "ehr_data": {
            "insulinOrderSets_name": "",
            "insulinOrderSets_profession": "",
            "insulinOrderSets_day": "",
            "insulinOrderSets_time": "",
            "insulinOrderSetForm": ""
          }
        },
        "fqn": "orderSets.insulinOrderSets",
        "tableChildren": [
          "insulinOrderSets_id",
          "insulinOrderSets_day",
          "insulinOrderSets_time",
          "insulinOrderSetForm",
          "insulinOrderSets_name",
          "insulinOrderSets_profession"
        ],
        "hasRecHeader": true
      },
      "preprintOrderSet": {
        "elementKey": "preprintOrderSet",
        "pageElementIndex": "2",
        "pageKey": "orderSets",
        "tableKey": "preprintOrderSet",
        "isTable": true,
        "form": {
          "elementKey": "preprintOrderSet",
          "label": "Preprinted",
          "addButtonText": "Add a preprinted order set",
          "formKey": "preprintOrderSet",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "orderSets.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "preprintOrderSet_name",
                "preprintOrderSet_profession",
                "preprintOrderSet_day",
                "preprintOrderSet_time"
              ]
            },
            {
              "elementKey": "cGroup48-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "orderSets.cGroup48-2",
              "gIndex": "2",
              "gChildren": [
                "preOrderSetFile"
              ]
            }
          ],
          "ehr_data": {
            "preprintOrderSet_name": "",
            "preprintOrderSet_profession": "",
            "preprintOrderSet_day": "",
            "preprintOrderSet_time": "",
            "preOrderSetFile": ""
          }
        },
        "fqn": "orderSets.preprintOrderSet",
        "tableChildren": [
          "preprintOrderSet_id",
          "preprintOrderSet_day",
          "preprintOrderSet_time",
          "preOrderSetFile",
          "preprintOrderSet_name",
          "preprintOrderSet_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medLabAccession": {
    "pageDataKey": "medLabAccession",
    "pIndex": "50",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "mlAccessioning_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.name"
      },
      {
        "elementKey": "mlAccessioning_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "mlAccessioning_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.profession"
      },
      {
        "elementKey": "mlAccessioning_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabAccession.day"
      },
      {
        "elementKey": "mlAccessioning_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabAccession.time"
      },
      {
        "elementKey": "accessionID",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabAccession.accessionID"
      },
      {
        "elementKey": "accRequisitionID",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabAccession.accRequisitionID"
      },
      {
        "elementKey": "accSpecimenType",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Blood",
            "text": "Blood"
          },
          {
            "key": "Urine",
            "text": "Urine"
          },
          {
            "key": "Tissue",
            "text": "Tissue"
          },
          {
            "key": "Saliva",
            "text": "Saliva"
          },
          {
            "key": "Stool",
            "text": "Stool"
          },
          {
            "key": "Swab",
            "text": "Swab"
          },
          {
            "key": "Sputum",
            "text": "Sputum"
          },
          {
            "key": "Fluid",
            "text": "Fluid"
          },
          {
            "key": "Bone",
            "text": "Bone"
          },
          {
            "key": "Hair",
            "text": "Hair"
          }
        ],
        "tableColumn": "4",
        "fqn": "medLabAccession.accSpecimenType"
      },
      {
        "elementKey": "accTestPanel",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "checkset",
        "options": [
          {
            "key": "cbc",
            "text": "CBC (Complete Blood Count)"
          },
          {
            "key": "hba1c",
            "text": "HbA1c (Glycated Hemoglobin)"
          },
          {
            "key": "ptinr",
            "text": "PT/INR (Prothrombin Time/International Normalized Ratio)"
          },
          {
            "key": "coag",
            "text": "Coagulation Profile"
          },
          {
            "key": "cmp",
            "text": "CMP (Comprehensive Metabolic Panel)"
          },
          {
            "key": "glufast",
            "text": "Glucose, Fasting"
          },
          {
            "key": "glurandom",
            "text": "Glucose, Random"
          },
          {
            "key": "iron",
            "text": "Iron Studies"
          },
          {
            "key": "lipid",
            "text": "Lipid Panel"
          },
          {
            "key": "psa",
            "text": "PSA (Prostate-Specific Antigen)"
          },
          {
            "key": "thyroid",
            "text": "Thyroid Panel"
          },
          {
            "key": "troponin",
            "text": "Troponin"
          },
          {
            "key": "tsh",
            "text": "TSH (Thyroid-Stimulating Hormone)"
          },
          {
            "key": "vitd",
            "text": "Vitamin D Levels"
          },
          {
            "key": "pregblood",
            "text": "Pregnancy test, blood"
          },
          {
            "key": "pregurin",
            "text": "Pregnancy test, urine"
          },
          {
            "key": "urinalysis",
            "text": "Urinalysis"
          },
          {
            "key": "bgasa",
            "text": "Blood gas, arterial"
          },
          {
            "key": "bgasv",
            "text": "Blood gas, veneous"
          },
          {
            "key": "bgasf",
            "text": "Blood gase, fetal cord"
          },
          {
            "key": "hepatitisc",
            "text": "Hepatitis C Antibody Test"
          },
          {
            "key": "other",
            "text": "Other"
          }
        ],
        "tableColumn": "5",
        "fqn": "medLabAccession.accTestPanel"
      },
      {
        "elementKey": "accTestOther",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "6",
        "fqn": "medLabAccession.accTestOther"
      },
      {
        "elementKey": "preAnalytic",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "7",
        "fqn": "medLabAccession.preAnalytic"
      },
      {
        "elementKey": "postAnalytic",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "8",
        "fqn": "medLabAccession.postAnalytic"
      },
      {
        "elementKey": "collectionDate",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medLabAccession.collectionDate"
      },
      {
        "elementKey": "collectionTime",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "10",
        "fqn": "medLabAccession.collectionTime"
      },
      {
        "elementKey": "collectionLocation",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "11",
        "fqn": "medLabAccession.collectionLocation"
      },
      {
        "elementKey": "accComments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "12",
        "fqn": "medLabAccession.accComments"
      },
      {
        "elementKey": "mlChain_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.name"
      },
      {
        "elementKey": "mlChain_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "mlChain_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.profession"
      },
      {
        "elementKey": "mlChain_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabAccession.day"
      },
      {
        "elementKey": "mlChain_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabAccession.time"
      },
      {
        "elementKey": "chainAccessionID",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabAccession.chainAccessionID"
      },
      {
        "elementKey": "chainEvent",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabAccession.chainEvent"
      },
      {
        "elementKey": "chainDate",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabAccession.chainDate"
      },
      {
        "elementKey": "chainTime",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabAccession.chainTime"
      },
      {
        "elementKey": "chainDetails",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "6",
        "fqn": "medLabAccession.chainDetails"
      },
      {
        "elementKey": "chainComments",
        "formIndex": "2",
        "inputType": "textarea",
        "tableColumn": "7",
        "fqn": "medLabAccession.chainComments"
      },
      {
        "elementKey": "mlTestStatus_name",
        "formIndex": "3",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.name"
      },
      {
        "elementKey": "mlTestStatus_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "mlTestStatus_profession",
        "formIndex": "3",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabAccession.profession"
      },
      {
        "elementKey": "mlTestStatus_day",
        "formIndex": "3",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabAccession.day"
      },
      {
        "elementKey": "mlTestStatus_time",
        "formIndex": "3",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabAccession.time"
      },
      {
        "elementKey": "testAccessionID",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabAccession.testAccessionID"
      },
      {
        "elementKey": "testStatus",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabAccession.testStatus"
      },
      {
        "elementKey": "testDate",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabAccession.testDate"
      },
      {
        "elementKey": "testTime",
        "formIndex": "3",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabAccession.testTime"
      },
      {
        "elementKey": "testComments",
        "formIndex": "3",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "medLabAccession.testComments"
      }
    ],
    "pageElements": {
      "mlAccessioning": {
        "elementKey": "mlAccessioning",
        "pageElementIndex": "1",
        "pageKey": "medLabAccession",
        "tableKey": "mlAccessioning",
        "isTable": true,
        "form": {
          "elementKey": "mlAccessioning",
          "label": "Accessioning",
          "addButtonText": "Create an accessioning record",
          "formKey": "mlAccessioning",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabAccession.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "mlAccessioning_name",
                "mlAccessioning_profession",
                "mlAccessioning_day",
                "mlAccessioning_time"
              ]
            },
            {
              "elementKey": "cGroup50-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabAccession.cGroup50-2",
              "gIndex": "2",
              "gChildren": [
                "accessionID",
                "accRequisitionID",
                "accSpecimenType",
                "accTestPanel",
                "accTestOther",
                "preAnalytic",
                "postAnalytic",
                "collectionDate",
                "collectionTime",
                "collectionLocation"
              ]
            },
            {
              "elementKey": "cGroup50-3",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabAccession.cGroup50-3",
              "gIndex": "3",
              "gChildren": [
                "accComments"
              ]
            }
          ],
          "ehr_data": {
            "mlAccessioning_name": "",
            "mlAccessioning_profession": "",
            "mlAccessioning_day": "",
            "mlAccessioning_time": "",
            "accessionID": "",
            "accRequisitionID": "",
            "accSpecimenType": "",
            "accTestPanel": "",
            "accTestOther": "",
            "preAnalytic": "",
            "postAnalytic": "",
            "collectionDate": "",
            "collectionTime": "",
            "collectionLocation": "",
            "accComments": ""
          }
        },
        "fqn": "medLabAccession.mlAccessioning",
        "tableChildren": [
          "mlAccessioning_id",
          "mlAccessioning_day",
          "mlAccessioning_time",
          "accessionID",
          "accRequisitionID",
          "accSpecimenType",
          "accTestPanel",
          "accTestOther",
          "preAnalytic",
          "postAnalytic",
          "collectionDate",
          "collectionTime",
          "collectionLocation",
          "accComments",
          "mlAccessioning_name",
          "mlAccessioning_profession"
        ],
        "hasRecHeader": true
      },
      "mlChain": {
        "elementKey": "mlChain",
        "pageElementIndex": "2",
        "pageKey": "medLabAccession",
        "tableKey": "mlChain",
        "isTable": true,
        "form": {
          "elementKey": "mlChain",
          "label": "Chain of Custody",
          "addButtonText": "Create a chain of custody record",
          "formKey": "mlChain",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabAccession.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "mlChain_name",
                "mlChain_profession",
                "mlChain_day",
                "mlChain_time"
              ]
            },
            {
              "elementKey": "cGroup50-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabAccession.cGroup50-2",
              "gIndex": "2",
              "gChildren": [
                "chainAccessionID",
                "chainEvent",
                "chainDate",
                "chainTime",
                "chainDetails",
                "chainComments"
              ]
            }
          ],
          "ehr_data": {
            "mlChain_name": "",
            "mlChain_profession": "",
            "mlChain_day": "",
            "mlChain_time": "",
            "chainAccessionID": "",
            "chainEvent": "",
            "chainDate": "",
            "chainTime": "",
            "chainDetails": "",
            "chainComments": ""
          }
        },
        "fqn": "medLabAccession.mlChain",
        "tableChildren": [
          "mlChain_id",
          "mlChain_day",
          "mlChain_time",
          "chainAccessionID",
          "chainEvent",
          "chainDate",
          "chainTime",
          "chainDetails",
          "chainComments",
          "mlChain_name",
          "mlChain_profession"
        ],
        "hasRecHeader": true
      },
      "mlTestStatus": {
        "elementKey": "mlTestStatus",
        "pageElementIndex": "3",
        "pageKey": "medLabAccession",
        "tableKey": "mlTestStatus",
        "isTable": true,
        "form": {
          "elementKey": "mlTestStatus",
          "label": "Test Status",
          "addButtonText": "Create a test status record",
          "formKey": "mlTestStatus",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabAccession.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "mlTestStatus_name",
                "mlTestStatus_profession",
                "mlTestStatus_day",
                "mlTestStatus_time"
              ]
            },
            {
              "elementKey": "cGroup50-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabAccession.cGroup50-2",
              "gIndex": "2",
              "gChildren": [
                "testAccessionID",
                "testStatus",
                "testDate",
                "testTime",
                "testComments"
              ]
            }
          ],
          "ehr_data": {
            "mlTestStatus_name": "",
            "mlTestStatus_profession": "",
            "mlTestStatus_day": "",
            "mlTestStatus_time": "",
            "testAccessionID": "",
            "testStatus": "",
            "testDate": "",
            "testTime": "",
            "testComments": ""
          }
        },
        "fqn": "medLabAccession.mlTestStatus",
        "tableChildren": [
          "mlTestStatus_id",
          "mlTestStatus_day",
          "mlTestStatus_time",
          "testAccessionID",
          "testStatus",
          "testDate",
          "testTime",
          "testComments",
          "mlTestStatus_name",
          "mlTestStatus_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "specialIndicators": {
    "pageDataKey": "specialIndicators",
    "pIndex": "51",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "precautionList_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "specialIndicators.name"
      },
      {
        "elementKey": "precautionList_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "precautionList_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "specialIndicators.profession"
      },
      {
        "elementKey": "precautionList_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "specialIndicators.day"
      },
      {
        "elementKey": "precautionList_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "specialIndicators.time"
      },
      {
        "elementKey": "precautionIndicator",
        "formIndex": "1",
        "inputType": "select",
        "options": [
          {
            "key": "Airborne",
            "text": "Airborne Precautions"
          },
          {
            "key": "Contact",
            "text": "Contact Precautions"
          },
          {
            "key": "Droplet",
            "text": "Droplet Precautions"
          },
          {
            "key": "DC",
            "text": "Droplet/Contact Precautions"
          },
          {
            "key": "Routine",
            "text": "Routine Precautions"
          }
        ],
        "tableColumn": "2",
        "fqn": "specialIndicators.precautionIndicator"
      },
      {
        "elementKey": "precautionComment",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "specialIndicators.precautionComment"
      },
      {
        "elementKey": "riskList_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "specialIndicators.name"
      },
      {
        "elementKey": "riskList_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "riskList_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "specialIndicators.profession"
      },
      {
        "elementKey": "riskList_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "specialIndicators.day"
      },
      {
        "elementKey": "riskList_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "specialIndicators.time"
      },
      {
        "elementKey": "riskIndicator",
        "formIndex": "2",
        "inputType": "select",
        "options": [
          {
            "key": "AVB",
            "text": "AVB (Aggressive Violent Behaviour"
          },
          {
            "key": "ARO",
            "text": "ARO (Antibiotic Resistant Organism)"
          },
          {
            "key": "DNA",
            "text": "DNA (Do Not Acknowledge)"
          }
        ],
        "tableColumn": "2",
        "fqn": "specialIndicators.riskIndicator"
      },
      {
        "elementKey": "riskComment",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "specialIndicators.riskComment"
      }
    ],
    "pageElements": {
      "precautionList": {
        "elementKey": "precautionList",
        "pageElementIndex": "1",
        "pageKey": "specialIndicators",
        "tableKey": "precautionList",
        "isTable": true,
        "form": {
          "elementKey": "precautionList",
          "label": "Precautions",
          "addButtonText": "Add an precaution",
          "formKey": "precautionList",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "specialIndicators.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "precautionList_name",
                "precautionList_profession",
                "precautionList_day",
                "precautionList_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-2",
              "fqn": "specialIndicators.cGroup",
              "gIndex": "2",
              "gChildren": [
                "precautionIndicator",
                "precautionComment"
              ]
            }
          ],
          "ehr_data": {
            "precautionList_name": "",
            "precautionList_profession": "",
            "precautionList_day": "",
            "precautionList_time": "",
            "precautionIndicator": "",
            "precautionComment": ""
          }
        },
        "fqn": "specialIndicators.precautionList",
        "tableChildren": [
          "precautionList_id",
          "precautionList_day",
          "precautionList_time",
          "precautionIndicator",
          "precautionComment",
          "precautionList_name",
          "precautionList_profession"
        ],
        "hasRecHeader": true
      },
      "riskList": {
        "elementKey": "riskList",
        "pageElementIndex": "2",
        "pageKey": "specialIndicators",
        "tableKey": "riskList",
        "isTable": true,
        "form": {
          "elementKey": "riskList",
          "label": "Indicators",
          "addButtonText": "Add an indicator",
          "formKey": "riskList",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "specialIndicators.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "riskList_name",
                "riskList_profession",
                "riskList_day",
                "riskList_time"
              ]
            },
            {
              "elementKey": "cGroup",
              "formCss": "grid-left-to-right-2",
              "fqn": "specialIndicators.cGroup",
              "gIndex": "2",
              "gChildren": [
                "riskIndicator",
                "riskComment"
              ]
            }
          ],
          "ehr_data": {
            "riskList_name": "",
            "riskList_profession": "",
            "riskList_day": "",
            "riskList_time": "",
            "riskIndicator": "",
            "riskComment": ""
          }
        },
        "fqn": "specialIndicators.riskList",
        "tableChildren": [
          "riskList_id",
          "riskList_day",
          "riskList_time",
          "riskIndicator",
          "riskComment",
          "riskList_name",
          "riskList_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medLabPhoneLog": {
    "pageDataKey": "medLabPhoneLog",
    "pIndex": "52",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "phLogTable_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabPhoneLog.name"
      },
      {
        "elementKey": "phLogTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "phLogTable_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabPhoneLog.profession"
      },
      {
        "elementKey": "phLogTable_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabPhoneLog.day"
      },
      {
        "elementKey": "phLogTable_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabPhoneLog.time"
      },
      {
        "elementKey": "testAccessionID",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabPhoneLog.testAccessionID"
      },
      {
        "elementKey": "mlphRecipient",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabPhoneLog.mlphRecipient"
      },
      {
        "elementKey": "mlphReadBack",
        "formIndex": "1",
        "inputType": "checkbox",
        "tableColumn": "4",
        "fqn": "medLabPhoneLog.mlphReadBack"
      },
      {
        "elementKey": "mlplReason",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabPhoneLog.mlplReason"
      },
      {
        "elementKey": "accComments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "6",
        "fqn": "medLabPhoneLog.accComments"
      }
    ],
    "pageElements": {
      "phLogTable": {
        "elementKey": "phLogTable",
        "pageElementIndex": "1",
        "pageKey": "medLabPhoneLog",
        "tableKey": "phLogTable",
        "isTable": true,
        "form": {
          "elementKey": "phLogTable",
          "label": "Phone Log",
          "addButtonText": "Create a phone log",
          "formKey": "phLogTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabPhoneLog.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "phLogTable_name",
                "phLogTable_profession",
                "phLogTable_day",
                "phLogTable_time"
              ]
            },
            {
              "elementKey": "cGroup50-2",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabPhoneLog.cGroup50-2",
              "gIndex": "2",
              "gChildren": [
                "testAccessionID",
                "mlphRecipient",
                "mlphReadBack"
              ]
            },
            {
              "elementKey": "cGroup50-3",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabPhoneLog.cGroup50-3",
              "gIndex": "3",
              "gChildren": [
                "mlplReason",
                "accComments"
              ]
            }
          ],
          "ehr_data": {
            "phLogTable_name": "",
            "phLogTable_profession": "",
            "phLogTable_day": "",
            "phLogTable_time": "",
            "testAccessionID": "",
            "mlphRecipient": "",
            "mlphReadBack": "",
            "mlplReason": "",
            "accComments": ""
          }
        },
        "fqn": "medLabPhoneLog.phLogTable",
        "tableChildren": [
          "phLogTable_id",
          "phLogTable_day",
          "phLogTable_time",
          "testAccessionID",
          "mlphRecipient",
          "mlphReadBack",
          "mlplReason",
          "accComments",
          "phLogTable_name",
          "phLogTable_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "leveyJennings": {
    "pageDataKey": "leveyJennings",
    "pIndex": "53",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "tableLJ_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "leveyJennings.name"
      },
      {
        "elementKey": "tableLJ_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableLJ_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "leveyJennings.profession"
      },
      {
        "elementKey": "tableLJ_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "leveyJennings.day"
      },
      {
        "elementKey": "tableLJ_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "leveyJennings.time"
      },
      {
        "elementKey": "ljAnalyte",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "leveyJennings.ljAnalyte"
      },
      {
        "elementKey": "ljUseControl",
        "formIndex": "1",
        "inputType": "checkbox",
        "tableColumn": "3",
        "fqn": "leveyJennings.ljUseControl"
      },
      {
        "elementKey": "ljControl",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "4",
        "fqn": "leveyJennings.ljControl"
      },
      {
        "elementKey": "ljStdDev",
        "formIndex": "1",
        "inputType": "decimal2",
        "tableColumn": "5",
        "fqn": "leveyJennings.ljStdDev"
      },
      {
        "elementKey": "ljValue1",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "6",
        "fqn": "leveyJennings.ljValue1"
      },
      {
        "elementKey": "ljValue2",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "7",
        "fqn": "leveyJennings.ljValue2"
      },
      {
        "elementKey": "ljValue3",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "8",
        "fqn": "leveyJennings.ljValue3"
      },
      {
        "elementKey": "ljValue4",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "9",
        "fqn": "leveyJennings.ljValue4"
      },
      {
        "elementKey": "ljValue5",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "10",
        "fqn": "leveyJennings.ljValue5"
      },
      {
        "elementKey": "ljValue6",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "11",
        "fqn": "leveyJennings.ljValue6"
      },
      {
        "elementKey": "ljValue7",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "12",
        "fqn": "leveyJennings.ljValue7"
      },
      {
        "elementKey": "ljValue8",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "13",
        "fqn": "leveyJennings.ljValue8"
      },
      {
        "elementKey": "ljValue9",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "14",
        "fqn": "leveyJennings.ljValue9"
      },
      {
        "elementKey": "ljValue10",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "15",
        "fqn": "leveyJennings.ljValue10"
      },
      {
        "elementKey": "ljValue11",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "16",
        "fqn": "leveyJennings.ljValue11"
      },
      {
        "elementKey": "ljValue12",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "17",
        "fqn": "leveyJennings.ljValue12"
      },
      {
        "elementKey": "ljValue13",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "18",
        "fqn": "leveyJennings.ljValue13"
      },
      {
        "elementKey": "ljValue14",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "19",
        "fqn": "leveyJennings.ljValue14"
      },
      {
        "elementKey": "ljValue15",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "20",
        "fqn": "leveyJennings.ljValue15"
      },
      {
        "elementKey": "ljValue16",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "21",
        "fqn": "leveyJennings.ljValue16"
      },
      {
        "elementKey": "ljValue17",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "22",
        "fqn": "leveyJennings.ljValue17"
      },
      {
        "elementKey": "ljValue18",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "23",
        "fqn": "leveyJennings.ljValue18"
      },
      {
        "elementKey": "ljValue19",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "24",
        "fqn": "leveyJennings.ljValue19"
      },
      {
        "elementKey": "ljValue20",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "25",
        "fqn": "leveyJennings.ljValue20"
      },
      {
        "elementKey": "ljValue21",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "26",
        "fqn": "leveyJennings.ljValue21"
      },
      {
        "elementKey": "ljValue22",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "27",
        "fqn": "leveyJennings.ljValue22"
      },
      {
        "elementKey": "ljValue23",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "28",
        "fqn": "leveyJennings.ljValue23"
      },
      {
        "elementKey": "ljValue24",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "29",
        "fqn": "leveyJennings.ljValue24"
      },
      {
        "elementKey": "ljValue25",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "30",
        "fqn": "leveyJennings.ljValue25"
      },
      {
        "elementKey": "ljValue26",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "31",
        "fqn": "leveyJennings.ljValue26"
      },
      {
        "elementKey": "ljValue27",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "32",
        "fqn": "leveyJennings.ljValue27"
      },
      {
        "elementKey": "ljValue28",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "33",
        "fqn": "leveyJennings.ljValue28"
      },
      {
        "elementKey": "ljValue29",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "34",
        "fqn": "leveyJennings.ljValue29"
      },
      {
        "elementKey": "ljValue30",
        "formIndex": "1",
        "inputType": "number",
        "tableColumn": "35",
        "fqn": "leveyJennings.ljValue30"
      },
      {
        "elementKey": "ljcomments",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "textarea",
        "tableColumn": "36",
        "fqn": "leveyJennings.ljcomments"
      },
      {
        "elementKey": "lj_aux_data",
        "formIndex": "1",
        "formOption": "hideElement",
        "inputType": "aux_data",
        "fqn": "leveyJennings.lj_aux_data"
      },
      {
        "elementKey": "ljAuxEntryTable_name",
        "formIndex": "2",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "leveyJennings.name"
      },
      {
        "elementKey": "ljAuxEntryTable_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "ljAuxEntryTable_profession",
        "formIndex": "2",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "leveyJennings.profession"
      },
      {
        "elementKey": "ljAuxEntryTable_day",
        "formIndex": "2",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "leveyJennings.day"
      },
      {
        "elementKey": "ljAuxEntryTable_time",
        "formIndex": "2",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "leveyJennings.time"
      },
      {
        "elementKey": "ljAuxValue",
        "formIndex": "2",
        "inputType": "text",
        "tableColumn": "1",
        "fqn": "leveyJennings.ljAuxValue"
      }
    ],
    "pageElements": {
      "tableLJ": {
        "elementKey": "tableLJ",
        "pageElementIndex": "1",
        "pageKey": "leveyJennings",
        "tableKey": "tableLJ",
        "isTable": true,
        "auxTableRef": "ljAuxEntryTable",
        "form": {
          "elementKey": "tableLJ",
          "label": "Levey-Jennings",
          "addButtonText": "Add a Levey-Jennings Chart",
          "formOption": "openToEdit",
          "formKey": "tableLJ",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "leveyJennings.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableLJ_name",
                "tableLJ_profession",
                "tableLJ_day",
                "tableLJ_time"
              ]
            },
            {
              "elementKey": "ljGroup1",
              "formCss": "grid-left-to-right-3",
              "fqn": "leveyJennings.ljGroup1",
              "gIndex": "2",
              "gChildren": [
                "ljAnalyte",
                "ljUseControl",
                "ljControl",
                "ljStdDev",
                "ljValue1",
                "ljValue2",
                "ljValue3",
                "ljValue4",
                "ljValue5",
                "ljValue6",
                "ljValue7",
                "ljValue8",
                "ljValue9",
                "ljValue10",
                "ljValue11",
                "ljValue12",
                "ljValue13",
                "ljValue14",
                "ljValue15",
                "ljValue16",
                "ljValue17",
                "ljValue18",
                "ljValue19",
                "ljValue20",
                "ljValue21",
                "ljValue22",
                "ljValue23",
                "ljValue24",
                "ljValue25",
                "ljValue26",
                "ljValue27",
                "ljValue28",
                "ljValue29",
                "ljValue30",
                "ljcomments",
                "lj_aux_data"
              ]
            }
          ],
          "ehr_data": {
            "tableLJ_name": "",
            "tableLJ_profession": "",
            "tableLJ_day": "",
            "tableLJ_time": "",
            "ljAnalyte": "",
            "ljUseControl": "",
            "ljControl": "",
            "ljStdDev": "",
            "ljValue1": "",
            "ljValue2": "",
            "ljValue3": "",
            "ljValue4": "",
            "ljValue5": "",
            "ljValue6": "",
            "ljValue7": "",
            "ljValue8": "",
            "ljValue9": "",
            "ljValue10": "",
            "ljValue11": "",
            "ljValue12": "",
            "ljValue13": "",
            "ljValue14": "",
            "ljValue15": "",
            "ljValue16": "",
            "ljValue17": "",
            "ljValue18": "",
            "ljValue19": "",
            "ljValue20": "",
            "ljValue21": "",
            "ljValue22": "",
            "ljValue23": "",
            "ljValue24": "",
            "ljValue25": "",
            "ljValue26": "",
            "ljValue27": "",
            "ljValue28": "",
            "ljValue29": "",
            "ljValue30": "",
            "ljcomments": ""
          }
        },
        "fqn": "leveyJennings.tableLJ",
        "tableChildren": [
          "tableLJ_id",
          "tableLJ_day",
          "tableLJ_time",
          "ljAnalyte",
          "ljUseControl",
          "ljControl",
          "ljStdDev",
          "ljValue1",
          "ljValue2",
          "ljValue3",
          "ljValue4",
          "ljValue5",
          "ljValue6",
          "ljValue7",
          "ljValue8",
          "ljValue9",
          "ljValue10",
          "ljValue11",
          "ljValue12",
          "ljValue13",
          "ljValue14",
          "ljValue15",
          "ljValue16",
          "ljValue17",
          "ljValue18",
          "ljValue19",
          "ljValue20",
          "ljValue21",
          "ljValue22",
          "ljValue23",
          "ljValue24",
          "ljValue25",
          "ljValue26",
          "ljValue27",
          "ljValue28",
          "ljValue29",
          "ljValue30",
          "ljcomments",
          "tableLJ_name",
          "tableLJ_profession"
        ],
        "hasRecHeader": true
      },
      "ljAuxEntryTable": {
        "elementKey": "ljAuxEntryTable",
        "pageElementIndex": "2",
        "pageKey": "leveyJennings",
        "tableKey": "ljAuxEntryTable",
        "mainTableKey": "tableLJ",
        "isTable": true,
        "isAuxTable": true,
        "form": {
          "elementKey": "ljAuxEntryTable",
          "label": "LJvalue",
          "addButtonText": "Add LJ value",
          "formKey": "ljAuxEntryTable",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "leveyJennings.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "ljAuxEntryTable_name",
                "ljAuxEntryTable_profession",
                "ljAuxEntryTable_day",
                "ljAuxEntryTable_time"
              ]
            },
            {
              "elementKey": "auxljgroup",
              "formCss": "grid-left-to-right-1",
              "fqn": "leveyJennings.auxljgroup",
              "gIndex": "2",
              "gChildren": [
                "ljAuxValue"
              ]
            }
          ],
          "ehr_data": {
            "ljAuxEntryTable_name": "",
            "ljAuxEntryTable_profession": "",
            "ljAuxEntryTable_day": "",
            "ljAuxEntryTable_time": "",
            "ljAuxValue": ""
          }
        },
        "fqn": "leveyJennings.tableLJ.ljAuxEntryTable",
        "tableChildren": [
          "ljAuxEntryTable_id",
          "ljAuxEntryTable_day",
          "ljAuxEntryTable_time",
          "ljAuxValue",
          "ljAuxEntryTable_name",
          "ljAuxEntryTable_profession"
        ],
        "hasRecHeader": true
      }
    }
  },
  "medLabBody": {
    "pageDataKey": "medLabBody",
    "pIndex": "54",
    "hasGridTable": true,
    "pageChildren": [
      {
        "elementKey": "tableLabBody_name",
        "formIndex": "1",
        "inputType": "practitionerName",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabBody.name"
      },
      {
        "elementKey": "tableLabBody_id",
        "inputType": "generatedId",
        "tableColumn": 0,
        "label": "Row id",
        "tableCss": "row-id"
      },
      {
        "elementKey": "tableLabBody_profession",
        "formIndex": "1",
        "inputType": "practitionerProfession",
        "tableColumn": "1",
        "recHeader": true,
        "fqn": "medLabBody.profession"
      },
      {
        "elementKey": "tableLabBody_day",
        "formIndex": "1",
        "inputType": "visitDay",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "visitDay",
        "recHeader": true,
        "fqn": "medLabBody.day"
      },
      {
        "elementKey": "tableLabBody_time",
        "formIndex": "1",
        "inputType": "visitTime",
        "mandatory": true,
        "tableColumn": "1",
        "validation": "time24",
        "recHeader": true,
        "fqn": "medLabBody.time"
      },
      {
        "elementKey": "bdyAccession",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "2",
        "fqn": "medLabBody.bdyAccession"
      },
      {
        "elementKey": "bdyCollDay",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "3",
        "fqn": "medLabBody.bdyCollDay"
      },
      {
        "elementKey": "bdyCollTime",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "4",
        "fqn": "medLabBody.bdyCollTime"
      },
      {
        "elementKey": "bdyOrderPhys",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "5",
        "fqn": "medLabBody.bdyOrderPhys"
      },
      {
        "elementKey": "bdyLabel1",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel1"
      },
      {
        "elementKey": "bdyLabel2",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel2"
      },
      {
        "elementKey": "bdyLabel3",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel3"
      },
      {
        "elementKey": "bdyWBCLabel",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabBody.bdyWBCLabel"
      },
      {
        "elementKey": "bdyWBC",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "6",
        "fqn": "medLabBody.bdyWBC",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyWBCRefInt",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "7",
        "fqn": "medLabBody.bdyWBCRefInt"
      },
      {
        "elementKey": "bdyRBCLabel",
        "formIndex": "1",
        "inputType": "form_label",
        "fqn": "medLabBody.bdyRBCLabel"
      },
      {
        "elementKey": "bdyRBC",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "8",
        "fqn": "medLabBody.bdyRBC",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyRBCRefInt",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "9",
        "fqn": "medLabBody.bdyRBCRefInt"
      },
      {
        "elementKey": "bdyType",
        "formIndex": "1",
        "formCss": "grid-span-3-columns",
        "inputType": "checkset",
        "options": [
          {
            "key": "blood",
            "text": "Blood"
          },
          {
            "key": "csfl",
            "text": "Cerebrospinal Fluid (CSF)"
          },
          {
            "key": "syno",
            "text": "Synovial Fluid"
          },
          {
            "key": "pleu",
            "text": "Pleural Fluid"
          },
          {
            "key": "periton",
            "text": "Peritoneal Fluid"
          },
          {
            "key": "pericar",
            "text": "Pericardial Fluid"
          },
          {
            "key": "sptm",
            "text": "Sputum"
          },
          {
            "key": "smen",
            "text": "Semen"
          },
          {
            "key": "vag",
            "text": "Vaginal Fluid"
          },
          {
            "key": "amniotic",
            "text": "Amniotic Fluid"
          },
          {
            "key": "saliva",
            "text": "Saliva"
          },
          {
            "key": "wdext",
            "text": "Wound Exudate"
          },
          {
            "key": "dialy",
            "text": "Dialysis Fluid"
          },
          {
            "key": "cvswb",
            "text": "Cervical/Vaginal Swabs"
          },
          {
            "key": "npdsc",
            "text": "Nipple Discharge"
          },
          {
            "key": "saspi",
            "text": "Salivary Gland Aspirate"
          }
        ],
        "tableColumn": "10",
        "fqn": "medLabBody.bdyType"
      },
      {
        "elementKey": "bdyColour",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "clrless",
            "text": "Colorless"
          },
          {
            "key": "red",
            "text": "Red"
          },
          {
            "key": "xanth",
            "text": "Xanthochromic"
          },
          {
            "key": "sxanth",
            "text": "Slightly Xanthochromic"
          }
        ],
        "tableColumn": "11",
        "fqn": "medLabBody.bdyColour"
      },
      {
        "elementKey": "bdyClarity",
        "formIndex": "1",
        "inputType": "checkset",
        "options": [
          {
            "key": "clear",
            "text": "Clear"
          },
          {
            "key": "hazy",
            "text": "Hazy"
          },
          {
            "key": "cloudy",
            "text": "Cloudy"
          },
          {
            "key": "turbid",
            "text": "Turbid"
          },
          {
            "key": "bloody",
            "text": "Bloody"
          }
        ],
        "tableColumn": "12",
        "fqn": "medLabBody.bdyClarity"
      },
      {
        "elementKey": "bdyVolume",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "mL",
        "tableColumn": "13",
        "fqn": "medLabBody.bdyVolume",
        "suffixText": "mL"
      },
      {
        "elementKey": "bdyLabel5",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel5"
      },
      {
        "elementKey": "bdyLabel6",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel6"
      },
      {
        "elementKey": "bdyLabel7",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel7"
      },
      {
        "elementKey": "bdyLabel8",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel8"
      },
      {
        "elementKey": "bdyLabel9",
        "formIndex": "1",
        "inputType": "formTableHeader",
        "fqn": "medLabBody.bdyLabel9"
      },
      {
        "elementKey": "bdyLabel10",
        "formIndex": "1",
        "inputType": "formTableText",
        "fqn": "medLabBody.bdyLabel10"
      },
      {
        "elementKey": "bdyNeutrophilsrel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "14",
        "fqn": "medLabBody.bdyNeutrophilsrel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyNeutrophilsabs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "15",
        "fqn": "medLabBody.bdyNeutrophilsabs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyNeutrophilsrelref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "16",
        "fqn": "medLabBody.bdyNeutrophilsrelref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyNeutrophilsabsref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "17",
        "fqn": "medLabBody.bdyNeutrophilsabsref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyLabel11",
        "formIndex": "1",
        "inputType": "formTableText",
        "fqn": "medLabBody.bdyLabel11"
      },
      {
        "elementKey": "bdyLymphocytesrel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "18",
        "fqn": "medLabBody.bdyLymphocytesrel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyLymphocytesabs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "19",
        "fqn": "medLabBody.bdyLymphocytesabs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyLymphocytesrelref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "20",
        "fqn": "medLabBody.bdyLymphocytesrelref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyLymphocytesabsref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "21",
        "fqn": "medLabBody.bdyLymphocytesabsref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyLabel12",
        "formIndex": "1",
        "inputType": "formTableText",
        "fqn": "medLabBody.bdyLabel12"
      },
      {
        "elementKey": "bdyMonocytesrel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "22",
        "fqn": "medLabBody.bdyMonocytesrel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyMonocytesabs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "23",
        "fqn": "medLabBody.bdyMonocytesabs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyMonocytesrelref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "24",
        "fqn": "medLabBody.bdyMonocytesrelref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyMonocytesabsref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "25",
        "fqn": "medLabBody.bdyMonocytesabsref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyLabel13",
        "formIndex": "1",
        "inputType": "formTableText",
        "fqn": "medLabBody.bdyLabel13"
      },
      {
        "elementKey": "bdyMacrophagerel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "26",
        "fqn": "medLabBody.bdyMacrophagerel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyMacrophageabs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "27",
        "fqn": "medLabBody.bdyMacrophageabs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyMacrophagerelref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "28",
        "fqn": "medLabBody.bdyMacrophagerelref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyMacrophageabsref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "29",
        "fqn": "medLabBody.bdyMacrophageabsref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyOther1label",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "30",
        "fqn": "medLabBody.bdyOther1label"
      },
      {
        "elementKey": "bdyOther1rel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "31",
        "fqn": "medLabBody.bdyOther1rel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyOther1abs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "32",
        "fqn": "medLabBody.bdyOther1abs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyOther1relref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "33",
        "fqn": "medLabBody.bdyOther1relref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyOther1absref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "34",
        "fqn": "medLabBody.bdyOther1absref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyOther2label",
        "formIndex": "1",
        "inputType": "text",
        "tableColumn": "35",
        "fqn": "medLabBody.bdyOther2label"
      },
      {
        "elementKey": "bdyOther2rel",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "36",
        "fqn": "medLabBody.bdyOther2rel",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyOther2abs",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "37",
        "fqn": "medLabBody.bdyOther2abs",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyOther2relref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "%",
        "tableColumn": "38",
        "fqn": "medLabBody.bdyOther2relref",
        "suffixText": "%"
      },
      {
        "elementKey": "bdyOther2absref",
        "formIndex": "1",
        "inputType": "text",
        "suffix": "10^6/L",
        "tableColumn": "39",
        "fqn": "medLabBody.bdyOther2absref",
        "suffixText": "10^6/L"
      },
      {
        "elementKey": "bdyComments",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "40",
        "fqn": "medLabBody.bdyComments"
      },
      {
        "elementKey": "bdyInternal",
        "formIndex": "1",
        "inputType": "textarea",
        "tableColumn": "41",
        "fqn": "medLabBody.bdyInternal"
      }
    ],
    "pageElements": {
      "tableLabBody": {
        "elementKey": "tableLabBody",
        "pageElementIndex": "1",
        "pageKey": "medLabBody",
        "tableKey": "tableLabBody",
        "isTable": true,
        "form": {
          "elementKey": "tableLabBody",
          "label": "Body Fluids",
          "addButtonText": "Add a Bodily Fluid Report",
          "formKey": "tableLabBody",
          "ehr_groups": [
            {
              "elementKey": "hdrGroup",
              "formCss": "record-header",
              "fqn": "medLabBody.hdrGroup",
              "gIndex": "1",
              "gChildren": [
                "tableLabBody_name",
                "tableLabBody_profession",
                "tableLabBody_day",
                "tableLabBody_time"
              ]
            },
            {
              "elementKey": "bdyGroup1",
              "formCss": "grid-left-to-right-4",
              "fqn": "medLabBody.bdyGroup1",
              "gIndex": "2",
              "gChildren": [
                "bdyAccession",
                "bdyCollDay",
                "bdyCollTime",
                "bdyOrderPhys"
              ]
            },
            {
              "elementKey": "bdyGroup2",
              "formCss": "body-main-table form-table-group",
              "fqn": "medLabBody.bdyGroup2",
              "gIndex": "3",
              "gChildren": [
                "bdyLabel1",
                "bdyLabel2",
                "bdyLabel3",
                "bdyWBCLabel",
                "bdyWBC",
                "bdyWBCRefInt",
                "bdyRBCLabel",
                "bdyRBC",
                "bdyRBCRefInt"
              ]
            },
            {
              "elementKey": "bdyGroup3",
              "formCss": "grid-left-to-right-3",
              "fqn": "medLabBody.bdyGroup3",
              "gIndex": "4",
              "gChildren": [
                "bdyType",
                "bdyColour",
                "bdyClarity",
                "bdyVolume"
              ]
            },
            {
              "elementKey": "bdyGroup4",
              "formCss": "body-diff-table form-table-group",
              "fqn": "medLabBody.bdyGroup4",
              "gIndex": "5",
              "gChildren": [
                "bdyLabel5",
                "bdyLabel6",
                "bdyLabel7",
                "bdyLabel8",
                "bdyLabel9",
                "bdyLabel10",
                "bdyNeutrophilsrel",
                "bdyNeutrophilsabs",
                "bdyNeutrophilsrelref",
                "bdyNeutrophilsabsref",
                "bdyLabel11",
                "bdyLymphocytesrel",
                "bdyLymphocytesabs",
                "bdyLymphocytesrelref",
                "bdyLymphocytesabsref",
                "bdyLabel12",
                "bdyMonocytesrel",
                "bdyMonocytesabs",
                "bdyMonocytesrelref",
                "bdyMonocytesabsref",
                "bdyLabel13",
                "bdyMacrophagerel",
                "bdyMacrophageabs",
                "bdyMacrophagerelref",
                "bdyMacrophageabsref",
                "bdyOther1label",
                "bdyOther1rel",
                "bdyOther1abs",
                "bdyOther1relref",
                "bdyOther1absref",
                "bdyOther2label",
                "bdyOther2rel",
                "bdyOther2abs",
                "bdyOther2relref",
                "bdyOther2absref"
              ]
            },
            {
              "elementKey": "bdyGroup5",
              "formCss": "grid-left-to-right-1",
              "fqn": "medLabBody.bdyGroup5",
              "gIndex": "6",
              "gChildren": [
                "bdyComments",
                "bdyInternal"
              ]
            }
          ],
          "ehr_data": {
            "tableLabBody_name": "",
            "tableLabBody_profession": "",
            "tableLabBody_day": "",
            "tableLabBody_time": "",
            "bdyAccession": "",
            "bdyCollDay": "",
            "bdyCollTime": "",
            "bdyOrderPhys": "",
            "bdyWBC": "",
            "bdyWBCRefInt": "",
            "bdyRBC": "",
            "bdyRBCRefInt": "",
            "bdyType": "",
            "bdyColour": "",
            "bdyClarity": "",
            "bdyVolume": "",
            "bdyNeutrophilsrel": "",
            "bdyNeutrophilsabs": "",
            "bdyNeutrophilsrelref": "",
            "bdyNeutrophilsabsref": "",
            "bdyLymphocytesrel": "",
            "bdyLymphocytesabs": "",
            "bdyLymphocytesrelref": "",
            "bdyLymphocytesabsref": "",
            "bdyMonocytesrel": "",
            "bdyMonocytesabs": "",
            "bdyMonocytesrelref": "",
            "bdyMonocytesabsref": "",
            "bdyMacrophagerel": "",
            "bdyMacrophageabs": "",
            "bdyMacrophagerelref": "",
            "bdyMacrophageabsref": "",
            "bdyOther1label": "",
            "bdyOther1rel": "",
            "bdyOther1abs": "",
            "bdyOther1relref": "",
            "bdyOther1absref": "",
            "bdyOther2label": "",
            "bdyOther2rel": "",
            "bdyOther2abs": "",
            "bdyOther2relref": "",
            "bdyOther2absref": "",
            "bdyComments": "",
            "bdyInternal": ""
          }
        },
        "fqn": "medLabBody.tableLabBody",
        "tableChildren": [
          "tableLabBody_id",
          "tableLabBody_day",
          "tableLabBody_time",
          "bdyAccession",
          "bdyCollDay",
          "bdyCollTime",
          "bdyOrderPhys",
          "bdyWBC",
          "bdyWBCRefInt",
          "bdyRBC",
          "bdyRBCRefInt",
          "bdyType",
          "bdyColour",
          "bdyClarity",
          "bdyVolume",
          "bdyNeutrophilsrel",
          "bdyNeutrophilsabs",
          "bdyNeutrophilsrelref",
          "bdyNeutrophilsabsref",
          "bdyLymphocytesrel",
          "bdyLymphocytesabs",
          "bdyLymphocytesrelref",
          "bdyLymphocytesabsref",
          "bdyMonocytesrel",
          "bdyMonocytesabs",
          "bdyMonocytesrelref",
          "bdyMonocytesabsref",
          "bdyMacrophagerel",
          "bdyMacrophageabs",
          "bdyMacrophagerelref",
          "bdyMacrophageabsref",
          "bdyOther1label",
          "bdyOther1rel",
          "bdyOther1abs",
          "bdyOther1relref",
          "bdyOther1absref",
          "bdyOther2label",
          "bdyOther2rel",
          "bdyOther2abs",
          "bdyOther2relref",
          "bdyOther2absref",
          "bdyComments",
          "bdyInternal",
          "tableLabBody_name",
          "tableLabBody_profession"
        ],
        "hasRecHeader": true
      }
    }
  }
}
export default DEFS